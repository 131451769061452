import React from 'react';
import { useNavigate } from 'react-router-dom';

//Material
import { Grid, Tooltip, Typography } from '@mui/material';

import { Stack } from '@mui/system';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import StarBorderOutlinedIcon from '@mui/icons-material/StarBorderOutlined';
import DateRangeIcon from '@mui/icons-material/DateRange';
import NewspaperOutlinedIcon from '@mui/icons-material/NewspaperOutlined';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import ShoppingBagOutlinedIcon from '@mui/icons-material/ShoppingBagOutlined';

import { connect, useDispatch } from 'react-redux';

import { base } from '../redux/thunk';

import ApiService from '../helpers/apiHelper';
import { timeDiffNotif } from '../helpers/date-helpers';
import { setIsNewNotification } from '../redux/mainReducer';
// import ApiService from '../helpers/apiHelper';

function Notification({ deleteFunc, closeFunc, index, el, language, userData }) {
  const apiNotificationDelete = new ApiService('notification/notification');
  // const apiNotificationDelete = new ApiService('/notification');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const notificationClick = (type) => {
    apiNotificationDelete
      .deleteBody([el?.id])
      .then(async (res) => {
        const isNotification = await fetch(
          // `${window.config.REACT_APP_API_HOST_3}/notification/has-new-notification?userId=${userData.id}`,
          // {
            `${window.location.protocol}//${window.location.hostname}/v1/notification/notification/has-new-notification?userId=${userData?.id}`,
            {
            method: 'GET',
            // eslint-disable-next-line
            headers: {
              'Content-Type': 'application/json',
              Authorization: localStorage.getItem('accessToken')
            }
          }
        );
        const isNotificationTrue = await isNotification.json();
        if (!isNotificationTrue) {
          dispatch(setIsNewNotification(false));
        } else {
          dispatch(setIsNewNotification(true));
        }
        if (
          type === 'LIKE' ||
          type === 'LIKE_COMMENT' ||
          type === 'COMMENT' ||
          type === 'RESPONSE_COMMENT' ||
          type === 'NEW_POST'
        ) {
          navigate(`/${language}/post/${el?.postId}`);
          closeFunc();
        } else if (type === 'FEEDBACK' || type === 'NEW_SERVICE') {
          navigate(`/${language}/service/${el?.serviceId}`);
          closeFunc();
        } else if (type === 'SUBSCRIPTION') {
          navigate(`/${language}/user-page/${el?.initiatorId}?type=posts`);
          closeFunc();
        } else if (type === 'NEW_ORDER') {
          navigate(`/${language}/scheduler?tab=1`);
          closeFunc();
        } else {
          navigate(`/${language}/scheduler?tab=2`);
          closeFunc();
        }
      })
      .catch((error) => console.error(error));
  };
  return (
    <Grid
      key={`notification-${index}`}
      container
      onClick={(event) => {
        event.preventDefault();
        event.stopPropagation();
        notificationClick(el?.type);
      }}
      sx={{
        padding: '10px 0px',
        justifyContent: 'space-between',
        borderTop: index === 0 ? '1px solid #60a5df' : 'none',
        borderBottom: '1px solid #60a5df',
        minWidth: '350px',
        minHeight: '70px',
        cursor: 'pointer',
        ':hover': {
          backgroundColor: '#deecf8ad'
        }
      }}
    >
      <Grid item xs={1.2} sx={{ display: 'flex', alignItems: 'center' }}>
        {el?.type === 'FEEDBACK' ? (
          <StarBorderOutlinedIcon sx={{ color: '#60a5df', fontSize: '25px' }} />
        ) : null}
        {el?.type === 'NEW_POST' ? (
          <NewspaperOutlinedIcon sx={{ color: '#60a5df', fontSize: '25px' }} />
        ) : null}
        {el?.type === 'NEW_SERVICE' ? (
          <ShoppingBagOutlinedIcon sx={{ color: '#60a5df', fontSize: '25px' }} />
        ) : null}
        {el?.type === 'SUBSCRIPTION' ? (
          <PermIdentityIcon sx={{ color: '#60a5df', fontSize: '25px' }} />
        ) : null}
        {el?.type === 'LIKE' || el?.type === 'LIKE_COMMENT' ? (
          <FavoriteBorderOutlinedIcon sx={{ color: '#60a5df', fontSize: '25px' }} />
        ) : null}
        {el?.type === 'COMMENT' || el?.type === 'RESPONSE_COMMENT' ? (
          <ChatBubbleOutlineIcon sx={{ color: '#60a5df', fontSize: '25px' }} />
        ) : null}

        {el?.type === 'NEW_ORDER' || el?.type === 'CHANGE_STATUS_ORDER' ? (
          <DateRangeIcon sx={{ color: '#60a5df', fontSize: '25px' }} />
        ) : null}
      </Grid>
      <Grid item xs={9.8} sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography
          sx={{
            fontSize: '16px',
            textAlign: 'left',
            position: 'relative'
          }}
        >
          {el?.type === 'LIKE' && `Нова вподобайка до вашого посту від @${el?.initiatorNickname}`}
          {el?.type === 'LIKE_COMMENT' &&
            `Користувач @${el?.initiatorNickname} вподобав ваш коментар`}
          {el?.type === 'FEEDBACK' &&
            `Користувач @${el?.initiatorNickname} додав новий відгук до вашої послуги`}
          {el?.type === 'SUBSCRIPTION' && `У вас новий підписник: @${el?.initiatorNickname}`}
          {el?.type === 'COMMENT' && `Користувач @${el?.initiatorNickname} прокоментував ваш пост`}
          {el?.type === 'RESPONSE_COMMENT' &&
            `Користувач @${el?.initiatorNickname} відповів на ваш коментар`}
          {el?.type === 'NEW_SERVICE' && `Користувач @${el?.initiatorNickname} додав нову послугу`}
          {el?.type === 'NEW_POST' &&
            `Користувач @${el?.initiatorNickname}  запостив щось новеньке`}
          {el?.type === 'NEW_ORDER' && `Вам надійшло нове замовлення`}
          {el?.type === 'CHANGE_STATUS_ORDER' &&
            `Ваше замовлення №${el?.orderNumber} змінило статус`}
        </Typography>
      </Grid>
      <Grid item xs={1} sx={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
        <Stack sx={{ alignItems: 'end' }}>
          <Tooltip title="Видалити сповіщення" placement="top">
            <ClearOutlinedIcon
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
                deleteFunc('item', el?.id);
              }}
              sx={{
                color: '#60a5df',
                cursor: 'pointer',
                fontSize: '22px',
                mb: 1,
                mr: 0.1,
                zIndex: 99
              }}
            />
          </Tooltip>

          <Typography
            sx={{ fontSize: '12px', fontFamily: 'Kyiv-Sans-Regular', color: 'A1A1A1', mr: 0.5, }}
          >
            {timeDiffNotif(el?.creationTime)}
          </Typography>
        </Stack>
      </Grid>
    </Grid>
  );
}
const mapStateToProps = (state) => {
  return {
    language: state.Intl.locale,
    userData: state.global.userData,
    subscriptions: state.global.userSubscriptions,
    blockedUsers: state.global.blockedUsers
  };
};
export default connect(mapStateToProps, {
  getUserSubscriptionsThunk: base.getUserSubscriptionsThunk
})(Notification);
