import React, { useEffect, useState } from 'react';
import Page from '../../components/Page';

//Material
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Avatar, Box, Grid, Menu, MenuItem, Skeleton, Stack, Typography } from '@mui/material';
// import { useNavigate, useSearchParams } from 'react-router-dom';
import { connect, useDispatch, useSelector } from 'react-redux';
import basePhoto from '../../assets/DefaultIMG.png';
import { LetsGoBtn2 } from '../../components/Buttons';
import ServiceCard from '../../components/ServiceCard';
import { Spinner } from '../../components/Spinner';
// import { StyledTitle } from '../../components/StyledComponents';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import BookmarkBorderOutlinedIcon from '@mui/icons-material/BookmarkBorderOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import GridViewIcon from '@mui/icons-material/GridView';
import LinkIcon from '@mui/icons-material/Link';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PieChartOutlineOutlinedIcon from '@mui/icons-material/PieChartOutlineOutlined';
// import ReplyIcon from '@mui/icons-material/Reply';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import { useNavigate, useParams } from 'react-router-dom';
import CourierDeliveryLogo from '../../assets/courierdelivery.png';
import NovaPoshtaLogo from '../../assets/novaposhta.png';
import UkrPoshtaLogo from '../../assets/ukrposhta.png';
import ApiService from '../../helpers/apiHelper';

import DefaultAvatar from '../../assets/defaultPostImg.jpg';

import ImageGallery from 'react-image-gallery';
import Slider from 'react-slick';
import { LetsGoBtn3 } from '../../components/Buttons';
import RespondCard from '../../components/RespondCard';
import RespondForm from '../../components/RespondForm';
import { StyledTitle } from '../../components/StyledComponents';
import { dateFormattingByDate } from '../../helpers/date-helpers';
import {
  setIsOpenComplainModal,
  setIsOpenResendingModal,
  setIsOpenServiceModal,
  setUserSavedServices
} from '../../redux/mainReducer';
import ServiceModal from '../modals/ServiceModal';
import './index.css';

import { useSnackbar } from 'notistack';
import '../MainPage/index.css';
// import UserContactsModal from '../modals/UserContactsModal';

// const CssTextField = styled(TextField)({
//   '& label.Mui-focused': {
//     color: '#000000'
//   },
//   '& label': {
//     color: '#A1A1A1',
//     fontFamily: 'Kyiv-Sans-Light'
//   },
//   // '& .MuiInput-root.Mui-focused:after': {
//   //   borderBottom: '2px #000000 solid'
//   // },
//   // '& .MuiInput-root:hover:not(.Mui-disabled):before': {
//   //   borderBottom: '2px #000000 solid'
//   // },
//   // '& .MuiInput-root:not(.Mui-error):before': {
//   //   borderBottom: '2px #000000 solid'
//   // },
//   margin: '5px 0px'
// });

function ServicePage({ userData, language, blockedUsers, ...props }) {
  // const translate = useTranslate();
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [photos, setPhotos] = useState([]);
  const [isLoadingPhotos, setIsLoadingPhotos] = useState(false);
  const [data, setData] = useState(null);
  const [feedbacks, setFeedbacks] = useState([]);
  const [feedbacksQuery, setFeedbacksQuery] = useState({ page: 0, size: 4 });
  const [feedbacksTotalPages, setFeedbacksTotalPages] = useState(0);
  const [additionalGoods, setAdditionalGoods] = useState([]);
  const [isOpenFeedbackForm, setIsOpenFeedbackForm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const isOpenServiceModal = useSelector((state) => state.global.isOpenServiceModal);
  const userSavedServices = useSelector((state) => state.global.userSavedServices);

  const [anchorEl, setAnchorEl] = useState(null);

  // const isOpenUserContactsModal = useSelector((state) => state.global.isOpenUserContactsModal);

  const apiSavedService = new ApiService('posts/saved-service');
  // const apiSavedService = new ApiService('saved-service');
  const apiPhoto = new ApiService('photo/get-photo');
  // const apiPhoto = new ApiService('get-photo');

  const apiServicesSearch = new ApiService('posts/service/anotherUserServices');
  const apiServices = new ApiService('posts/service');
  const apiFeedback = new ApiService('posts/feedback');
  const apiComplaint = new ApiService('posts/service/complaint-service');

  // const apiServicesSearch = new ApiService('service/anotherUserServices');
  // const apiServices = new ApiService('service');
  // const apiFeedback = new ApiService('feedback');
  // const apiComplaint = new ApiService('service/complaint-service');

  const sendComplaint = (type, text) => {
    apiComplaint
      .updateDataID(data?.id, {
        complaintType: type,
        complaintDescription: text.length !== 0 ? text : null
      })
      .then((res) => {
        dispatch(setIsOpenComplainModal({ isOpen: false, func: null }));
        enqueueSnackbar('Скаргу надіслано.', { variant: 'success' });
      })
      .catch((error) => {
        console.error(error);
        enqueueSnackbar('Сталась помилка.', { variant: 'error' });
      });
  };
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const dataSetter = () => {
    setIsLoading(true);
    apiServices
      .getItemById(params.id)
      .then((data) => {
        setIsLoading(false);
        apiFeedback
          .getItemById(`get-feedback-for-service/${params.id}`, { page: 0, size: 4 })
          .then((feedbacks) => {
            apiServicesSearch
              .getItemById(data.result.userDTO.id, {
                unnecessaryServiceId: params.id,
                size: 9,
                page: 0
              })
              .then((services) => {
                setData(data.result);
                setAdditionalGoods(services.result);
              });
            setFeedbacks(feedbacks.result);
            setFeedbacksTotalPages(feedbacks?.total);
          });
      })
      .catch((error) => {
        if (error.message.includes(`Service with postId [${params.id}] not found`)) {
          navigate(`/${language}/error`);
        }
        if (error.message.includes(`Can't get service post`)) {
          navigate(`/${language}/error`);
        }
        console.error(error.message);
      });
  };
  const feedbackRefresh = () => {
    apiFeedback
      .getItemById(`get-feedback-for-service/${params.id}`, {
        page: 0,
        size: (feedbacksQuery?.page + 1) * 4
      })
      .then((res) => {
        setFeedbacks(res.result);
        setFeedbacksTotalPages(res?.total);
      });
  };
  const feedbackSetter = (query) => {
    apiFeedback.getItemById(`get-feedback-for-service/${params.id}`, query).then((res) => {
      const newFeedbacks = [...feedbacks, ...res.result];
      setFeedbacks(newFeedbacks);
      setFeedbacksQuery({
        ...feedbacksQuery,
        page: feedbacksQuery.page + 1
      });
      setFeedbacksTotalPages(res?.total);
    });
  };
  useEffect(() => {
    if (params?.id) {
      dataSetter();
    }
    return () => {
      setFeedbacks([]);
      setFeedbacksQuery({ page: 0, size: 4 });
      setData(null);
      setAdditionalGoods([]);
    };
    // eslint-disable-next-line
  }, [params.id]);

  const settings = {
    autoplay: false,
    dots: true,
    infinite: additionalGoods.length < 3 ? false : true,
    speed: 2000,
    slidesToShow: 3,
    slidesToScroll: 3,
    arrows: true

    // beforeChange: (current, next) => setActiveSlide(next)a
  };
  useEffect(() => {
    let mounted = true;
    const getPhotos = async () => {
      const newPhotos = await Promise.all(
        data?.encodedPhotos.map((photo) =>
          apiPhoto
            .getItemById(photo)
            .then((res) => {
              return res.result;
            })
            .catch((error) => console.error(error))
        )
      ).then((res) => {
        setIsLoadingPhotos(false);
        return res;
      });
      setPhotos(newPhotos);
    };
    if (data?.encodedPhotos && data?.encodedPhotos.length !== 0 && mounted) {
      setIsLoadingPhotos(true);
      getPhotos();
    } else {
      setPhotos([]);
    }
    return () => (mounted = false);
    // eslint-disable-next-line
  }, [data]);
  return (
    <Page title={data && data.nameService}>
      {/* <UserContactsModal
        isSendDataRequest={isSendDataRequest}
        setIsSendDataRequest={setIsSendDataRequest}
        sendingData={{ type: 'service', id: params.id }}
        nickname={userData.nickname}
        isOpenModal={isOpenUserContactsModal}
        data={props.subscriptions}
      /> */}
      {data !== null && !isLoading ? (
        <Grid
          container
          spacing={2}
          style={{
            maxWidth: '1440px',
            padding: '0px 100px',
            marginTop: '100px'
          }}
        >
          {isOpenServiceModal && (
            <ServiceModal setter={dataSetter} isOpenModal={isOpenServiceModal} data={data} />
          )}
          {/* <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
            <Typography
              variant="subtitle"
              sx={{
                display: 'inline-flex',
                cursor: 'pointer',
                color: 'black',
                ':hover': { color: '#60A5DF' }
              }}
              onClick={() => navigate(-1)}
            >
              <ReplyIcon sx={{ mr: 1, color: 'black' }} />
              Повернутись на попередню сторінку
            </Typography>
          </Grid> */}
          <Grid
            item
            xs={5}
            sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'start' }}
          >
            {photos && photos.length !== 0 && !isLoadingPhotos ? (
              <ImageGallery
                style={{ marginTop: 0, maxWidth: '300px !important' }}
                showPlayButton={false}
                thumbnailPosition="left"
                showBullets={false}
                showFullscreenButton={false}
                renderLeftNav={(onClick) => (
                  <ArrowBackIosIcon
                    sx={{
                      position: 'absolute',
                      top: 'calc(50% - 15px)',
                      zIndex: 99,
                      color: 'white',
                      left: '5px',
                      fontSize: '30px',
                      cursor: 'pointer',
                      ':hover': {
                        color: '#60A5DF',
                        fontSize: '32px'
                      },
                      filter: 'drop-shadow(0 2px 2px #1a1a1a)'
                    }}
                    onClick={onClick}
                  />
                )}
                renderRightNav={(onClick) => (
                  <ArrowForwardIosIcon
                    sx={{
                      position: 'absolute',
                      top: 'calc(50% - 15px)',

                      zIndex: 99,
                      color: 'white',
                      right: '0px',

                      fontSize: '30px',
                      cursor: 'pointer',
                      ':hover': {
                        color: '#60A5DF',
                        fontSize: '32px'
                      },
                      filter: 'drop-shadow(0 2px 2px #1a1a1a)'
                    }}
                    onClick={onClick}
                  />
                )}
                items={photos.map((photo) => {
                  return {
                    original: `data:${photo?.contentType};base64, ${photo?.encodedContent}`,
                    thumbnail: `data:${photo?.contentType};base64, ${photo?.encodedContent}`,
                    originalHeight: 400,
                    thumbnailHeight: 65,
                    originalWidth: 400,
                    originalClass: 'service-photo-slide',
                    thumbnailClass: 'thumbnailSlide'
                  };
                })}
              />
            ) : null}
            {(data !== null && !data?.encodedPhotos) ||
            (data !== null && data?.encodedPhotos && data?.encodedPhotos.length === 0) ? (
              <img
                src={basePhoto}
                alt="defaultImg"
                height={350}
                style={{ margin: '0px', objectFit: 'contain', maxWidth: '400px !important' }}
              />
            ) : null}

            {isLoadingPhotos && (
              <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'start',
                    mr: 1,
                    height: 390,
                    maxWidth: '100%'
                  }}
                >
                  {data.encodedPhotos.map((photo, index) => (
                    <Skeleton
                      sx={{ height: 96, width: 96, mt: index !== 0 ? 1 : 0, borderRadius: '5px' }}
                      animation="wave"
                      variant="rectangular"
                    />
                  ))}
                </Box>
                <Skeleton
                  sx={{ height: 390, width: '100%', borderRadius: '5px' }}
                  animation="wave"
                  variant="rectangular"
                />
              </Box>
            )}
          </Grid>
          <Grid item xs={7} sx={{ position: 'relative' }}>
            <Menu
              anchorEl={anchorEl}
              id="action-menu"
              open={open}
              onClose={handleClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: 'hidden',
                  boxShadow: 'none',
                  borderRadius: '5px',
                  border: '1px solid black',
                  paddingTop: '10px',
                  paddingLeft: '20px',
                  paddingRight: '20px',
                  width: 'max-content',
                  mt: 1
                }
              }}
              autoFocus={false}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
              {data?.userDTO?.id === userData?.id && (
                <MenuItem
                  sx={{ padding: 0, mb: 1 }}
                  onClick={() => {
                    dispatch(setIsOpenServiceModal(true));
                    handleClose();
                  }}
                >
                  <ModeEditOutlineOutlinedIcon sx={{ color: '#60A5DF', mr: 1 }} />
                  <Typography
                    component="p"
                    fontSize={16}
                    sx={{ lineHeight: '18px' }}
                    fontWeight={400}
                  >
                    Редагувати
                  </Typography>
                </MenuItem>
              )}

              <MenuItem
                sx={{ padding: 0, mb: 1 }}
                onClick={() => {
                  navigator.clipboard.writeText(`${window.location.origin}/ua/service/${data?.id}`);
                  handleClose();
                  enqueueSnackbar('Посилання на послугу скопійовано', { variant: 'success' });
                }}
              >
                <LinkIcon sx={{ color: '#60A5DF', mr: 1 }} />
                <Typography
                  component="p"
                  fontSize={16}
                  sx={{ lineHeight: '18px' }}
                  fontWeight={400}
                >
                  Скопіювати посилання на послугу
                </Typography>
              </MenuItem>

              {data?.userDTO?.id !== userData?.id && (
                <MenuItem
                  sx={{ padding: 0 }}
                  onClick={() => {
                    dispatch(setIsOpenComplainModal({ isOpen: true, func: sendComplaint }));
                    handleClose();
                  }}
                >
                  <ErrorOutlineIcon sx={{ color: '#60A5DF', mr: 1 }} />
                  <Typography
                    component="p"
                    fontSize={16}
                    sx={{ lineHeight: '18px' }}
                    fontWeight={400}
                  >
                    Поскаржитись
                  </Typography>
                </MenuItem>
              )}
            </Menu>

            <Stack spacing={0} flexDirection="column">
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography sx={{ color: '#868686', fontSize: '16px' }} variant="subtitle">
                  {dateFormattingByDate(new Date(data.creationTimeForService))}
                </Typography>
                <MoreVertIcon
                  onClick={(event) => handleClick(event)}
                  sx={{ ml: 1, cursor: 'pointer', ':hover': { color: '#60A5DF' } }}
                />
              </Box>

              <Typography sx={{ fontWeight: '500', fontSize: '32px !important' }} variant="h2">
                {data.nameService}
              </Typography>
              <Typography
                sx={{
                  fontSize: '16px',
                  lineHeight: '20px',
                  display: 'flex',
                  alignItems: 'center'
                }}
              >
                <GridViewIcon
                  sx={{
                    fontSize: '20px',
                    color: '#60A5DF',
                    marginRight: '8px',
                    marginBottom: '2px'
                  }}
                />
                {data.category}
              </Typography>
              <Typography
                sx={
                  data?.availability === 'Є в наявності'
                    ? {
                        fontSize: '16px',
                        color: '#156611',
                        marginBottom: '30px !important',
                        ml: '2px'
                      }
                    : { fontSize: '16px', color: 'red', marginBottom: '30px !important', ml: '2px' }
                }
              >
                {data.availability}
              </Typography>
              <Typography
                sx={{ fontWeight: '500', fontSize: '28px !important', color: '#60A5DF' }}
                variant="h2"
              >
                {`${data.price} ${data.currency}`}
              </Typography>
              <Typography sx={{ fontSize: '16px' }}>
                {`за ${data.valueOfInitOfMeasure} ${data.initOfMeasure}`}
              </Typography>
              <Grid container spacing={1} sx={{ display: 'flex', mt: 2 }}>
                {data.paymentOption.length !== 0 && (
                  <Grid item xs={5} sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography
                      variant="subtitle2"
                      sx={{ mb: 2, fontSize: 16, fontFamily: 'Kyiv-Sans-Bold', color: '#60A5DF' }}
                    >
                      Доступні варіанти оплати:
                    </Typography>
                    {data.paymentOption.includes('Повна оплата') && (
                      <Typography
                        variant="body2"
                        sx={{
                          fontSize: 13,
                          fontFamily: 'Kyiv-Sans-Light',
                          display: 'inline-flex',
                          color: 'black',
                          lineHeight: '2'
                        }}
                      >
                        <CheckCircleOutlineOutlinedIcon sx={{ color: '#60A5DF', mr: 0.5 }} />
                        Повна оплата
                      </Typography>
                    )}
                    {data.paymentOption.includes('Часткова передоплата') && (
                      <Typography
                        variant="body2"
                        sx={{
                          fontSize: 13,
                          fontFamily: 'Kyiv-Sans-Light',
                          display: 'inline-flex',
                          color: 'black',
                          lineHeight: '2'
                        }}
                      >
                        <PieChartOutlineOutlinedIcon sx={{ color: '#60A5DF', mr: 0.5 }} />
                        Часткова передоплата
                      </Typography>
                    )}
                    {data.paymentOption.includes('Оплата при отриманні') && (
                      <Typography
                        variant="body2"
                        sx={{
                          fontSize: 13,
                          fontFamily: 'Kyiv-Sans-Light',
                          display: 'inline-flex',
                          color: 'black',
                          lineHeight: '2'
                        }}
                      >
                        <AccessTimeOutlinedIcon sx={{ color: '#60A5DF', mr: 0.5 }} />
                        Оплата при отриманні
                      </Typography>
                    )}
                  </Grid>
                )}
                {data.deliveryOption.length !== 0 && (
                  <Grid item xs={7} sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography
                      variant="subtitle2"
                      sx={{ mb: 2, fontSize: 16, fontFamily: 'Kyiv-Sans-Bold', color: '#60A5DF' }}
                    >
                      Доступні варіанти доставки:
                    </Typography>
                    <Grid container spacing={1}>
                      {data.deliveryOption.includes('УкрПошта') && (
                        <Grid item xs={3.5}>
                          <img alt="ukrposhtalogo" src={UkrPoshtaLogo} height={25} width={90} />
                        </Grid>
                      )}

                      {data.deliveryOption.includes('Нова Пошта') && (
                        <Grid item xs={2.7}>
                          <img alt="novaposhtalogo" src={NovaPoshtaLogo} height={25} width={69} />
                        </Grid>
                      )}
                      {data.deliveryOption.includes("Кур'єрська доставка") && (
                        <Grid item xs={3}>
                          <img
                            alt="courierdeliverylogo"
                            src={CourierDeliveryLogo}
                            height={25}
                            width={94}
                          />
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                )}
              </Grid>
              <Typography
                sx={{ fontSize: '16px', marginTop: '30px !important', textAlign: 'left' }}
                children={data.description
                  .split('\r\n')
                  .filter((el) => el.length !== 0)
                  .map((string, index) => {
                    return (
                      <p key={index} style={{ margin: '0' }}>
                        {string}
                        <br />
                      </p>
                    );
                  })}
              />
            </Stack>
            <Stack spacing={4} flexDirection="row" sx={{ marginTop: '40px', alignItems: 'center' }}>
              {data?.userDTO &&
                data.userDTO?.id !== userData.id &&
                data.availability !== 'Немає в наявності' &&
                !blockedUsers.find((el) => el.id === data?.userDTO?.id) && (
                  <LetsGoBtn3
                    onClick={() => navigate(`/${language}/order-page/${data.id}`)}
                    text="Замовити"
                    width="255px"
                  />
                )}

              {userData && userSavedServices.length !== 0 && userSavedServices.includes(data.id) ? (
                <BookmarkIcon
                  onClick={() => {
                    apiSavedService.deleteItemQuery({ postId: data.id }).then((res) => {
                      // dataSetter();
                      const newArray = userSavedServices.filter((id) => data.id !== id);
                      dispatch(setUserSavedServices([...newArray]));
                    });
                  }}
                  sx={{
                    width: '25px',
                    height: '40px',
                    margin: '3px 10px 0px 20px !important',
                    cursor: 'pointer',
                    color: 'rgb(239, 201, 56)',
                    ':hover': {
                      color: 'rgb(239, 201, 56)',
                      cursor: 'pointer'
                    }
                  }}
                />
              ) : (
                <BookmarkBorderOutlinedIcon
                  onClick={() => {
                    apiSavedService.updateDataQueryPost({ serviceId: data.id }).then((res) => {
                      // dataSetter();
                      const newArray = [...userSavedServices, data.id];
                      dispatch(setUserSavedServices([...newArray]));
                    });
                  }}
                  sx={{
                    width: '25px',
                    height: '40px',
                    margin: '3px 10px 0px 20px !important',
                    cursor: 'pointer',
                    '&:hover': {
                      color: '#EFC938'
                    }
                  }}
                />
              )}

              <SendOutlinedIcon
                onClick={() => {
                  dispatch(
                    setIsOpenResendingModal({ isOpen: true, id: params.id, type: 'service' })
                  );
                }}
                sx={{
                  width: '23px',
                  height: '30px',
                  margin: '0px  !important',
                  transform: 'rotate(315deg)',
                  cursor: 'pointer',
                  '&:hover': {
                    color: '#60A5DF'
                  }
                }}
              />
            </Stack>
            <Typography
              variant="subtitle2"
              sx={{
                fontFamily: 'Kyiv-Sans-Bold',
                fontSize: '16px',
                marginTop: '30px !important',
                marginBottom: '5px !important',
                textAlign: 'left',
                fontWeight: '700'
              }}
            >
              Продавець
            </Typography>
            <Stack flexDirection="row">
              <Avatar
                src={
                  data?.userDTO && data?.userDTO?.encodedMediaDTO
                    ? `data:${data.userDTO?.encodedMediaDTO.contentType};base64, ${data.userDTO?.encodedMediaDTO.encodedContent}`
                    : DefaultAvatar
                }
                onClick={() => navigate(`/${language}/user-page/${data.userDTO.id}?type=posts`)}
                sx={{
                  width: 70,
                  height: 70,
                  cursor: 'pointer',
                  border: !blockedUsers.find((el) => el.id === data?.userDTO?.id)
                    ? '1px solid #60A5DF'
                    : 'none',
                  boxShadow: blockedUsers.find((el) => el.id === data?.userDTO?.id)
                    ? '0px 0px 2px 2px rgba(255,0,0,1)'
                    : 'none'
                }}
              />
              <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '15px' }}>
                {data?.userDTO && data?.userDTO?.name && data?.userDTO?.surname && (
                  <Typography
                    variant="subtitle"
                    sx={{
                      fontFamily: 'Public Sans,sans-serif !important',
                      fontSize: '18px',
                      textAlign: 'left',
                      cursor: 'pointer'
                    }}
                    onClick={() => navigate(`/${language}/user-page/${data.userDTO.id}?type=posts`)}
                  >
                    {`${data?.userDTO && data?.userDTO?.name} ${
                      data?.userDTO && data?.userDTO?.surname
                    }`}
                  </Typography>
                )}

                <Typography
                  variant="subtitle"
                  sx={{
                    fontFamily: 'Public Sans,sans-serif !important',
                    fontSize:
                      data?.userDTO && data?.userDTO?.name && data?.userDTO?.surname
                        ? '14px'
                        : '18px',
                    textAlign: 'left',
                    cursor: 'pointer'
                  }}
                  onClick={() => navigate(`/${language}/user-page/${data.userDTO.id}?type=posts`)}
                >
                  {`@${data?.userDTO && data.userDTO.nickname}`}
                </Typography>
              </div>
            </Stack>
          </Grid>
          <Grid item xs={12} sx={{ mt: 6 }}>
            <StyledTitle
              maxWidth={800}
              sx={{
                lineHeight: '38px !important',
                fontSize: '32px !important',
                textTransform: 'initial !important',
                margin: '0px auto !important'
              }}
              className="bottomBlock-title"
            >
              Що кажуть користувачі
            </StyledTitle>
            <Grid container spacing={2}>
              {(data?.userDTO && data.userDTO.id !== userData.id && feedbacks.length === 0) ||
              (!data?.userDTO && feedbacks.length === 0) ? (
                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', mt: '20px' }}>
                  <Typography fontSize={16}>
                    У цього товару поки що немає відгуків. Напиши перший відгук!
                  </Typography>
                </Grid>
              ) : null}
              <Grid
                item
                xs={12}
                sx={
                  feedbacks.length === 0
                    ? {
                        display: 'flex',
                        justifyContent: 'center',
                        marginBottom: '20px',
                        flexDirection: 'column',
                        alignItems: 'center'
                      }
                    : { marginBottom: '20px' }
                }
              >
                {(data?.userDTO &&
                  data?.userDTO.id !== userData.id &&
                  !blockedUsers.find((el) => el.id === data?.userDTO?.id)) ||
                !data?.userDTO ? (
                  <LetsGoBtn2
                    disabled={blockedUsers.find((el) => el.id === data?.userDTO?.id)}
                    text="Залишити відгук"
                    width="180px"
                    onClick={() => setIsOpenFeedbackForm(true)}
                  />
                ) : null}

                {isOpenFeedbackForm && (
                  <RespondForm
                    id={data.id}
                    feedbackSetter={feedbackRefresh}
                    setIsOpenFeedbackForm={setIsOpenFeedbackForm}
                  />
                )}
              </Grid>
              {feedbacks.map((feedback, index) => (
                <Grid
                  key={`feedback${index}`}
                  item
                  xs={12}
                  sx={{ display: 'flex', justifyContent: 'center' }}
                >
                  <RespondCard
                    data={feedback}
                    serviceOwnerId={data?.userDTO?.id}
                    setter={feedbackRefresh}
                  />
                </Grid>
              ))}

              {feedbacks.length !== 0 &&
                feedbacksTotalPages !== 1 &&
                feedbacksQuery?.page !== feedbacksTotalPages - 1 && (
                  <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                    <LetsGoBtn2
                      onClick={() => {
                        feedbackSetter({
                          ...feedbacksQuery,
                          page: feedbacksQuery.page + 1
                        });
                      }}
                      text="Завантажити більше відгуків"
                      width="fit-content"
                    />
                  </Grid>
                )}

              {data?.userDTO && data.userDTO.id === userData.id && feedbacks.length === 0 && (
                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', mt: '20px' }}>
                  <Typography fontSize={16}>У вас поки що немає відгуків. </Typography>
                </Grid>
              )}
            </Grid>
          </Grid>
          {data?.userDTO && data.userDTO.id !== userData.id && (
            <Grid item xs={12} sx={{ mt: 6 }}>
              {additionalGoods.length !== 0 && (
                <StyledTitle
                  maxWidth={800}
                  sx={{
                    lineHeight: '38px !important',
                    fontSize: '32px !important',
                    textTransform: 'initial !important'
                  }}
                  className="bottomBlock-title"
                >
                  Інші товари цього продавця
                </StyledTitle>
              )}

              <Grid container style={{ width: '1440px !important' }}>
                {/* {additionalGoods.map((card, index) => (
                  <Grid
                    key={`services${index}`}
                    item
                    xs={4}
                    sx={{ display: 'flex', justifyContent: 'center' }}
                  >
                    <ServiceCard el={data} noWidth />
                  </Grid>
                ))} */}

                <Slider {...settings}>
                  {additionalGoods.map((el, index) => {
                    return (
                      <div key={index} style={{ display: 'flex', flexDirection: 'row' }}>
                        <div style={{ cursor: 'pointer' }}>
                          <ServiceCard el={el} noWidth />
                        </div>
                      </div>
                    );
                  })}
                </Slider>
              </Grid>
            </Grid>
          )}
        </Grid>
      ) : (
        <div
          style={{
            // height: '240px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            marginBottom: '100px',
            height: '100vh'
          }}
        >
          <Spinner />
        </div>
      )}
    </Page>
  );
}
const mapStateToProps = (state) => {
  return {
    language: state.Intl.locale,
    userData: state.global.userData,
    subscriptions: state.global.userSubscriptions,
    blockedUsers: state.global.blockedUsers
  };
};
export default connect(mapStateToProps)(ServicePage);
