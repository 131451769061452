import React from 'react';

//Material
import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Grid,
  Modal,
  TextField,
  Typography
} from '@mui/material';

import styled from '@emotion/styled';
import { Form, FormikProvider, useFormik } from 'formik';
import { useTranslate } from 'react-redux-multilingual';
import * as Yup from 'yup';
import './AuthModal.css';
// import { useSnackbar } from 'notistack';
import { useSnackbar } from 'notistack';
import { connect, useDispatch } from 'react-redux';

import { LetsGoBtn3 } from '../../components/Buttons';
import { StyledLabel } from '../../components/StyledComponents';
import ApiService from '../../helpers/apiHelper';
import { setIsOpenEnable2FAModal } from '../../redux/mainReducer';
// import {  encode as btoa, decode as atob } from 'base-64';
// import sha256 from 'crypto-js/sha256';


import starBlue from '../../assets/StarBlue.png';
import starGreen from '../../assets/StarGreen.png';

import InputMask from 'react-input-mask';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  maxWidth: 670,
  bgcolor: 'background.paper',
  borderRadius: '20px',
  border: 'none',
  boxShadow: 24,
  padding: '50px 80px 50px 80px',
  overflow: 'scroll',
  height: 'inherit',
  maxHeight: '100vh',
  scrollbarWidth: 'none',
  '&::-webkit-scrollbar': {
    display: 'none'
  },
  display: 'flex',
  justifyContent: 'center'
};

// const StyledButton = styled(LoadingButton)(({ theme }) => ({
//   color: theme.palette.common.white,
//   fontFamily: 'Noto Sans',
//   textTransform: 'uppercase',
//   borderRadius: '50px',
//   maxWidth: 'fit-content',
//   minWidth: '150px',
//   padding: '0 20px',
//   '&:hover': { backgroundColor: 'transparent' },
//   boxShadow: '0px 7px 10px 1px rgb(145 158 171 / 30%)',
//   background:
//     'linear-gradient(353.54deg, #043B1F 3.03%, #0D7834 37.41%, #0E8D3D 67.77%, #0B9552 104.82%)'
// }));

const CssTextField2FA = styled(TextField)({
  '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
    border: '2px solid #60A5DF'
  },
  '& label.Mui-focused': {
    color: '#000000'
  },
  '& label': {
    color: '#A1A1A1',
    fontFamily: 'Kyiv-Sans-Light'
  },
  '& .MuiInput-root.Mui-focused:after': {
    borderBottom: '2px #000000 solid'
  },
  '& .MuiInput-root:hover:not(.Mui-disabled):before': {
    borderBottom: '2px #000000 solid'
  },
  '& .MuiInput-root:not(.Mui-error):before': {
    borderBottom: '2px #000000 solid'
  },
  margin: '5px auto'
});

function Enable2FAModal({ isOpenModal,userData}) {
  const dispatch = useDispatch();
  
  // useEffect(() => {
  //   if (window.screen.width <= 600) {
  //     setScreenType('mobile');
  //   }
  // }, []);
  const translate = useTranslate();
  const { enqueueSnackbar } = useSnackbar();

  // const apiE2FA = new ApiService('/settings/enable-2fa');
  const apiE2FA = new ApiService('auth/settings/enable-2fa');

  const schema = Yup.object().shape({
    verify: Yup.string().required(translate('error_required'))
  }); 

  const formik = useFormik({
    initialValues: {   
      verify: ''
    },
    enableReinitialize: true,
    validationSchema: schema,

    onSubmit: (values, { resetForm, setErrors }) => {
   
      apiE2FA
          .updateData({ verify: values.verify },false)
          .then(async (res) => {            
            dispatch(setIsOpenEnable2FAModal(false));           
            resetForm();
          })
          .catch((error) => {
            if (typeof error !== 'object') {
              enqueueSnackbar(error, { variant: 'error' });
            } else {
              if (Object.keys(error).length !== 0) {
                setErrors(error);
              } else enqueueSnackbar("Відсутній зв'язок із сервером", { variant: 'error' });
            }
          });
     
  }

});

  const {
    errors,
    touched,
    values,
    handleSubmit,
    getFieldProps,
    submitForm,
    resetForm,
  } = formik;
 const revertData = ()=>{
  const iFrame = document.getElementById('accSettingsPage')
  if(iFrame){
    iFrame.contentWindow.postMessage({
      userData,
      tokens: {
        accessToken: localStorage.getItem('accessToken'),
        refreshToken: localStorage.getItem('refreshToken')
      },
      message:'REVERT2FA'
    }, '*')
  }
 }
  return (
    <div>
      <Modal
        open={isOpenModal}
        onClose={(event, reason) => {
          if (reason === 'backdropClick') {
            return;
          }
          dispatch(setIsOpenEnable2FAModal(false));     
          revertData()
          resetForm();
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="bonfair_auth_modal"
      >
        <Box sx={style} className="bonfair_auth_modal-box">
          <img
            style={{ position: 'fixed', zIndex: '0', top: '0', left: '0' }}
            src={starGreen}
            alt="star-green"
          />
          <img
            style={{ position: 'fixed', zIndex: '0', bottom: '40px', right: '0' }}
            src={starBlue}
            alt="star-green"
          />
          <CloseIcon
            className="bonfair_auth_modal-close"
            style={{ position: 'absolute', right: '10px', top: '10px', cursor: 'pointer' }}
            onClick={() => {
              dispatch(setIsOpenEnable2FAModal(false));   
              revertData()
              resetForm();
            }}
          />

          <FormikProvider value={formik}>
            <Form
              autoComplete="off"
              noValidate
              onSubmit={handleSubmit}
              style={{ display: 'flex', flexDirection: 'column', marginTop: '10px' }}
            >
              <Grid>
                <Grid item xs={12} md={12} lg={12}>
                        <StyledLabel className="enable2FAModal-title-text">
                          {`Ви вмикаєте двофакторну аутентифікацію`}
                        </StyledLabel>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sx={{ display: 'flex', flexDirection: 'column' }}
                        className="authModal-inputs-first"
                      >
                        <Typography
                          className="authModal-main-text"
                          style={{
                            marginBottom: '30px',
                            textAlign: 'center',
                            fontSize: '16px',
                            fontFamily: 'Kyiv-Sans-Light'
                          }}
                        >
                          {`На вказану вами електронну пошту надіслано листа з кодом підтвердження, будь ласка введіть його нижче.`}
                        </Typography>

                        <InputMask
                          alwaysShowMask
                          maskChar="X"
                          mask="999999"
                          value={values.verify}
                          {...getFieldProps('verify')}
                        >
                          {(inputProps) => (
                            <CssTextField2FA
                              {...inputProps}
                              placeholder="XXXXXX"
                              size="normal"
                              inputProps={{ style: { textAlign: 'center' } }}
                              error={Boolean(touched.verify && errors.verify)}
                            >
                              {values.verify}
                            </CssTextField2FA>
                          )}
                        </InputMask>

                        {touched.verify && errors.verify && (
                          <div
                            style={{
                              color: 'red',
                              margin: '5px 0px',
                              fontSize: '14px',
                              fontFamily: 'Kyiv-Sans-Regular'
                            }}
                          >
                            {errors.verify}
                          </div>
                        )}
                      </Grid>
               
                 
                    <Grid
                      item
                      xs={12}
                      style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}
                    >
                      <LetsGoBtn3
                        id='loginBtn2FA'
                        disabled={values.verify.length === 0}
                        size="large"
                        onClick={() => {
                          submitForm();
                        }}
                        width={257}
                        style={{ textTransform: 'inherit' }}
                        text={'Підтвердити'}
                      />
                    </Grid>
                 
               
                </Grid>
             
            </Form>
          </FormikProvider>
        </Box>
      </Modal>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    language: state.Intl.locale,
    userData:state.global.userData
  };
};
export default connect(mapStateToProps)(Enable2FAModal);
