// @flow
import * as React from 'react';
import Star from '../assets/Star.png';

export const Spinner = ({ type = 'big', marginTop = '0px', ...props }) => {
  return (
    <div
      {...props}
      className="spinner"
      style={
        type === 'small'
          ? { width: '24px', height: '24px', margin: '0 auto' }
          : { width: '56px', height: '56px', margin: '0 auto', marginTop }
      }
    >
      <img
        alt="spinner"
        src={Star}
        style={
          type === 'small'
            ? { width: '24px', height: '24px', margin: '0 auto' }
            : { width: '56px', height: '56px', margin: '0 auto', marginTop }
        }
      />
    </div>
  );
};
