import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
// import { useNavigate } from 'react-router-dom';

//Material

import ReplyIcon from '@mui/icons-material/Reply';
import {
  Autocomplete,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
  styled
} from '@mui/material';
import { Form, FormikProvider, useFormik } from 'formik';
import InputMask from 'react-input-mask';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { LetsGoBtn3 } from '../../components/Buttons';
import Page from '../../components/Page';
import ServiceCardOrder from '../../components/ServiceCardOrder';
import { Spinner } from '../../components/Spinner';
import { StyledTitle } from '../../components/StyledComponents';
import ApiService from '../../helpers/apiHelper';
import { UACities } from '../../services/UACIties';
import '../MainPage/index.css';

const CssTextField = styled(TextField)({
  '& label.Mui-focused': {
    color: '#000000'
  },
  '& label': {
    color: '#A1A1A1',
    fontFamily: 'Kyiv-Sans-Light'
  },
  fontFamily: 'Kyiv-Sans-Regular !important',
  margin: '5px 0px'
});

function OrderPage({ language, userData, blockedUsers, usersWhoBlocked }) {
  // const apiService = new ApiService('service');
  // const apiOrderCreate = new ApiService('order/create');
  const apiService = new ApiService('posts/service');
  const apiOrderCreate = new ApiService('posts/order/create');

  const params = useParams();
  const navigate = useNavigate();
  // eslint-disable-next-line
  const [stage, setStage] = useState(0);
  const [cities, setCities] = useState([]);
  // eslint-disable-next-line
  const [departments, setDepartments] = useState([]);
  const [service, setService] = useState(null);
  const [isLoading, setIsLoadiang] = useState(false);

  const dataSetter = () => {
    setIsLoadiang(true);

    apiService
      .getItemById(params.id)
      .then((res) => {
        if (
          res.result?.availability !== 'Немає в наявності' &&
          res.result?.userDTO?.id !== userData?.id &&
          !blockedUsers.find((el) => el?.id === res.result?.userDTO?.id)
        ) {
          setService(res.result);
          setIsLoadiang(false);
        } else {
          navigate(-1);
        }
      })
      .catch((error) => {
        console.error(error);
        if (error?.message && error?.message.includes("Can't get service post")) {
          navigate(-1);
        }
      });
  };
  const schema = Yup.object().when((values, schema) => {
    if (values.deliveryType === "Кур'єрська доставка") {
      return schema.shape({
        buyerName: Yup.string()
          .max(100, 'Максимально 100 символів')
          .min(2, 'Мінімально 2 символи')
          .required("Це поле обов'язкове"),
        buyerSurname: Yup.string()
          .max(100, 'Максимально 100 символів')
          .min(2, 'Мінімально 2 символи')
          .required("Це поле обов'язкове"),
        buyerNumber: Yup.string()
          .min(13, 'Введіть коректний номер телефону')
          .required("Це поле обов'язкове"),
        region: Yup.object().required("Це поле обов'язкове"),
        city: Yup.string().required("Це поле обов'язкове"),
        street: Yup.string()
          .max(256, 'Максимально 256 символів')
          .min(2, 'Мінімально 2 символи')
          .required("Це поле обов'язкове"),
        houseNumber: Yup.string()
          .max(5, 'Максимально 5 символів')
          .min(1, 'Мінімально 1 символи')
          .required("Це поле обов'язкове"),
        paymentType: Yup.string().required('Потрібно обрати хочаб один варінт'),
        deliveryType: Yup.string().required('Потрібно обрати хочаб один варінт'),
        comment: Yup.string().max(150, 'Максимально 150 символів')
      });
    } else {
      return schema.shape({
        buyerName: Yup.string()
          .max(100, 'Максимально 100 символів')
          .min(2, 'Мінімально 2 символи')
          .required("Це поле обов'язкове"),
        buyerSurname: Yup.string()
          .max(100, 'Максимально 100 символів')
          .min(2, 'Мінімально 2 символи')
          .required("Це поле обов'язкове"),
        buyerNumber: Yup.string()
          .min(13, 'Введіть коректний номер телефону')
          .required("Це поле обов'язкове"),
        city: Yup.string().required("Це поле обов'язкове"),
        paymentType: Yup.string().required('Потрібно обрати хочаб один варінт'),
        deliveryType: Yup.string().required('Потрібно обрати хочаб один варінт'),
        comment: Yup.string().max(150, 'Максимально 150 символів'),
        department: Yup.string().required("Це поле обов'язкове")
      });
    }
  });

  const formik = useFormik({
    initialValues: {
      buyerName: userData?.name ? userData.name : '',
      buyerSurname: userData?.surname ? userData.surname : '',
      buyerNumber: userData?.number ? userData.number : '',
      qty: 1,
      region: '',
      city: '',
      street: '',
      houseNumber: '',
      paymentType: '',
      // service?.paymentOption && service?.paymentOption.length !== 0
      //   ? service?.paymentOption[0]
      //   : '',
      deliveryType: '',
      // service?.deliveryOption && service?.deliveryOption.length !== 0
      //   ? service?.deliveryOption[0]
      //   : '',
      comment: '',
      department: ''
    },
    enableReinitialize: true,
    validationSchema: schema,
    // eslint-disable-next-line
    onSubmit: (values, { resetForm, setErrors }) => {
      setIsLoadiang(true);

      apiOrderCreate
        .sendRequest(
          values?.deliveryType === "Кур'єрська доставка"
            ? {
                serviceId: service?.id,
                qty: values.qty,
                contact: `${values.buyerName} ${values.buyerSurname} ${values.buyerNumber}`,
                deliveryType: `${values.deliveryType}, ${
                  values?.city && values?.city.length !== 0 ? `н.п.${values?.city}, ` : ''
                }${
                  values?.region && values?.region?.region && values?.region?.region.length !== 0
                    ? `${values?.region?.region} обл., `
                    : ''
                }${
                  values?.department && values?.department.length !== 0
                    ? `Відділення ${values?.department},`
                    : ''
                }${values?.street && values?.street.length !== 0 ? `вул.${values?.street}, ` : ''}${
                  values?.houseNumber && values?.houseNumber.length !== 0
                    ? `буд.${values?.houseNumber}`
                    : ''
                }`,
                paymentType: values.paymentType,
                comment: values.comment,
                price: (+service.price * values.qty).toFixed(2)
              }
            : {
                serviceId: service?.id,
                qty: values.qty,
                contact: `${values.buyerName} ${values.buyerSurname} ${values.buyerNumber}`,
                deliveryType: `${values.deliveryType}, ${
                  values?.city && values?.city.length !== 0 ? `н.п.${values?.city}, ` : ''
                }${
                  values?.region && values?.region?.region && values?.region?.region.length !== 0
                    ? `${values?.region?.region} обл., `
                    : ''
                }${
                  values?.department && values?.department.length !== 0
                    ? `Відділення ${values?.department}`
                    : ''
                }`,
                paymentType: values.paymentType,
                comment: values.comment,
                price: (+service.price * values.qty).toFixed(2)
              }
        )
        .then((res) => {
          setStage(1);
          setIsLoadiang(false);
        })
        .catch((error) => console.error(error));
    }
  });
  const {
    errors,
    touched,
    values,
    handleSubmit,
    getFieldProps,
    submitForm,
    setErrors,
    setTouched,
    // eslint-disable-next-line
    resetForm,
    setFieldValue
  } = formik;

  useEffect(() => {
    if (blockedUsers) {
      dataSetter();
    }
    // eslint-disable-next-line
  }, [blockedUsers]);
  useEffect(() => {
    if (values.region !== '') {
      setCities(values.region.cities);
    }
  }, [values.region]);
  return (
    <Page
      title={'Сторінка замовлення'}
      style={{
        display: 'flex',
        marginTop: '100px',
        alignItems: 'center',
        flexDirection: 'column',
        height: 'max-content',
        padding: '0px 100px'
      }}
    >
      <Stack spacing={1} justifyContent="center" alignItems="center">
        {stage === 0 ? (
          <>
            {' '}
            {!isLoading ? (
              <Grid
                container
                style={{
                  maxWidth: '1440px',
                  minWidth: '1265px'
                }}
                spacing={3}
              >
                <Grid item xs={12} sx={{ paddingBottom: '0px' }}>
                  <StyledTitle
                    maxWidth={800}
                    sx={{
                      lineHeight: '38px !important',
                      fontSize: '32px !important',
                      textTransform: 'initial !important',
                      margin: '0px auto 20px !important'
                    }}
                    className="bottomBlock-title"
                  >
                    Оформити замовлення
                  </StyledTitle>
                </Grid>
                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-start', mb: 1 }}>
                  <Typography
                    variant="subtitle"
                    sx={{
                      display: 'inline-flex',
                      cursor: 'pointer',
                      color: 'black',
                      ':hover': { color: '#60A5DF' }
                    }}
                    onClick={() => navigate(-1)}
                  >
                    <ReplyIcon sx={{ mr: 1, color: 'black' }} />
                    Повернутись на попередню сторінку
                  </Typography>
                </Grid>

                <FormikProvider value={formik}>
                  <Form
                    autoComplete="off"
                    noValidate
                    onSubmit={handleSubmit}
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      marginTop: '10px',
                      paddingLeft: '24px'
                    }}
                  >
                    <Grid
                      container
                      spacing={0}
                      style={{
                        maxWidth: '1440px',
                        minWidth: '1265px'
                      }}
                      // sx={{ paddingBottom: '0px', display: 'flex', flexDirection: 'column' }}
                    >
                      {' '}
                      <Grid
                        item
                        xs={12}
                        sx={{
                          paddingBottom: '20px',
                          display: 'flex',
                          flexDirection: 'column',
                          position: 'relative'
                        }}
                      >
                        <Typography
                          variant="subtitle2"
                          sx={{ fontSize: 16, fontFamily: 'Kyiv-Sans-Bold', mb: 2 }}
                        >
                          Ваше замовлення:
                        </Typography>
                        {+values?.qty > +service?.availableQuantity ? (
                          <Typography
                            variant="subtitle"
                            sx={{
                              fontSize: 14,
                              fontFamily: 'Kyiv-Sans-Regular !important',
                              mb: 0,
                              color: 'red',
                              maxWidth: '490px',
                              position: 'absolute',
                              right: '0',
                              top: '-5px'
                            }}
                          >
                            Наразі доступна кількість вказана за товаром, менша ніж Ви хочете
                            замовити. Зв'яжіться із продавцем після оформлення замовлення!
                          </Typography>
                        ) : null}

                        {service && (
                          <ServiceCardOrder
                            el={service}
                            qty={values.qty}
                            setter={(value) => setFieldValue('qty', value)}
                          />
                        )}
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sx={{ paddingBottom: '0px', display: 'flex', flexDirection: 'column' }}
                      >
                        <Typography
                          variant="subtitle2"
                          sx={{ fontSize: 16, fontFamily: 'Kyiv-Sans-Bold', pb: 1 }}
                        >
                          Ваші контактні дані:
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={4}
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          flexDirection: 'column',
                          position: 'relative',
                          mb: 2
                        }}
                      >
                        <CssTextField
                          label="Ім'я*"
                          placeholder="Ім'я*"
                          variant="standard"
                          fullWidth
                          size="normal"
                          sx={{ width: 398, marginTop: '0px !important' }}
                          inputProps={{ style: { textAlign: 'left' } }}
                          error={Boolean(touched.buyerName && errors.buyerName)}
                          {...getFieldProps('buyerName')}
                          value={values.buyerName}
                        >
                          {values.buyerName}
                        </CssTextField>
                        {touched.buyerName && errors.buyerName && (
                          <div
                            style={{
                              position: 'absolute',
                              bottom: '-12px',
                              color: 'red',
                              margin: '0px 0px',
                              fontSize: '14px',
                              fontFamily: 'Kyiv-Sans-Regular'
                            }}
                          >
                            {errors.buyerName}
                          </div>
                        )}
                      </Grid>
                      <Grid
                        item
                        xs={4}
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          flexDirection: 'column',
                          position: 'relative',
                          mb: 2
                        }}
                      >
                        <CssTextField
                          label="Прізвище*"
                          placeholder="Прізвище*"
                          variant="standard"
                          fullWidth
                          size="normal"
                          sx={{ width: 398, marginTop: '0px !important' }}
                          inputProps={{ style: { textAlign: 'left' } }}
                          error={Boolean(touched.buyerSurname && errors.buyerSurname)}
                          {...getFieldProps('buyerSurname')}
                          value={values.buyerSurname}
                        >
                          {values.buyerSurname}
                        </CssTextField>
                        {touched.buyerSurname && errors.buyerSurname && (
                          <div
                            style={{
                              position: 'absolute',
                              bottom: '-12px',
                              color: 'red',
                              margin: '0px 0px',
                              fontSize: '14px',
                              fontFamily: 'Kyiv-Sans-Regular'
                            }}
                          >
                            {errors.buyerSurname}
                          </div>
                        )}
                      </Grid>
                      <Grid
                        item
                        xs={4}
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          flexDirection: 'column',
                          position: 'relative',
                          mb: 2
                        }}
                      >
                        <InputMask
                          alwaysShowMask
                          maskChar=""
                          mask="+380999999999"
                          value={values.number}
                          {...getFieldProps('buyerNumber')}
                        >
                          {(inputProps) => (
                            <CssTextField
                              {...inputProps}
                              label="Телефон*"
                              placeholder="Телефон*"
                              variant="standard"
                              fullWidth
                              size="normal"
                              sx={{ width: 398, marginTop: '0px !important' }}
                              inputProps={{ style: { textAlign: 'left' } }}
                              error={Boolean(touched.buyerNumber && errors.buyerNumber)}
                            >
                              {values.buyerNumber}
                            </CssTextField>
                          )}
                        </InputMask>

                        {touched.buyerNumber && errors.buyerNumber && (
                          <div
                            style={{
                              position: 'absolute',
                              bottom: '-12px',
                              color: 'red',
                              margin: '0px 0px',
                              fontSize: '14px',
                              fontFamily: 'Kyiv-Sans-Regular'
                            }}
                          >
                            {errors.buyerNumber}
                          </div>
                        )}
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sx={{ paddingBottom: '0px', display: 'flex', flexDirection: 'column' }}
                      >
                        <Typography
                          variant="subtitle2"
                          sx={{ fontSize: 16, fontFamily: 'Kyiv-Sans-Bold', pb: 1 }}
                        >
                          Доступний спосіб оплати:
                        </Typography>
                        <Typography
                          variant="subtitle"
                          sx={{ fontSize: 16, color: '#B1B1B1', mb: 2 }}
                        >
                          Продаць із вами зв’яжеться.
                        </Typography>
                        <FormControl>
                          <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            value={values.paymentType}
                            onChange={(event) => setFieldValue('paymentType', event.target.value)}
                            sx={{ position: 'relative' }}
                          >
                            {service?.paymentOption &&
                              service?.paymentOption.length !== 0 &&
                              service?.paymentOption.map((option) => (
                                <FormControlLabel
                                  key={option}
                                  value={option}
                                  control={<Radio />}
                                  label={option}
                                />
                              ))}
                            {touched.paymentType && errors.paymentType && (
                              <div
                                style={{
                                  position: 'absolute',
                                  right: '30%',
                                  top: '25%',
                                  color: 'red',
                                  margin: '0px 0px',
                                  fontSize: '14px',
                                  fontFamily: 'Kyiv-Sans-Regular'
                                }}
                              >
                                {errors.paymentType}
                              </div>
                            )}
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sx={{ mt: '10px', display: 'flex', flexDirection: 'column' }}
                      >
                        <Typography
                          variant="subtitle2"
                          sx={{ fontSize: 16, fontFamily: 'Kyiv-Sans-Bold', pb: 1 }}
                        >
                          Доступний спосіб доставки:
                        </Typography>
                        <Typography
                          variant="subtitle"
                          sx={{ fontSize: 16, color: '#B1B1B1', mb: 2 }}
                        >
                          Зверніть увагу, що терміни доставки можуть змінюватись в залежності від
                          обраного вами способу.
                        </Typography>
                        <FormControl>
                          <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            value={values.deliveryType}
                            sx={{ position: 'relative' }}
                            onChange={(event) => {
                              setFieldValue('street', '');
                              setFieldValue('houseNumber', '');
                              setFieldValue('region', '');
                              setFieldValue('city', '');
                              setCities([]);
                              setErrors({});
                              setTouched({
                                ...touched,
                                deliveryType: false,
                                department: false,
                                city: false,
                                region: false,
                                street: false,
                                houseNumber: false
                              });
                              setFieldValue('deliveryType', event.target.value);
                            }}
                          >
                            {service?.deliveryOption &&
                              service?.deliveryOption.length !== 0 &&
                              service?.deliveryOption.map((option) => (
                                <FormControlLabel
                                  key={option}
                                  value={option}
                                  control={<Radio />}
                                  label={option}
                                />
                              ))}
                            {touched.deliveryType && errors.deliveryType && (
                              <div
                                style={{
                                  position: 'absolute',
                                  right: '30%',
                                  top: '25%',
                                  color: 'red',
                                  margin: '0px 0px',
                                  fontSize: '14px',
                                  fontFamily: 'Kyiv-Sans-Regular'
                                }}
                              >
                                {errors.deliveryType}
                              </div>
                            )}
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                      {/* {values.deliveryType === "Кур'єрська доставка" && ( */}
                      <Grid item xs={3} sx={{ position: 'relative' }}>
                        <Autocomplete
                          disableClearable
                          options={UACities}
                          // defaultValue={'Україна'}
                          sx={{ maxWidth: 300 }}
                          value={values.region}
                          getOptionLabel={(option) => (option?.region ? option?.region : '')}
                          onChange={(event, value) => {
                            setCities(value.cities);
                            setFieldValue('region', value);
                            setFieldValue('city', '');
                            setFieldValue('street', '');
                            setFieldValue('houseNumber', '');
                          }}
                          // inputValue={values.region?.region ? values.region?.region : ''}
                          renderInput={(params) => (
                            <TextField {...params} label="Область" variant="standard" />
                          )}
                        />
                        {touched.region && errors.region && (
                          <div
                            style={{
                              position: 'absolute',
                              bottom: '-12px',
                              color: 'red',
                              margin: '0px 0px',
                              fontSize: '14px',
                              fontFamily: 'Kyiv-Sans-Regular'
                            }}
                          >
                            {errors.region}
                          </div>
                        )}
                      </Grid>
                      {/* )} */}
                      <Grid item xs={3} sx={{ position: 'relative' }}>
                        <Autocomplete
                          disableClearable
                          disabled={cities.length === 0}
                          options={cities}
                          // defaultValue={'Україна'}
                          sx={{ maxWidth: 300 }}
                          value={values.city}
                          onChange={(event, value) => setFieldValue('city', value)}
                          renderInput={(params) => (
                            <TextField {...params} label="Населений пункт*" variant="standard" />
                          )}
                        />
                        {touched.city && errors.city && (
                          <div
                            style={{
                              position: 'absolute',
                              bottom:
                                values.deliveryType === "Кур'єрська доставка" ? '-12px' : '2px',
                              color: 'red',
                              margin: '0px 0px',
                              fontSize: '14px',
                              fontFamily: 'Kyiv-Sans-Regular'
                            }}
                          >
                            {errors.city}
                          </div>
                        )}
                      </Grid>
                      {values.deliveryType !== "Кур'єрська доставка" ? (
                        <Grid item xs={4} sx={{ paddingBottom: '20px', position: 'relative' }}>
                          {/* <Autocomplete
                            disableClearable
                            disabled={departments.length === 0}
                            options={departments}
                            // defaultValue={'Україна'}
                            sx={{ maxWidth: 300 }}
                            value={values.department}
                            onChange={(event, value) => setFieldValue('department', value)}
                            renderInput={(params) => (
                              <TextField {...params} label="Відділення*" variant="standard" />
                            )}
                          /> */}

                          <CssTextField
                            label="Відділення*"
                            placeholder="Введіть номер відділення*"
                            variant="standard"
                            fullWidth
                            size="normal"
                            sx={{ width: 400, marginTop: '0px !important' }}
                            inputProps={{ style: { textAlign: 'left' } }}
                            error={Boolean(touched.department && errors.department)}
                            {...getFieldProps('department')}
                            value={values.department}
                          >
                            {values.department}
                          </CssTextField>
                          {/* {touched.department && errors.department && (
                            <div
                              style={{
                                position: 'absolute',
                                bottom: '-12px',
                                color: 'red',
                                margin: '0px 0px',
                                fontSize: '14px',
                                fontFamily: 'Kyiv-Sans-Regular'
                              }}
                            >
                              {errors.department}
                            </div>
                          )} */}
                          {touched.department && errors.department && (
                            <div
                              style={{
                                position: 'absolute',
                                bottom: '2px',
                                color: 'red',
                                margin: '0px 0px',
                                fontSize: '14px',
                                fontFamily: 'Kyiv-Sans-Regular'
                              }}
                            >
                              {errors.department}
                            </div>
                          )}
                        </Grid>
                      ) : (
                        <>
                          <Grid
                            item
                            xs={3}
                            sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              flexDirection: 'column',
                              position: 'relative'
                            }}
                          >
                            <CssTextField
                              label="Вулиця*"
                              placeholder="Вулиця*"
                              variant="standard"
                              fullWidth
                              size="normal"
                              sx={{ width: 300, marginTop: '0px !important' }}
                              inputProps={{ style: { textAlign: 'left' } }}
                              error={Boolean(touched.street && errors.street)}
                              {...getFieldProps('street')}
                              value={values.street}
                            >
                              {values.street}
                            </CssTextField>
                            {touched.street && errors.street && (
                              <div
                                style={{
                                  position: 'absolute',
                                  bottom: '-12px',
                                  color: 'red',
                                  margin: '0px 0px',
                                  fontSize: '14px',
                                  fontFamily: 'Kyiv-Sans-Regular'
                                }}
                              >
                                {errors.street}
                              </div>
                            )}
                          </Grid>
                          <Grid
                            item
                            xs={3}
                            sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              flexDirection: 'column',
                              position: 'relative'
                            }}
                          >
                            <CssTextField
                              label="Номер будинку*"
                              placeholder="Номер будинку*"
                              variant="standard"
                              fullWidth
                              size="normal"
                              sx={{ width: 150, marginTop: '0px !important' }}
                              inputProps={{ style: { textAlign: 'left' } }}
                              error={Boolean(touched.houseNumber && errors.houseNumber)}
                              {...getFieldProps('houseNumber')}
                              value={values.houseNumber}
                            >
                              {values.houseNumber}
                            </CssTextField>
                            {touched.houseNumber && errors.houseNumber && (
                              <div
                                style={{
                                  position: 'absolute',
                                  bottom: '-12px',
                                  color: 'red',
                                  margin: '0px 0px',
                                  fontSize: '14px',
                                  fontFamily: 'Kyiv-Sans-Regular'
                                }}
                              >
                                {errors.houseNumber}
                              </div>
                            )}
                          </Grid>
                        </>
                      )}
                      <Grid
                        item
                        xs={12}
                        sx={{
                          paddingBottom: '10px',
                          display: 'flex',
                          flexDirection: 'column',
                          mt: 2
                        }}
                      >
                        <Typography
                          variant="subtitle2"
                          sx={{ fontSize: 16, fontFamily: 'Kyiv-Sans-Bold', mb: 1 }}
                        >
                          Термін відправки замовлення:
                        </Typography>
                        <span
                          style={{
                            fontSize: 16,
                            display: 'flex',
                            marginBottom: '8px'
                          }}
                        >
                          Орієнтовний термін збору замовлення:
                          <Typography
                            variant="subtitle2"
                            sx={{ fontSize: 16, fontFamily: 'Kyiv-Sans-Bold', ml: 1 }}
                          >
                            {`${service?.estimatedTime && service?.estimatedTime} дні`}
                          </Typography>
                        </span>
                        <span
                          style={{
                            fontSize: 16,
                            display: 'flex'
                          }}
                        >
                          Відправка здійснюється:
                          {service?.deliveryDays && service?.deliveryDays.length !== 0 && (
                            <Typography
                              variant="subtitle2"
                              sx={{ fontSize: 16, fontFamily: 'Kyiv-Sans-Bold', ml: 1 }}
                            >
                              {`${
                                service?.deliveryDays &&
                                service?.deliveryDays.length !== 0 &&
                                service?.deliveryDays.includes('Понеділок') &&
                                service?.deliveryDays.includes('Вівторок') &&
                                service?.deliveryDays.includes('Середа') &&
                                service?.deliveryDays.includes('Четвер') &&
                                service?.deliveryDays.includes("П'ятниця") &&
                                service?.deliveryDays.includes('Субота') &&
                                service?.deliveryDays.includes('Неділя')
                                  ? 'Щодня'
                                  : service?.deliveryDays.map((day, index) => {
                                      switch (day) {
                                        case 'Понеділок':
                                          return ' Пн';
                                        case 'Вівторок':
                                          return ' Вт';
                                        case 'Середа':
                                          return ' Ср';
                                        case 'Четвер':
                                          return ' Чт';
                                        case "П'ятниця":
                                          return ' Пт';
                                        case 'Субота':
                                          return ' Сб';
                                        case 'Неділя':
                                          return ' Нд';
                                        default:
                                          return null;
                                      }
                                    })
                              }`}
                            </Typography>
                          )}
                        </span>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sx={{
                          paddingBottom: '0px',
                          display: 'flex',
                          flexDirection: 'column',
                          position: 'relative'
                        }}
                      >
                        <Typography
                          variant="subtitle2"
                          sx={{ fontSize: 16, fontFamily: 'Kyiv-Sans-Bold', mb: 1 }}
                        >
                          Ваш коментар до замовлення:
                        </Typography>
                        <CssTextField
                          placeholder="Тут ви можете вказати ваші побажання щодо замовлення або задати питання продавцю."
                          multiline
                          rows={6}
                          fullWidth
                          inputProps={{ style: { textAlign: 'left' } }}
                          error={Boolean(touched.comment && errors.comment)}
                          {...getFieldProps('comment')}
                          value={values.comment}
                        >
                          {values.comment}
                        </CssTextField>
                        {values.comment.length !== 0 && (
                          <p
                            style={{
                              position: 'absolute',
                              bottom: '-18px',
                              right: 0,
                              margin: '0 5px',
                              color: 'gray',
                              textAlign: 'right',
                              fontSize: '14px'
                            }}
                          >{`${values.comment.length}/150`}</p>
                        )}
                        {touched.comment && errors.comment && (
                          <div
                            style={{
                              position: 'absolute',
                              bottom: '-18px',
                              color: 'red',
                              margin: '5px 0px',
                              fontSize: '14px',
                              fontFamily: 'Kyiv-Sans-Regular'
                            }}
                          >
                            {errors.comment}
                          </div>
                        )}
                      </Grid>
                      <Grid item xs={12} sx={{ mt: 5, display: 'flex', justifyContent: 'center' }}>
                        <LetsGoBtn3
                          disabled={Object.keys(errors).length !== 0 || values.city.length === 0}
                          onClick={() => {
                            submitForm();
                          }}
                          text="Замовити"
                          width="255px"
                        />
                      </Grid>
                    </Grid>
                  </Form>
                </FormikProvider>
              </Grid>
            ) : (
              <div
                style={{
                  // height: '240px',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  marginBottom: '100px',
                  height: '100vh'
                }}
              >
                <Spinner />
              </div>
            )}
          </>
        ) : (
          <Grid
            container
            sx={{
              maxWidth: '1440px',
              minWidth: '1265px',
              mt: '20% !important'
            }}
            spacing={3}
          >
            <Grid item xs={12} sx={{ paddingBottom: '0px' }}>
              <StyledTitle
                maxWidth={800}
                sx={{
                  lineHeight: '38px !important',
                  fontSize: '32px !important',
                  textTransform: 'initial !important',
                  margin: '0px auto 20px !important'
                }}
                className="bottomBlock-title"
              >
                Замовлення прийняте!
              </StyledTitle>
            </Grid>
            <Grid item xs={12} sx={{ mt: 0, display: 'flex', justifyContent: 'center' }}>
              <Typography textAlign="center" sx={{ maxWidth: '640px' }}>
                Вітаю! Ваш запит надіслано продавцю. Чекайте на його повідомлення!
              </Typography>
            </Grid>
            <Grid item xs={12} sx={{ mt: 5, display: 'flex', justifyContent: 'center' }}>
              <LetsGoBtn3
                disabled={Object.keys(errors).length !== 0 || values.city.length === 0}
                onClick={() => {
                  navigate(`/${language}/ribbon`);
                }}
                text="Переглянути цікавеньке"
                width="335px"
              />
            </Grid>
          </Grid>
        )}
      </Stack>
    </Page>
  );
}
const mapStateToProps = (state) => {
  return {
    language: state.Intl.locale,
    userData: state.global.userData,
    blockedUsers: state.global.blockedUsers,
    usersWhoBlocked: state.global.usersWhoBlocked
  };
};
export default connect(mapStateToProps)(OrderPage);
