import React, { useEffect } from 'react';

//Material
import CloseIcon from '@mui/icons-material/Close';

import { Box, Modal } from '@mui/material';

import { connect, useDispatch } from 'react-redux';
import { setIsOpenPaymentModal } from '../../redux/mainReducer';

import './CancelModal.css';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 'fit-content',
  minWidth:500,
  maxWidth: 1100,
  // bgcolor: 'background.paper',
  bgcolor: '#eff1f3',
  borderRadius: '20px',
  border: 'none',
  boxShadow: 24,
  padding: '0px 35px',
  overflow: 'scroll',
  height: 'inherit',
  maxHeight: '90%',
  scrollbarWidth: 'none',
  '&::-webkit-scrollbar': {
    display: 'none'
  }
};

function PaymentModal({ isOpenModal, data, signature }) {
  const dispatch = useDispatch();

  const initiator = () => {
    window.LiqPayCheckout.init({
      data: data,
      signature: signature,
      embedTo: '#liqpay_checkout',
      language: 'uk',
      mode: 'embed' // embed || popup
    })
      .on('liqpay.callback', function (data) {
        console.log(data.status);
        console.log(data);
      })
      .on('liqpay.ready', function (data) {
        // ready
      })
      .on('liqpay.close', function (data) {
        // close
      });
  };

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      setTimeout(initiator, 500);
    }
    return () => {
      mounted = false;
      clearTimeout(initiator);
    };
    // eslint-disable-next-line
  }, [data, signature]);

  return (
    <div>
      <Modal
        open={isOpenModal}
        onClose={(event, reason) => {
          if (reason === 'backdropClick') {
            return;
          }
          dispatch(setIsOpenPaymentModal({ isOpen: false, data: null, signature: null }));
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="bonfair_cancel_modal"
      >
        <Box sx={style} className="bonfair_cancel_modal-box">
          <CloseIcon
            className="bonfair_cancel_modal-close"
            style={{ position: 'absolute', right: '10px', top: '10px', cursor: 'pointer' }}
            onClick={() => {
              dispatch(setIsOpenPaymentModal({ isOpen: false, data: null, signature: null }));
            }}
          />
          <div id="liqpay_checkout" />
        </Box>
      </Modal>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    language: state.Intl.locale
  };
};
export default connect(mapStateToProps)(PaymentModal);
