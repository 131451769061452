import React, { useEffect, useState } from 'react';

//Material
import { Modal, Box, Grid, Typography, Avatar, TextField, Tooltip } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
// import PersonOffOutlinedIcon from '@mui/icons-material/PersonOffOutlined';
import DoDisturbAltOutlinedIcon from '@mui/icons-material/DoDisturbAltOutlined';
// import styled from '@emotion/styled';
import './PostModal.css';
// import { useTranslate } from 'react-redux-multilingual';
// import { useSnackbar } from 'notistack';
import { StyledLabel } from '../../components/StyledComponents';
// import ApiService from '../../helpers/apiHelper';

import { setIsOpenUserReadersModal } from '../../redux/mainReducer';
import { connect, useDispatch } from 'react-redux';
// import { useSnackbar } from 'notistack';
// import {  encode as btoa, decode as atob } from 'base-64';
// import sha256 from 'crypto-js/sha256';

import starGreen from '../../assets/StarGreen.png';
import starBlue from '../../assets/StarBlue.png';
// import { useNavigate } from 'react-router-dom';

import { Stack } from '@mui/system';
import DefaultAvatar from '../../assets/defaultPostImg.jpg';

import { useNavigate, useParams } from 'react-router-dom';
import UserListCard from '../../components/UserListCard';
import expandIcon from '../../assets/arrowExpand.png';
import ApiService from '../../helpers/apiHelper';
import { base } from '../../redux/thunk';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  maxWidth: 700,
  bgcolor: 'background.paper',
  borderRadius: '20px',
  border: 'none',
  boxShadow: 24,
  padding: '50px 80px 50px 80px',
  overflow: 'scroll',
  height: 'inherit',
  maxHeight: '93vh',
  minHeight: '40vh',
  scrollbarWidth: 'none',
  '&::-webkit-scrollbar': {
    display: 'none'
  }
};

// const CssTextField = styled(TextField)({
//   '& label.Mui-focused': {
//     color: '#000000'
//   },
//   '& label': {
//     color: '#A1A1A1',
//     fontFamily: 'Kyiv-Sans-Light'
//   },

//   margin: '5px 0px'
// });

function UserReadersModal({
  isOpenModal,
  data,
  language,
  userData,
  nickname,
  getBlockedUsersThunk,
  getUserSubscribersThunk,
  getUserSubscriptionsThunk,
  blockedUsers
}) {
  // const [imgs, setImgs] = useState([]);
  const [screenType, setScreenType] = useState('desktop');
  const [searchvalue, setSearchValue] = useState('');
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    if (window.screen.width <= 600) {
      setScreenType('mobile');
    }
  }, []);
  // const translate = useTranslate();
  // const { enqueueSnackbar } = useSnackbar();
  // const api = new ApiService('posts/posts', translate);

  const apiChats = new ApiService('notification/chats');
  const apiBU = new ApiService('auth/blocked-users');
  // const apiBU = new ApiService('/blocked-users');
  // const apiChats = new ApiService('chats');
  return (
    <div>
      <Modal
        open={isOpenModal}
        onClose={(event, reason) => {
          if (reason === 'backdropClick') {
            return;
          }
          dispatch(setIsOpenUserReadersModal(false));
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="bonfair_post_modal"
      >
        <Box sx={style} className="bonfair_post_modal-box">
          <img
            style={{ position: 'fixed', zIndex: '0', top: '0', left: '0' }}
            src={starGreen}
            alt="star-green"
          />
          <img
            style={{ position: 'fixed', zIndex: '0', bottom: '40px', right: '0' }}
            src={starBlue}
            alt="star-green"
          />
          <CloseIcon
            className="bonfair_post_modal-close"
            style={{
              position: 'absolute',
              right: '10px',
              top: '10px',
              cursor: 'pointer',
              zIndex: '1000'
            }}
            onClick={() => {
              dispatch(setIsOpenUserReadersModal(false));
            }}
          />

          <Grid container spacing={screenType !== 'desktop' ? 1 : 2}>
            <Grid item xs={12} md={12} lg={12} sx={{ mb: 2 }}>
              <StyledLabel className="postModal-title-text">
                {/* {translate('bonfair_service_modal')} */}
                Підписники{params.id !== userData.id && ` @${nickname}`}
              </StyledLabel>
              {data.length === 0 ? (
                <Typography
                  sx={{ width: '428px', margin: '0 auto' }}
                  fontSize={16}
                  textAlign="center"
                >
                  {params.id !== userData.id
                    ? 'У цього користувача поки що немає підписників.'
                    : 'Поки підписників нема.'}
                </Typography>
              ) : (
                <Typography
                  sx={{ margin: '0 auto', color: '#A1A1A1' }}
                  fontSize={16}
                  textAlign="center"
                >
                  {`Кількість: ${data.length}`}
                </Typography>
              )}
            </Grid>
            {params.id === userData.id && data.length > 10 && (
              <Grid item xs={12} style={{ padding: '0px' }} sx={{ mb: 4, mt: 4 }}>
                <TextField
                  sx={{
                    input: {
                      fontFamily: 'Kyiv-Sans-Light',
                      fontSize: '16px',
                      fontWeight: '350',
                      lineHeight: '19px',
                      letterSpacing: '0em',
                      textAlign: 'left',
                      '&::placeholder': {
                        fontFamily: 'Kyiv-Sans-Light',
                        fontSize: '16px',
                        fontWeight: '350',
                        lineHeight: '19px',
                        letterSpacing: '0em',
                        textAlign: 'left'
                        // <----- Add this.
                        // opacity: 1,
                      }
                    }
                  }}
                  placeholder="Кого шукаємо?"
                  variant="standard"
                  fullWidth
                  value={searchvalue}
                  onChange={(e) => setSearchValue(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          width: 'fit-content',
                          cursor: 'pointer'
                        }}
                        // onClick={() => {
                        //   navigate(`/${language}/search?question=${value}`);
                        // }}
                      >
                        <img
                          alt="arrow"
                          src={expandIcon}
                          style={{ width: '35px', margin: '5px' }}
                        />
                      </div>
                    )
                  }}
                  // onKeyDown={(e) => {
                  //   if (e.key === 'Enter') {

                  //     navigate(`/${language}/search?question=${value}`);
                  //   }
                  // }}
                />
              </Grid>
            )}

            {searchvalue.length !== 0 ? (
              <>
                {data
                  .filter((user) =>
                    `${user.name}${user.surname}${user.nickname}`.includes(searchvalue)
                  )
                  .map((user, index) => (
                    <React.Fragment key={`userListItem${index}`}>
                      {params.id === userData.id ? (
                        <Grid
                          key={`listItemUser${index}`}
                          container
                          style={
                            !blockedUsers.find((el) => el.id === user.id)
                              ? {
                                  borderTop: '1px solid #60a5df',
                                  borderBottom: '1px solid #60a5df',
                                  padding: '5px',
                                  marginBottom: '10px'
                                }
                              : {
                                  borderTop: '1px solid red',
                                  borderBottom: '1px solid red',
                                  padding: '5px',
                                  marginBottom: '10px'
                                }
                          }
                        >
                          <Grid item xs={2}>
                            <Avatar
                              onClick={() => {
                                dispatch(setIsOpenUserReadersModal(false));
                                navigate(`/${language}/user-page/${user.id}?type=posts`);
                              }}
                              src={
                                user?.encodedMediaDTO
                                  ? `data:${user?.encodedMediaDTO.contentType};base64, ${user?.encodedMediaDTO.encodedContent}`
                                  : DefaultAvatar
                              }
                              sx={{
                                width: 70,
                                height: 70,
                                border: '1px solid #60A5DF',
                                cursor: 'pointer',
                                boxShadow: blockedUsers.find((el) => el.id === user?.id)
                                  ? '0px 0px 2px 2px rgba(255,0,0,1)'
                                  : 'none'
                              }}
                            />
                          </Grid>
                          <Grid item xs={8}>
                            <Stack sx={{ width: '100%', marginTop: '10px' }}>
                              {user?.name&&user?.surname&&<Typography
                                variant="subtitle"
                                sx={{
                                  fontFamily: 'Public Sans,sans-serif !important',
                                  fontSize: '18px',
                                  textAlign: 'left',
                                  cursor: 'pointer'
                                }}
                                onClick={() => {
                                  dispatch(setIsOpenUserReadersModal(false));
                                  navigate(`/${language}/user-page/${user.id}?type=posts`);
                                }}
                                src={
                                  user?.encodedMediaDTO
                                    ? `data:${user?.encodedMediaDTO.contentType};base64, ${user?.encodedMediaDTO.encodedContent}`
                                    : DefaultAvatar
                                }
                              >
                                {`${user?.name} ${user?.surname}`}
                              </Typography>}
                              
                              <Typography
                                variant="subtitle"
                                sx={{
                                  fontFamily: 'Public Sans,sans-serif !important',
                                  fontSize: user?.name&&user?.surname?'14px':'18px',
                                  textAlign: 'left',
                                  cursor: 'pointer'
                                }}
                                onClick={() => {
                                  dispatch(setIsOpenUserReadersModal(false));
                                  navigate(`/${language}/user-page/${user.id}?type=posts`);
                                }}
                                src={
                                  user?.encodedMediaDTO
                                    ? `data:${user?.encodedMediaDTO.contentType};base64, ${user?.encodedMediaDTO.encodedContent}`
                                    : DefaultAvatar
                                }
                              >
                                {`@${user?.nickname}`}
                              </Typography>
                            </Stack>
                          </Grid>

                          <Grid
                            item
                            xs={2}
                            sx={{ marginTop: '17px', justifyContent: 'flex-end', display: 'flex' }}
                          >
                            <Tooltip title="Написати">
                              <MailOutlineIcon
                                onClick={() => {
                                  apiChats
                                    .postDataQuery({
                                      userId: userData.id,
                                      recipientId: user.id
                                    })
                                    .then((res) => {
                                      dispatch(setIsOpenUserReadersModal(false));
                                      navigate(`/${language}/messages`);
                                    })
                                    .catch((error) => {
                                      if (
                                        error?.message.includes(
                                          'There is a chat between these users'
                                        )
                                      ) {
                                        const id = `${error.message.slice(
                                          error.message.indexOf('[') + 1,
                                          error.message.length - 1
                                        )}`;

                                        navigate(`/${language}/messages?chatId=${id}`);

                                        dispatch(setIsOpenUserReadersModal(false));
                                      }
                                    });
                                }}
                                sx={{ color: '#60A5DF', mr: 2, cursor: 'pointer' }}
                              />
                            </Tooltip>
                            <Tooltip title="Заблокувати">
                              <DoDisturbAltOutlinedIcon
                                sx={{ color: '#60A5DF', cursor: 'pointer' }}
                              />
                            </Tooltip>
                          </Grid>
                        </Grid>
                      ) : (
                        <UserListCard user={user} index={index} />
                      )}
                    </React.Fragment>
                  ))}
              </>
            ) : (
              <>
                {data.map((user, index) => (
                  <React.Fragment key={`userListItem${index}`}>
                    {params.id === userData.id ? (
                      <Grid
                        key={`listItemUser${index}`}
                        container
                        style={{
                          borderTop: '1px solid #60a5df',
                          borderBottom: '1px solid #60a5df',
                          padding: '5px',
                          marginBottom: '10px'
                        }}
                      >
                        <Grid item xs={2}>
                          <Avatar
                            onClick={() => {
                              dispatch(setIsOpenUserReadersModal(false));
                              navigate(`/${language}/user-page/${user.id}?type=posts`);
                            }}
                            src={
                              user?.encodedMediaDTO
                                ? `data:${user?.encodedMediaDTO.contentType};base64, ${user?.encodedMediaDTO.encodedContent}`
                                : DefaultAvatar
                            }
                            sx={{
                              width: 70,
                              height: 70,
                              border: '1px solid #60A5DF',
                              boxShadow: blockedUsers.find((el) => el.id === user?.id)
                                ? '0px 0px 2px 2px rgba(255,0,0,1)'
                                : 'none',
                              cursor: 'pointer'
                            }}
                          />
                        </Grid>
                        <Grid item xs={8}>
                          <Stack sx={{ width: '100%', marginTop: '10px' }}>
                            {user?.name&&user?.surname&&<Typography
                              variant="subtitle"
                              sx={{
                                fontFamily: 'Public Sans,sans-serif !important',
                                fontSize: '18px',
                                textAlign: 'left',
                                cursor: 'pointer'
                              }}
                              onClick={() => {
                                dispatch(setIsOpenUserReadersModal(false));
                                navigate(`/${language}/user-page/${user.id}?type=posts`);
                              }}
                              src={
                                user?.encodedMediaDTO
                                  ? `data:${user?.encodedMediaDTO.contentType};base64, ${user?.encodedMediaDTO.encodedContent}`
                                  : DefaultAvatar
                              }
                            >
                              {`${user?.name} ${user?.surname}`}
                            </Typography>}
                            
                            <Typography
                              variant="subtitle"
                              sx={{
                                fontFamily: 'Public Sans,sans-serif !important',
                                fontSize: user?.name&&user?.surname?'14px':'18px',
                                textAlign: 'left',
                                cursor: 'pointer'
                              }}
                              onClick={() => {
                                dispatch(setIsOpenUserReadersModal(false));
                                navigate(`/${language}/user-page/${user.id}?type=posts`);
                              }}
                              src={
                                user?.encodedMediaDTO
                                  ? `data:${user?.encodedMediaDTO.contentType};base64, ${user?.encodedMediaDTO.encodedContent}`
                                  : DefaultAvatar
                              }
                            >
                              {`@${user?.nickname}`}
                            </Typography>
                          </Stack>
                        </Grid>

                        <Grid
                          item
                          xs={2}
                          sx={{ marginTop: '17px', justifyContent: 'flex-end', display: 'flex' }}
                        >
                          <Tooltip title="Написати">
                            <MailOutlineIcon
                              onClick={() => {
                                apiChats
                                  .postDataQuery({
                                    userId: userData.id,
                                    recipientId: user.id
                                  })
                                  .then((res) => {
                                    navigate(`/${language}/messages`);
                                    dispatch(setIsOpenUserReadersModal(false));
                                  })
                                  .catch((error) => {
                                    if (
                                      error?.message.includes('There is a chat between these users')
                                    ) {
                                      const id = `${error.message.slice(
                                        error.message.indexOf('[') + 1,
                                        error.message.length - 1
                                      )}`;

                                      navigate(`/${language}/messages?chatId=${id}`);

                                      dispatch(setIsOpenUserReadersModal(false));
                                    }
                                  });
                              }}
                              sx={{ color: '#60A5DF', mr: 2, cursor: 'pointer', zIndex: '99' }}
                            />
                          </Tooltip>
                          {!blockedUsers.find((el) => el.id === user.id) && (
                            <Tooltip title="Заблокувати">
                              <DoDisturbAltOutlinedIcon
                                onClick={(event) => {
                                  event.preventDefault();
                                  event.stopPropagation();
                                  apiBU
                                    .updateDataQueryPost({ blockedUserId: user.id })
                                    .then((res) => {
                                      getBlockedUsersThunk();
                                      getUserSubscribersThunk(userData?.id)
                                      getUserSubscriptionsThunk(userData?.id)
                                    });
                                }}
                                sx={{ color: '#60A5DF', cursor: 'pointer', zIndex: '99' }}
                              />
                            </Tooltip>
                          )}
                        </Grid>
                      </Grid>
                    ) : (
                      <UserListCard user={user} index={index} />
                    )}
                  </React.Fragment>
                ))}
              </>
            )}
          </Grid>
        </Box>
      </Modal>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    language: state.Intl.locale,
    isOpenSearchBar: state.global.isOpenSearchBar,
    userData: state.global.userData,
    blockedUsers: state.global.blockedUsers
  };
};
export default connect(mapStateToProps, {
  getBlockedUsersThunk: base.getBlockedUsersThunk,
  getUserSubscribersThunk:base.getUserSubscribersThunk,
  getUserSubscriptionsThunk: base.getUserSubscriptionsThunk,
})(UserReadersModal);
