import * as React from 'react';

//Material
import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography } from '@mui/material';
import Decor from '../../../assets/questionsDecor.png';
//Components

import { StyledTitle } from '../../../components/StyledComponents';
// import expandIcon from '../../../assets/arrowExpand.png'
// import testPic from '../../../assets/testServices/testService1.png'

export default function BottomBlock() {
  const [expanded, setExpanded] = React.useState('');

  // useEffect(() => {
  //   const element = document.getElementById('questionsBlock');
  // }, []);
  // useEffect(() => {
  //   if (window.screen.width <= 900) {
  //     setScreenType('mobile');
  //   }
  // }, []);
  const text = [
    {
      title: 'Що таке Social Selling і чому тобі варто почати ним користуватись вже зараз?',
      description:
        'Зібрали поради, як краще використовувати всі принади майданчику та просувати свій бізнес. З ними вам буде простіше орієнтуватися та створювати послуги для збільшення кількості замовлень. '
    },
    {
      title: 'Як користуватися сервісом і отримувати більше прибутку і задоволення?',
      description:
        'Зібрали поради, як краще використовувати всі принади майданчику та просувати свій бізнес. З ними вам буде простіше орієнтуватися та створювати послуги для збільшення кількості замовлень. '
    },
    {
      title: 'Топ-5 видів просування на BONFAIR',
      description:
        'Зібрали поради, як краще використовувати всі принади майданчику та просувати свій бізнес. З ними вам буде простіше орієнтуватися та створювати послуги для збільшення кількості замовлень. '
    }
  ];
  const style = {
    width: '100%',
    bgcolor: 'transparent',
    borderTop: '1px solid  #60a5df',
    borderRadius: '0px !important'
  };

  const handleChange = (panel) => {
    if (panel !== expanded) {
      setExpanded(panel);
    } else setExpanded('');
  };
  return (
    <Grid item xs={12} sx={{ mb: 5, p: '0px 100px' }}>
      <StyledTitle className="bottomBlock-title">Поради</StyledTitle>

      {/* <Divider variant="fullWidth" style={{border:'1px solid #60A5DF'}} component="li" /> */}
      {text.map((el, index) => (
        <React.Fragment key={index}>
          <Accordion
            sx={style}
            expanded={expanded === `panel${index}`}
            onChange={() => handleChange(`panel${index}`)}
          >
            {/* <AccordionSummary   expandIcon={expanded === `panel${index}`&&<img  alt='arrow'src={expandIcon}   />}> */}
            <AccordionSummary>
              <img
                src={Decor}
                alt="decor"
                style={{ marginRight: '10px', width: '80px', height: '80px', alignSelf: 'center' }}
              />
              <Typography
                id={`helpTitle-${index}`}
                component="span"
                style={{ fontSize: '28px', fontWeight: 'bold', maxWidth: '80%', cursor: 'pointer' }}
              >
                {el.title}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography
                id={`helpDesc-${index}`}
                component="p"
                style={{ fontSize: '18px', maxWidth: '58%' }}
              >
                {el.description}
              </Typography>
              {/* <img
                src={testPic}
                alt='test'
                style={expanded === `panel${index}`?{ width: '250px', height: '250px', position:'absolute', top:'-5px', right:'10%', transform:'rotate(15deg)', transition: 'right 1s ease 0s', zIndex:'999' }:{ width: '250px', height: '250px', position:'absolute', top:'-5px', right:'-30%', transform:'rotate(15deg)',transition: 'right 0.5s ease 0s', zIndex:'999' }}
              /> */}
            </AccordionDetails>
          </Accordion>
          {/* <Divider variant="fullWidth" component="li" style={{border:'1px solid #60A5DF'}}/> */}
        </React.Fragment>
      ))}
    </Grid>
  );
}
