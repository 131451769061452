import React, { useEffect, useState } from 'react';

//Material
import CloseIcon from '@mui/icons-material/Close';
import { Box, Grid, Modal, TextField, Typography, styled } from '@mui/material';

// import styled from '@emotion/styled';
import './PostModal.css';
// import { useTranslate } from 'react-redux-multilingual';

import { StyledLabel } from '../../components/StyledComponents';

import { connect, useDispatch, useSelector } from 'react-redux';
import { FreeBtn, LetsGoBtn } from '../../components/Buttons';
import {
  setIsOpenResendingModal,
  setIsOpenUserContactsModal,
  setResendingText
} from '../../redux/mainReducer';
// import { useSnackbar } from 'notistack';
// import {  encode as btoa, decode as atob } from 'base-64';
// import sha256 from 'crypto-js/sha256';

import starBlue from '../../assets/StarBlue.png';
import starGreen from '../../assets/StarGreen.png';

import { useNavigate } from 'react-router-dom';
import UserListCard from '../../components/UserListCard';

import expandIcon from '../../assets/arrowExpand.png';
import ApiService from '../../helpers/apiHelper';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  maxWidth: 700,
  bgcolor: 'background.paper',
  borderRadius: '20px',
  border: 'none',
  boxShadow: 24,
  padding: '50px 80px 50px 80px',
  overflow: 'scroll',
  height: 'inherit',
  maxHeight: '100vh',
  minHeight: '40vh',
  scrollbarWidth: 'none',
  '&::-webkit-scrollbar': {
    display: 'none'
  }
};

// const CssTextField = styled(TextField)({
//   '& label.Mui-focused': {
//     color: '#000000'
//   },
//   '& label': {
//     color: '#A1A1A1',
//     fontFamily: 'Kyiv-Sans-Light'
//   },

//   margin: '5px 0px'
// });
const CssTextField = styled(TextField)({
  '& label.Mui-focused': {
    color: '#000000'
  },
  '& label': {
    color: '#A1A1A1',
    fontFamily: 'Kyiv-Sans-Light'
  },
  fontFamily: 'Kyiv-Sans-Regular !important',
  margin: '5px 0px'
});
function ResendingModal({ isOpenModal, type, id, func, language, userData, stompClient }) {

  const [selectedUsers, setSelectedUsers] = useState([]);
  const [screenType, setScreenType] = useState('desktop');
  const [searchvalue, setSearchValue] = useState('');
  const [text, setText] = useState('');

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const subscriptions = useSelector((state) => state.global.userSubscriptions);
  useEffect(() => {
    if (window.screen.width <= 600) {
      setScreenType('mobile');
    }
  }, []);
  // const translate = useTranslate();
  // const { enqueueSnackbar } = useSnackbar();

  const sendMessage = async () => {
    const chats = await Promise.all(
      selectedUsers.map(async (user) => {
        const chat = await fetch(
          // `${window.config.REACT_APP_API_HOST_3}/chats?userId=${userData.id}&recipientId=${user}`,
          // {
            
              `${window.location.protocol}//${window.location.hostname}/v1/notification/chats?userId=${userData.id}&recipientId=${user}`,
              {
            method: 'POST',
            // eslint-disable-next-line
            headers: {
              'Content-Type': 'application/json',
              Authorization: localStorage.getItem('accessToken')
            }
          }
        );
        const resolvedChat = await chat.json();
        if (
          resolvedChat?.message &&
          resolvedChat?.message.includes('There is a chat between these users')
        ) {
          const id = `${resolvedChat.message.slice(
            resolvedChat.message.indexOf('[') + 1,
            resolvedChat.message.length - 1
          )}`;
          return id;
        }
        if (resolvedChat?.chatId) {
          return resolvedChat.chatId;
        }
        
      })
    ).then((res) => {
      return res;
    });

    if (chats.length !== 0 && !chats.includes(undefined)) {
      if (!type && !id) {
        func(chats);
        setSelectedUsers([]);
        dispatch(setIsOpenResendingModal({ isOpen: false, id: null, type: null, func: null }));
        dispatch(setResendingText(text));
      } else {
        if (stompClient) {
          stompClient.publish({
            destination: '/queue/chat-message',
            body: JSON.stringify({
              chatIds: chats,
              appliedTo: '',
              typeMessage: type,
              includeId: id,
              text: text
            }),
            headers: {
              Authorization: localStorage.getItem('accessToken')
            }
          });
          dispatch(setIsOpenResendingModal({ isOpen: false, id: null, type: null, func: null }));
          navigate(`/${language}/messages`);
        }
      }
    } else {
      const apiRefresh = new ApiService('auth/refresh');
      apiRefresh
        .refreshRequest({ refreshToken: localStorage.getItem('refreshToken')}, {})
        .then((res) => {
          localStorage.setItem('accessToken', `Bearer ${res.accessToken}`);
          localStorage.setItem('refreshToken', `${res.refreshToken}`);
          sendMessage();
        })
        .catch((error) => {
          localStorage.removeItem('accessToken');
          localStorage.removeItem('refreshToken');
          window.location.href = '/';
          return console.error(error);
        });
    }
  };

  return (
    <div>
      <Modal
        open={isOpenModal}
        onClose={(event, reason) => {
          if (reason === 'backdropClick') {
            return;
          }
          dispatch(setIsOpenResendingModal({ isOpen: false, id: null, type: null }));
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="bonfair_post_modal"
      >
        <Box sx={style} className="bonfair_post_modal-box">
          <img
            style={{ position: 'fixed', zIndex: '0', top: '0', left: '0' }}
            src={starGreen}
            alt="star-green"
          />
          <img
            style={{ position: 'fixed', zIndex: '0', bottom: '40px', right: '0' }}
            src={starBlue}
            alt="star-green"
          />
          <CloseIcon
            className="bonfair_post_modal-close TEST"
            style={{ position: 'absolute', right: '10px', top: '10px', cursor: 'pointer' }}
            onClick={() => {
              dispatch(setIsOpenResendingModal({ isOpen: false, id: null, type: null }));
            }}
          />

          <Grid container spacing={screenType !== 'desktop' ? 1 : 2}>
            <Grid item xs={12} md={12} lg={12}>
              <StyledLabel className="postModal-title-text">
                {/* {translate('bonfair_service_modal')} */}
                Контакти
              </StyledLabel>
              {subscriptions.length === 0 ? (
                <Typography
                  sx={{ width: '428px', margin: '0 auto' }}
                  fontSize={16}
                  textAlign="center"
                >
                  {
                    'Поки контактів нема. Віримо, що це ненадовго. Спробуйте знайти своїх через пошук.'
                  }
                </Typography>
              ) : (
                <Typography
                  sx={{ margin: '0 auto', color: '#A1A1A1' }}
                  fontSize={16}
                  textAlign="center"
                >
                  {`Кількість: ${subscriptions.length}`}
                </Typography>
              )}
            </Grid>

            <Grid
              item
              xs={12}
              style={{ padding: '0px' }}
              sx={{ mb: 4, mt: 4, justifyContent: 'center', display: 'flex' }}
            >
              {subscriptions.length > 10 && (
                <TextField
                  sx={{
                    input: {
                      fontFamily: 'Kyiv-Sans-Light',
                      fontSize: '16px',
                      fontWeight: '350',
                      lineHeight: '19px',
                      letterSpacing: '0em',
                      textAlign: 'left',
                      '&::placeholder': {
                        fontFamily: 'Kyiv-Sans-Light',
                        fontSize: '16px',
                        fontWeight: '350',
                        lineHeight: '19px',
                        letterSpacing: '0em',
                        textAlign: 'left'
                        // <----- Add this.
                        // opacity: 1,
                      }
                    }
                  }}
                  placeholder="Кого шукаємо?"
                  variant="standard"
                  fullWidth
                  value={searchvalue}
                  onChange={(e) => setSearchValue(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          width: 'fit-content',
                          cursor: 'pointer'
                        }}
                        // onClick={() => {
                        //   navigate(`/${language}/search?question=${value}`);
                        // }}
                      >
                        <img
                          alt="arrow"
                          src={expandIcon}
                          style={{ width: '35px', margin: '5px' }}
                        />
                      </div>
                    )
                  }}
                  // onKeyDown={(e) => {
                  //   if (e.key === 'Enter') {

                  //     navigate(`/${language}/search?question=${value}`);
                  //   }
                  // }}
                />
              )}
              {subscriptions.length === 0 && (
                <FreeBtn
                  padding="8px 20px"
                  onClick={() => {
                    dispatch(setIsOpenUserContactsModal(false));
                    navigate(`/${language}/search?question=&type=users`);
                    dispatch(setIsOpenResendingModal({ isOpen: false, id: null, type: null, func: null }));
                  }}
                  text="Перейти до пошуку"
                />
              )}
            </Grid>

            <Grid
              item
              xs={12}
              sx={{
                maxHeight: '60vh',
                overflow: 'scroll',
                scrollbarWidth: 'none',
                '&::-webkit-scrollbar': {
                  display: 'none'
                }
              }}
            >
              {searchvalue.length !== 0 ? (
                <>
                  {subscriptions
                    .filter((user) =>
                      `${user.name}${user.surname}${user.nickname}`.includes(searchvalue)
                    )
                    .map((user, index) => (
                      <React.Fragment key={`userCard${index}`}>
                        <UserListCard
                          selectedUsers={selectedUsers}
                          setSelectedUsers={setSelectedUsers}
                          isResendMessage={true}
                          user={user}
                          index={index}
                        />
                      </React.Fragment>
                    ))}
                </>
              ) : (
                <>
                  {subscriptions.map((user, index) => (
                    <React.Fragment key={`userCard${index}`}>
                      <UserListCard
                        selectedUsers={selectedUsers}
                        setSelectedUsers={setSelectedUsers}
                        isResendMessage={true}
                        user={user}
                        index={index}
                      />
                    </React.Fragment>
                  ))}
                </>
              )}
            </Grid>
            {selectedUsers.length !== 0 && (
              <Grid
                item
                xs={12}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  alignItems: 'center'
                }}
              >
                <CssTextField
                  placeholder="Ваше повідомлення"
                  variant="standard"
                  fullWidth
                  size="normal"
                  inputProps={{ style: { textAlign: 'left', marginTop: '15px' } }}
                  onChange={(event) => {
                    setText(event.target.value);
                  }}
                  value={text}
                />

                <LetsGoBtn
                  text="Відправити"
                  width="250px"
                  margin="20px 0px 0px 0px"
                  onClick={sendMessage}
                />
              </Grid>
            )}
          </Grid>
        </Box>
      </Modal>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    language: state.Intl.locale,
    userData: state.global.userData,
    isOpenSearchBar: state.global.isOpenSearchBar,
    stompClient: state.global.stompClient
  };
};
export default connect(mapStateToProps)(ResendingModal);
