import React from 'react';

//Material
import { Avatar, Grid, Typography } from '@mui/material';

import DoneIcon from '@mui/icons-material/Done';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import StarIcon from '@mui/icons-material/Star';
import StarOutlineOutlinedIcon from '@mui/icons-material/StarOutlineOutlined';
import { Stack } from '@mui/system';
import { connect } from 'react-redux';

import DefaultAvatar from '../assets/defaultPostImg.jpg';

import ApiService from '../helpers/apiHelper';
import { timeDiffChat } from '../helpers/date-helpers';

function UserListCardChat({
  // clearMessages,
  stompClient,
  setSelectedChatPrev,
  dataSetter,
  selectedChat,
  chatSetter,
  index,
  user,
  textSetter,
  language,
  userData,
  setMessagesPage,
  blockedUsers
}) {
  const apiImportant = new ApiService('notification/participants/make-important');

  // const apiImportant = new ApiService('/participants/make-important');
  
  const importantHandler = () => {
    if (user.isImportantForUser) {
      apiImportant.updateDataQuery({ chatId: user.chatId, isChatImportant:false }).then((res) => {
        dataSetter();
      });
    } else {
      apiImportant.updateDataQuery({ chatId: user.chatId, isChatImportant:true }).then((res) => {
        dataSetter();
      });
    }
  };
  // useEffect(() => {
  //   clearMessages([]);
  //   // eslint-disable-next-line
  // }, [selectedChat]);
  
  return (
    <Grid
      key={`UserListCardChat${index}`}
      onClick={(event) => {
        event.stopPropagation();
        textSetter('');
        chatSetter(user);
        setMessagesPage(0);
        setSelectedChatPrev(selectedChat);
        
        if (selectedChat) {
          if (user?.lastMessage?.senderId !== userData?.id&&!user.lastMessage?.messageIsRead) {
            stompClient.publish({
              destination: '/queue/chat-info-message',
              body: JSON.stringify({
                chatId: user.chatId,
                serviceMessageRecipientId: user.userInfoDTO.id,
                senderId:userData.id
              }),
              headers: {
                Authorization: localStorage.getItem('accessToken')
              }
            });
          }
        }
      }}
      container
      sx={!blockedUsers.find((el) => el.id === user?.userInfoDTO?.id)
        ?{
        padding: '10px',
        marginBottom: '10px',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderRadius: '15px',
        cursor: 'pointer',
        minHeight: '90px',
        background: selectedChat?.chatId === user?.chatId ? '#deecf8ad' : '',
        ':hover': {
          background: '#deecf8ad'
        }
      }:{
        padding: '10px',
        marginBottom: '10px',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderRadius: '15px',
        cursor: 'pointer',
        minHeight: '90px',
        background: selectedChat?.chatId === user?.chatId ? 'rgb(248 222 222 / 68%)' : '',
        ':hover': {
          background: 'rgb(248 222 222 / 68%)'
        }
      }}
    >
      <Grid item xs={2.5}>
        <Avatar
          onClick={() => {
            // navigate(`/${language}/user-page/${user.id}`);
          }}
          src={
            user.userInfoDTO?.encodedMediaDTO
              ? `data:${user.userInfoDTO?.encodedMediaDTO.contentType};base64, ${user.userInfoDTO?.encodedMediaDTO.encodedContent}`
              : DefaultAvatar
          }
          sx={{
            width: 63,
            height: 63,
            border: '1px solid #60A5DF',
            cursor: 'pointer',
            boxShadow: blockedUsers.find(
              (el) => el.id === user?.userInfoDTO?.id
            )
              ? '0px 0px 2px 2px rgba(255,0,0,1)'
              : 'none',
          }}
        />
      </Grid>
      <Grid item xs={8}>
        <Stack sx={{ width: '100%' }}>
          {user?.userInfoDTO?.name&&user?.userInfoDTO?.surname?<Typography
            variant="subtitle"
            sx={{
              fontWeight:
                user?.lastMessage &&
                !user?.lastMessage?.messageIsRead &&
                user?.lastMessage?.senderId !== userData.id
                  ? '700'
                  : '400',
              fontFamily: 'Public Sans,sans-serif !important',
              fontSize: '16px',
              textAlign: 'left',
              cursor: 'pointer'
            }}
            onClick={() => {
              // navigate(`/${language}/user-page/${user.id}`);
            }}
          >
            {`${user?.userInfoDTO?.name} ${user?.userInfoDTO?.surname}`}
          </Typography>:<Typography
            variant="subtitle"
            sx={{
              fontWeight:
                user?.lastMessage &&
                !user?.lastMessage?.messageIsRead &&
                user?.lastMessage?.senderId !== userData.id
                  ? '700'
                  : '400',
              fontFamily: 'Public Sans,sans-serif !important',
              fontSize: '16px',
              textAlign: 'left',
              cursor: 'pointer'
            }}
            onClick={() => {
              // navigate(`/${language}/user-page/${user.id}`);
            }}
          >
            {`@${user?.userInfoDTO?.nickname}`}
          </Typography>}
          
          {user?.lastMessage && (
            <Typography
              variant="subtitle"
              sx={{
                fontFamily: 'Public Sans,sans-serif !important',
                fontWeight:
                  user?.lastMessage &&
                  !user?.lastMessage?.messageIsRead &&
                  user?.lastMessage?.senderId !== userData.id
                    ? '700'
                    : '400',
                fontSize: '14px',
                textAlign: 'left',
                cursor: 'pointer',
                display: '-webkit-box',
                WebkitLineClamp: 1,
                WebkitBoxOrient: 'vertical',
                overflowY: 'hidden',
                textOverflow: 'ellipsis',
                wordBreak: 'break-all',
                maxWidth: '90%'
              }}
            >
              {`${user?.lastMessage?.senderId === userData.id ? 'Ви: ' : ''} ${
                user?.lastMessage?.type === 'text' &&
                user?.lastMessage?.text &&
                user?.lastMessage?.text?.length !== 0
                  ? user?.lastMessage?.text
                  : ''
              }${
                user?.lastMessage?.text?.length === 0 &&
                user?.lastMessage?.forwardedMessage &&
                user?.forwardedMessage?.abstractMessageWithDTO?.text?.length !== 0
                  ? user?.lastMessage?.forwardedMessage?.abstractMessageWithDTO?.text
                  : ''
              }${user?.lastMessage?.type === 'user' ? 'Надіслано профіль' : ''}${
                user?.lastMessage?.type === 'post' ? 'Надіслано пост' : ''
              }${user?.lastMessage?.type === 'service' ? 'Надіслано послугу' : ''}`}
            </Typography>
          )}
        </Stack>
      </Grid>
      <Grid item xs={1.5} sx={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
        <Stack sx={{ width: '100%', alignItems: 'end' }}>
          {!user.isImportantForUser ? (
            <StarOutlineOutlinedIcon
              onClick={(event) => {
                event.stopPropagation();
                importantHandler();
              }}
              sx={{
                zIndex: 99,
                color: '#D6D6D6',
                ':hover': {
                  color: 'rgb(239, 201, 56)'
                }
              }}
            />
          ) : (
            <StarIcon
              onClick={(event) => {
                event.stopPropagation();
                importantHandler();
              }}
              sx={{
                zIndex: 99,
                color: 'rgb(239, 201, 56)'
              }}
            />
          )}
          <div style={{ display: 'flex' }}>
            {user?.lastMessage && (
              <>
                {!user?.lastMessage?.messageIsRead ? (
                  <DoneIcon sx={{ fontSize: '19px', color: '#216BAA' }} />
                ) : (
                  <DoneAllIcon sx={{ fontSize: '19px', color: '#216BAA' }} />
                )}
              </>
            )}

            <Typography
              variant="subtitle"
              sx={{
                fontFamily: 'Public Sans,sans-serif !important',
                fontSize: '14px',
                textAlign: 'left',
                cursor: 'pointer',
                ml: 0.5
              }}
              onClick={() => {
                // navigate(`/${language}/user-page/${user.id}`);
              }}
            >
              {user?.lastMessage?.sendingTime && typeof user?.lastMessage?.sendingTime === 'string'
                ? timeDiffChat(user?.lastMessage?.sendingTime)
                : timeDiffChat(new Date(user?.lastMessage?.sendingTime * 1000))}
            </Typography>
          </div>
        </Stack>
      </Grid>
    </Grid>
  );
}
const mapStateToProps = (state) => {
  return {
    language: state.Intl.locale,
    userData: state.global.userData,
    blockedUsers: state.global.blockedUsers,
  };
};
export default connect(mapStateToProps)(UserListCardChat);
