import React, { useEffect, useState } from 'react';

//Material
import {
  Avatar,
  Box,
  ClickAwayListener,
  Grid,
  Menu,
  MenuItem,
  TextField,
  Typography,
  styled
} from '@mui/material';
// import { dateFormattingByDate } from '../helpers/date-helpers';
import DefaultAvatar from '../assets/defaultPostImg.jpg';
import ReplyIcon from '@mui/icons-material/Reply';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import ApiService from '../helpers/apiHelper';
import { LetsGoBtn2 } from './Buttons';
import { connect, useDispatch, useSelector } from 'react-redux';
import { timeDiff } from '../helpers/date-helpers';
import { useNavigate } from 'react-router-dom';
import EmojiPicker from 'emoji-picker-react';
import EmojiEmotionsOutlinedIcon from '@mui/icons-material/EmojiEmotionsOutlined';

import { setIsOpenComplainModal, setIsOpenDeleteModal } from '../redux/mainReducer';
import { useSnackbar } from 'notistack';

const CssTextField = styled(TextField)({
  '& label.Mui-focused': {
    color: '#000000'
  },
  '& label': {
    color: '#A1A1A1',
    fontFamily: 'Kyiv-Sans-Light'
  },
  'textarea::placeholder': {
    textAlign: 'left',
    lineHeight: '25.9px'
  },
  margin: '5px 20px 0px 0px'
});

function ResponseBlock({
  parrentCommentWriter,
  dataSet,
  index,
  response,
  setter,
  setToDelete,
  setModalText,
  isOnComments,

  ...props
}) {
  const [responses, setResponses] = useState([]);
  // eslint-disable-next-line
  const [isShowResp, setIsShowResp] = useState(true);
  const [error, setError] = useState('');
  const [responseText, setResponseText] = useState('');
  const [isShowRespField, setIsShowRespField] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const isOpenDeleteModal = useSelector((state) => state.global.isOpenDeleteModal);
  const userData = useSelector((state) => state.global.userData);
  const language = useSelector((state) => state.Intl.locale);

  const [isEditing, setIsEditing] = useState(false);
  const [editedComment, setEditedComment] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [isShowEmoji, setIsShowEmoji] = useState(false);
  const [isShowEmojiEdit, setIsShowEmojiEdit] = useState(false);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const apiCommentUpdate = new ApiService('posts/comment/update-comment');
  const apiAddLike = new ApiService('posts/comment/like');
  const apiComment = new ApiService('posts/comment');
  const apiResponses = new ApiService('posts/comment/get-responses');
  const apiComplaint = new ApiService('posts/comment/complaint-comment');

  // const apiResponses = new ApiService('/comment/get-responses');
  // const apiComment = new ApiService('/comment');
  // const apiCommentUpdate = new ApiService('/comment/update-comment');
  // const apiAddLike = new ApiService('/comment/add-like');
  // const apiComplaint = new ApiService('/comment/complaint-comment');

  const sendComplaint = (type, text) => {
    apiComplaint
      .updateDataID(response?.id, {
        complaintType: type,
        complaintDescription: text.length !== 0 ? text : null
      })
      .then((res) => {
        dispatch(setIsOpenComplainModal({ isOpen: false, func: null }));
        enqueueSnackbar('Скаргу надіслано.', { variant: 'success' });
      })
      .catch((error) => {
        console.error(error);
        enqueueSnackbar('Сталась помилка.', { variant: 'error' });
      });
  };

  const getResponses = () => {
    apiResponses.getItemById(response.id).then((res) => {
      setResponses([...res.result]);
    });
  };

  const deleteElement = (id) => {
    apiComment.deleteItem(id).then((res) => {
      if (dataSet) {
        dataSet();
      }
      if (setter) {
        setter();
      }
      dispatch(setIsOpenDeleteModal({ ...isOpenDeleteModal, isOpen: false }));
    });
  };
  // eslint-disable-next-line
  useEffect(() => getResponses(), []);
  return (
    <div
      key={`response${index}`}
      style={{ display: 'flex', flexDirection: 'column', marginTop: '10px' }}
    >
      <Grid container style={{ display: 'flex' }}>
        <Grid xs={1.5} style={{ display: 'flex' }}>
          <Avatar
            onClick={() => navigate(`/${language}/user-page/${response.userDTO.id}?type=posts`)}
            alt="avatar"
            src={
              response?.userDTO?.encodedMediaDTO
                ? `data:${response?.userDTO?.encodedMediaDTO.contentType};base64, ${response?.userDTO?.encodedMediaDTO.encodedContent}`
                : DefaultAvatar
            }
            sx={{
              width: 70,
              height: 70,
              mr: 2,
              border: '1px solid #60A5DF',
              cursor: 'pointer',
              boxShadow:
                props?.blockedUsers &&
                props?.blockedUsers.find((user) => user.id === response?.userDTO?.id)
                  ? '0px 0px 2px 2px rgba(255,0,0,1)'
                  : 'none'
            }}
          />
        </Grid>
        <Grid item xs={10}>
          {' '}
          <Typography
            onClick={() => navigate(`/${language}/user-page/${response.userDTO.id}?type=posts`)}
            variant="subtitle2"
            sx={{
              fontFamily: 'Kyiv-Sans-Bold',
              fontSize: '16px',
              marginBottom: '5px !important',
              textAlign: 'left',
              fontWeight: '700',
              cursor: 'pointer',
              position: 'relative'
            }}
          >
            {`${response?.userDTO?.nickname}`}

            <Typography sx={{ color: '#868686', fontSize: '16px', ml: 2 }} variant="subtitle">
              {timeDiff(response?.creationTimeForComment)}
            </Typography>

            {/* {userData.id === response.userDTO.id ? (
            <MoreVertIcon
              sx={{ ml: 1, cursor: 'pointer', ':hover': { color: '#60A5DF' } }}
              onClick={handleClick}
            />
          ) : null} */}

            <MoreVertIcon
              onClick={(event) => {
                event.stopPropagation();
                handleClick(event);
              }}
              sx={{
                ml: 1,
                cursor: 'pointer',
                ':hover': { color: '#60A5DF' },
                position: 'absolute',
                right: '-62px'
              }}
            />
          </Typography>
          {isEditing ? (
            <div
              style={{
                display: 'flex',
                alignItems: 'start',
                flexDirection: 'column'
              }}
            >
              <CssTextField
                multiline
                minRows={1}
                maxRows={9}
                variant="standard"
                placeholder="Відповісти"
                fullWidth
                sx={{ ml: 0.5 }}
                size="normal"
                inputProps={{ style: { textAlign: 'left' } }}
                InputProps={{
                  endAdornment: (
                    <>
                      <EmojiEmotionsOutlinedIcon
                        onClick={() => {
                          setIsShowEmojiEdit(!isShowEmojiEdit);
                        }}
                        sx={{
                          color: '#60A5DF',
                          cursor: 'pointer',
                          fontSize: '20px',
                          mr: 1
                        }}
                      />
                    </>
                  )
                }}
                value={editedComment}
                onChange={(event) => {
                  if (isShowEmojiEdit) {
                    setIsShowEmojiEdit(false);
                  }
                  if (event.target.value.length <= 500) setEditedComment(event.target.value);
                  setError('');
                }}
              />
              <p
                style={{ margin: '0 5px', color: 'lightgray', textAlign: 'left', fontSize: '14px' }}
              >{`${editedComment.length}/500`}</p>
              {error.length !== 0 && (
                <p
                  style={{ margin: '0 5px', color: 'red', textAlign: 'left', fontSize: '14px' }}
                >{`${error}`}</p>
              )}
              {isShowEmojiEdit && (
                <ClickAwayListener
                  onClickAway={() => {
                    setIsShowEmojiEdit(false);
                  }}
                >
                  <Box>
                    {' '}
                    <EmojiPicker
                      onEmojiClick={(emojiData, event) => {
                        let newStr = `${editedComment}${emojiData.emoji}`;
                        setEditedComment(newStr);
                      }}
                      skinTonesDisabled
                      searchDisabled
                      previewConfig={{ showPreview: false }}
                      style={{
                        position: 'absolute',
                        bottom: error.length !== 0 ? '-190px' : '-210px',
                        right: '40px',
                        zIndex: '99'
                      }}
                      width={'60%'}
                      height={'300px'}
                    />
                  </Box>
                </ClickAwayListener>
              )}
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  width: '100%',
                  margin: '20px 0px 0px 20px'
                }}
              >
                <LetsGoBtn2
                  text="Зберегти зміни"
                  width="155px"
                  marginTop="0px"
                  onClick={() => {
                    setIsShowEmojiEdit(false);
                    if (editedComment.length === 0) {
                      setError('Мінімальна довжина відповіді: 1 символ');
                    } else if (editedComment.length > 500) {
                      setError('Максимальна довжина відповіді: 500 символів');
                    } else {
                      apiCommentUpdate
                        .updateDataID(response.id, { text: editedComment })
                        .then((res) => {
                          setter();
                          setIsEditing(false);
                          setEditedComment('');
                        });
                    }
                  }}
                />
                <Typography
                  fontSize={14}
                  variant="body"
                  sx={{
                    textDecoration: 'underline',
                    margin: '0px',
                    ml: 3,
                    fontWeight: '700',
                    cursor: 'pointer',
                    zIndex: '98',
                    ':hover': {
                      fontWeight: '1000'
                    }
                  }}
                  onClick={() => {
                    setIsShowEmojiEdit(false);
                    setIsEditing(false);
                  }}
                >
                  Скасувати
                </Typography>
              </div>
            </div>
          ) : (
            <div>
              <Typography
                sx={{
                  fontSize: '16px',
                  marginBottom: '5px !important',
                  textAlign: 'left'
                }}
                children={response?.text
                  .split('\r\n')
                  .filter((el) => el.length !== 0)
                  .map((string, index) => {
                    return (
                      <p key={index} style={{ margin: '0' }}>
                        <span
                          onClick={() => navigate(`/${language}/user-page/${response.userDTO.id}?type=posts`)}
                          style={{
                            margin: '0px 5px 0px 0px',
                            fontSize: '16px',
                            textDecoration: 'underline',
                            cursor: 'pointer',
                            width: 'fit-content'
                          }}
                          // >{`@${parrentCommentWriter.nickname}`}</span>
                        >{`@${parrentCommentWriter?.nickname}`}</span>
                        {string}
                        <br />
                      </p>
                    );
                  })}
              />
              <div style={{ display: 'flex' }}>
                {response?.valueLikes > 0 && response?.valueLikes}

                {response?.idsUsersThatLikesComment.length !== 0 &&
                response?.idsUsersThatLikesComment.includes(userData?.id) ? (
                  <FavoriteIcon
                    onClick={() =>
                      props?.blockedUsers &&
                      !props?.blockedUsers.find((user) => user.id === response?.userDTO?.id) &&
                      apiAddLike
                        .updateDataQuery({ commentId: response.id, isLikedComment: false })
                        .then((res) => setter())
                    }
                    sx={{
                      color: 'red',
                      cursor: 'pointer',
                      ml: 1
                    }}
                  />
                ) : (
                  <FavoriteBorderIcon
                    onClick={() =>
                      props?.blockedUsers &&
                      !props?.blockedUsers.find((user) => user.id === response?.userDTO?.id) &&
                      apiAddLike
                        .updateDataQuery({ commentId: response.id, isLikedComment: true })
                        .then((res) => setter())
                    }
                    sx={{
                      ':hover': {
                        color: 'red'
                      },
                      cursor: 'pointer',
                      ml: 1
                    }}
                  />
                )}
                {props?.blockedUsers &&
                !props?.blockedUsers.find((user) => user.id === response?.userDTO?.id) &&
                isOnComments ? (
                  <ReplyIcon
                    onClick={() => setIsShowRespField(!isShowRespField)}
                    sx={{
                      ml: 2,
                      cursor: 'pointer',
                      ':hover': {
                        color: '#60A5DF'
                      }
                    }}
                  />
                ) : null}
              </div>
            </div>
          )}
          {isShowRespField && isOnComments && (
            <>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <CssTextField
                  disabled={
                    props?.blockedUsers &&
                    props?.blockedUsers.find((user) => user.id === response?.userDTO?.id)
                  }
                  variant="standard"
                  placeholder="Відповісти"
                  fullWidth
                  multiline
                  minRows={1}
                  maxRows={10}
                  size="normal"
                  inputProps={{ style: { textAlign: 'left' } }}
                  value={responseText}
                  onChange={(event) => {
                    if (isShowEmoji) {
                      setIsShowEmoji(false);
                    }
                    if (event.target.value.length <= 500) setResponseText(event.target.value);
                    setError('');
                  }}
                  InputProps={{
                    startAdornment: (
                      <p
                        style={{
                          margin: '0px 5px 0px 0px',
                          fontFamily: 'Kyiv-Sans-Light',
                          fontSize: '15px'
                        }}
                      >
                        @{response?.userDTO?.nickname}
                      </p>
                    ),
                    endAdornment: (
                      <>
                        <EmojiEmotionsOutlinedIcon
                          onClick={() => {
                            setIsShowEmoji(!isShowEmoji);
                          }}
                          sx={{
                            color: '#60A5DF',
                            cursor: 'pointer',
                            fontSize: '20px',
                            mr: 1
                          }}
                        />
                      </>
                    )
                  }}
                />
                {isShowEmoji && (
                  <ClickAwayListener
                    onClickAway={() => {
                      setIsShowEmoji(false);
                    }}
                  >
                    <Box>
                      <EmojiPicker
                        onEmojiClick={(emojiData, event) => {
                          let newStr = `${responseText}${emojiData.emoji}`;
                          setResponseText(newStr);
                        }}
                        skinTonesDisabled
                        searchDisabled
                        previewConfig={{ showPreview: false }}
                        style={{
                          position: 'absolute',
                          bottom: error.length !== 0 ? '-250px' : '-270px',
                          right: '166px',
                          zIndex: '99'
                        }}
                        width={'60%'}
                        height={'300px'}
                      />
                    </Box>
                  </ClickAwayListener>
                )}
                <LetsGoBtn2
                  disabled={
                    props?.blockedUsers &&
                    props?.blockedUsers.find((user) => user.id === response?.userDTO?.id)
                  }
                  text="Додати"
                  width="105px"
                  marginTop="0px"
                  onClick={() => {
                    setIsShowEmoji(false);
                    if (responseText.length === 0) {
                      setError('Мінімальна довжина відповіді: 1 символ');
                    } else if (responseText.length > 500) {
                      setError('Максимальна довжина відповіді: 500 символів');
                    } else {
                      apiComment
                        .sendRequest({
                          text: responseText,
                          id: response.id,
                          typeComment: 'response'
                        })
                        .then((res) => {
                          setter();
                          setIsShowRespField(false);
                          setResponseText('');
                          getResponses();
                        });
                    }
                  }}
                />
              </div>
              <p
                style={{ margin: '0 5px', color: 'lightgray', textAlign: 'left', fontSize: '14px' }}
              >{`${responseText.length}/500`}</p>
              {error.length !== 0 && (
                <p
                  style={{ margin: '0 5px', color: 'red', textAlign: 'left', fontSize: '14px' }}
                >{`${error}`}</p>
              )}
            </>
          )}
          {/* {response.numberResponses !== 0 && (
          <>
            {!isShowResp ? (
              <Typography
                onClick={() => {
                  getResponses();
                  setIsShowResp(true);
                }}
                variant="subtitle"
                sx={{
                  display: 'inline-flex',
                  cursor: 'pointer',
                  color: '#A1A1A1',
                  fontSize: '14px',
                  textDecoration: 'underline',
                  textUnderlineOffset: '2px'
                }}
              >
                <ReplyIcon sx={{ mr: 1, fontSize: 20, color: '#A1A1A1' }} />
                {`Показати відповіді (${response.numberResponses})`}
              </Typography>
            ) : (
              <Typography
                onClick={() => {
                  // setResponses([]);
                  setIsShowResp(false);
                }}
                variant="subtitle"
                sx={{
                  display: 'inline-flex',
                  cursor: 'pointer',
                  color: '#A1A1A1',
                  fontSize: '14px',
                  textDecoration: 'underline',
                  textUnderlineOffset: '2px'
                }}
              >
                <ReplyIcon sx={{ mr: 1, fontSize: 20, color: '#A1A1A1' }} />

                {`Приховати відповіді (${response.numberResponses})`}
              </Typography>
            )}
          </>
        )} */}
        </Grid>

        <Menu
          anchorEl={anchorEl}
          id="action-menu"
          open={open}
          onClose={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: 'hidden',
              boxShadow: 'none',
              borderRadius: '5px',
              border: '1px solid black',
              paddingTop: '10px',
              paddingLeft: '20px',
              paddingRight: '20px',
              width: '250px',
              mt: 1
            }
          }}
          autoFocus={false}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
          {response?.userDTO?.id !== userData?.id && (
            <div>
              <MenuItem
                sx={{ padding: 0, mb: 1 }}
                onClick={() => {
                  dispatch(setIsOpenComplainModal({ isOpen: true, func: sendComplaint }));

                  handleClose();
                }}
              >
                <ErrorOutlineIcon sx={{ color: '#60A5DF', mr: 1 }} />
                <Typography component="p" fontSize={16} lineHeight="18px" fontWeight={400}>
                  Поскаржитись
                </Typography>
              </MenuItem>
            </div>
          )}
          {response?.userDTO?.id === userData?.id && (
            <div>
              {isOnComments ? (
                <MenuItem
                  sx={{ padding: 0, mb: 1 }}
                  onClick={(event) => {
                    event.stopPropagation();
                    setIsShowRespField(false);
                    setEditedComment(response?.text);
                    setIsEditing(true);
                    handleClose();
                  }}
                >
                  <ModeEditOutlineOutlinedIcon sx={{ color: '#60A5DF', mr: 1 }} />
                  <Typography component="p" fontSize={16} lineHeight="18px" fontWeight={400}>
                    Редагувати
                  </Typography>
                </MenuItem>
              ) : null}

              <MenuItem
                sx={{ padding: 0, mb: 1 }}
                onClick={(event) => {
                  event.stopPropagation();
                  event.preventDefault();
                  dispatch(
                    setIsOpenDeleteModal({
                      isOpen: true,
                      text: 'цю відповідь?',
                      func: deleteElement,
                      id: response.id
                    })
                  );
                  handleClose();
                }}
              >
                <DeleteOutlineOutlinedIcon sx={{ color: '#60A5DF', mr: 1 }} />
                <Typography component="p" fontSize={16} lineHeight="18px" fontWeight={400}>
                  Видалити відповідь
                </Typography>
              </MenuItem>
            </div>
          )}
        </Menu>
      </Grid>

      {/* <Typography
          sx={{
            fontSize: '16px',
            marginBottom: '5px !important',
            textAlign: 'left'
          }}
          children={response.text
            .split('\r\n')
            .filter((el) => el.length !== 0)
            .map((string, index) => {
              return (
                <p key={index} style={{ margin: '0' }}>
                  {string}
                  <br />
                </p>
              );
            })}
        />

        <div style={{ display: 'flex' }}>
          {response.valueLikes > 0 && response.valueLikes}
          {response?.idsUsersThatLikesComment.length !== 0 &&
          response?.idsUsersThatLikesComment.includes(userData?.id) ? (
            <FavoriteIcon
              onClick={() =>
                apiRemoveLike.postDataQuery({ commentId: response.id }).then((res) => setter())
              }
              sx={{
                color: 'red',
                cursor: 'pointer',
                ml: 1
              }}
            />
          ) : (
            <FavoriteBorderIcon
              onClick={() =>
                apiAddLike.updateDataQuery({ commentId: response.id }).then((res) => setter())
              }
              sx={{
                ':hover': {
                  color: 'red'
                },
                cursor: 'pointer',
                ml: 1
              }}
            />
          )}
          <ReplyIcon
            onClick={() => setIsShowRespField(!isShowRespField)}
            sx={{
              ml: 2,
              cursor: 'pointer',
              ':hover': {
                color: '#60A5DF'
              }
            }}
          />
        </div> */}

      {isShowResp &&
        responses.length !== 0 &&
        responses.map((resp, index) => (
          <ResponseBlock
            response={resp}
            setter={getResponses}
            dataSet={setter}
            parrentCommentWriter={response.userDTO}
          />
        ))}
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    language: state.Intl.locale,
    userData: state.global.userData,
    blockedUsers: state.global.blockedUsers
  };
};
export default connect(mapStateToProps)(ResponseBlock);
