import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

//Components MUi
import {
  Avatar,
  Box,
  Collapse,
  Grid,
  IconButton,
  Pagination,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
// Additional components
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';
import ArrowDropUpOutlinedIcon from '@mui/icons-material/ArrowDropUpOutlined';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import StarOutlineOutlinedIcon from '@mui/icons-material/StarOutlineOutlined';

import DefaultAvatar from '../../../assets/defaultPostImg.jpg';
import { FreeBtn } from '../../../components/Buttons';
import ApiService from '../../../helpers/apiHelper';
import { dateFormattingByDateShort } from '../../../helpers/date-helpers';
import { StatusHandler } from '../handlers';
import { Spinner } from '../../../components/Spinner';
import { StyledTitle } from '../../../components/StyledComponents';

function createTableData(
  service,
  seller,
  price,
  buyingDate,
  qty,
  status,
  details,
  estimatedDateShipment,
  serviceID,
  currency
) {
  return {
    service,
    seller,
    price,
    buyingDate,
    qty,
    status,
    details,
    estimatedDateShipment,
    serviceID,
    currency
  };
}

function Row(props) {
  const { row, language, userData } = props;
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const apiChats = new ApiService('notification/chats');

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell component="th" scope="row" sx={{ borderColor: '#60A5DF', p: '5px 16px' }}>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography
              onClick={() => navigate(`/${language}/service/${row.serviceID}`)}
              sx={{
                fontFamily: 'Kyiv-Sans-Light',
                fontSize: '16px',
                textDecoration: 'underline',
                cursor: 'pointer',
                width: 'fit-content'
              }}
            >
              {row.service}
            </Typography>
            {row?.details && (
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography
                  sx={{
                    fontFamily: 'Kyiv-Sans-Light',
                    color: '#757575',
                    fontSize: '16px',
                    cursor: 'pointer',
                    width: 'max-content'
                  }}
                  onClick={() => setOpen(!open)}
                >
                  {open ? 'Згорнути деталі' : 'Деталі замовлення'}
                </Typography>
                <IconButton
                  disableRipple
                  disableFocusRipple
                  aria-label="expand row"
                  size="small"
                  sx={{
                    ':hover': {
                      backgroundColor: 'unset !important'
                    }
                  }}
                  onClick={() => setOpen(!open)}
                >
                  {open ? <ArrowDropUpOutlinedIcon /> : <ArrowDropDownOutlinedIcon />}
                </IconButton>
              </Box>
            )}
          </Box>
        </TableCell>
        <TableCell align="center" sx={{ borderColor: '#60A5DF', p: '5px 16px' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {' '}
            <Avatar
              onClick={() => {
                navigate(`/${language}/user-page/${row.seller.id}?type=posts`);
              }}
              src={
                row.seller?.encodedMediaDTO
                  ? `data:${row.seller?.encodedMediaDTO.contentType};base64, ${row.seller?.encodedMediaDTO.encodedContent}`
                  : DefaultAvatar
              }
              sx={{
                width: 48,
                height: 48,
                mr: 2,
                cursor: 'pointer',
                border: !props?.blockedUsers.find((el) => el.id === row?.seller?.id)
                  ? '1px solid #60A5DF'
                  : 'none',
                boxShadow: props?.blockedUsers.find((el) => el.id === row?.seller?.id)
                  ? '0px 0px 2px 2px rgba(255,0,0,1)'
                  : 'none'
              }}
            />
            <Stack sx={{ width: 'max-content', marginTop: '5px' }}>
              {row?.seller?.name && row?.seller?.surname && (
                <Typography
                  variant="subtitle"
                  sx={{
                    fontFamily: 'Public Sans,sans-serif !important',
                    fontSize: '18px',
                    textAlign: 'left',
                    cursor: 'pointer'
                  }}
                  onClick={() => {
                    navigate(`/${language}/user-page/${row.seller.id}?type=posts`);
                  }}
                >
                  {`${row.seller?.name} ${row.seller.surname}`}
                </Typography>
              )}

              <Typography
                variant="subtitle"
                sx={{
                  fontFamily: 'Public Sans,sans-serif !important',
                  fontSize: row?.seller?.name && row?.seller?.surname ? '14px' : '18px',
                  textAlign: 'left',
                  cursor: 'pointer'
                }}
                onClick={() => {
                  navigate(`/${language}/user-page/${row.seller.id}?type=posts`);
                }}
              >
                {`@${row.seller?.nickname}`}
              </Typography>
            </Stack>
          </Box>
        </TableCell>
        <TableCell align="center" sx={{ borderColor: '#60A5DF', p: '5px 16px' }}>
          <Typography sx={{ fontSize: '16px' }}>{`${row?.price ? row?.price : ''} ${
            row?.currency ? row?.currency : ''
          }`}</Typography>
        </TableCell>
        {/* <TableCell align="center" sx={{ borderColor: '#60A5DF', p: '5px 16px' }}>
          <Typography sx={{ fontSize: '16px' }}>{row.buyingDate}</Typography>
        </TableCell> */}
        <TableCell align="center" sx={{ borderColor: '#60A5DF', p: '5px 16px' }}>
          <Typography sx={{ fontSize: '16px' }}>{row.qty}</Typography>
        </TableCell>
        <TableCell align="center" sx={{ borderColor: '#60A5DF', p: '5px 16px' }}>
          {StatusHandler(row.status, 'history')}
        </TableCell>
        <TableCell align="center" sx={{ borderColor: '#60A5DF', p: '5px 16px' }}>
          {row?.estimatedDateShipment && row?.estimatedDateShipment.length !== 0
            ? row?.estimatedDateShipment
            : '---'}
        </TableCell>
      </TableRow>

      <TableRow>
        {' '}
        {row?.details && (
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Grid container>
                <Grid
                  item
                  xs={4}
                  sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'start' }}
                >
                  <Typography sx={{ fontSize: '14px', fontWeight: '600' }}>
                    Про замовлення:
                  </Typography>
                  <Typography sx={{ fontSize: '14px' }}>{row.details.about}</Typography>
                  <Typography sx={{ fontSize: '14px', fontWeight: '600' }}>
                    Дата замовлення:
                  </Typography>
                  <Typography sx={{ fontSize: '14px' }}>{row.buyingDate}</Typography>
                  <Typography sx={{ fontSize: '14px', fontWeight: '600' }}>
                    Контактні дані:
                  </Typography>
                  <Typography sx={{ fontSize: '14px' }}>{row.details.contacts}</Typography>
                  <Typography sx={{ fontSize: '14px', fontWeight: '600' }}>
                    Спосіб доставки:
                  </Typography>
                  <Typography sx={{ fontSize: '14px' }}>{row.details.deliveryType}</Typography>
                  <Typography sx={{ fontSize: '14px', fontWeight: '600' }}>
                    Спосіб оплати:
                  </Typography>
                  <Typography sx={{ fontSize: '14px' }}>{row.details.paymentType}</Typography>
                  {row.details.comment.length !== 0 && (
                    <>
                      <Typography sx={{ fontSize: '14px', fontWeight: '600' }}>
                        Коментар до замовлення:
                      </Typography>
                      <Typography sx={{ fontSize: '14px' }}>{row.details.comment}</Typography>
                    </>
                  )}
                </Grid>
                <Grid
                  item
                  xs={8}
                  sx={{
                    display: 'flex',
                    flexDirection: row.status !== 'DONE' ? 'column' : 'row',
                    alignItems: 'end',
                    justifyContent: 'end',
                    mb: 2
                  }}
                >
                  {row.status === 'DONE' && (
                    <Typography
                      onClick={() => navigate(`/${language}/service/${row.serviceID}`)}
                      sx={{
                        fontFamily: 'Kyiv-Sans-Light',
                        fontSize: '16px',
                        textDecoration: 'underline',
                        cursor: 'pointer',
                        width: 'fit-content',
                        mr: 2,
                        mb: 1.6,
                        display: 'flex'
                      }}
                    >
                      <StarOutlineOutlinedIcon sx={{ color: '#60A5DF', mr: 0.4 }} />
                      Написати відгук
                    </Typography>
                  )}
                  <FreeBtn
                    padding="10px 24px"
                    onClick={() => {
                      apiChats
                        .postDataQuery({
                          userId: userData.id,
                          recipientId: row.seller.id
                        })
                        .then((res) => {
                          navigate(`/${language}/messages`);
                        })
                        .catch((error) => {
                          if (error?.message.includes('There is a chat between these users')) {
                            const id = `${error.message.slice(
                              error.message.indexOf('[') + 1,
                              error.message.length - 1
                            )}`;

                            navigate(`/${language}/messages?chatId=${id}`);
                          }
                        });
                    }}
                    text="Написати продавцю"
                    icon={
                      <MailOutlineIcon sx={{ color: '#60A5DF', marginRight: '10px !important' }} />
                    }
                  />
                </Grid>
              </Grid>
            </Collapse>
          </TableCell>
        )}
      </TableRow>
    </React.Fragment>
  );
}

function History({ language, data, userData, blockedUsers, ...props }) {
  const apiHistoryOrders = new ApiService('posts/scheduler/history');
  // const apiHistoryOrders = new ApiService('/scheduler/history');
  // eslint-disable-next-line
  const [rows, setRows] = useState([]);
  const [isLoading, setIsLoadiang] = useState(false);
  const [pages, setPages] = useState(0);
  const [selectedPage, setSelectedPage] = useState(1);
  // eslint-disable-next-line
  const [params, setParams] = useSearchParams();

  const dataSetter = () => {
    setIsLoadiang(true);
    apiHistoryOrders
      .getAll({
        from: 0,
        size: 5,
        search: params.get('question') ? params.get('question') : '',
        sortDirection: params.get('sd') ? params.get('sd') : 'DESC'
      })
      .then((res) => {
        const preparedRows = res.result.map((order) =>
          createTableData(
            order?.service?.nameService,
            order?.seller,
            +order?.price,
            dateFormattingByDateShort(new Date(order?.buyingDate)),
            `${order?.qty} од.`,
            order?.status,
            {
              about: order?.service?.nameService,
              contacts: order?.details?.contact,
              deliveryType: order?.details?.deliveryType,
              paymentType: order?.details?.paymentType,
              comment: order?.details?.comment
            },
            order?.estimatedTimeShipment !== null && order?.estimatedTimeShipment
              ? dateFormattingByDateShort(new Date(order?.estimatedTimeShipment))
              : null,
            order?.service?.id,
            order?.currency
          )
        );
        setRows(preparedRows);
        setIsLoadiang(false);
        const countPages = Number.isInteger(res.count / 5)
          ? res.count / 5
          : Math.floor(res.count / 5) + 1;

        setPages(countPages);
      })
      .catch((error) => {
        setIsLoadiang(false);
        console.error(error);
      });
  };
  const handleChangePage = (page) => {
    apiHistoryOrders
      .getAll({
        from: 5 * (page - 1),
        size: 5,
        search: params.get('question') ? params.get('question') : '',
        sortDirection: params.get('sd') ? params.get('sd') : 'DESC'
      })
      .then((res) => {
        const preparedRows = res.result.map((order) =>
          createTableData(
            order?.service?.nameService,
            order?.seller,
            +order?.price,
            dateFormattingByDateShort(new Date(order?.buyingDate)),
            `${order?.qty} ${order?.service?.initOfMeasure}.`,
            order?.status,
            {
              about: order?.service?.nameService,
              contacts: order?.details?.contact,
              deliveryType: order?.details?.deliveryType,
              paymentType: order?.details?.paymentType,
              comment: order?.details?.comment
            },
            order?.estimatedTimeShipment !== null && order?.estimatedTimeShipment
              ? dateFormattingByDateShort(new Date(order?.estimatedTimeShipment))
              : null,
            order?.service?.id,
            order?.currency
          )
        );
        setRows(preparedRows);
        setIsLoadiang(false);
        // setFrom(5 * (page - 1));
      })
      .catch((error) => {
        console.error(error);
      });
  };
  useEffect(() => {
    dataSetter();
    // eslint-disable-next-line
  }, []);
  return (
    <TableContainer
      component={Paper}
      sx={
        rows.length !== 0
          ? { borderRadius: '0px !important', mt: 2.4 }
          : { borderRadius: '0px !important', mt: 2.4, backgroundColor: 'transparent !important' }
      }
    >
      <Table aria-label="collapsible table">
        <TableHead sx={{ background: '#E2F2FF' }}>
          <TableRow>
            <TableCell
              align="center"
              sx={{
                fontFamily: 'Kyiv-Sans-Light',
                fontSize: '16px',
                borderColor: '#60A5DF',
                padding: '10px'
              }}
            >
              Послуга
            </TableCell>
            <TableCell
              align="center"
              sx={{
                fontFamily: 'Kyiv-Sans-Light',
                fontSize: '16px',
                borderColor: '#60A5DF',
                padding: '10px'
              }}
            >
              Продавець
            </TableCell>
            <TableCell
              align="center"
              sx={{
                fontFamily: 'Kyiv-Sans-Light',
                fontSize: '16px',
                borderColor: '#60A5DF',
                padding: '10px'
              }}
            >
              Сума
            </TableCell>
            {/* <TableCell
              align="center"
              sx={{
                fontFamily: 'Kyiv-Sans-Light',
                fontSize: '16px',
                borderColor: '#60A5DF',
                padding: '10px'
              }}
            >
              Дата замовлення
            </TableCell> */}
            <TableCell
              align="center"
              sx={{
                fontFamily: 'Kyiv-Sans-Light',
                fontSize: '16px',
                borderColor: '#60A5DF',
                padding: '10px'
              }}
            >
              Кількість
            </TableCell>
            <TableCell
              align="center"
              sx={{
                fontFamily: 'Kyiv-Sans-Light',
                fontSize: '16px',
                borderColor: '#60A5DF',
                padding: '10px'
              }}
            >
              Статус
            </TableCell>
            <TableCell
              width={'12%'}
              align="center"
              sx={{
                fontFamily: 'Kyiv-Sans-Light',
                fontSize: '16px',
                borderColor: '#60A5DF',
                padding: '10px'
              }}
            >
              Орієнтовна дата відправки
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!isLoading ? (
            <>
              {rows.length !== 0 ? (
                rows.map((row, index) => (
                  <Row
                    key={`${row.service + index}`}
                    row={row}
                    language={language}
                    userData={userData}
                    blockedUsers={blockedUsers}
                  />
                ))
              ) : (
                <TableCell
                  style={{ paddingBottom: 200, paddingTop: 200 }}
                  sx={{ backgroundColor: 'transparent', borderBottom: 'none' }}
                  colSpan={7}
                >
                  <Grid
                    container
                    sx={{
                      maxWidth: '100%'
                      // mt: '20% !important'
                    }}
                    spacing={3}
                  >
                    <Grid item xs={12} sx={{ paddingBottom: '0px' }}>
                      <StyledTitle
                        maxWidth={800}
                        sx={{
                          lineHeight: '38px !important',
                          fontSize: '32px !important',
                          textTransform: 'initial !important',
                          margin: '0px auto 20px !important'
                        }}
                        className="bottomBlock-title"
                      >
                        Тут порожньо
                      </StyledTitle>
                    </Grid>
                    <Grid item xs={12} sx={{ mt: 0, display: 'flex', justifyContent: 'center' }}>
                      <Typography textAlign="center" sx={{ maxWidth: '640px' }}>
                        Поки що у вас не було покупок.
                      </Typography>
                    </Grid>
                  </Grid>
                </TableCell>
              )}
            </>
          ) : (
            <TableCell style={{ paddingBottom: 200, paddingTop: 200 }} colSpan={7}>
              <Spinner />
            </TableCell>
          )}
        </TableBody>
      </Table>
      {rows.length !== 0 && !isLoading && (
        <Pagination
          sx={{
            mt: 0,
            mb: 0,
            display: 'flex',
            justifyContent: 'end',
            '& ul > li > button:not(.Mui-selected)': {
              backgroundColor: 'transparent',
              color: 'gray',
              borderColor: `gray!important`,
              margin: 1
            },
            '& .MuiPaginationItem-root': {
              color: 'gray',
              fontSize: '18px'
            },
            '& .Mui-selected': {
              backgroundColor: 'transparent',
              color: '#60A5DF',
              borderColor: '#60A5DF'
            }
          }}
          count={pages}
          defaultPage={1}
          siblingCount={2}
          variant="outlined"
          shape="rounded"
          onChange={(event, value) => {
            if (selectedPage !== +value) {
              setSelectedPage(+value);
              handleChangePage(value);
            } else {
              return;
            }
          }}
        />
      )}
    </TableContainer>
  );
}
const mapStateToProps = (state) => {
  return {
    language: state.Intl.locale,
    userData: state.global.userData,
    blockedUsers: state.global.blockedUsers
  };
};

export default connect(mapStateToProps)(History);
