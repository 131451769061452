import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Page from '../../components/Page';
// import { useNavigate } from 'react-router-dom';

//Material
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
// import PersonOffOutlinedIcon from '@mui/icons-material/PersonOffOutlined';

import { Grid, Stack, Switch, Typography, styled } from '@mui/material';
import { ToTopBtnFree } from '../../components/Buttons';
import ApiService from '../../helpers/apiHelper';
import '../MainPage/index.css';

import InfiniteScroll from 'react-infinite-scroll-component';
import { Element } from 'react-scroll';
import PostCardRibbon from '../../components/PostCardRibbon';
import ServiceCard from '../../components/ServiceCard';
import ServiceRibbonCard from '../../components/ServiceRibbonCard';
import { Spinner } from '../../components/Spinner';
import { StyledTitle } from '../../components/StyledComponents';
import UserListCard from '../../components/UserListCard';
import { renderBanner } from '../../components/Banners/Banners';
// import { Spinner } from '../../components/Spinner';

const MaterialUISwitch = styled(Switch)(({ theme }) => ({
  width: 46,
  height: 26,
  padding: 6,
  '& .MuiSwitch-switchBase': {
    margin: 0,
    padding: '3px',
    transform: 'translateX(0px)',
    '&.Mui-checked': {
      transform: 'translateX(19px)',
      '& + .MuiSwitch-track': {
        opacity: 0.5,
        backgroundColor: '#60a5df'
      }
    }
  },
  '& .MuiSwitch-thumb': {
    backgroundColor: '#60a5df',
    width: 20,
    height: 20
  },
  '& .MuiSwitch-track': {
    opacity: 0.5,
    backgroundColor: '#60a5df',
    borderRadius: 20 / 2
  }
}));

function RibbonPage({ userData }) {
  // eslint-disable-next-line
  const [containerHeight, setContainerHeight] = useState(0);
  const [page, setPage] = useState(0);
  const [pageScroll, setPageScroll] = useState(0);
  const [loadMore, setLoadMore] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [contentMaxCount, setContentMaxCount] = useState(0);
  const [contentSwitched, setContentSwitched] = useState(false);
  const [isLoading, setIsLoadiang] = useState(false);
  const [isLoadingUsers, setIsLoadiangUsers] = useState(false);
  const [isLoadingServices, setIsLoadiangServices] = useState(false);
  const [content, setContent] = useState([]);
  const [users, setUsers] = useState([]);
  const [services, setServices] = useState([]);
  const [randomDesc, setRandomDesc] = useState(0);
  const descriptions = [
    'На даний момент у вас немає новин. Як тільки щось попадеться під руку - одразу покажем.',
    'Зараз новин немає, але цей момент не за горами. Перевірте цю сторінку пізніше.',
    'Зараз тут і кіт не валявся. Але скоро будуть новини. Подивіться поки вкладку “Цікавеньке”'
  ];

  // const apiInterestingUsers = new ApiService('/ribbon/get-interested-user');
  // const apiInterestingServices = new ApiService('ribbon/get-interested-services');
  // const apiInteresting = new ApiService('/ribbon/get-all-interested');
  // const apiSubscribers = new ApiService('/ribbon/get-all-my-subscribers');

  const apiInterestingUsers = new ApiService('posts/ribbon/get-interested-user');
  const apiInterestingServices = new ApiService('posts/ribbon/get-interested-services');
  const apiInteresting = new ApiService('posts/ribbon/get-all-interested');
  const apiSubscribers = new ApiService('posts/ribbon/get-all-my-subscribers');

  // const isOpenServiceModal = useSelector((state) => state.global.isOpenServiceModal);

  // const [params] = useSearchParams();
  // const navigate = useNavigate();
  // console.log(contentSwitched);
  // const testUsers = ['1', '2', '3'];
  // function getFileFromBase64(string64, fileName) {
  //   const trimmedString = string64.replace('dataimage/jpegbase64', '');
  //   const imageContent = atob(trimmedString);
  //   const buffer = new ArrayBuffer(imageContent.length);
  //   const view = new Uint8Array(buffer);

  //   for (let n = 0; n < imageContent.length; n++) {
  //     view[n] = imageContent.charCodeAt(n);
  //   }
  //   const type = 'image/jpeg';
  //   const blob = new Blob([buffer], { type });
  //   return new File([blob], fileName, { lastModified: new Date().getTime(), type });
  // }

  const handleChange = (event) => {
    setContentSwitched(event.target.checked);
    setPage(0);
    setHasMore(true);
  };
  const interestedContentSetter = () => {
    setIsLoadiangUsers(true);
    setIsLoadiangServices(true);
    apiInterestingUsers.getAll({ userId: userData.id }).then((res) => {
      setUsers(res.result);
      setIsLoadiangUsers(false);
    });
    apiInterestingServices.getAll({ userId: userData.id }).then((res) => {
      setServices(res.result);
      setIsLoadiangServices(false);
    });
  };
  const dataSetter = () => {
    setIsLoadiang(true);
    setContent([]);
    if (!contentSwitched) {
      apiInteresting
        .getAll({ userId: userData.id, from: 0, size: 5 })
        .then((res) => {
          setContent(res.result);
          setContentMaxCount(res.totalElements);
          // setContentSwitched(false);
          setIsLoadiang(false);
        })
        .catch((error) => {
          console.error(error);
          setIsLoadiang(false);
        });
    } else {
      apiSubscribers
        .getAll({ userId: userData.id, from: 0, size: 5 })
        .then((res) => {
          setContent(res.result);
          // setContent([]);
          setContentMaxCount(res.totalElements);
          // setContentSwitched(true);
          setIsLoadiang(false);
        })
        .catch((error) => {
          console.error(error);
          setIsLoadiang(false);
        });
    }
  };
  const handleLoadMore = () => {
    setLoadMore(true);

    if (!contentSwitched) {
      apiInteresting
        .getAll({ userId: userData.id, from: page + 5, size: 5 })
        .then((res) => {
          setContent([...content, ...res.result]);
          setContentMaxCount(res.totalElements);
          setPage(page + 5);
          setLoadMore(false);
        })
        .catch((error) => {
          console.error(error);
          setLoadMore(false);
        });
    } else {
      apiSubscribers
        .getAll({ userId: userData.id, from: page + 5, size: 5 })
        .then((res) => {
          setContent([...content, ...res.result]);
          setPage(page + 5);
          setContentMaxCount(res.totalElements);
          setLoadMore(false);
        })
        .catch((error) => {
          console.error(error);
          setLoadMore(false);
        });
    }
  };

  // eslint-disable-next-line

  // const [anchorEl, setAnchorEl] = useState(null);
  // const open = Boolean(anchorEl);
  useEffect(() => {
    if (userData?.id) {
      if (!contentSwitched) {
        // setContentSwitched(false);
        dataSetter(false);
      } else {
        // setContentSwitched(true);
        dataSetter(true);
      }
    }
    // eslint-disable-next-line
  }, [contentSwitched]);
  useEffect(() => {
    if (userData?.id) {
      interestedContentSetter();
    }
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    setRandomDesc(Math.floor(Math.random() * descriptions.length));
    // eslint-disable-next-line
  }, []);
  // const handleClick = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };
  // const handleClose = () => {
  //   setAnchorEl(null);
  // };
  useEffect(() => {
    const container = document.getElementById('scrollableDiv');
    if (container) {
      container.addEventListener('scroll', () => setPageScroll(container.scrollTop));
    }
    return () => {
      if (container) {
        container.removeEventListener('scroll', () => setPageScroll(container.scrollTop));
      }
    };
  });
  useEffect(() => {
    if (content.length !== 0 && content.length >= contentMaxCount) {
      setHasMore(false);
    } else {
      setHasMore(true);
    }
    // eslint-disable-next-line
  }, [content]);
  useEffect(() => {
    const height = document.getElementById('leftContainer');
    if (height && height?.clientHeight) {
      setContainerHeight(height.clientHeight);
    }
  }, [users, services]);
  return (
    <Page
      title="Стрічка"
      style={{
        display: 'flex',
        marginTop: '100px',
        alignItems: 'center',
        flexDirection: 'column',
        height: 'max-content',
        padding: '0px 100px'
      }}
    >
      <Element name="topRibbonPage" />
      <Stack spacing={1} justifyContent="center" alignItems="center">
        <Grid
          container
          sx={{
            width: '100%',
            minWidth: '1240px'
          }}
          spacing={2}
        >
          <Grid
            item
            xs={12}
            sx={{
              display: 'flex',
              justifyContent: 'flex-end'
            }}
          >
            <Stack direction="row" spacing={1} alignItems="center" sx={{ pr: 1 }}>
              <Typography
                variant="subtitle"
                sx={
                  !contentSwitched
                    ? { fontSize: '13px', color: 'black !important' }
                    : { fontSize: '13px' }
                }
              >
                Цікавеньке
              </Typography>
              <MaterialUISwitch
                disabled={isLoading}
                checked={contentSwitched}
                onChange={handleChange}
              />

              <Typography
                variant="subtitle"
                sx={
                  contentSwitched
                    ? { fontSize: '13px', color: 'black !important' }
                    : { fontSize: '13px' }
                }
              >
                Мої підписки
              </Typography>
            </Stack>
          </Grid>

          {/* <Grid item xs={4} /> */}
          <Grid
            id="leftContainer"
            item
            xs={4}
            style={
              {
                // position: 'fixed',
                maxWidth: '421.66px',
                height: 'fit-content',
                minHeight: '500px',
                top: '193px',
                overflowX: 'hidden'
              }
              // { position: 'unset', maxWidth: '421.66px' }
            }
          >
            {isLoadingUsers ? (
              <div
                style={{
                  // height: '240px',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  marginBottom: '100px'
                }}
              >
                <Typography
                  variant="subtitle"
                  component={'p'}
                  sx={{
                    fontSize: '16px',
                    p: '0px 0px 100px 0px !important',
                    fontFamily: 'Kyiv-Sans-Bold'
                  }}
                >
                  Користувачі, які можуть вас зацікавити:
                </Typography>
                <Spinner />
              </div>
            ) : null}
            {users.length !== 0 ? (
              <React.Fragment>
                <Typography
                  variant="subtitle"
                  component={'p'}
                  sx={{ fontSize: '16px', mb: '10px !important', fontFamily: 'Kyiv-Sans-Bold' }}
                >
                  Користувачі, які можуть вас зацікавити:
                </Typography>

                {users.map((user, index) => (
                  <UserListCard user={user} index={index} key={`user-ribbon-${index}`} />
                ))}
              </React.Fragment>
            ) : null}
            {isLoadingServices ? (
              <div
                style={{
                  // height: '240px',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center'
                }}
              >
                <Typography
                  variant="subtitle"
                  component={'p'}
                  sx={{
                    fontSize: '16px',
                    p: '0px 0px 100px 0px !important',
                    fontFamily: 'Kyiv-Sans-Bold'
                  }}
                >
                  Можуть вам сподобатися:
                </Typography>
                <Spinner />
              </div>
            ) : null}
            {services.length !== 0 ? (
              <Stack
                spacing={3}
                sx={
                  users.length === 0
                    ? { p: '0px 102.5px 0px 0px', mt: '0px' }
                    : { p: '0px 102.5px 0px 0px', mt: '100px' }
                }
              >
                <Typography
                  variant="subtitle"
                  component={'p'}
                  sx={{ fontSize: '16px', mb: '0px !important', fontFamily: 'Kyiv-Sans-Bold' }}
                >
                  Можуть вам сподобатися:
                </Typography>
                {services.map((service, index) => (
                  <ServiceCard noWidth maxHeight="327px" key={index} index={index} el={service} />
                ))}
              </Stack>
            ) : null}
          </Grid>
          <Grid item xs={8} sx={{ paddingBottom: '80px', position: 'relative' }} id="scrollParrent">
            <ToTopBtnFree
              onClick={() => {
                const container = document.getElementById('scrollableDiv');
                container.childNodes[0].scrollIntoView({
                  behavior: 'smooth',
                  block: 'center',
                  inline: 'start'
                });
              }}
              visible={pageScroll > 700}
              // sx={{ position: 'absolute', right: '8px !important', bottom: '0' }}
              icon={<ArrowUpwardIcon sx={{ fontSize: '22px' }} />}
            />
            {content.length === 0 ? (
              <>
                {isLoading ? (
                  <div style={{ marginTop: '40%', marginBottom: '180px' }}>
                    <Spinner />
                  </div>
                ) : (
                  <Stack spacing={5} alignItems="center">
                    {!contentSwitched ? (
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <StyledTitle
                          maxWidth={800}
                          sx={{
                            lineHeight: '38px !important',
                            fontSize: '32px !important',
                            textTransform: 'initial !important',
                            margin: '80px auto 0px !important'
                          }}
                          className="bottomBlock-title"
                        >
                          Упс, тут пусто
                        </StyledTitle>
                        <Typography
                          style={{
                            fontStyle: 'normal',
                            fontWeight: '400',
                            fontSize: '16px',
                            lineHeight: '29px',
                            textAlign: 'center',
                            margin: '30px 0px 40px 0px',
                            maxWidth: '500px'
                          }}
                        >
                          {descriptions[randomDesc]}
                        </Typography>
                      </div>
                    ) : (
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <StyledTitle
                          maxWidth={800}
                          sx={{
                            lineHeight: '38px !important',
                            fontSize: '32px !important',
                            textTransform: 'initial !important',
                            margin: '80px auto 0px !important'
                          }}
                          className="bottomBlock-title"
                        >
                          Упс, тут пусто
                        </StyledTitle>
                        <Typography
                          style={{
                            fontStyle: 'normal',
                            fontWeight: '400',
                            fontSize: '16px',
                            lineHeight: '29px',
                            textAlign: 'center',
                            margin: '30px 0px 40px 0px',
                            maxWidth: '500px'
                          }}
                        >
                          {descriptions[randomDesc]}
                        </Typography>
                      </div>
                    )}
                  </Stack>
                )}
              </>
            ) : (
              <InfiniteScroll
                dataLength={content.length}
                next={handleLoadMore}
                hasMore={hasMore}
                scrollableTarget="scrollableDiv"
                scrollThreshold="1000px"
              >
                <Grid
                  id="scrollableDiv"
                  container
                  spacing={1}
                  sx={{
                    overflowY: 'scroll',
                    maxHeight: containerHeight,
                    p: '10px',
                    justifyContent: 'center',
                    display: 'flex'
                  }}
                >
                  {content.map((el, index) => {
                    const getContent = () => {
                      if (el.contentType === 'SERVICE') {
                        return (
                          <Grid key={`service${index}`} item xs={12}>
                            <ServiceRibbonCard
                              noWidth
                              sx={{ marginBottom: '10px !important' }}
                              key={index}
                              index={index}
                              el={el}
                            />
                          </Grid>
                        );
                      }
                      if (el.contentType === 'POST') {
                        return (
                          <Grid key={`post${index}`} item xs={12}>
                            <PostCardRibbon
                              noWidth
                              sx={{ marginBottom: '10px !important' }}
                              key={index}
                              index={index}
                              el={el}
                              dataSetter={dataSetter}
                            />
                          </Grid>
                        );
                      }
                      if (el.contentType === 'ADVERTISING_BANNER') {
                        return (
                          <Grid key={`banner${index}`} item xs={12}>
                            {renderBanner(el?.numberOfAdvertisingBanner)}
                          </Grid>
                        );
                      }
                    };
                    return getContent();
                  })}
                  {loadMore ? (
                    <Grid key={`spinner`} item xs={12} sx={{ marginTop: '5%', marginBottom: '5%' }}>
                      <Spinner />
                    </Grid>
                  ) : null}
                  {/* {contentMaxCount > content.length && (
                  <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                  <LetsGoBtn2
                  sx={{ margin: '30px auto !important' }}
                  text={loadMore ? <Spinner type="small" /> : 'Завантажити більше'}
                  onClick={handleLoadMore}
                  />
                  </Grid>
                )} */}
                </Grid>
              </InfiniteScroll>
            )}
          </Grid>
        </Grid>
      </Stack>
    </Page>
  );
}
const mapStateToProps = (state) => {
  return {
    language: state.Intl.locale,
    userData: state.global.userData,
    subscribers: state.global.userSubscribers,
    subscriptions: state.global.userSubscriptions
  };
};
export default connect(mapStateToProps)(RibbonPage);
