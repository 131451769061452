import React, { useState } from 'react';

//Material
import { Box, ClickAwayListener, Rating, TextField, Typography, styled } from '@mui/material';

import ApiService from '../helpers/apiHelper';
import CloseIcon from '@mui/icons-material/Close';
import EmojiPicker from 'emoji-picker-react';
import EmojiEmotionsOutlinedIcon from '@mui/icons-material/EmojiEmotionsOutlined';
import { LetsGoBtn2 } from './Buttons';

const CssTextField = styled(TextField)({
  '& label.Mui-focused': {
    color: '#000000'
  },
  '& label': {
    color: '#A1A1A1',
    fontFamily: 'Kyiv-Sans-Light'
  },
  '& .MuiInputBase-root': { alignItems: 'end' },
  fontFamily: 'Kyiv-Sans-Regular !important',
  margin: '5px 0px'
});

function RespondForm({ id, setIsOpenFeedbackForm, feedbackSetter }) {
  const [rating, setRating] = useState(0);
  const [error, setError] = useState('');
  const [ratingError, setRatingError] = useState('');
  const [text, setText] = useState('');
  const [isShowEmoji, setIsShowEmoji] = useState(false);

  const apiFeedback = new ApiService('posts/feedback');
  // const apiFeedback = new ApiService('feedback');
  const handleSend = () => {
    const data = {
      text,
      serviceId: id,
      rating
    };
    if (text.length === 0) {
      setError('Мінімальна довжина відгука: 1 символ');
    } else if (text.length > 500) {
      setError('Максимальна довжина відгука: 500 символів');
    } else if (rating === 0 && text.length === 0) {
      setError('Мінімальна довжина відгука: 1 символ');
      setRatingError('Потрібно обрати оцінку');
    } else if (rating === 0) {
      setRatingError('Потрібно обрати оцінку');
    } else {
      apiFeedback.sendRequest(data).then((res) => {
        feedbackSetter(true);
        setIsOpenFeedbackForm(false);
        setError('');
        setRatingError('');
      });
    }
  };

  return (
    <div
      style={{
        border: '1px solid black',
        backgroundColor: 'white',
        borderRadius: '20px',
        width: '100%',
        padding: '30px 15px 15px 15px',
        marginTop: '20px',
        position: 'relative'
      }}
    >
      <CloseIcon
        className="bonfair_service_modal-close"
        style={{ position: 'absolute', right: '10px', top: '8px', cursor: 'pointer' }}
        onClick={() => {
          setIsOpenFeedbackForm(false);
        }}
      />

      <CssTextField
        placeholder="Опиши більш детально свої враження. Нам дійсно важлива твоя думка!"
        variant="outlined"
        multiline
        rows={8}
        fullWidth
        size="normal"
        inputProps={{ style: { textAlign: 'left' } }}
        InputProps={{
          endAdornment: (
            <>
              <EmojiEmotionsOutlinedIcon
                onClick={() => {
                  setIsShowEmoji(!isShowEmoji);
                }}
                sx={{
                  color: '#60A5DF',
                  cursor: 'pointer',
                  fontSize: '20px',
                  mr: 1
                }}
              />
            </>
          )
        }}
        onChange={(event) => {
          if (isShowEmoji) {
            setIsShowEmoji(false);
          }
          if (event.target.value.length <= 500) setText(event.target.value);
          setError('');
        }}
        value={text}
      />
      {isShowEmoji && (
        <ClickAwayListener
          onClickAway={() => {
            setIsShowEmoji(false);
          }}
        >
          <Box>
            <EmojiPicker
              onEmojiClick={(emojiData, event) => {
                let newStr = `${text}${emojiData.emoji}`;
                setText(newStr);
              }}
              skinTonesDisabled
              searchDisabled
              previewConfig={{ showPreview: false }}
              style={{
                position: 'absolute',
                bottom: error.length !== 0 ? '-80px' : '-100px',
                right: '46px',
                zIndex: '99'
              }}
              width={'60%'}
              height={'300px'}
            />
          </Box>
        </ClickAwayListener>
      )}
      <p
        style={{ margin: '0 5px', color: 'gray', textAlign: 'right', fontSize: '14px' }}
      >{`${text.length}/500`}</p>
      {error.length !== 0 && (
        <p
          style={{ margin: '0 5px', color: 'red', textAlign: 'left', fontSize: '14px' }}
        >{`${error}`}</p>
      )}
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'column',
          width: '220px',
          marginTop: '20px'
        }}
      >
        <Typography
          variant="subtitle"
          sx={{
            fontFamily: 'Public Sans,sans-serif !important',
            fontSize: '16px',
            textAlign: 'left'
          }}
        >
          Оцінка послуги*
        </Typography>
        <Rating
          sx={{ width: 'fit-content !important' }}
          precision={1}
          value={rating}
          onChange={(event, value) => setRating(value)}
        />
        {ratingError.length !== 0 && (
          <p
            style={{ margin: '0 5px', color: 'red', textAlign: 'left', fontSize: '14px' }}
          >{`${ratingError}`}</p>
        )}
        <LetsGoBtn2 text="Опублікувати відгук" width="190px" onClick={handleSend} />
      </div>
    </div>
  );
}

export default RespondForm;
