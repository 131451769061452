import { Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import oops1 from '../../assets/oops1.png';
import oops2 from '../../assets/oops2.png';
import { LetsGoBtn3 } from '../../components/Buttons';

import '../MainPage/index.css';
import Page from '../../components/Page';

function ErrorPage() {
  const [randomPic, setRandomPic] = useState(0);
  const [randomDesc, setRandomDesc] = useState(0);
  const navigate = useNavigate();
  const imgs = [oops1, oops2];
  const descriptions = [
    'Схоже, тут  дуля з маком. Можливо ця сторінка була видалена або ніколи не існувала.'
  ];
  useEffect(() => {
    setRandomPic(Math.floor(Math.random() * imgs.length));
    setRandomDesc(Math.floor(Math.random() * descriptions.length));
    // eslint-disable-next-line
  }, []);
  return (
    <Page
      title="Помилка!"
      style={{
        display: 'flex',
        marginTop: '150px',
        alignItems: 'center',
        flexDirection: 'column',
        height: 'max-content',
        padding: '0px 100px'
      }}
    >
      <div
        style={{
          height: 'calc(94vh - 230px)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column'
        }}
      >
        <img alt="404" src={imgs[randomPic]} style={{ width: '471px', height: '273px' }} />

        <Typography
          style={{
            fontStyle: 'normal',
            fontWeight: '400',
            fontSize: '24px',
            lineHeight: '29px',
            textAlign: 'center',
            margin: '0px 0px 40px 0px',
            maxWidth: '700px'
          }}
        >
          {descriptions[randomDesc]}
        </Typography>
        <LetsGoBtn3 width={257} onClick={() => navigate('/')} text="Накивати п’ятами" />
      </div>
    </Page>
  );
}

export default ErrorPage;
