import React from 'react';

// material

import { Box, TextField } from '@mui/material';
import { connect, useDispatch } from 'react-redux';
import expandIcon from '../../assets/arrowExpand.png';
import ClearIcon from '@mui/icons-material/Clear';
// components

import './index.css';
import { useNavigate } from 'react-router';
import { useState } from 'react';
import { setIsOpenSearchBar } from '../../redux/mainReducer';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

//

// ----------------------------------------------------------------------

function SearchBar({ isOpenSearchBar, language }) {
  const [params] = useSearchParams();
  const [value, setValue] = useState('');
  const dispatch = useDispatch();

  useEffect(() => {
    window.location.href.includes('search') && dispatch(setIsOpenSearchBar(true));
    window.location.href.includes('search') && setValue(params.get('question')?params.get('question'):'');
    // eslint-disable-next-line
  }, []);
  const navigate = useNavigate();
  return (
    <Box
      id="searchNav"
      className={`header-search-menu ${isOpenSearchBar ? 'header-search-openMenu' : ''}`}
    >
      <TextField
        style={{ marginLeft: '30px', marginRight: '20px' }}
        sx={{
          input: {
            fontFamily: 'Kyiv-Sans-Light',
            fontSize: '16px',
            fontWeight: '350',
            lineHeight: '19px',
            letterSpacing: '0em',
            textAlign: 'left',
            '&::placeholder': {
              fontFamily: 'Kyiv-Sans-Light',
              fontSize: '16px',
              fontWeight: '350',
              lineHeight: '19px',
              letterSpacing: '0em',
              textAlign: 'left'
              // <----- Add this.
              // opacity: 1,
            }
          }
        }}
        placeholder="Введіть свій пошуковий запит"
        variant="standard"
        fullWidth
        value={value}
        onChange={(e) => (e.target?.value !== null ? setValue(e.target.value) : setValue(''))}
        InputProps={{
          endAdornment: (
            <>{isOpenSearchBar && value.length!==0&&<ClearIcon sx={{cursor:'pointer', ":hover":{
              color:'#60A5DF'
            }}} onClick={()=>{
              setValue('')
              navigate(`/${language}/search?question=&type=${params.get('type')}`);
            }}/> }<div
              style={{
                display: 'flex',
                alignItems: 'center',
                width: 'fit-content',
                cursor: 'pointer'
              }}
              onClick={() => {
                navigate(`/${language}/search?question=${value}&type=${params.get('type')}`);
              }}
            >
              {isOpenSearchBar && (
                <img alt="arrow" src={expandIcon} style={{ width: '35px', margin: '5px' }} />
              )}
            </div></>
           
          )
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            navigate(`/${language}/search?question=${value}&type=${params.get('type')}`);
          }
        }}
      />
    </Box>
  );
}
const mapStateToProps = (state) => {
  return {
    language: state.Intl.locale,
    isOpenSearchBar: state.global.isOpenSearchBar
  };
};
export default connect(mapStateToProps)(SearchBar);
