import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

//Components MUi
import {
  Paper,
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Avatar,
  Grid,
  Stack,
  Pagination
} from '@mui/material';
// Additional components

import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';
import ArrowDropUpOutlinedIcon from '@mui/icons-material/ArrowDropUpOutlined';
import MailOutlineIcon from '@mui/icons-material/MailOutline';

import DefaultAvatar from '../../../assets/defaultPostImg.jpg';
import { StatusHandlerSeller } from '../handlers';
import { FreeBtn } from '../../../components/Buttons';
import ApiService from '../../../helpers/apiHelper';
import { dateFormattingByDateShort } from '../../../helpers/date-helpers';
import { Spinner } from '../../../components/Spinner';
import { StyledTitle } from '../../../components/StyledComponents';

function createTableData(
  number,
  service,
  buyer,
  buyingDate,
  qty,
  status,
  details,
  orderId,
  serviceID,
  price,
  currency
) {
  return {
    number,
    service,
    buyer,
    buyingDate,
    qty,
    status,
    details,
    orderId,
    serviceID,
    price,
    currency
  };
}

function Row(props) {
  const { row, language, userData } = props;
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();
  const apiChats = new ApiService('notification/chats');
  const apiChangeOrderStatus = new ApiService('posts/order/change-status');
  // const apiChangeOrderStatus = new ApiService('/order/change-status');

  const handleChangeStatus = (status) => {
    apiChangeOrderStatus
      .updateDataQueryPost({ orderId: row.orderId, orderStatus: status })
      .then((res) => {
        props.tableRefresh();
      })
      .catch((error) => console.error(error));
  };
  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell align="center" sx={{ borderColor: '#60A5DF', p: '5px 16px' }}>
          <Typography sx={{ fontSize: '16px' }}>{row.number}</Typography>
        </TableCell>
        <TableCell component="th" scope="row" sx={{ borderColor: '#60A5DF', p: '5px 16px' }}>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography
              onClick={() => navigate(`/${language}/service/${row.serviceID}`)}
              sx={{
                fontFamily: 'Kyiv-Sans-Light',
                fontSize: '16px',
                textDecoration: 'underline',
                cursor: 'pointer',
                width: 'fit-content'
              }}
            >
              {row.service}
            </Typography>
            {row?.details && (
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography
                  sx={{
                    fontFamily: 'Kyiv-Sans-Light',
                    color: '#757575',
                    fontSize: '16px',
                    cursor: 'pointer',
                    width: 'max-content'
                  }}
                  onClick={() => setOpen(!open)}
                >
                  {open ? 'Згорнути деталі' : 'Деталі замовлення'}
                </Typography>{' '}
                <IconButton
                  disableRipple
                  disableFocusRipple
                  aria-label="expand row"
                  size="small"
                  sx={{
                    ':hover': {
                      backgroundColor: 'unset !important'
                    }
                  }}
                  onClick={() => setOpen(!open)}
                >
                  {open ? <ArrowDropUpOutlinedIcon /> : <ArrowDropDownOutlinedIcon />}
                </IconButton>
              </Box>
            )}
          </Box>
        </TableCell>
        <TableCell align="center" sx={{ borderColor: '#60A5DF', p: '5px 16px' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Avatar
              onClick={() => {
                navigate(`/${language}/user-page/${row.buyer.id}?type=posts`);
              }}
              src={
                row.buyer?.encodedMediaDTO
                  ? `data:${row.buyer?.encodedMediaDTO.contentType};base64, ${row.buyer?.encodedMediaDTO.encodedContent}`
                  : DefaultAvatar
              }
              sx={{
                width: 48,
                height: 48,
                mr: 2,

                border: !props?.blockedUsers.find((el) => el.id === row?.buyer?.id)
                  ? '1px solid #60A5DF'
                  : 'none',
                boxShadow: props?.blockedUsers.find((el) => el.id === row?.buyer?.id)
                  ? '0px 0px 2px 2px rgba(255,0,0,1)'
                  : 'none',
                cursor: 'pointer'
              }}
            />

            <Stack sx={{ width: 'max-content', marginTop: '5px' }}>
              {row?.buyer?.name && row?.buyer?.surname && (
                <Typography
                  variant="subtitle"
                  sx={{
                    fontFamily: 'Public Sans,sans-serif !important',
                    fontSize: '18px',
                    textAlign: 'left',
                    cursor: 'pointer'
                  }}
                  onClick={() => {
                    navigate(`/${language}/user-page/${row.buyer.id}?type=posts`);
                  }}
                >
                  {`${row?.buyer?.name} ${row?.buyer?.surname}`}
                </Typography>
              )}

              <Typography
                variant="subtitle"
                sx={{
                  fontFamily: 'Public Sans,sans-serif !important',
                  fontSize: row?.buyer?.name && row?.buyer?.surname ? '14px' : '18px',
                  textAlign: 'left',
                  cursor: 'pointer'
                }}
                onClick={() => {
                  navigate(`/${language}/user-page/${row.buyer.id}?type=posts`);
                }}
              >
                {`@${row.buyer?.nickname}`}
              </Typography>
            </Stack>
          </Box>
        </TableCell>

        <TableCell align="center" sx={{ borderColor: '#60A5DF', p: '5px 16px' }}>
          <Typography sx={{ fontSize: '16px' }}>{`${row?.price ? row?.price : ''} ${
            row?.currency ? row?.currency : ''
          }`}</Typography>
        </TableCell>
        <TableCell align="center" sx={{ borderColor: '#60A5DF', p: '5px 16px' }}>
          <Typography sx={{ fontSize: '16px' }}>{row.qty}</Typography>
        </TableCell>
        <TableCell align="center" sx={{ borderColor: '#60A5DF', p: '5px 16px' }}>
          {StatusHandlerSeller(row.status, handleChangeStatus)}
        </TableCell>
      </TableRow>
      <TableRow>
        {row?.details && (
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Grid container>
                <Grid
                  item
                  xs={4}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'start',
                    pl: 7.8
                  }}
                >
                  <Typography sx={{ fontSize: '14px', fontWeight: '600' }}>
                    Про замовлення:
                  </Typography>
                  <Typography sx={{ fontSize: '14px' }}>{row.details.about}</Typography>
                  <Typography sx={{ fontSize: '14px', fontWeight: '600' }}>
                    Дата замовлення:
                  </Typography>
                  <Typography sx={{ fontSize: '14px' }}>{row.buyingDate}</Typography>
                  <Typography sx={{ fontSize: '14px', fontWeight: '600' }}>
                    Контактні дані:
                  </Typography>
                  <Typography sx={{ fontSize: '14px' }}>{row.details.contacts}</Typography>
                  <Typography sx={{ fontSize: '14px', fontWeight: '600' }}>
                    Спосіб доставки:
                  </Typography>
                  <Typography sx={{ fontSize: '14px' }}>{row.details.deliveryType}</Typography>
                  <Typography sx={{ fontSize: '14px', fontWeight: '600' }}>
                    Спосіб оплати:
                  </Typography>
                  <Typography sx={{ fontSize: '14px' }}>{row.details.paymentType}</Typography>
                  {row.details.comment.length !== 0 && (
                    <>
                      <Typography sx={{ fontSize: '14px', fontWeight: '600' }}>
                        Коментар до замовлення:
                      </Typography>
                      <Typography sx={{ fontSize: '14px' }}>{row.details.comment}</Typography>
                    </>
                  )}
                </Grid>
                <Grid
                  item
                  xs={8}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'end',
                    justifyContent: 'end',
                    mb: 2
                  }}
                >
                  <FreeBtn
                    padding="10px 24px"
                    onClick={() => {
                      apiChats
                        .postDataQuery({
                          userId: userData.id,
                          recipientId: row.buyer.id
                        })
                        .then((res) => {
                          navigate(`/${language}/messages`);
                        })
                        .catch((error) => {
                          if (error?.message.includes('There is a chat between these users')) {
                            const id = `${error.message.slice(
                              error.message.indexOf('[') + 1,
                              error.message.length - 1
                            )}`;

                            navigate(`/${language}/messages?chatId=${id}`);
                          }
                        });
                    }}
                    text="Написати покупцю"
                    icon={
                      <MailOutlineIcon sx={{ color: '#60A5DF', marginRight: '10px !important' }} />
                    }
                  />
                </Grid>
              </Grid>
            </Collapse>
          </TableCell>
        )}
      </TableRow>
    </React.Fragment>
  );
}

function Orders({ language, data, userData, countSetter, blockedUsers, ...props }) {
  // eslint-disable-next-line
  const apiMyOrders = new ApiService('posts/scheduler/orders');
  // const apiMyOrders = new ApiService('/scheduler/orders');

  // const rows = [
  //   createTableData('01', 'Frozen yoghurt', mockUser, '24/08/2022', '4 oд.', 'new', {
  //     about: 'Frozen yoghurt',
  //     contacts: 'Василь Симоненко, +380967438945',
  //     deliveryType: 'Нова Пошта, м. Дніпро, відділення 42',
  //     paymentType: 'Часткова передоплата',
  //     comment: 'Хочу подарункову упаковку'
  //   }),
  //   createTableData('02', 'Ice cream sandwich', mockUser, '24/08/2022', '4 oд.', 'sent'),
  //   createTableData('03', 'Eclair', mockUser, '24/08/2022', '4 oд.', 'processing'),
  //   createTableData('04', 'Cupcake', mockUser, '24/08/2022', '4 oд.', 'done'),
  //   createTableData('05', 'Gingerbread', mockUser, '24/08/2022', '4 oд.', 'canceled')
  // ];
  // eslint-disable-next-line
  const [params, setParams] = useSearchParams();
  const [rows, setRows] = useState([]);
  const [from, setFrom] = useState(0);
  const [pages, setPages] = useState(0);
  const [selectedPage, setSelectedPage] = useState(1);
  const [isLoading, setIsLoadiang] = useState(false);

  const dataSetter = () => {
    setIsLoadiang(true);
    apiMyOrders
      .getAll({
        from: 0,
        size: 5,
        search: params.get('question') ? params.get('question') : '',
        sortOrder: params.get('sd') ? params.get('sd') : 'DESC',
        status: params.get('status') ? params.get('status') : ''
      })
      .then((res) => {
        const preparedRows = res.result.map((order) =>
          createTableData(
            order?.orderNumber,
            order?.service?.nameService,
            order?.buyer,
            dateFormattingByDateShort(new Date(order?.buyingDate)),
            `${order?.qty} од.`,
            order?.status,
            {
              about: order?.service?.nameService,
              contacts: order?.details?.contact,
              deliveryType: order?.details?.deliveryType,
              paymentType: order?.details?.paymentType,
              comment: order?.details?.comment
            },
            order?.id,
            order?.service?.id,
            order?.price,
            order?.currency
          )
        );
        setRows(preparedRows);
        setIsLoadiang(false);
        const countPages = Number.isInteger(res.count / 5)
          ? res.count / 5
          : Math.floor(res.count / 5) + 1;

        setPages(countPages);
        countSetter({
          new: res?.newCounter,
          sentAwait: res?.processingCounter
        });

        // setContentMaxCount(res.totalElements);
      })
      .catch((error) => {
        setIsLoadiang(false);
        console.error(error);
      });
  };
  const tableRefresh = () => {
    setIsLoadiang(true);
    apiMyOrders
      .getAll({
        from: from,
        size: 5,
        search: params.get('question') ? params.get('question') : '',
        sortOrder: params.get('sd') ? params.get('sd') : 'DESC',
        status: params.get('status') ? params.get('status') : ''
      })
      .then((res) => {
        const preparedRows = res.result.map((order) =>
          createTableData(
            order?.orderNumber,
            order?.service?.nameService,
            order?.buyer,
            dateFormattingByDateShort(new Date(order?.buyingDate)),
            `${order?.qty} ${order?.service?.initOfMeasure}.`,
            order?.status,
            {
              about: order?.service?.nameService,
              contacts: order?.details?.contact,
              deliveryType: order?.details?.deliveryType,
              paymentType: order?.details?.paymentType,
              comment: order?.details?.comment
            },
            order?.id,
            order?.service?.id,
            order?.price,
            order?.currency
          )
        );
        setRows(preparedRows);
        countSetter({
          new: res?.newCounter,
          sentAwait: res?.processingCounter
        });
        setIsLoadiang(false);
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const handleChangePage = (page) => {
    apiMyOrders
      .getAll({
        from: 5 * (page - 1),
        size: 5,
        search: params.get('question') ? params.get('question') : '',
        sortOrder: params.get('sd') ? params.get('sd') : 'DESC',
        status: params.get('status') ? params.get('status') : ''
      })
      .then((res) => {
        const preparedRows = res.result.map((order) =>
          createTableData(
            order?.orderNumber,
            order?.service?.nameService,
            order?.buyer,
            dateFormattingByDateShort(new Date(order?.buyingDate)),
            `${order?.qty} ${order?.service?.initOfMeasure}.`,
            order?.status,
            {
              about: order?.service?.nameService,
              contacts: order?.details?.contact,
              deliveryType: order?.details?.deliveryType,
              paymentType: order?.details?.paymentType,
              comment: order?.details?.comment
            },
            order?.id,
            order?.service?.id,
            order?.price,
            order?.currency
          )
        );
        setRows(preparedRows);
        setIsLoadiang(false);
        setFrom(5 * (page - 1));
        countSetter({
          new: res?.newCounter,
          sentAwait: res?.processingCounter
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    dataSetter();
    // eslint-disable-next-line
    return () => {
      setFrom(0);
    };
    // eslint-disable-next-line
  }, []);
  return (
    <TableContainer
      component={Paper}
      sx={
        rows.length !== 0
          ? { borderRadius: '0px !important', mt: 3 }
          : { borderRadius: '0px !important', mt: 3, backgroundColor: 'transparent !important' }
      }
    >
      <Table aria-label="collapsible table">
        <TableHead sx={{ background: '#E2F2FF' }}>
          <TableRow>
            <TableCell
              align="center"
              sx={{
                fontFamily: 'Kyiv-Sans-Light',
                fontSize: '16px',
                borderColor: '#60A5DF',
                padding: '10px'
              }}
            >
              №
            </TableCell>
            <TableCell
              align="center"
              sx={{
                fontFamily: 'Kyiv-Sans-Light',
                fontSize: '16px',
                borderColor: '#60A5DF',
                padding: '10px'
              }}
            >
              Послуга
            </TableCell>
            <TableCell
              align="center"
              sx={{
                fontFamily: 'Kyiv-Sans-Light',
                fontSize: '16px',
                borderColor: '#60A5DF',
                padding: '10px'
              }}
            >
              Замовник
            </TableCell>

            <TableCell
              align="center"
              sx={{
                fontFamily: 'Kyiv-Sans-Light',
                fontSize: '16px',
                borderColor: '#60A5DF',
                padding: '10px'
              }}
            >
              Сума
            </TableCell>
            <TableCell
              align="center"
              sx={{
                fontFamily: 'Kyiv-Sans-Light',
                fontSize: '16px',
                borderColor: '#60A5DF',
                padding: '10px'
              }}
            >
              Кількість
            </TableCell>
            <TableCell
              align="center"
              sx={{
                fontFamily: 'Kyiv-Sans-Light',
                fontSize: '16px',
                borderColor: '#60A5DF',
                padding: '10px'
              }}
            >
              Статус
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody sx={{ backgroundColor: 'transparent !important' }}>
          {!isLoading ? (
            <>
              {rows.length !== 0 ? (
                rows.map((row, index) => (
                  <Row
                    key={`${row.service + index}`}
                    row={row}
                    language={language}
                    userData={userData}
                    tableRefresh={tableRefresh}
                    blockedUsers={blockedUsers}
                  />
                ))
              ) : (
                <TableCell
                  style={{ paddingBottom: 200, paddingTop: 200 }}
                  sx={{ backgroundColor: 'transparent', borderBottom: 'none' }}
                  colSpan={6}
                >
                  <Grid
                    container
                    sx={{
                      maxWidth: '100%'
                      // mt: '20% !important'
                    }}
                    spacing={3}
                  >
                    <Grid item xs={12} sx={{ paddingBottom: '0px' }}>
                      <StyledTitle
                        maxWidth={800}
                        sx={{
                          lineHeight: '38px !important',
                          fontSize: '32px !important',
                          textTransform: 'initial !important',
                          margin: '0px auto 20px !important'
                        }}
                        className="bottomBlock-title"
                      >
                        Тут порожньо
                      </StyledTitle>
                    </Grid>
                    <Grid item xs={12} sx={{ mt: 0, display: 'flex', justifyContent: 'center' }}>
                      <Typography textAlign="center" sx={{ maxWidth: '640px' }}>
                        Поки що у вас немає замовлень.
                      </Typography>
                    </Grid>
                  </Grid>
                </TableCell>
              )}
            </>
          ) : (
            <TableCell style={{ paddingBottom: 200, paddingTop: 200 }} colSpan={6}>
              <Spinner />
            </TableCell>
          )}
        </TableBody>
      </Table>
      {rows.length !== 0 && !isLoading && (
        <Pagination
          sx={{
            mt: 0,
            mb: 0,
            display: 'flex',
            justifyContent: 'end',
            '& ul > li > button:not(.Mui-selected)': {
              backgroundColor: 'transparent',
              color: 'gray',
              borderColor: `gray!important`,
              margin: 1
            },
            '& .MuiPaginationItem-root': {
              color: 'gray',
              fontSize: '18px'
            },
            '& .Mui-selected': {
              backgroundColor: 'transparent',
              color: '#60A5DF',
              borderColor: '#60A5DF'
            }
          }}
          count={pages}
          defaultPage={1}
          siblingCount={2}
          variant="outlined"
          shape="rounded"
          onChange={(event, value) => {
            if (selectedPage !== +value) {
              setSelectedPage(+value);
              handleChangePage(value);
            } else {
              return;
            }
          }}
        />
      )}
    </TableContainer>
  );
}
const mapStateToProps = (state) => {
  return {
    language: state.Intl.locale,
    userData: state.global.userData,
    blockedUsers: state.global.blockedUsers
  };
};

export default connect(mapStateToProps)(Orders);
