import React, { useEffect, useState } from 'react';

//Material
import { Modal, Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

// import styled from '@emotion/styled';
import './PostModal.css';
import ImageGallery from 'react-image-gallery';
import { setIsOpenFullscreenPicture } from '../../redux/mainReducer';
import { connect, useDispatch } from 'react-redux';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  // width: '100%',
  maxWidth: 1300,
  minWidth:'50vh',
  bgcolor: 'unset',
  
  borderRadius: '20px',
  border: 'none',
  padding: '50px 50px 0px 50px',
  overflow: 'hidden',
  minHeight: '50vh !important',
  maxHeight: '90vh',
  // minHeight: '40vh',
  backgroundSize:'contain',
  scrollbarWidth: 'none',
  '&::-webkit-scrollbar': {
    display: 'none'
  },
  
};

// const CssTextField = styled(TextField)({
//   '& label.Mui-focused': {
//     color: '#000000'
//   },
//   '& label': {
//     color: '#A1A1A1',
//     fontFamily: 'Kyiv-Sans-Light'
//   },

//   margin: '5px 0px'
// });

function FullscreenPicture({ pic, isOpenModal,setShowedImg,sliderStartIdx }) {
  // eslint-disable-next-line
  const [screenType, setScreenType] = useState('desktop');
  const dispatch = useDispatch();
  useEffect(() => {
    if (window.screen.width <= 600) {
      setScreenType('mobile');
    }
  }, []);

  return (
    <div>
      <Modal
        open={isOpenModal}
        onClose={(event, reason) => {
          setShowedImg([])
          dispatch(setIsOpenFullscreenPicture(false));
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="bonfair_post_modal"
      >
        <Box sx={{...style, }} className="bonfair_fullscreen_modal-box">
          <CloseIcon
            className="bonfair_post_modal-close"
            sx={{ position: 'absolute', right: '12px', top: '12px', cursor: 'pointer', color:'white', zIndex:99,  }}
            onClick={() => {
              dispatch(setIsOpenFullscreenPicture(false));
            }}
          />
          <ImageGallery
                style={{ marginTop: 0, maxWidth:'300px !important' }}
                showPlayButton={false}
                thumbnailPosition="bottom"
                showThumbnails={false}
                showBullets={false}
                showFullscreenButton={false}
                infinite={false}
                startIndex={sliderStartIdx}
                items={pic.map((photo) => {
                  return {
                    original: `${URL.createObjectURL(photo.img)}`,
                    thumbnail: `${URL.createObjectURL(photo.img)}`,
                    // original: `data:${photo.contentType};base64, ${photo.encodedContent}`,
                    // thumbnail: `data:${photo.contentType};base64, ${photo.encodedContent}`,
                    originalHeight: 300,
                    thumbnailHeight: 65,
                    originalClass: 'photoSlide',
                    // thumbnailClass: 'thumbnailSlide'
                  };
                })}
              />
          {/* <img src={URL.createObjectURL(pic)} alt="fullscreenPic" /> */}
        </Box>
      </Modal>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    language: state.Intl.locale,
    userData: state.global.userData,
    isOpenSearchBar: state.global.isOpenSearchBar
  };
};
export default connect(mapStateToProps)(FullscreenPicture);
