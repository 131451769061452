import { Grid, Typography } from '@mui/material';
import React from 'react';
import Page from '../../components/Page';
import { StyledTitle } from '../../components/StyledComponents';
import '../MainPage/index.css';

function AgreementPage() {
  return (
    <Page
      title="Користувацька угода"
      style={{
        display: 'flex',
        marginTop: '150px',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        height: 'max-content',
        padding: '0px 100px'
      }}
    >
      {/* <div
        style={{
          height: 'calc(94vh - 230px)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column'
        }}
      > */}
      <StyledTitle
        maxWidth={800}
        sx={{
          lineHeight: '38px !important',
          fontSize: '32px !important',
          textTransform: 'initial !important',
          margin: '0px auto !important'
        }}
        className="bottomBlock-title"
      >
        Угода користувача (Публічна оферта)
      </StyledTitle>
      <Grid container spacing={2} sx={{ maxWidth: '1240px', mt: 5 }}>
        <Grid item xs={12}>
          <Typography
            sx={{
              fontStyle: 'normal',
              fontWeight: '400',
              fontSize: '16px',
              lineHeight: '29px',
              textAlign: 'justify',
              margin: '10px 0px 10px 0px'
            }}
          >
            Відповідно до ст. 633 Цивільного кодексу України ця Угода користувача є публічним
            договором – офертою (пропозицією), (надалі – «Адміністрація Сайту» або «Адміністрація»),
            до невизначеного кола осіб (фізична особа, фізична особа - підприємець, юридична особа),
            надалі також – «Покупець», які мають намір скористатися сервісами та послугами, які
            пропонуються на сайті за адресою:{' '}
            <a href="https://dev.bonfairplace.com/ua" target="_blank" rel="noreferrer">
              https://dev.bonfairplace.com/ua
            </a>{' '}
            (далі - Сайт). Ця публічна оферта містить усі істотні умови, необхідні для укладання
            договорів про надання послуг між Адміністрацією Сайту та користувачем. <br />
            <br />
            Оскільки ця Угода є публічним договором, то відповідно до ст. ст. 633, 641, 642 ЦКУ та
            її умови є однаковими для усіх Користувачів, беззастережне прийняття її умов вважається
            акцептуванням цієї оферти користувачем, для чого Адміністрація публікує цей Договір.
            <br />
            <br />
            Ця Угода, у відповідності до 628 ЦКУ, є змішаним договором, тобто таким, в якому
            містяться елементи різних договорів: публічного договору, договору приєднання, договору
            надання послуг тощо. Ця Угода, у відповідності до ст. 634 ЦКУ, є договором приєднання,
            умови якого встановлені Адміністрацією та який може бути укладений лише шляхом
            приєднання користувача до запропонованого договору в цілому та без застережень, крім
            випадків прямо передбачених законом.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle2" sx={{ fontSize: 16, fontFamily: 'Kyiv-Sans-Bold' }}>
            Загальні положення
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            sx={{
              fontStyle: 'normal',
              fontWeight: '400',
              fontSize: '16px',
              lineHeight: '29px',
              textAlign: 'justify',
              margin: '10px 0px 10px 0px'
            }}
          >
            1.1. Ця Угода користувача (далі – Угода), користування WEB-сайтом{' '}
            <a href="https://dev.bonfairplace.com/ua" target="_blank" rel="noreferrer">
              https://dev.bonfairplace.com/ua
            </a>{' '}
            розроблена Адміністрацією Сайту і є публічною офертою, що визначає умови використання та
            розвитку Сайту, а також права та обов'язки його Адміністрації та користувачів. Угода
            поширюється також на відносини, пов'язані з правами та інтересами третіх осіб, які не є
            користувачами Сайту, але чиї права та інтереси можуть бути порушені в результаті дій
            користувачів сайту.
            <br /> 1.2. Предметом Угоди є надання Адміністрацією Сайту Користувачеві послуг з
            використання Сайту і його ресурсів і сервісів (далі – Послуги). До Угоди між
            Користувачем і Адміністрацією Сайту відносяться всі спеціальні документи, описи та
            умови, що регулюють надання окремих сервісів Сайту і розміщені у відповідних розділах
            Сайту в мережі Інтернет.
            <br /> 1.3. Користувач зобов'язаний повністю ознайомитися з цією Угодою до моменту його
            реєстрації на Сайті. Реєстрація Користувача на Сайті означає повне і беззастережне
            прийняття (Акцепт) Користувачем цієї Угоди.
            <br /> 1.4. Цю Угоду може бути змінено та/або доповнено Адміністрацією Сайту в
            односторонньому порядку без будь-якого спеціального повідомлення. Актуальна редакція
            Угоди завжди розміщена у відповідному розділі Сайту.
            <br /> Продовження використання Сайту Користувачем після внесення змін та/або доповнень
            до цієї Угоди означає прийняття та згоду Користувача з такими змінами та/або
            доповненнями.
            <br /> 1.5. Згода з умовами цієї Угоди є обов'язковою умовою для реєстрації на Сайті та
            його використання. Користувач підтверджує свою згоду з умовами Угоди натисканням кнопки
            прийняття Угоди Користувача під час реєстрації на Сайті.
            <br /> 1.6. Терміни та визначення. У цій Угоді, якщо з тексту прямо не випливає інше,
            такі терміни матимуть такі значення:
          </Typography>
          <ul
            style={{
              fontFamily: 'Public Sans !important',
              fontStyle: 'normal',
              fontWeight: '400',
              fontSize: '16px',
              lineHeight: '29px',
              textAlign: 'justify',
              margin: '10px 0px 10px 0px'
            }}
          >
            <li
              style={{
                fontFamily: 'unset !important'
              }}
            >
              Сайт - сукупність інформації, текстів, графічних елементів дизайну, зображень, фото та
              відеоматеріалів та інших результатів інтелектуальної діяльності, а також програм для
              ЕОМ, що містяться в інформаційній системі, що забезпечує доступність такої інформації
              за мережевою адресою (домену) в мережі Інтернет.
            </li>
            <li>
              Реєстрація на Сайті - створення облікового запису з метою входу на Сайт для одержання
              безпосередньо інформації та контенту, розміщення контенту та отримання Послуг.
            </li>
            <li>
              Акцепт – повне, безумовне та беззастережне прийняття Замовником умов Оферти цієї Угоди
              без підпису письмового примірника Угоди Сторонами. Відповідь про згоду укласти Угоду
              на інших умовах, ніж запропоновано в Оферті, не є акцептом.
            </li>
            <li>
              Користувач - фізична або юридична особа, зареєстрована на Сайті як Користувач з метою
              проведення тестування, навчання, отримання безпосередньо інформації та контенту,
              розміщення контенту та отримання Послуг, шляхом акцепту оферти Угоди Користувача.
            </li>
            <li>
              Особистий кабінет Користувача - сукупність захищених сторінок Сайту, створених у
              результаті реєстрації Користувача, використовуючи які, Користувач має можливість
              розміщувати та споживати контент на Сайті та отримувати Послуги. У Особистому кабінеті
              доступна можливість зміни відомостей про Користувача, пароля, а також здійснення інших
              дій, передбачених функціями Особистого кабінету.
            </li>
            <li>
              Контент (вміст) — будь-яке інформаційно-значиме наповнення інформаційного ресурсу
              (Сайту) — тексти, графіка, мультимедіа — вся інформація, яку Користувач може
              завантажити на диск комп'ютера або розмістити на Сайті з дотриманням чинного
              законодавства, а також прав та інтересів інших Користувачів та третіх осіб.
            </li>
            <li>
              Адміністрація Сайту - Товариство з обмеженою відповідальністю
              <em
                style={{
                  textDecoration: 'underline',
                  margin: '0px',
                  width: 'fit-content',
                  fontFamily: 'Public Sans,sans-serif !important',
                  fontStyle: 'normal'
                }}
              >
                {' «Б&Б Рішення»'}
              </em>{' '}
              , ЄДРПОУ
              <em
                style={{
                  textDecoration: 'underline',
                  margin: '0px',
                  width: 'fit-content',
                  fontFamily: 'Public Sans,sans-serif !important',
                  fontStyle: 'normal'
                }}
              >
                {' 44686788'}
              </em>
              . Адреса місцезнаходження: 65052, Одеська обл., місто Одеса, вулиця Дюківська, будинок
              5, квартира 172.
            </li>
            <li>Сторони — Адміністрація сайту та Користувач.</li>
          </ul>
          <Typography
            style={{
              fontStyle: 'normal',
              fontWeight: '400',
              fontSize: '16px',
              lineHeight: '29px',
              textAlign: 'justify',
              margin: '10px 0px 10px 0px'
            }}
          >
            Додаткові сервіси (послуги) - сервіси або послуги, доступні Користувачеві на умовах
            платної підписки.
            <br /> <br />
            Підписка - форма замовлення та оплати додаткових сервісів (послуг), за якою Користувач
            набуває доступу до вказаних сервісів (послуг) за певну плату на визначений строк або у
            межах визначеного ліміту. Умови підписки та її різновиди визначаються Адміністрацією
            Сайту та інформація про них розміщується у відповідних розділах Сайту. Умови підписки,
            опубліковані на Сайті є невід'ємною частиною цієї Угоди.
            <br /> <br />
            Всі інші терміни та визначення, що зустрічаються в тексті Угоди, тлумачаться Сторонами
            відповідно до норм чинного законодавства України, норм міжнародних договорів і
            сформованих в мережі Інтернет звичайними правилами тлумачення відповідних термінів.
            <br /> <br />
            Назви змістів (статей) цієї Угоди призначені виключно для зручності користування текстом
            Угоди і буквального юридичного значення не мають.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle2" sx={{ fontSize: 16, fontFamily: 'Kyiv-Sans-Bold' }}>
            Сайт
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            sx={{
              fontStyle: 'normal',
              fontWeight: '400',
              fontSize: '16px',
              lineHeight: '29px',
              textAlign: 'justify',
              margin: '10px 0px 10px 0px'
            }}
          >
            2.1. Сайт — є комерційним інтернет-ресурсом.
            <br /> 2.2. Усі права на Сайт в цілому і на використання мережевої адреси (доменного
            імені, під-доменів, кореневого під-домена) належать Адміністрації Сайту. Остання надає
            доступ до Сайту всім зацікавленим особам відповідно до цієї Угоди, законодавства
            України, а також нормам міжнародних договорів, угод, конвенцій, протоколів.
            <br /> 2.3. Цією Угодою встановлені умови, згідно з якими права на використання
            інформації та результатів інтелектуальної діяльності - контенту (включаючи, але не
            обмежуючись літературними, музичними, аудіовізуальними творами та фонограмами, творами
            графіки та дизайну, фотографічними творами, програмами для ЕОМ, іншими немайновими
            правами) у складі окремих розділів Сайту, можуть належати Користувачам Сайту, що
            самостійно створили та розмістили зазначені об'єкти на Сайті без безпосередньої участі
            Адміністрації Сайту або з такою участю. <br />
            2.4. Адміністрація Сайту не несе відповідальності за зміст та форму створеного та
            розміщеного Користувачами контенту, зокрема, але не виключно, за дотримання
            Користувачами авторських або інших прав інтелектуальної власності третіх осіб та за
            достовірність інформаційної складової розміщеного Користувачами контенту. При цьому
            Адміністрація залишає за собою право видалити будь-який розміщений Користувачем контент,
            якщо зміст або форма цього контенту суперечить нормам суспільної моралі або порушує
            чинне законодавство України. Користувач бере на себе обов'язок при створенні та
            розміщенні на Сайті контенту дотримуватися чинного законодавства, норм суспільної моралі
            та умов цієї Угоди.
            <br /> 2.5. Ця Угода вступає в силу з моменту реєстрації Користувача в якості
            Користувача на Сайті і має силу до моменту її розірвання будь-якою із Сторін. Розірвання
            Угоди реалізується шляхом деактивації Особистого кабінету користувача.
            <br /> 2.6. Ця Угода поширюється на всіх Користувачів Сайту, незалежно від мети їхнього
            використання Сайту. Дія цієї Угоди поширюється на усіх Користувачів з моменту Реєстрації
            та кожного наступного доступу до Сайту за допомогою логіну та паролю. Натисканням кнопки
            «Зареєструватися» Користувач висловлює свою повну згоду та розуміння того, що дана Угода
            поширюється на будь-який наданий Користувачу Контент та/або Послугу за допомогою сервісу
            Сайта. Укладати цю Угоду мають право особи, які мають повну цивільну дієздатність та
            правоздатність. У випадку незгоди з цими умовами Угоди або з її окремими положеннями
            Користувач зобов’язується припинити перегляд та будь-яке використання Сайту.
            <br /> 2.7. Ця Угода стосується Сайту у його дійсній реалізації, а також будь-якого його
            розвитку, або додавання до нього нових послуг.
            <br />
            2.8. Користувач розуміє і погоджується з тим, що всі послуги та сервіси Сайту надаються
            «як є» і що Адміністрація Сайту не несе відповідальності за всі затримки, збої,
            видалення або не збереження будь-якої персональної інформації у зв'язку з порушеннями
            електропостачання або збоями в мережі Інтернет.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle2" sx={{ fontSize: 16, fontFamily: 'Kyiv-Sans-Bold' }}>
            Адміністрація сайту
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            sx={{
              fontStyle: 'normal',
              fontWeight: '400',
              fontSize: '16px',
              lineHeight: '29px',
              textAlign: 'justify',
              margin: '10px 0px 10px 0px'
            }}
          >
            3.1. Звернення, пропозиції і претензії фізичних і юридичних осіб до Адміністрації Сайту
            у зв'язку з цією Угодою і всіма питаннями щодо функціонування Сайту, порушеннями прав та
            інтересів третіх осіб при його використанні, а також для запитів уповноважених
            законодавством України, можуть бути спрямовані у підтримку або на поштову адресу
            Адміністрації Сайту{' '}
            <a href="mailto:info@bandbsolution.com.ua" target="_blank" rel="noreferrer">
              info@bandbsolution.com.ua
            </a>{' '}
            .<br />
            3.2. Щодо функціонування і розвитку Сайту Адміністрація керується законодавством
            України, нормами міжнародних договорів, угод, конвенцій, протоколів, цією Угодою та
            іншими спеціальними документами, які розроблені або можуть бути розроблені і прийняті
            Адміністрацією Сайту з метою регламентації надання Користувачам окремих сервісів Сайту.
            <br />
            3.3. Жодні положення цієї Угоди не надають Користувачеві право на використання фірмового
            найменування, товарних знаків, доменних імен та інших відмінних знаків Адміністрації
            Сайту. Право на використання фірмового найменування, товарних знаків, доменних імен та
            інших відмінних знаків Адміністрації Сайту може бути надано виключно за письмовою угодою
            з Адміністрацією Сайту.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle2" sx={{ fontSize: 16, fontFamily: 'Kyiv-Sans-Bold' }}>
            Реєстрація на Сайті і статус Користувача
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            sx={{
              fontStyle: 'normal',
              fontWeight: '400',
              fontSize: '16px',
              lineHeight: '29px',
              textAlign: 'justify',
              margin: '10px 0px 10px 0px'
            }}
          >
            4.1. Реєстрація Користувача на Сайті є безкоштовною та добровільною. Доступ та
            можливість використання додаткових сервісів (послуг) на Сайті можуть бути платними на
            умовах підписки, визначених у відповідних розділах Сайту. Адміністрація надає
            Користувачу після його реєстрації на Сайті доступ Особистого кабінету користувача та до
            сервісів Сайту.
            <br />
            4.2. Адміністрація Сайту не несе відповідальності за достовірність даних, зазначених
            користувачем при реєстрації. Користувач зобов'язаний самостійно підтримувати
            актуальність та достовірність реєстраційних даних.
            <br />
            4.3. При реєстрації на Сайті Користувач зобов'язаний надати Адміністрації Сайту
            необхідну інформацію для формування персонального облікового запису, включаючи
            унікальний для кожного Користувача логін (адресу електронної пошти), а також іншу
            інформацію, запитувану у реєстраційній формі.
            <br />
            4.4. Здійснюючи реєстрацію на Сайті, Користувач надає Адміністрації Сайту згоду на
            обробку та використання своїх персональних та контактних даних, а також будь-яких інших
            наданих Користувачем добровільно даних, з метою забезпечення та покращення роботи
            сервісів Сайту та на їх передання третім особам з метою, прямо пов'язаною із
            функціонуванням Сайту. Також, реєстрацією на Сайті Користувач підтверджує свою згоду на
            отримання від Адміністрації Сайту повідомлень, зокрема рекламного та інформаційного
            характеру.
            <br />
            4.5. Користувачами Сайту можуть бути юридичні особи, зареєстровані належним чином в
            установленому порядку та/або фізичні особи — Користувачі, зареєстровані на Сайті і які
            прийняли умови цієї Угоди, мають необхідний відповідно до п.2.6. обсяг цивільної
            дієздатності, володіють відповідними повноваженнями і використовують певні
            Адміністрацією сервіси та ресурси Сайту протягом строку визначеного Адміністрацією.
            <br />
            4.6. Після надання інформації, зазначеної у п. 4.3. цієї Угоди і правильного
            послідовного виконання всіх реєстраційних дій на Сайті, підтвердження згоди з умовами
            цієї Угоди, створюється Особистий кабінет Користувача.
            <br />
            4.7. При реєстрації Користувач погоджується з цією Угодою та приймає на себе зазначені в
            ній права і обов'язки, пов'язані з використанням та функціонуванням Сайту, а також несе
            повну відповідальність перед Адміністрацією та/або третіми особами за порушення умов
            цієї Угоди.
            <br />
            4.8. Якщо Користувач надає невірну інформацію або у Адміністрації є підстави вважати, що
            надана інформація невірна, неповна або неточна, Адміністрація має право призупинити або
            скасувати реєстрацію та відмовити у використанні сервісу Сайту (деактивувати Особистий
            кабінет Користувача).
            <br />
            4.9. Вибраний Користувачем логін і наданий Адміністрацією Сайту пароль є необхідною
            інформацією для доступу Користувача на Сайт. Користувач не має права передавати свої
            логін та пароль третім особам, несе повну відповідальність за їх збереження, самостійно
            обираючи спосіб їх зберігання.
            <br />
            4.10. Якщо Користувачем не доведено зворотнє, будь-які дії, вчинені з використанням його
            логіна і пароля, вважаються виконаними відповідними Користувачем. У разі
            несанкціонованого доступу до логіну та паролю і/або персональній сторінці Користувача,
            або розповсюдження логіна і пароля Користувач зобов'язаний негайно повідомити про це
            Адміністрацію Сайту в установленому порядку.
            <br />
            4.11. Адміністрація не несе відповідальності за безпеку доступу до даних та контенту
            розміщених Користувачем на Сайті. Користувач несе самостійну відповідальність за
            збереження даних та контенту, що розміщуються ним на Сайті.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle2" sx={{ fontSize: 16, fontFamily: 'Kyiv-Sans-Bold' }}>
            Права та обов'язки Користувача
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            sx={{
              fontStyle: 'normal',
              fontWeight: '400',
              fontSize: '16px',
              lineHeight: '29px',
              textAlign: 'justify',
              margin: '10px 0px 10px 0px'
            }}
          >
            5.1. Користувач має право на умовах, викладених на Сайті:
            <br />
            5.1.1. Звертатися до Адміністрації Сайту з консультаційних питань користування сервісом
            Сайту;
            <br />
            5.1.2. Розміщувати на Сайті контент за власним бажанням;
            <br />
            5.1.3. Спілкуватися з іншими Користувачами Сайту за допомогою сервісів Сайту.
            <br />
            5.1.4. Скасувати підписку шляхом звернення до Адміністрації сайту або за допомогою
            відповідних функцій у особистому кабінеті.
            <br />
            5.1.5. Вимагати від Адміністрації сайту повернення сплачених за підписку коштів у таких
            випадках:
            <ul
              style={{
                fontFamily: 'Public Sans !important',
                fontStyle: 'normal',
                fontWeight: '400',
                fontSize: '16px',
                lineHeight: '29px',
                textAlign: 'justify',
                margin: '10px 0px 10px 0px'
              }}
            >
              <li>Неправильне стягнення плати за послуги.</li>
              <li>Ненадання послуг, передбачених цією угодою.</li>
              <li>Невідповідність послуг заявленим умовам.</li>
            </ul>
            5.2. При користуванні Послугами Сайту Користувач зобов'язаний:
            <br />
            5.2.1. суворо дотримуватися положень чинного законодавства України, а також норм
            міжнародних договорів, угод, конвенцій, протоколів, цієї Угоди та інших спеціальних
            документів Адміністрації Сайту;
            <br />
            5.2.2. ознайомитися з функціональними можливостями Сайту;
            <br />
            5.2.3. надавати при реєстрації достовірні і повні дані, стежити за їх актуалізацією;
            <br />
            5.2.4. здійснювати оплату за надання платної інформації та сервісів;
            <br />
            5.2.5. інформувати Адміністрацію Сайту про несанкціонований доступ до персональної
            сторінки та/або про несанкціонований доступ та/або використання пароля і логіна
            Користувача;
            <br />
            5.2.6. не передавати третім особам пароль доступу до Особистого кабінету, якщо це може
            призвести до порушення законодавства, цієї Угоди, спеціальних документів Адміністрації
            сайту;
            <br />
            5.2.7. не розміщувати на Сайті інформацію та контент, які можуть порушувати права та
            інтереси інших осіб;
            <br />
            5.2.8. перед розміщенням інформації і контенту (включаючи, але не обмежуючись,
            зображеннями інших осіб, чужими текстами різного змісту) попередньо оцінювати законність
            їх розміщення;
            <br />
            5.2.9. здійснювати резервне копіювання важливої для Користувача що зберігається на його
            персональній сторінці інформації;
            <br />
            5.3. У разі наявності сумнівів щодо законності здійснення тих чи інших дій, у тому
            числі, за розміщення інформації, контенту або надання доступу, Адміністрація Сайту
            рекомендує утриматися від здійснення таких дій.
            <br />
            5.4. Користувачеві при використанні Сайту категорично заборонено:
            <br />
            5.4.1. Реєструватися в якості Користувача від імені або замість іншої особи без згоди
            такої особи, тобто, створювати «фальшивий аккаунт».
            <br />
            5.4.2. Вводити Користувачів в оману щодо своєї особи, а також будь-якої іншої
            недостовірної інформації;
            <br />
            5.4.3. Спотворювати відомості про себе;
            <br />
            5.4.4. Завантажувати, зберігати, публікувати, поширювати і надавати доступ або іншим
            чином використовувати будь-яку інформацію, яка:
            <br />
            -містить погрози, дискредитує, ображає, принижує честь і гідність чи ділову репутацію
            або порушує недоторканність приватного життя інших Користувачів або третіх осіб;
            <br />
            -є вульгарною або непристойною, що містить нецензурну лексику, містить порнографічні
            фото-відео матеріали, зображення, тексти або сцени сексуального характеру;
            <br />
            -містить сцени насильства, або жорстокого поводження з тваринами;
            <br />
            -містить опис засобів і способів суїциду, будь-яке підбурювання до його здійснення;
            <br />
            -пропагує та/або сприяє розпалюванню расової, релігійної, етнічної ненависті або
            ворожнечі, пропагує фашизм чи ідеологію расової переваги;
            <br />
            -містить екстремістські матеріали;
            <br />
            -пропагує злочинну діяльність або містить поради, інструкції або керівництва по
            здійсненню злочинних дій, що містить інформацію обмеженого доступу, включаючи але не
            обмежуючись, державною та комерційною таємницею, інформацією про приватне життя третіх
            осіб;
            <br />
            -містить рекламу або описує привабливість вживання наркотичних речовин, у тому числі
            "цифрових наркотиків" (звукових файлів, що діють на мозок людини за рахунок бінауральних
            ритмів), інформацію про поширення наркотиків, рецепти їх виготовлення та поради щодо
            вживання;
            <br />
            -носить шахрайський характер;
            <br />
            -а також порушує інші права та інтереси громадян і юридичних осіб або вимоги
            законодавства України, а також норм міжнародних договорів, угод, конвенцій, протоколів.
            <br />
            5.4.5. Незаконно завантажувати, зберігати, публікувати, поширювати і надавати доступ або
            іншим чином використовувати інтелектуальну власність Користувачів і третіх осіб;
            <br />
            5.4.6. Здійснювати масові розсилки повідомлень без згоди Користувачів Сайту;
            <br />
            5.4.7. Використовувати програмне забезпечення та здійснювати дії, спрямовані на
            порушення нормального функціонування Сайту і його сервісів або персональних сторінок
            Користувачів;
            <br />
            5.4.8. Завантажувати, зберігати, публікувати, поширювати і надавати доступ або іншим
            чином використовувати віруси та інші шкідливі програми;
            <br />
            5.4.9. Використовувати без спеціального на те дозволу Адміністрації Сайту автоматизовані
            скрипти (програми) для збору інформації на Сайті та/або взаємодії з Сайтом і його
            сервісами;
            <br />
            5.4.10. Будь-яким способом, в тому числі, але не обмежуючись, шляхом обману, зловживання
            довірою, злому, намагатися отримати доступ до логіну та паролю іншого Користувача;
            <br />
            5.4.11. Здійснювати незаконні збір та обробку персональних даних інших осіб;
            <br />
            5.4.12. Здійснювати (намагатися отримати) доступ до будь-яких Послуг іншим способом,
            окрім як через інтерфейс наданий Адміністрацією Сайту, за винятком випадків, коли такі
            дії були прямо дозволені Користувачеві згідно з окремою угодою з Адміністрацією Сайту;
            <br />
            5.4.13. Розміщувати будь-яку іншу інформацію, яка, на особисту думку Адміністрації, є
            небажаною, не відповідає цілям створення Сайту, обмежує інтереси Користувачів або по
            іншим причинам є небажаною для розміщення на Сайті.
            <br />
            5.5. Користувач несе особисту відповідальність за будь-яку інформацію та контент, які
            розміщує на Сайті, повідомляє іншим Користувачам, а також за будь-які взаємодії з іншими
            Користувачами, здійснюючи їх на свій власний страх і ризик.
            <br />
            5.6. У разі незгоди Користувача з цією Угодою або її оновленням, Користувач зобов'язаний
            відмовитися від її використання, проінформувавши про це Адміністрацію Сайту в
            установленому порядку.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle2" sx={{ fontSize: 16, fontFamily: 'Kyiv-Sans-Bold' }}>
            Права та обов'язки Адміністрації Сайту
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            sx={{
              fontStyle: 'normal',
              fontWeight: '400',
              fontSize: '16px',
              lineHeight: '29px',
              textAlign: 'justify',
              margin: '10px 0px 10px 0px'
            }}
          >
            6.1. Адміністрація Сайту має право:
            <br />
            6.1.1. на власний розсуд вносити зміни в дану Угоду;
            <br />
            6.1.2. на свій розсуд обмежити доступ до будь-якої інформації, розміщеної Користувачем;
            <br />
            6.1.3. Адміністрація зберігає за собою право в будь-який час змінювати оформлення Сайту,
            його зміст, список сервісів, змінювати або доповнювати використовуванні скрипти,
            програмне забезпечення та інші об'єкти, що використовуються або зберігаються на Сайті,
            будь-які серверні додатки у будь-який час з попереднім повідомленням або без такого;
            <br />
            6.1.4. Адміністрація залишає за собою право на свій власний розсуд, а також при
            отриманні інформації від інших користувачів або третіх осіб про порушення Користувачем
            цієї Угоди, змінювати (модерувати) або видаляти будь-яку опубліковану Користувачем
            інформацію, що порушує заборони, встановлені цією Угодою (включаючи особисті
            повідомлення), призупиняти, обмежувати або припиняти доступ Користувача до всіх або до
            будь-якого з розділів або сервісів Сайту в будь-який час з будь-якої причини або без
            пояснення причин, з попереднім повідомленням або без такого, не відповідаючи за будь-яку
            шкоду, яка може бути заподіяна Користувачеві такою дією. Адміністрація Сайту закріплює
            за собою право видалити обліковий запис Користувача, персональний аккаунт Користувача
            та/або призупинити, обмежити або припинити доступ Користувача до будь-якого з сервісів
            Сайту, якщо Адміністрація виявить, що на її думку, Користувач представляє загрозу для
            Сайту та його Користувачів.
            <br />
            6.1.5. Відмовити у реєстрації облікового запису Користувача, обліковий запис якого було
            раніше видалено за порушення умов Угоди.
            <br />
            6.1.6. . Адміністрація Сайту має право розпоряджатися статистичною інформацією,
            пов'язаною з функціонуванням Сайту, а також інформацією Користувачів для забезпечення
            адресного показу рекламної інформації різним аудиторіям Користувачів Сайту. Для цілей
            організації функціонування та технічної підтримки Сайту і виконання цієї Угоди,
            Адміністрація Сайту має технічну можливість доступу до персональної сторінки
            Користувача, яку реалізує тільки у випадках, встановлених цією Угодою.
            <br />
            6.1.7. Адміністрація Сайту має право направляти Користувачеві інформацію про розвиток
            Сайту і його сервісів, а також рекламувати власну діяльність і послуги.
            <br />
            6.2. Адміністрація Сайту зобов'язується:
            <br />
            6.2.1. здійснювати захист облікового запису Користувача від несанкціонованого доступу, а
            також інших неправомірних дій стосовно такої інформації усіма що знаходяться в її
            розпорядженні технічними засобами;
            <br />
            6.2.2. у разі розміщення Користувачем інформації, поширення якої обмежено або заборонено
            чинним законодавством України, а також нормами міжнародних договорів, угод, конвенцій,
            протоколів або винести Користувачеві попередження і видалити таку інформацію, або
            деактивувати обліковий запис Користувача, або застосувати один або декілька заходів
            одночасно;
            <br />
            6.2.3. надати всю доступну інформацію про Користувача уповноваженим на те органам
            державної влади у випадках, встановлених чинним законодавством.
            <br />
            6.2.4. На вимогу Користувача надати інформацію про Послуги;
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle2" sx={{ fontSize: 16, fontFamily: 'Kyiv-Sans-Bold' }}>
            Умови про права інтелектуальної власності
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            sx={{
              fontStyle: 'normal',
              fontWeight: '400',
              fontSize: '16px',
              lineHeight: '29px',
              textAlign: 'justify',
              margin: '10px 0px 10px 0px'
            }}
          >
            7.1. Виключні права на Контент, розміщений на Сайті, належать його авторам або
            власникам.
            <br />
            7.1.1. жоден Контент не може бути скопійований (відтворений), перероблений, поширений,
            відображений у Фреймі, опублікований, завантажений, переданий, проданий або іншим
            способом використаний цілком або по частинах без попереднього дозволу правовласника,
            крім випадків, коли правовласник висловив свою згоду на вільне використання Контенту
            будь-якою особою.
            <br />
            7.1.2. Користувач, розміщуючи на Сайті належний йому на законних підставах Контент,
            надає іншим Користувачам невиключне право на його використання шляхом перегляду,
            відтворення (у тому числі копіювання), переробку (у тому числі на роздруківку копій) та
            інші права виключно з метою особистого некомерційного використання, крім випадків, коли
            таке використання завдає або може завдати шкоду охоронюваним законом інтересам
            правовласника.
            <br />
            7.1.3. використання Користувачем Контенту, доступ до якого отриманий виключно для
            особистого некомерційного використання, дозволяється за умови збереження всіх знаків
            авторства (копірайтів) або інших повідомлень про авторство, збереження імені автора в
            незмінному вигляді, збереженні твору в незмінному вигляді.
            <br />
            7.1.4. Крім свого власного Контенту, Користувач не має права завантажувати або іншим
            чином доводити до загального відома (публікувати на Сайті) Контент інших сайтів, баз
            даних та інші результати інтелектуальної діяльності при відсутності явно вираженої згоди
            правовласника на такі дії.
            <br />
            7.1.5. Будь-яке використання Сайту або Контенту, крім дозволеного в цій Угоді або у разі
            явної згоди правовласника на таке використання, без попереднього письмового дозволу
            правовласника, категорично заборонено.
            <br />
            7.1.6. Якщо інше явно не зазначено в цій Угоді, ніщо в цій Угоді не може розглядатися як
            передача виключних прав на Контент.
            <br />
            7.2. Відповідальність за порушення виключних прав.
            <br />
            7.2.1. Користувач несе особисту відповідальність за будь-який Контент або іншу
            інформацію, які він завантажує або іншим чином доводить до загального відома
            (оприлюднює) на Сайті або з його допомогою. Користувач не має права завантажувати,
            публікувати або передавати Контент на Сайті, якщо він не володіє відповідними правами на
            вчинення таких дій, придбаними або переданими йому у відповідності з законодавством
            України, а також нормами міжнародних договорів, угод, конвенцій, протоколів.
            <br />
            7.2.2. Адміністрація Сайту може, але не зобов'язана переглядати Сайт на наявність
            забороненого Контенту і може видаляти або переміщати (без попередження) будь-який
            Контент або користувачів на свій власний розсуд, з будь-якої причини або без причини,
            включаючи без всяких обмежень переміщення або видалення Контенту, який, на особисту
            думку Адміністрації, порушує цю Угоду та/або може порушувати права, завдати шкоди або
            загрожувати безпеці інших Користувачів або третіх осіб.
            <br />
            7.2.3. Розміщуючи свій Контент на Сайті, Користувач передає Адміністрації право робити
            копії свого Контенту з метою впорядкування і полегшення публікації і зберігання Контенту
            на Сайті.
            <br />
            7.2.4. Розміщуючи свій Контент в будь-якій частині Сайту, Адміністрація не має права
            використовувати Контент Користувача у власних цілях.
            <br />
            7.2.5. Якщо Користувач видаляє свій Контент з Сайту, права, зазначені в п. 7.2.3. -
            7.2.4. цих Правил, будуть автоматично відкликані, проте Адміністрація має право
            зберігати архівні копії Контенту протягом невизначеного терміну.
            <br />
            7.3. Сайт і Контент третіх осіб.
            <br />
            7.3.1. Сайт містить або може містити посилання на інші сайти в мережі Інтернет (сайти
            третіх осіб) так само, як і статті, фотографії, ілюстрації, графічні зображення, музику,
            звуки, відео, інформацію, додатки, програми та інший Контент, що належить або виходить
            від третіх осіб (Контент третіх осіб), що є результатом інтелектуальної діяльності і
            охороняються у відповідності до законодавства України, а також норм міжнародних
            договорів, угод, конвенцій, протоколів.
            <br />
            7.3.2. Зазначені треті особи та їх Контент не перевіряються Адміністрацією на
            відповідність тим чи іншим вимогам достовірності, повноти, сумлінності тощо.
            Адміністрація не несе відповідальність за будь-яку інформацію, розміщену на сайтах
            третіх осіб, до яких Користувач отримує доступ через Сайт або через Контент третіх осіб,
            включаючи, в тому числі, будь-які думки або твердження, висловлені на сайтах третіх осіб
            або в їх вмісту.
            <br />
            7.3.3. Посилання на будь-який сайт, продукт, послугу, будь-яку інформацію комерційного
            або некомерційного характеру, що розміщена на Сайті, не є схваленням або рекомендацією
            даних продуктів (послуг) з боку Адміністрації.
            <br />
            7.3.4. Якщо Користувач вирішив покинути Сайт і перейти до сайтів третіх осіб або
            використати, або встановити програми третіх осіб, він робить це на власний ризик і з
            цього моменту ця Угода більше не поширюється на Користувача. При подальших діях
            Користувачу варто керуватися нормами і політикою, у тому числі діловими звичаями тих
            осіб, чий Контент він збирається використовувати.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle2" sx={{ fontSize: 16, fontFamily: 'Kyiv-Sans-Bold' }}>
            Функціонування Сайту і відповідальність при його використанні
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            sx={{
              fontStyle: 'normal',
              fontWeight: '400',
              fontSize: '16px',
              lineHeight: '29px',
              textAlign: 'justify',
              margin: '10px 0px 10px 0px'
            }}
          >
            8.1. Користувачі несуть відповідальність за власні дії у зв'язку зі створенням та
            розміщенням інформації на власній персональній сторінці на Сайті (Особистому кабінеті
            Користувача), а також у зв'язку з розміщенням інформації на персональних сторінках інших
            Користувачів і в інших розділах Сайту у відповідності до чинного законодавства України,
            а також норм міжнародних договорів, угод, конвенцій, протоколів.
            <br />
            8.2. Адміністрація сайту надає технічну можливість його використання Користувачами, але
            не контролює і не несе відповідальності за дії або бездіяльність будь-яких осіб щодо
            використання Сайту.
            <br />
            8.3. Адміністрація Сайту не займається попередньою модерацією або цензурою інформації
            Користувачів і вживає заходи щодо захисту прав та інтересів осіб та забезпечення
            додержання вимог законодавства України, а також норм міжнародних договорів, угод,
            конвенцій, протоколів, у порядку визначеним законодавством України.
            <br />
            8.4. Адміністрація Сайту не несе відповідальності за порушення Користувачем цієї Угоди.
            <br />
            8.5. Адміністрація Сайту не несе відповідальності за втрату інформації внаслідок
            технічних збоїв та помилок у роботі сайту.
            <br />
            8.6. Адміністрація Сайту докладає усіх розумних і достатніх зусиль для забезпечення
            функціонування і працездатності Сайту. Адміністрація Сайту не несе відповідальності за
            тимчасові збої і перерви в роботі Сайту і викликані ними втрату інформації.
            Адміністрація не несе відповідальності за будь-які збитки комп'ютеру Користувача або
            іншої особи, мобільним пристроям, будь-якому іншому обладнанню або програмному
            забезпеченню, викликане або пов'язане зі скачуванням матеріалів з Сайту або за
            посиланнями розміщеними на Сайті.
            <br />
            8.7. Адміністрація Сайту має право направляти Користувачеві інформацію про розвиток
            Сайту і його сервісів, а також рекламувати власну діяльність і послуги.
            <br />
            8.8. Обмеження відповідальності Адміністрації Сайту:
            <br />
            <br /> Сайт і його сервіси, включаючи всі скрипти, додатки, контент і оформлення Сайту
            поставляються «як є». Адміністрація відмовляється від будь-яких гарантій того, що Сайт
            або його сервіси можуть підходити або не підходити для конкретних цілей використання.
            Адміністрація не може гарантувати та не обіцяє жодних специфічних результатів від
            використання Сайту та/або його сервісів;
            <br />
            <br /> Щоб уникнути непорозумінь Користувачеві слід дотримуватись обережності у
            завантаженні з Сайту або з розміщеними на ньому посиланнях і використанні будь-яких
            файлів, у тому числі програмного забезпечення. Адміністрація Сайту настійливо рекомендує
            використовувати тільки ліцензійне, в тому числі антивірусне програмне забезпечення;
            <br />
            <br />
            Використовуючи Сайт, Користувач погоджується з тим, що скачує з Сайту або з його
            допомогою будь-які матеріали на свій власний ризик і несе особисту відповідальність за
            можливі наслідки використання зазначених матеріалів, в тому числі за шкоду, що може
            заподіятись комп'ютеру Користувача або третім особам, за втрату даних або будь-якої
            іншої шкоди; <br />
            <br />
            За жодних обставин Адміністрація Сайту або її представники не несуть відповідальність
            перед Користувачем або перед будь-якими третіми особами за будь-який непрямий,
            випадковий, ненавмисний збиток, включаючи упущену вигоду або втрачені дані, шкоду честі,
            гідності або діловій репутації, викликаний у зв'язку з використанням Сайту, вмісту Сайту
            або інших матеріалів, до яких Користувачі або інші особи отримали доступ за допомогою
            Сайту, навіть якщо Адміністрація Сайту попереджала або вказувала на можливість такої
            шкоди. <br />
            <br />
            Адміністрація Сайту робить всі зусилля для збереження інформації, розміщеної
            користувачами але не гарантує її збереження у разі настання обставин непереборної дії.
            <br />
            <br />
            Адміністрація Сайту не здійснює обов'язкову перевірку розміщеної інформації і не несе
            відповідальності за відповідність інформації законодавству України, а також норм
            міжнародних договорів, конвенцій, протоколів.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle2" sx={{ fontSize: 16, fontFamily: 'Kyiv-Sans-Bold' }}>
            Конфіденційність
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            sx={{
              fontStyle: 'normal',
              fontWeight: '400',
              fontSize: '16px',
              lineHeight: '29px',
              textAlign: 'justify',
              margin: '10px 0px 10px 0px'
            }}
          >
            9.1. Сторони визнають конфіденційною будь-яку інформацію отриману Сторонами один від
            одного в процесі виконання цієї Угоди, окрім інформації, розміщеної Користувачем у
            публічних частинах Особистого кабінету та інформації, розміщеної Користувачем для
            загального доступу.
            <br />
            9.2. Сторони зобов'язуються не розголошувати конфіденційну інформацію без згоди іншої
            Сторони, за винятком випадків, коли така інформація повинна бути передана на законних
            підставах і обґрунтованим вимогам компетентних органів державної влади відповідно до
            законодавства України, а також норм міжнародних договорів, угод, конвенцій, протоколів,
            а також вживати всіх заходів захисту для того, щоб конфіденційна інформація стала відома
            третім особам.
            <br />
            9.3. Користувач, реєструючись на Сайті, надає Адміністрації право (згоду) передавати
            конфіденційну інформацію третім особам для забезпечення функціонування та розвитку
            сайту.
            <br />
            9.4. За неправомірне розголошення конфіденційної інформації та завдану внаслідок цього
            шкоду Сторони несуть відповідальність згідно з законодавством України, а також нормами
            міжнародних договорів, конвенцій, протоколів.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle2" sx={{ fontSize: 16, fontFamily: 'Kyiv-Sans-Bold' }}>
            Умови обробки персональних даних
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            sx={{
              fontStyle: 'normal',
              fontWeight: '400',
              fontSize: '16px',
              lineHeight: '29px',
              textAlign: 'justify',
              margin: '10px 0px 10px 0px'
            }}
          >
            10.1. Ці умови про обробку персональних даних розроблені відповідно до законодавства
            України, а також норм міжнародних договорів, угод, конвенцій, протоколів.
            <br />
            10.2. Всі Користувачі, що заповнили відомості, що становлять персональні дані на Сайті,
            а також розмістили іншу інформацію, вищезазначеними діями підтверджують свою згоду на
            обробку персональних даних та їх передачу оператору з обробки персональних даних.
            <br />
            10.3. Під персональними даними Користувача розуміється будь-яка біографічна інформація:
            прізвища, імена, по батькові; дата народження; поштові адреси (за місцем реєстрації та
            для контактів); відомості про громадянство; номер основного документа, що посвідчує
            особу, відомості про дату видачі зазначеного документа та органу що його видав; номери
            телефонів; номери факсів; адреса електронної пошти (e-mail).
            <br />
            10.4. Користувачі спрямовують свої персональні дані Адміністрації Сайту в цілях
            отримання доступу до інформаційних матеріалів на Сайті або на будь-якій з його сторінок.
            Користувач, приймаючи умови цієї Угоди, висловлює свою зацікавленість і повну згоду, що
            обробка персональних даних може включати в себе наступні дії: збір, систематизацію,
            накопичення, зберігання, уточнення (оновлення, зміну), використання, знищення.
            <br />
            10.5. Користувач гарантує: що надана ним інформація є повною, точною і достовірною; при
            наданні інформації не порушується чинне законодавство України, а також норми міжнародних
            договорів, угод, конвенцій, протоколів, законні права та інтереси третіх осіб; вся
            надана інформація заповнена Користувачем стосовно себе особисто.
            <br />
            10.6. Обробка персональних даних провадиться Адміністрацією з використанням баз даних на
            території України та поза її територією.
            <br />
            10.7. Користувач може у будь-який час відкликати згоду на обробку персональних даних,
            направивши Адміністрації Сайту відповідне повідомлення (заяву) на адресу, вказану у
            пункті 3.1. цієї Угоди рекомендованим листом з повідомленням про вручення. При цьому
            Користувач погоджується з тим, що за Адміністрацією залишається право обробки
            персональних даних Користувача у випадках, передбачених законодавством України, а також
            нормами міжнародних договорів, угод, конвенцій, протоколів. Відкликання вказаної згоди
            тягне за собою обов'язкову деактивацію Особистого кабінету користувача.
            <br />
            10.8. У разі вчинення Користувачем дій, що підпадають під дії як незаконні або ганьблять
            честь, гідність та ділової репутацію як Адміністрації Сайту так і третіх осіб, його
            персональні дані за законним запитом можуть стати доступні третім особам, про що
            Користувач дає свою згоду, реєструючись на Сайті.
            <br />
            10.9. Користувач дає згоду на отримання інформаційних розсилок та рекламних матеріалів
            від Адміністрації, або від інших осіб за дорученням Адміністрації, на адресу електронної
            пошти та контактний телефон, зазначені Користувачем при реєстрації на Сайті.
            <br />
            10.10. Згода на отримання інформаційних розсилок та рекламних матеріалів може бути
            відкликана Користувачем у будь-який час шляхом направлення Адміністрації відповідного
            повідомлення на адресу, вказану у пункті 3.1. цієї Угоди рекомендованим листом з
            повідомленням про вручення. Після отримання такого повідомлення Адміністрація припиняє
            надсилати на вказані Користувачем при реєстрації адресу електронної пошти та контактний
            номер телефону повідомлення з рекламними матеріалами.
            <br />
            10.11. Користувач надає свою згоду на використання його зображення в якості фотографії
            Користувача (аватар) Адміністрацією на безоплатній основі.
            <br />
            10.12. Користувач зобов'язується утриматися від використання зображень третіх осіб як
            фотографії Користувача (аватар) на Сайті.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle2" sx={{ fontSize: 16, fontFamily: 'Kyiv-Sans-Bold' }}>
            Заявки на повернення оплати за підписку
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            sx={{
              fontStyle: 'normal',
              fontWeight: '400',
              fontSize: '16px',
              lineHeight: '29px',
              textAlign: 'justify',
              margin: '10px 0px 10px 0px'
            }}
          >
            11.1. Користувач повинен зобов'язаний звернутися до Адміністрації сайту та подати
            письмову заявку на повернення коштів протягом 10 календарних днів з моменту виникнення
            підстав для повернення коштів відповідно до п.5.1.5. цієї Угоди.
            <br />
            11.2. Заявка на повернення коштів повинна містити:
            <br />
            - Ім'я та контактні дані користувача.
            <br />
            - Підстави для повернення коштів. <br />
            - Документи, що підтверджують оплату послуг.
            <br />
            11.3. Адміністрація сайту зобов'язана розглянути заявку протягом 10 робочих днів з
            моменту її отримання. У межах зазначеного строку Адміністрація сайту може запросити
            додаткову інформацію або документи, необхідні для прийняття рішення. Адміністрація може
            прийняти одне з трьох рішень: про відхилення заявки через відсутність підстав для
            повернення коштів; про часткове повернення коштів; про повне повернення коштів.
            <br />
            11.4. У разі позитивного рішення Адміністрації, повернення або часткове повернення
            коштів здійснюється на рахунок Користувача протягом 10 робочих днів з моменту прийняття
            рішення.
            <br />
            11.5. Повернення коштів не здійснюється у наступних випадках:
            <br />
            <ul
              style={{
                fontFamily: 'Public Sans !important',
                fontStyle: 'normal',
                fontWeight: '400',
                fontSize: '16px',
                lineHeight: '29px',
                textAlign: 'justify',
                margin: '10px 0px 10px 0px'
              }}
            >
              <li>Якщо послуги були надані в повному обсязі та належної якості.</li>
              <li>Якщо Користувач порушив умови цієї угоди.</li>
            </ul>
            11.6. Рішення Адміністрації сайту щодо повернення коштів є остаточним і не підлягає
            оскарженню, за винятком випадків, передбачених чинним законодавством.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle2" sx={{ fontSize: 16, fontFamily: 'Kyiv-Sans-Bold' }}>
            Прикінцеві положення
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            sx={{
              fontStyle: 'normal',
              fontWeight: '400',
              fontSize: '16px',
              lineHeight: '29px',
              textAlign: 'justify',
              margin: '10px 0px 10px 0px'
            }}
          >
            12.1. Ця Угода, з моменту її укладення, є Договором між Користувачем і Адміністрацією
            Сайту щодо порядку використання Сайту і його сервісів і замінює собою всі попередні
            угоди між Користувачем і Адміністрацією;
            <br />
            12.2. Ця Угода регулюється і тлумачиться відповідно до законодавства України, а також
            норм міжнародних договорів, угод, конвенцій, протоколів. Питання, не врегульовані цією
            Угодою, підлягають вирішенню відповідно до законодавства України, а також норм
            міжнародного права.
            <br />
            12.3. У разі виникнення будь-яких суперечок або розбіжностей, пов'язаних з виконанням
            цієї Угоди, Користувач та Адміністрація Сайту прикладуть усі зусилля для їх вирішення
            шляхом проведення переговорів між ними. У випадку, якщо спори не будуть дозволені шляхом
            переговорів, спори підлягають вирішенню в порядку, встановленому чинним законодавством
            України, а також норм міжнародних договорів, угод, конвенцій, протоколів.
            <br />
            12.4. Ця Угода вступає в силу з моменту приєднання до неї і діє протягом невизначеного
            терміну.
            <br />
            12.5. Ця Угода складена українською мовою і може бути надана Користувачу для
            ознайомлення іншою мовою. У разі розбіжності україномовної версії Угоди і версії Угоди
            на іншій мові, застосовуються положення україномовної версії цієї Угоди.
            <br />
            12.6. Якщо з тих чи інших причин одне або декілька положень цієї Угоди буде визнано
            недійсним або не матиме юридичної сили, це не впливає на дійсність або застосовність
            інших положень цієї Угоди.
            <br />
            <br />
            РЕКВІЗИТИ АДМІНІСТРАЦІЇ САЙТУ <br />
            Повна назва: ТОВАРИСТВО З ОБМЕЖЕНОЮ ВІДПОВІДАЛЬНІСТЮ "Б&Б Рішення"
            <br />
            Код ЄДРПОУ: 44686788
            <br />
            Реєстраційний номер: 1005561020000080606
            <br />
            Адреса: Україна, 65052, Одеська обл., місто Одеса, вулиця Дюківська, будинок 5, квартира
            172.
          </Typography>
        </Grid>
      </Grid>
    </Page>
  );
}

export default AgreementPage;
