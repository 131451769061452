import * as React from 'react';
import { useState } from 'react';
import FiberNewOutlinedIcon from '@mui/icons-material/FiberNewOutlined';
import AirportShuttleOutlinedIcon from '@mui/icons-material/AirportShuttleOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import HourglassEmptyOutlinedIcon from '@mui/icons-material/HourglassEmptyOutlined';
import { Menu, MenuItem, Typography } from '@mui/material';
// eslint-disable-next-line
export const StatusHandler = (status, type) => {
  // eslint-disable-next-line
  switch (status) {
    case 'DONE':
      return (
        <Typography
          sx={{
            display: 'flex',
            alignItems: 'center',
            border: '1px solid #60A5DF',
            borderRadius: '20px',
            p: '5px 15px',
            background: '#E8F4FF',
            maxWidth: '200px',
            m: '0 auto'
          }}
        >
          <CheckCircleOutlineOutlinedIcon sx={{ mr: 2, color: '#60A5DF' }} /> Отримано
        </Typography>
      );
    case 'NEW':
      if (type === 'history') {
        return (
          <Typography
            sx={{
              display: 'flex',
              alignItems: 'center',
              border: '1px solid #5CB85C',
              borderRadius: '20px',
              p: '5px 15px',
              background: '#EDFFED',
              maxWidth: '200px',
              m: '0 auto'
            }}
          >
            <HourglassEmptyOutlinedIcon sx={{ mr: 2, color: '#5CB85C' }} /> Необроблене
          </Typography>
        );
      } else {
        return (
          <Typography
            sx={{
              display: 'flex',
              alignItems: 'center',
              border: '1px solid #5CB85C',
              borderRadius: '20px',
              p: '5px 15px',
              background: '#EDFFED',
              maxWidth: '200px',
              m: '0 auto'
            }}
          >
            <FiberNewOutlinedIcon sx={{ mr: 2, color: '#5CB85C' }} /> Нове
          </Typography>
        );
      }
    case 'SENT':
      return (
        <Typography
          sx={{
            display: 'flex',
            alignItems: 'center',
            border: '1px solid #663A8C',
            borderRadius: '20px',
            p: '5px 15px',
            background: '#F7EEFF',
            maxWidth: '200px',
            m: '0 auto'
          }}
        >
          <AirportShuttleOutlinedIcon sx={{ mr: 2, color: '#663A8C' }} /> Відправлено
        </Typography>
      );
    case 'CANCELED':
      return (
        <Typography
          sx={{
            display: 'flex',
            alignItems: 'center',
            border: '1px solid #DF6060',
            borderRadius: '20px',
            p: '5px 15px',
            background: '#FFE7E7',
            maxWidth: '200px',
            m: '0 auto'
          }}
        >
          <CancelOutlinedIcon sx={{ mr: 2, color: '#DF6060' }} /> Скасовано
        </Typography>
      );
    case 'PROCESSING':
      return (
        <Typography
          sx={{
            display: 'flex',
            alignItems: 'center',
            border: '1px solid #FDC940',
            borderRadius: '20px',
            p: '5px 15px',
            background: '#FFF7E3',
            maxWidth: '200px',
            m: '0 auto'
          }}
        >
          <AccessTimeOutlinedIcon sx={{ mr: 2, color: '#FDC940' }} /> В обробці
        </Typography>
      );
  }
}; // eslint-disable-next-line
export const StatusHandlerSeller = (status, handler) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const menu = (
    <Menu
      anchorEl={anchorEl}
      id="action-menu"
      open={open}
      onClose={handleClose}
      onMouseLeave={handleClose}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: 'hidden',
          boxShadow: 'none',
          borderRadius: '5px',
          border: '1px solid black',
          paddingTop: '10px',
          paddingLeft: '20px',
          paddingRight: '20px',
          width: '200px',
          mt: 1
        }
      }}
      autoFocus={false}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
    >
      {/* <MenuItem
        onClick={(event) => {
          event.stopPropagation();
          event.preventDefault();
          handleClose();
          handler('NEW');
        }}
        sx={{ padding: 0, mb: 1 }}
      >
        <Typography
          sx={{
            display: 'flex',
            alignItems: 'center',

            p: '5px 15px',

            cursor: 'pointer'
          }}
        >
          <FiberNewOutlinedIcon sx={{ mr: 2, color: '#5CB85C' }} /> Нове
        </Typography>
      </MenuItem> */}
      {status !== 'PROCESSING' &&
        status !== 'SENT' &&
        status !== 'DONE' &&
        status !== 'CANCELED' && (
          <MenuItem
            onClick={(event) => {
              event.stopPropagation();
              event.preventDefault();
              handleClose();
              handler('PROCESSING');
            }}
            sx={{ padding: 0, mb: 1 }}
          >
            <Typography
              sx={{
                display: 'flex',
                alignItems: 'center',
                p: '5px 15px',
                cursor: 'pointer'
              }}
            >
              <AccessTimeOutlinedIcon sx={{ mr: 2, color: '#FDC940' }} /> В обробці
            </Typography>
          </MenuItem>
        )}
      {status !== 'SENT' && status !== 'DONE' && status !== 'CANCELED' && (
        <MenuItem
          onClick={(event) => {
            event.stopPropagation();
            event.preventDefault();
            handleClose();
            handler('SENT');
          }}
          sx={{ padding: 0, mb: 1 }}
        >
          <Typography
            sx={{
              display: 'flex',
              alignItems: 'center',
              p: '5px 15px',
              cursor: 'pointer'
            }}
          >
            <AirportShuttleOutlinedIcon sx={{ mr: 2, color: '#663A8C' }} /> Відправлено
          </Typography>
        </MenuItem>
      )}

      <MenuItem
        onClick={(event) => {
          event.stopPropagation();
          event.preventDefault();
          handleClose();
          handler('DONE');
        }}
        sx={{ padding: 0, mb: 1 }}
      >
        <Typography
          sx={{
            display: 'flex',
            alignItems: 'center',
            p: '5px 15px',
            cursor: 'pointer'
          }}
        >
          <CheckCircleOutlineOutlinedIcon sx={{ mr: 2, color: '#60A5DF' }} /> Отримано
        </Typography>
      </MenuItem>
      {/* {status !== 'SENT' && ( */}
      <MenuItem
        onClick={(event) => {
          event.stopPropagation();
          event.preventDefault();
          handleClose();
          handler('CANCELED');
        }}
        sx={{ padding: 0, mb: 1 }}
      >
        <Typography
          sx={{
            display: 'flex',
            alignItems: 'center',
            p: '5px 15px',
            cursor: 'pointer'
          }}
        >
          <CancelOutlinedIcon sx={{ mr: 2, color: '#DF6060' }} /> Скасовано
        </Typography>
      </MenuItem>
      {/* )} */}
    </Menu>
  );
  // eslint-disable-next-line
  const renderStatus = (status) => {
    // eslint-disable-next-line
    switch (status) {
      case 'DONE':
        return (
          <>
            <Typography
              sx={{
                display: 'flex',
                alignItems: 'center',
                border: '1px solid #60A5DF',
                borderRadius: '20px',
                p: '5px 15px',
                background: '#E8F4FF',
                maxWidth: '200px',
                m: '0 auto'
              }}
              // onClick={handleClick}
            >
              <CheckCircleOutlineOutlinedIcon sx={{ mr: 2, color: '#60A5DF' }} /> Отримано
              {/* <ArrowDropDownOutlinedIcon sx={{ color: '#60A5DF', margin: '0px 0px 0px auto' }} /> */}
            </Typography>
          </>
        );
      case 'NEW':
        return (
          <>
            {' '}
            <Typography
              sx={{
                display: 'flex',
                alignItems: 'center',
                border: '1px solid #5CB85C',
                borderRadius: '20px',
                p: '5px 15px',
                background: '#EDFFED',
                cursor: 'pointer',
                maxWidth: '200px',
                m: '0 auto'
              }}
              onClick={handleClick}
            >
              <FiberNewOutlinedIcon sx={{ mr: 2, color: '#5CB85C' }} /> Нове
              <ArrowDropDownOutlinedIcon sx={{ color: '#5CB85C', margin: '0px 0px 0px auto' }} />
            </Typography>
            {menu}
          </>
        );
      case 'SENT':
        return (
          <>
            <Typography
              sx={{
                display: 'flex',
                alignItems: 'center',
                border: '1px solid #663A8C',
                borderRadius: '20px',
                p: '5px 15px',
                background: '#F7EEFF',
                cursor: 'pointer',
                maxWidth: '200px',
                m: '0 auto'
              }}
              onClick={handleClick}
            >
              <AirportShuttleOutlinedIcon sx={{ mr: 2, color: '#663A8C' }} /> Відправлено
              <ArrowDropDownOutlinedIcon sx={{ color: '#663A8C', margin: '0px 0px 0px auto' }} />
            </Typography>
            {menu}
          </>
        );
      case 'CANCELED':
        return (
          <>
            <Typography
              sx={{
                display: 'flex',
                alignItems: 'center',
                border: '1px solid #DF6060',
                borderRadius: '20px',
                p: '5px 15px',
                background: '#FFE7E7',
                maxWidth: '200px',
                m: '0 auto'
              }}
              // onClick={handleClick}
            >
              <CancelOutlinedIcon sx={{ mr: 2, color: '#DF6060' }} /> Скасовано
              {/* <ArrowDropDownOutlinedIcon sx={{ color: '#DF6060', margin: '0px 0px 0px auto' }} /> */}
            </Typography>
          </>
        );
      case 'PROCESSING':
        return (
          <>
            <Typography
              sx={{
                display: 'flex',
                alignItems: 'center',
                border: '1px solid #FDC940',
                borderRadius: '20px',
                p: '5px 15px',
                background: '#FFF7E3',
                cursor: 'pointer',
                maxWidth: '200px',
                m: '0 auto'
              }}
              onClick={handleClick}
            >
              <AccessTimeOutlinedIcon sx={{ mr: 2, color: '#FDC940' }} /> В обробці
              <ArrowDropDownOutlinedIcon sx={{ color: '#FDC940', margin: '0px 0px 0px auto' }} />
            </Typography>
            {menu}
          </>
        );
    }
  };
  return <>{renderStatus(status)}</>;
};
