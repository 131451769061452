import { setIsOpenPaymentModal } from '../redux/mainReducer';
import ApiService from './apiHelper';
const api = new ApiService('auth/payment-by-card');
export const makePayment = (dispatch, type) => {
  const getDesc = () => {
    switch (type) {
      case 'MONTH_PREMIUM':
        return 'Преміум “Місяць”';
      case 'THREE_MONTH_PREMIUM':
        return 'Преміум “3 місяці”';
      case 'YEAR_PREMIUM':
        return 'Преміум “Рік”';
      case 'FREE_TRIAL_PREMIUM':
        return 'Преміум “Пробний період на місяць”';
      default:
        return;
    }
  };
  api
    .sendRequest({ description: getDesc(), paymentType: type })
    .then((res) => {
      dispatch(
        setIsOpenPaymentModal({
          isOpen: true,
          data: res.result.data,
          signature: res.result.signature
        })
      );
    })
    .catch((error) => console.error(error));
};
