import React, { useEffect, useState, useCallback } from 'react';

//Material
import CloseIcon from '@mui/icons-material/Close';

import { Box, Grid, Modal, Slider, Stack, Typography } from '@mui/material';

import Cropper from 'react-easy-crop';
import { getCroppedImg, getRotatedImage } from '../../services/canvasUtils';
import { getOrientation } from 'get-orientation/browser';

import { StyledLabel } from '../../components/StyledComponents';
import { connect, useDispatch } from 'react-redux';
import { LetsGoBtn3 } from '../../components/Buttons';
import { setIsOpenImageCropModal } from '../../redux/mainReducer';

import starBlue from '../../assets/StarBlue.png';
import starGreen from '../../assets/StarGreen.png';

import './CropModal.css';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  maxWidth: 900,
  bgcolor: 'background.paper',
  borderRadius: '20px',
  border: 'none',
  boxShadow: 24,
  padding: '50px 80px 50px 80px',
  overflow: 'scroll',
  height: 'inherit',
  maxHeight: '100vh',
  scrollbarWidth: 'none',
  '&::-webkit-scrollbar': {
    display: 'none'
  }
};
const ORIENTATION_TO_ANGLE = {
  3: 180,
  6: 90,
  8: -90
};

function urltoFile(url, filename, mimeType) {
  mimeType = mimeType || (url.match(/^data:([^;]+);/) || '')[1];
  return fetch(url)
    .then(function (res) {
      return res.arrayBuffer();
    })
    .then(function (buf) {
      return new File([buf], filename, { type: mimeType });
    });
}

function CropModal({ isOpenModal, file, setter }) {
  const [imageSrc, setImageSrc] = useState(null);
  const [fileSaved, setFileSaved] = useState(null);

  const [screenType, setScreenType] = useState('desktop');
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const reset = () => {
    setZoom(1);
    setRotation(0);
    setCrop({ x: 0, y: 0 });
  };

  const dispatch = useDispatch();

  useEffect(() => {
    if (window.screen.width <= 600) {
      setScreenType('mobile');
    }
  }, []);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(
        imageSrc,
        croppedAreaPixels,
        fileSaved.type,
        rotation
      );

      urltoFile(croppedImage, fileSaved.name, fileSaved.type).then((file) => {
        setter(file);
        dispatch(setIsOpenImageCropModal(false));
        reset();
      });
    } catch (e) {
      console.error(e);
    }
    // eslint-disable-next-line
  }, [imageSrc, croppedAreaPixels, rotation]);

  function readFile(file) {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.addEventListener('load', () => resolve(reader.result), false);
      reader.readAsDataURL(file);
    });
  }
  const onFileChange = async (file) => {
    let imageDataUrl = await readFile(file);
    try {
      // apply rotation if needed
      const orientation = await getOrientation(file);
      const rotation = ORIENTATION_TO_ANGLE[orientation];
      if (rotation) {
        imageDataUrl = await getRotatedImage(imageDataUrl, rotation);
      }
    } catch (e) {
      console.warn('failed to detect the orientation');
    }
    setImageSrc(imageDataUrl);
  };
  useEffect(() => {
    if (file) {
      onFileChange(file);
      setFileSaved(file);
    }
    // eslint-disable-next-line
  }, [file]);

  return (
    <div>
      <Modal
        open={isOpenModal}
        onClose={(event, reason) => {
          if (reason === 'backdropClick') {
            return;
          }
          reset();
          dispatch(setIsOpenImageCropModal(false));
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="bonfair_crop_modal"
      >
        <Box sx={style} className="bonfair_crop_modal-box">
          <img
            style={{ position: 'fixed', zIndex: '0', top: '0', left: '0' }}
            src={starGreen}
            alt="star-green"
          />
          <img
            style={{ position: 'fixed', zIndex: '0', bottom: '40px', right: '0' }}
            src={starBlue}
            alt="star-green"
          />
          <CloseIcon
            className="bonfair_crop_modal-close"
            style={{ position: 'absolute', right: '10px', top: '10px', cursor: 'pointer' }}
            onClick={() => {
              reset();
              dispatch(setIsOpenImageCropModal(false));
            }}
          />

          <Grid container spacing={screenType !== 'desktop' ? 1 : 2}>
            <Grid item xs={12} md={12} lg={12}>
              <StyledLabel className="cropModal-title-text">
                {/* {translate('bonfair_service_modal')} */}
                {'Редагуємо фото'}
              </StyledLabel>
            </Grid>

            <Grid
              item
              xs={12}
              md={12}
              lg={12}
              sx={{
                display: 'flex',
                alignItems: 'left',
                flexDirection: 'column',
                position: 'relative',
                height: '400px'
              }}
            >
              <Cropper
                image={imageSrc}
                crop={crop}
                rotation={rotation}
                zoom={zoom}
                aspect={4/4}
                onCropChange={setCrop}
                onRotationChange={setRotation}
                onCropComplete={onCropComplete}
                onZoomChange={setZoom}
              />
            </Grid>

            <Grid
              item
              xs={12}
              style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}
            >
              <Stack
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                  marginRight: '30px'
                }}
              >
                <Typography variant="subtitle2" sx={{ fontSize: 16, fontFamily: 'Kyiv-Sans-Bold' }}>
                  Зум
                </Typography>
                <Slider
                  value={zoom}
                  min={1}
                  max={3}
                  step={0.1}
                  aria-label="Volume"
                  onChange={(e, zoom) => setZoom(zoom)}
                />
              </Stack>
              <Stack style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                <Typography variant="subtitle2" sx={{ fontSize: 16, fontFamily: 'Kyiv-Sans-Bold' }}>
                  Повернути
                </Typography>
                <Slider
                  value={rotation}
                  min={0}
                  max={360}
                  step={1}
                  aria-labelledby="Rotation"
                  onChange={(e, rotation) => setRotation(rotation)}
                />
              </Stack>
            </Grid>
            <Grid
              item
              xs={12}
              style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}
            >
              <LetsGoBtn3
                size="large"
                onClick={() => {
                  showCroppedImage();
                }}
                width={257}
                style={{ textTransform: 'inherit' }}
                text="Зберегти зміни"
              />
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    language: state.Intl.locale
  };
};
export default connect(mapStateToProps)(CropModal);
