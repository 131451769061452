import React, { useState } from 'react';

//Material
import { Box, ClickAwayListener, Rating, TextField, Typography, styled } from '@mui/material';
import { dateFormattingByDate } from '../helpers/date-helpers';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import ReplyIcon from '@mui/icons-material/Reply';
import { setIsOpenDeleteModal } from '../redux/mainReducer';
import ApiService from '../helpers/apiHelper';
import EmojiPicker from 'emoji-picker-react';
import EmojiEmotionsOutlinedIcon from '@mui/icons-material/EmojiEmotionsOutlined';
import { LetsGoBtn2 } from './Buttons';

const CssTextField = styled(TextField)({
  '& label.Mui-focused': {
    color: '#000000'
  },
  '& label': {
    color: '#A1A1A1',
    fontFamily: 'Kyiv-Sans-Light'
  },
  '& .MuiInputBase-root': { alignItems: 'end' }
});

export default function RespondCard({
  setter,
  index,
  data,
  setToDelete,
  setModalText,
  serviceOwnerId,
  ...props
}) {
  const apiFeedback = new ApiService('posts/feedback');
  const apiFeedbackResp = new ApiService('posts/feedback/add-feedback-answer');
  const apiFeedbackRespDelete = new ApiService('posts/feedback/delete-feedback-answer');
  // const apiFeedback = new ApiService('feedback');
  // const apiFeedbackResp = new ApiService('/feedback/add-feedback-answer');
  // const apiFeedbackRespDelete = new ApiService('/feedback/delete-feedback-answer');

  const [isShowResponseField, setIsShowResponseField] = useState(false);
  const [responseText, setResponseText] = useState('');
  const [isShowEmoji, setIsShowEmoji] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const language = useSelector((state) => state.Intl.locale);
  const userData = useSelector((state) => state.global.userData);

  const feedbackDelete = (id) => {
    apiFeedback.deleteItem(id).then((res) => setter());
  };
  const responseDelete = (id) => {
    apiFeedbackRespDelete.deleteItem(id).then((res) => setter());
  };
  return (
    <div
      style={{
        border: '1px solid black',
        backgroundColor: 'white',
        borderRadius: '20px',
        width: '100%',
        padding: '15px'
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'row'
        }}
      >
        <Typography
          sx={{
            fontSize: '16px',
            marginBottom: '5px !important',
            textAlign: 'left'
          }}
          children={data.text
            .split('\r\n')
            .filter((el) => el.length !== 0)
            .map((string, index) => {
              return (
                <p key={index} style={{ margin: '0' }}>
                  {string}
                  <br />
                </p>
              );
            })}
        />
        <Box sx={{ display: 'flex', width: 'fit-content' }}>
          {serviceOwnerId === userData?.id && !data?.answerFeedback && (
            <ReplyIcon
              onClick={() => setIsShowResponseField(!isShowResponseField)}
              sx={{
                color: '#CBCBCB',
                cursor: 'pointer',
                ':hover': {
                  color: '#60A5DF'
                }
              }}
            />
          )}

          {userData.id === data.userDTO.id && (
            <DeleteOutlineOutlinedIcon
              onClick={() =>
                dispatch(
                  setIsOpenDeleteModal({
                    isOpen: true,
                    text: 'цей відгук?',
                    func: feedbackDelete,
                    id: data.id
                  })
                )
              }
              sx={{
                color: '#CBCBCB',
                cursor: 'pointer',
                ':hover': {
                  color: '#60A5DF'
                }
              }}
            />
          )}
        </Box>
      </div>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        {' '}
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            // width: '220px',
            marginTop: '20px'
          }}
        >
          <div style={{ display: 'flex' }}>
            <Typography
              onClick={(e) => {
                e.stopPropagation();
                navigate(`/${language}/user-page/${data.userDTO.id}?type=posts?type=posts`);
              }}
              variant="subtitle"
              sx={{
                display: 'flex',
                fontFamily: 'Public Sans,sans-serif !important',
                fontSize: '16px',
                textAlign: 'left',
                fontWeight: '700',
                marginRight: '10px',
                cursor: 'pointer',
                alignItems: 'center'
              }}
            >
              {`@${data.userDTO.nickname}`}
            </Typography>{' '}
            <Rating
              sx={{ ml: '15px' }}
              name="read-only"
              value={data.rating}
              precision={0.5}
              readOnly
            />
          </div>

          <Typography sx={{ color: '#868686', fontSize: '16px' }} variant="subtitle">
            {dateFormattingByDate(new Date(data.creationTimeForFeedback))}
          </Typography>
        </div>
        {isShowResponseField && (
          <>
            {' '}
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'end',
                position: 'relative',
                // width: '50%',
                marginTop: '30px'
              }}
            >
              <CssTextField
                variant="outlined"
                placeholder="Надайте відповідь на цей відгук."
                multiline
                rows={8}
                fullWidth
                size="normal"
                inputProps={{ style: { textAlign: 'left' } }}
                InputProps={{
                  endAdornment: (
                    <>
                      <EmojiEmotionsOutlinedIcon
                        onClick={async () => {
                          setIsShowEmoji(!isShowEmoji);
                        }}
                        sx={{
                          color: '#60A5DF',
                          cursor: 'pointer',
                          fontSize: '20px',
                          mr: 1
                        }}
                      />
                    </>
                  )
                }}
                value={responseText}
                onChange={(event) => {
                  if (isShowEmoji) {
                    setIsShowEmoji(false);
                  }
                  if (event.target.value.length <= 500) setResponseText(event.target.value);
                  setError('');
                }}
              />
              {isShowEmoji && (
                <ClickAwayListener
                  onClickAway={() => {
                    setIsShowEmoji(false);
                  }}
                >
                  <Box>
                    <EmojiPicker
                      id="emogiPicker"
                      onEmojiClick={(emojiData, event) => {
                        let newStr = `${responseText}${emojiData.emoji}`;
                        setResponseText(newStr);
                      }}
                      skinTonesDisabled
                      searchDisabled
                      previewConfig={{ showPreview: false }}
                      style={{
                        position: 'absolute',
                        bottom: error.length !== 0 ? '-200px' : '-220px',
                        right: '32px',
                        zIndex: '99'
                      }}
                      width={'50%'}
                      height={'300px'}
                    />
                  </Box>
                </ClickAwayListener>
              )}
              <p
                style={{ margin: '0 5px', color: 'lightgray', textAlign: 'left', fontSize: '14px' }}
              >{`${responseText.length}/500`}</p>
              {error.length !== 0 && (
                <p
                  style={{ margin: '0 5px', color: 'red', textAlign: 'left', fontSize: '14px' }}
                >{`${error}`}</p>
              )}
              <Box sx={{ display: 'flex', width: '100%', alignItems: 'center' }}>
                {' '}
                <LetsGoBtn2
                  text="Додати"
                  width="105px"
                  marginTop="0px"
                  onClick={() => {
                    setIsShowEmoji(false);
                    if (responseText.length === 0) {
                      setError('Мінімальна довжина відповіді: 1 символ');
                    } else if (responseText.length > 500) {
                      setError('Максимальна довжина відповіді: 500 символів');
                    } else {
                      apiFeedbackResp
                        .updateDataID(data?.id, { answerText: responseText })
                        .then((res) => {
                          setter();
                          setIsShowResponseField(false);
                          setResponseText('');
                        });
                    }
                  }}
                />
                <Typography
                  fontSize={14}
                  variant="body"
                  sx={{
                    textDecoration: 'underline',
                    margin: '0px',
                    ml: 3,
                    fontWeight: '700',
                    cursor: 'pointer',
                    zIndex: '98',
                    ':hover': {
                      fontWeight: '1000'
                    }
                  }}
                  onClick={() => {
                    setIsShowResponseField(false);
                    setIsShowEmoji(false);
                    setError('');
                    setResponseText('');
                  }}
                >
                  Скасувати
                </Typography>
              </Box>
            </div>
          </>
        )}
        {data?.answerFeedback && (
          <div style={{ borderTop: '1px solid #60A5DF', marginTop: '30px', paddingTop: '10px' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              {' '}
              <Typography
                variant="subtitle"
                sx={{
                  display: 'flex',
                  fontFamily: 'Public Sans,sans-serif !important',
                  fontSize: '16px',
                  textAlign: 'left',
                  fontWeight: '700',
                  marginRight: '10px',

                  alignItems: 'center'
                }}
              >
                {`Відповідь від продавця:`}
              </Typography>
              {serviceOwnerId === userData?.id && data?.answerFeedback && (
                <DeleteOutlineOutlinedIcon
                  onClick={() =>
                    dispatch(
                      setIsOpenDeleteModal({
                        isOpen: true,
                        text: 'цю відповідь?',
                        func: responseDelete,
                        id: data?.id
                      })
                    )
                  }
                  sx={{
                    color: '#CBCBCB',
                    cursor: 'pointer',
                    ':hover': {
                      color: '#60A5DF'
                    }
                  }}
                />
              )}
            </div>

            <Typography
              sx={{
                fontSize: '16px',
                marginBottom: '5px !important',
                marginTop: '15px !important',
                textAlign: 'left'
              }}
              children={data?.answerFeedback?.text
                .split('\r\n')
                .filter((el) => el.length !== 0)
                .map((string, index) => {
                  return (
                    <p key={index} style={{ margin: '0' }}>
                      {string}
                      <br />
                    </p>
                  );
                })}
            />
          </div>
        )}
      </Box>
    </div>
  );
}
