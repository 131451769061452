import React, { useEffect, useState } from 'react';

//Material
import { Avatar, Box, Menu, MenuItem, Paper, Skeleton, Typography } from '@mui/material';

import { Stack } from '@mui/system';

import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';

import BookmarkIcon from '@mui/icons-material/Bookmark';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';

import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import LinkIcon from '@mui/icons-material/Link';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import SpeakerNotesOffOutlinedIcon from '@mui/icons-material/SpeakerNotesOffOutlined';
import SpeakerNotesOutlinedIcon from '@mui/icons-material/SpeakerNotesOutlined';

import ImageGallery from 'react-image-gallery';
// import { useTranslate } from 'react-redux-multilingual';
import { useSnackbar } from 'notistack';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import DefaultAvatar from '../assets/defaultPostImg.jpg';
import ApiService from '../helpers/apiHelper';
import { dateFormattingByDate } from '../helpers/date-helpers';
import '../pages/modals/PostModal.css';
import {
  setIsOpenAuthModal,
  setIsOpenComplainModal,
  setIsOpenDeleteModal,
  setIsOpenPostModal,
  setIsOpenResendingModal,
  setUserData,
  setUserSavedPosts
  // setIsOpenUserContactsModal
} from '../redux/mainReducer';
// import UserContactsModal from '../pages/modals/UserContactsModal';
function PostCard({
  index,
  el,
  noWidth = false,
  language,
  userData,
  dataSetter,
  blockedUsers,
  usersWhoBlocked,
  commentsSwitcher,
  ...props
}) {
  // const translate = useTranslate();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [photos, setPhotos] = useState([]);
  const [isLoadingPhotos, setIsLoadingPhotos] = useState(false);
  const [likes, setLikes] = useState([]);
  const [likesCount, setLikesCount] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const userSavedPosts = useSelector((state) => state.global.userSavedPosts);
  // const isOpenUserContactsModal = useSelector((state) => state.global.isOpenUserContactsModal);

  // const apiSavePost = new ApiService('saved-post');
  const apiSavePost = new ApiService('posts/saved-post');

  // const apiPhoto = new ApiService('get-photo');
  const apiPhoto = new ApiService('photo/get-photo');

  const apiAddLike = new ApiService('posts/post/like');
  const postLikes = new ApiService('posts/post/get-users-by-like');
  const post = new ApiService('posts/post');
  const apiSwitchComments = new ApiService('posts/post/switch-comment');
  const apiComplaint = new ApiService('posts/post/complaint-post');

  // const apiAddLike = new ApiService('post/like');
  // const postLikes = new ApiService('post/get-users-by-like');
  // const post = new ApiService('post');
  // const apiSwitchComments = new ApiService('post/switch-comment');
  //   const apiComplaint = new ApiService('post/complaint-post');

  const isHoverSaved = window.location.href.includes('saved');
  const isHoverSearch =
    window.location.href.includes('search') || window.location.href.includes('ribbon');
  const isHoverMy = window.location.href.includes('user-page');
  const isHoverSelf = window.location.href.includes('post/');

  const sendComplaint = (type, text) => {
    apiComplaint
      .updateDataID(el?.id, {
        complaintType: type,
        complaintDescription: text.length !== 0 ? text : null
      })
      .then((res) => {
        dispatch(setIsOpenComplainModal({ isOpen: false, func: null }));
        enqueueSnackbar('Скаргу надіслано.', { variant: 'success' });
      })
      .catch((error) => {
        console.error(error);
        enqueueSnackbar('Сталась помилка.', { variant: 'error' });
      });
  };

  const setter = () => {
    postLikes.getItemById(el.id).then((res) => {
      setLikesCount(res.count);
      setLikes(res.result);
    });
  };
  const postDelete = (id) => {
    post.deleteItem(id).then((res) => {
      if (
        window.location.href.includes('search') ||
        window.location.href.includes('user-page') ||
        window.location.href.includes('saved')
      ) {
        dataSetter();
      }
      if (window.location.href.includes('post/')) {
        navigate(-1);
      }
    });
  };
  useEffect(() => {
    setter();
    // eslint-disable-next-line
    return () => {
      setLikesCount(0);
      setLikes([]);
    };
    // eslint-disable-next-line
  }, [el]);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const postDeleteOut = () => {
    dispatch(
      setIsOpenDeleteModal({ isOpen: true, text: 'цей пост?', func: postDelete, id: el.id })
    );
  };
  useEffect(() => {
    let mounted = true;
    const getPhotos = async () => {
      const newPhotos = await Promise.all(
        el?.encodedPhotos.map((photo) =>
          apiPhoto
            .getItemById(photo)
            .then((res) => {
              return res.result;
            })
            .catch((error) => console.error(error))
        )
      ).then((res) => {
        setIsLoadingPhotos(false);
        return res;
      });
      setPhotos(newPhotos);
    };
    if (el?.encodedPhotos && el?.encodedPhotos.length !== 0 && mounted) {
      setIsLoadingPhotos(true);
      getPhotos();

      return () => (mounted = false);
    }
    // eslint-disable-next-line
  }, []);
  return (
    <Paper
      // {...props}
      key={index}
      className="postCard"
      sx={{
        width: !noWidth ? '49%' : '100%',
        height: 'unset',
        margin: '0px 0.5%',
        textAlign: 'center',
        backgroundColor: 'white',
        border: '1px solid black !important',
        borderRadius: '5px',
        padding: '30px',
        '&:hover':
          isHoverMy || isHoverSearch || isHoverSelf || isHoverSaved
            ? {}
            : { boxShadow: '-10px 10px 3px black', transform: 'scale(1.025)', transition: 'all 1s' }
      }}
    >
      {/* <UserContactsModal
        isSendDataRequest={isSendDataRequest}
        setIsSendDataRequest={setIsSendDataRequest}
        sendingData={{ type: 'post', id: el.id }}
        nickname={userData.nickname}
        isOpenModal={isOpenUserContactsModal}
        data={props.subscriptions}
      /> */}
      <Stack
        spacing={2}
        direction="column"
        justifyContent="space-between"
        style={{ padding: '0', height: '100%' }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          spacing={2}
          style={{ alignItems: 'left' }}
        >
          <div style={{ display: 'flex' }}>
            <Avatar
              onClick={() => {
                if (!el?.userRibbonDTO) {
                  if (userData?.id) {
                    navigate(`/${language}/user-page/${el.userDTO.id}?type=posts`);
                  } else {
                    dispatch(setIsOpenAuthModal(true));
                  }
                } else {
                  if (userData?.id) {
                    navigate(`/${language}/user-page/${el.userRibbonDTO.id}?type=posts`);
                  } else {
                    dispatch(setIsOpenAuthModal(true));
                  }
                }
              }}
              alt="avatar"
              src={
                (el?.userDTO && el?.userDTO?.encodedMediaDTO) ||
                (el?.userRibbonDTO && el?.userRibbonDTO?.encodedMediaDTO)
                  ? `data:${
                      el?.userDTO
                        ? el.userDTO?.encodedMediaDTO.contentType
                        : el.userRibbonDTO?.encodedMediaDTO.contentType
                    };base64, ${
                      el?.userDTO
                        ? el.userDTO?.encodedMediaDTO.encodedContent
                        : el.userRibbonDTO?.encodedMediaDTO.encodedContent
                    }`
                  : DefaultAvatar
              }
              sx={{
                width: 70,
                height: 70,
                border: '1px solid #60A5DF',
                mr: 2,
                cursor: 'pointer',
                boxShadow: blockedUsers.find(
                  (user) => user.id === el?.userDTO?.id || user.id === el?.userRibbonDTO?.id
                )
                  ? '0px 0px 2px 2px rgba(255,0,0,1)'
                  : 'none'
              }}
            />
            <Stack>
              {(el?.userDTO && el?.userDTO?.name && el?.userDTO?.surname) ||
              (el?.userRibbonDTO && el.userRibbonDTO?.name && el.userRibbonDTO?.surname) ? (
                <Typography
                  fontSize={18}
                  fontWeight={400}
                  sx={{ cursor: 'pointer', textAlign: 'left', lineHeight: '20px' }}
                  onClick={() => {
                    if (!el?.userRibbonDTO) {
                      if (userData?.id) {
                        navigate(`/${language}/user-page/${el.userDTO.id}?type=posts`);
                      } else {
                        dispatch(setIsOpenAuthModal(true));
                      }
                    } else {
                      if (userData?.id) {
                        navigate(`/${language}/user-page/${el.userRibbonDTO.id}?type=posts`);
                      } else {
                        dispatch(setIsOpenAuthModal(true));
                      }
                    }
                  }}
                >
                  {`${el?.userDTO ? el?.userDTO?.name : el.userRibbonDTO?.name} ${
                    el?.userDTO ? el?.userDTO?.surname : el.userRibbonDTO?.surname
                  }`}
                </Typography>
              ) : null}

              <Typography
                onClick={() => {
                  if (!el?.userRibbonDTO) {
                    if (userData?.id) {
                      navigate(`/${language}/user-page/${el.userDTO.id}?type=posts`);
                    } else {
                      dispatch(setIsOpenAuthModal(true));
                    }
                  } else {
                    if (userData?.id) {
                      navigate(`/${language}/user-page/${el.userRibbonDTO.id}?type=posts`);
                    } else {
                      dispatch(setIsOpenAuthModal(true));
                    }
                  }
                }}
                component="p"
                fontSize={
                  (el?.userDTO && el?.userDTO?.name && el?.userDTO?.surname) ||
                  (el?.userRibbonDTO && el.userRibbonDTO?.name && el.userRibbonDTO?.surname)
                    ? 14
                    : 18
                }
                fontWeight={400}
                sx={{
                  wordBreak: 'break-all',
                  textAlign: 'left',
                  mt: 1,
                  cursor: 'pointer',
                  lineHeight: '16px'
                }}
              >
                {`@${el?.userDTO ? el?.userDTO?.nickname : el.userRibbonDTO?.nickname}`}
              </Typography>
            </Stack>
          </div>
          <div style={{ display: 'flex', justifyContent: 'start' }}>
            <Typography sx={{ color: '#868686', fontSize: '16px' }} variant="subtitle">
              {dateFormattingByDate(new Date(el.creationTimeForPost))}
            </Typography>{' '}
            <MoreVertIcon
              onClick={(event) => handleClick(event)}
              sx={{ ml: 1, cursor: 'pointer', ':hover': { color: '#60A5DF' } }}
            />
          </div>

          <Menu
            anchorEl={anchorEl}
            id="action-menu"
            open={open}
            onClose={handleClose}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: 'hidden',
                boxShadow: 'none',
                borderRadius: '5px',
                border: '1px solid black',
                paddingTop: '10px',
                paddingLeft: '20px',
                paddingRight: '20px',
                width: '310px',
                mt: 1
              }
            }}
            autoFocus={false}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          >
            {el?.userDTO?.id === userData?.id && (
              <MenuItem
                sx={{ padding: 0, mb: 1 }}
                onClick={(event) => {
                  event.stopPropagation();
                  event.preventDefault();
                  handleClose();
                  dispatch(setIsOpenPostModal({ isOpen: true, func: dataSetter, data: el }));
                }}
              >
                <ModeEditOutlineOutlinedIcon sx={{ color: '#60A5DF', mr: 1 }} />
                <Typography
                  component="p"
                  fontSize={16}
                  sx={{ lineHeight: '18px' }}
                  fontWeight={400}
                >
                  Редагувати
                </Typography>
              </MenuItem>
            )}

            <MenuItem
              sx={{ padding: 0, mb: 1 }}
              onClick={() => {
                navigator.clipboard.writeText(`${window.location.origin}/ua/post/${el?.id}`);
                handleClose();
                enqueueSnackbar('Посилання на пост скопійовано', { variant: 'success' });
              }}
            >
              <LinkIcon sx={{ color: '#60A5DF', mr: 1 }} />
              <Typography component="p" fontSize={16} sx={{ lineHeight: '18px' }} fontWeight={400}>
                Скопіювати посилання на пост
              </Typography>
            </MenuItem>
            {el?.userDTO?.id === userData?.id && (
              <MenuItem
                sx={{ padding: 0, mb: 1 }}
                onClick={() => {
                  apiSwitchComments
                    .updateDataQueryId(el?.id, {
                      isPossibleWriteCommentsToPost: el?.turnOnComments ? false : true
                    })
                    .then((res) => {
                      if (window.location.href.includes('post/')) {
                        commentsSwitcher();
                        handleClose();
                      } else if (window.location.href.includes('search')) {
                        dataSetter(el?.id);
                        handleClose();
                      } else {
                        dataSetter(false);
                        handleClose();
                      }
                    })
                    .catch((error) => console.error(error));
                }}
              >
                {el?.turnOnComments ? (
                  <SpeakerNotesOffOutlinedIcon sx={{ color: '#60A5DF', mr: 1 }} />
                ) : (
                  <SpeakerNotesOutlinedIcon sx={{ color: '#60A5DF', mr: 1 }} />
                )}

                <Typography
                  component="p"
                  fontSize={16}
                  sx={{ lineHeight: '18px' }}
                  fontWeight={400}
                >
                  {el?.turnOnComments ? 'Вимкнути коментарі' : 'Увімкнути коментарі'}
                </Typography>
              </MenuItem>
            )}
            {el?.userDTO?.id === userData?.id && (
              <MenuItem
                sx={{ padding: 0, mb: 1 }}
                onClick={() => {
                  if (window.location.href.includes('user-page')) {
                    handleClose();
                    dispatch(
                      setIsOpenDeleteModal({
                        isOpen: true,
                        text: 'цей пост?',
                        func: postDelete,
                        id: el.id
                      })
                    );
                  } else {
                    postDeleteOut();
                    handleClose();
                  }
                }}
              >
                <DeleteOutlineOutlinedIcon sx={{ color: '#60A5DF', mr: 1 }} />
                <Typography
                  component="p"
                  fontSize={16}
                  sx={{ lineHeight: '18px' }}
                  fontWeight={400}
                >
                  Видалити пост
                </Typography>
              </MenuItem>
            )}

            {userData?.id && el?.userDTO?.id !== userData?.id && (
              <MenuItem
                sx={{ padding: 0 }}
                onClick={() => {
                  dispatch(setIsOpenComplainModal({ isOpen: true, func: sendComplaint }));
                  handleClose();
                }}
              >
                <ErrorOutlineIcon sx={{ color: '#60A5DF', mr: 1 }} />
                <Typography
                  component="p"
                  fontSize={16}
                  sx={{ lineHeight: '18px' }}
                  fontWeight={400}
                >
                  Поскаржитись
                </Typography>
              </MenuItem>
            )}
          </Menu>
        </Stack>
        <Typography
          variant="body1"
          sx={{ wordBreak: 'break-all !important' }}
          style={
            !window.location.href.includes('post/')
              ? {
                  fontSize: '16px',
                  textAlign: 'left',
                  wordBreak: 'break-all !important',
                  display: '-webkit-box',
                  WebkitLineClamp: 2,
                  WebkitBoxOrient: 'vertical',
                  overflowY: 'hidden',
                  textOverflow: 'ellipsis'
                }
              : { fontSize: '16px', textAlign: 'left', wordBreak: 'break-all !important' }
          }
          children={
            el?.description && (
              <>
                {!window.location.href.includes('post/') ? (
                  // ? el?.description
                  //     .slice(0, 165)
                  //     .split('\r\n')
                  //     .filter((el) => el.length !== 0)
                  //     .map((string, index) => {
                  //       return (
                  //         <React.Fragment key={index}>
                  //           <p className='postDescription' style={{ margin: '0', wordBreak: 'break-all !important'}}>
                  //             {string}
                  //             <br />
                  //           </p>
                  //         </React.Fragment>
                  //       );
                  //     })
                  <>
                    {el?.description.length > 165
                      ? el?.description.slice(0, 165).concat('...').split('\r\n')
                      : el?.description.slice(0, 165).split('\r\n')}
                  </>
                ) : (
                  el?.description
                    // .slice(0, 165)
                    .split('\r\n')
                    .filter((el) => el.length !== 0)
                    .map((string, index) => {
                      return (
                        <React.Fragment key={index}>
                          <p
                            className="postDescription"
                            style={{ margin: '0', wordBreak: 'break-all !important' }}
                          >
                            {string}
                            <br />
                          </p>
                        </React.Fragment>
                      );
                    })
                )}
              </>
            )
          }
        />
        {!window.location.href.includes('post/') &&
          el?.description &&
          el?.description.length > 165 && (
            <span
              onClick={() => {
                if (userData?.id) {
                  navigate(`/${language}/post/${el.id}`);
                } else {
                  dispatch(setIsOpenAuthModal(true));
                }
              }}
              style={{
                textAlign: 'right',
                textDecoration: 'underline',
                cursor: 'pointer',
                marginTop: '0px',
                marginLeft: 'auto',
                width: 'fit-content',
                lineHeight: '20px'
              }}
              fontSize={16}
              fontWeight={400}
            >
              {'Читати більше'}
            </span>
          )}
        {/* {el?.encodedContent.length!==0&& el?.encodedContent.length===1&&<></>} */}
        {isLoadingPhotos && el.encodedPhotos.length === 1 && (
          <Skeleton sx={{ height: 679, mt: '40px' }} animation="wave" variant="rectangular" />
        )}
        {el !== null &&
          el &&
          el?.encodedPhotos &&
          el?.encodedPhotos.length !== 0 &&
          el?.encodedPhotos.length === 1 &&
          photos.length !== 0 &&
          !isLoadingPhotos &&
          photos.map((photo, index) => (
            <img
              key={`photo${index}`}
              src={`data:${photo?.contentType};base64, ${photo?.encodedContent}`}
              alt={photo?.nameFile}
              style={{ margin: '40px 0px 0px', objectFit: 'cover', maxHeight: '679px' }}
            />
          ))}
        {el !== null &&
        el &&
        el?.encodedPhotos &&
        el?.encodedPhotos.length !== 0 &&
        el?.encodedPhotos.length > 1 &&
        photos.length !== 0 &&
        !isLoadingPhotos ? (
          <ImageGallery
            style={{ marginTop: 0 }}
            showPlayButton={false}
            showBullets={false}
            showFullscreenButton={false}
            items={photos.map((photo) => {
              return {
                original: `data:${photo?.contentType};base64, ${photo?.encodedContent}`,
                thumbnail: `data:${photo?.contentType};base64, ${photo?.encodedContent}`,
                originalHeight: 300,
                thumbnailHeight: 65
              };
            })}
          />
        ) : null}
        {el.encodedPhotos.length > 1 && isLoadingPhotos && (
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Skeleton
              sx={{ height: 679, width: '100%', borderRadius: '5px' }}
              animation="wave"
              variant="rectangular"
            />
            <Box sx={{ display: 'flex', justifyContent: 'space-around', mt: 2, maxWidth: '100%' }}>
              {el.encodedPhotos.map((photo, index) => (
                <Skeleton
                  sx={{ height: 95, width: 95, ml: index !== 0 ? 1 : 0 }}
                  animation="wave"
                  variant="rectangular"
                />
              ))}
            </Box>
          </Box>
        )}

        <Stack direction="row" justifyContent="space-between">
          <Stack direction="row" spacing={0}>
            {likesCount > 0 && <Typography>{likesCount}</Typography>}
            {likes.length !== 0 && likes.find((like) => like.id === userData?.id) ? (
              <FavoriteIcon
                onClick={() => {
                  if (userData?.id) {
                    if (
                      !blockedUsers.find(
                        (user) => user.id === el?.userDTO?.id || user.id === el?.userRibbonDTO?.id
                      )
                    ) {
                      apiAddLike
                        .updateDataQuery({ postId: el.id, isLikePost: false })
                        .then((res) => setter());
                    }
                  } else {
                    dispatch(setIsOpenAuthModal(true));
                  }
                }}
                sx={{
                  color: 'red',
                  cursor: 'pointer',
                  marginLeft: '5px'
                }}
              />
            ) : (
              <FavoriteBorderIcon
                onClick={() => {
                  if (userData?.id) {
                    if (
                      !blockedUsers.find(
                        (user) => user.id === el?.userDTO?.id || user.id === el?.userRibbonDTO?.id
                      ) &&
                      !usersWhoBlocked.find(
                        (user) => user === el?.userDTO?.id || user === el?.userRibbonDTO?.id
                      )
                    ) {
                      apiAddLike
                        .updateDataQuery({ postId: el.id, isLikePost: true })
                        .then((res) => setter());
                    }
                  } else {
                    dispatch(setIsOpenAuthModal(true));
                  }
                }}
                sx={{
                  ':hover': {
                    color: 'red'
                  },
                  cursor: 'pointer',
                  marginLeft: '5px'
                }}
              />
            )}

            {el.numberComments > 0 && <Typography sx={{ ml: 3 }}>{el.numberComments}</Typography>}
            <ChatBubbleOutlineIcon
              sx={{
                ':hover': {
                  color: 'rgb(96, 165, 223)'
                },
                cursor: 'pointer',
                marginLeft: '5px'
              }}
              onClick={() => {
                if (userData?.id) {
                  if (isHoverSelf) {
                    // navigate(`/${language}/post/${el.id}`);
                    return;
                  } else {
                    // navigate(`/${language}/post/${el.id}`);
                    window.open(`${window.location.origin}/${language}/post/${el.id}`);
                  }
                } else {
                  dispatch(setIsOpenAuthModal(true));
                }
              }}
            />
          </Stack>
          <Stack direction="row" spacing={2}>
            {userData &&
            userSavedPosts &&
            userSavedPosts.length !== 0 &&
            userSavedPosts.includes(el.id) ? (
              <BookmarkIcon
                onClick={() => {
                  if (userData?.id) {
                    apiSavePost.deleteItemQuery({ postId: el.id }).then((res) => {
                      const newArray = userSavedPosts.filter((id) => el.id !== id);
                      if (window.location.href.includes('saved')) {
                        dispatch(setUserData({ ...userData, savedPosts: newArray }));
                        dispatch(setUserSavedPosts([...newArray]));
                      } else {
                        dispatch(setUserSavedPosts([...newArray]));
                      }
                    });
                  } else {
                    dispatch(setIsOpenAuthModal(true));
                  }
                }}
                sx={{
                  color: 'rgb(239, 201, 56)',
                  ':hover': {
                    color: 'rgb(239, 201, 56)',
                    cursor: 'pointer'
                  }
                }}
              />
            ) : (
              <BookmarkBorderIcon
                onClick={() => {
                  if (userData?.id) {
                    apiSavePost.updateDataQueryPost({ postId: el.id }).then((res) => {
                      const newArray = [...userSavedPosts, el.id];
                      if (window.location.href.includes('saved')) {
                        dispatch(setUserData({ ...userData, savedPosts: newArray }));
                        dispatch(setUserSavedPosts([...newArray]));
                      } else {
                        dispatch(setUserSavedPosts([...newArray]));
                      }
                    });
                  } else {
                    dispatch(setIsOpenAuthModal(true));
                  }
                }}
                sx={{
                  ':hover': {
                    color: 'rgb(239, 201, 56)',
                    cursor: 'pointer'
                  }
                }}
              />
            )}

            <SendOutlinedIcon
              onClick={() => {
                if (userData?.id) {
                  dispatch(setIsOpenResendingModal({ isOpen: true, id: el.id, type: 'post' }));
                } else {
                  dispatch(setIsOpenAuthModal(true));
                }
              }}
              sx={{
                ':hover': {
                  color: 'rgb(96, 165, 223)',
                  cursor: 'pointer'
                },
                transform: 'rotate(315deg)',
                width: '23px',
                height: '22px'
              }}
            />
          </Stack>
        </Stack>
      </Stack>
    </Paper>
  );
}
const mapStateToProps = (state) => {
  return {
    language: state.Intl.locale,
    userData: state.global.userData,
    blockedUsers: state.global.blockedUsers,
    usersWhoBlocked: state.global.usersWhoBlocked
  };
};
export default connect(mapStateToProps)(PostCard);
