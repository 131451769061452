import React, { useEffect, useRef } from 'react';

//Material

//Components
import Page from '../../components/Page';

//Blocks

import 'animate.css';
import { connect, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  setIsOpenAuthModal,
  setIsOpenChangeMailModal,
  setIsOpenDeleteAccModal,
  setIsOpenEnable2FAModal,
  setIsOpenPremiumCancelModal,
  setIsOpenPremiumModal,
  setUserData,
  setUserSubscribers,
  setUserSubscriptions
} from '../../redux/mainReducer';
import './index.css';
import { base } from '../../redux/thunk';

// import { useSelector } from 'react-redux';

function AccSettingsPage({ language, userData, getBlockedUsersThunk }) {
  const iframe = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const dataSender = async () => {
    iframe.current.contentWindow.postMessage(
      {
        userData,
        tokens: {
          accessToken: localStorage.getItem('accessToken'),
          refreshToken: localStorage.getItem('refreshToken')
        }
      },

      '*'
    );
  };

  // useEffect(() => {
  //   if (iframe) {
  //     dataSender();
  //     // setTimeout(dataSender, 200);
  //   }
  //   // eslint-disable-next-line
  //   return () => clearTimeout(dataSender);
  //   // eslint-disable-next-line
  // }, [iframe]);
  const iFrameMessageReceiveParent = (event) => {
    // window.removeEventListener('message', iFrameMessageReceiveParent);
    if (event.origin === 'https://dev.bonfairplace.com') {
      // if (event.origin === 'https://localhost:3001') {
      if (event?.data && event?.data === 'RENDERED') {
        dataSender();
      }
      if (event?.data && event?.data === 'PASSWORD CHANGED') {
        iframe.current.contentWindow.postMessage(
          {
            userData: {},
            tokens: {
              accessToken: '',
              refreshToken: ''
            }
          },

          '*'
        );

        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        dispatch(setUserData({}));
        dispatch(setUserSubscribers([]));
        dispatch(setUserSubscriptions([]));
        window.removeEventListener('message', iFrameMessageReceiveParent);
        navigate(`/${language}`);
        dispatch(setIsOpenAuthModal(true));
      }
      if (event?.data && event?.data === 'EMAIL CHANGED') {
        dispatch(setIsOpenChangeMailModal(true));
        window.removeEventListener('message', iFrameMessageReceiveParent);
      }
      if (event?.data && event?.data === 'PREMIUM CANCEL') {
        dispatch(setIsOpenPremiumCancelModal(true));
        window.removeEventListener('message', iFrameMessageReceiveParent);
      }
      if (event?.data && event?.data === 'PREMIUM CHOOSE') {
        dispatch(setIsOpenPremiumModal(true));
        window.removeEventListener('message', iFrameMessageReceiveParent);
      }
      if (event?.data && event?.data?.message && event?.data?.message === 'REFRESH') {
        localStorage.setItem('accessToken', `${event?.data?.tokens?.accessToken}`);
        localStorage.setItem('refreshToken', `${event?.data?.tokens?.refreshToken}`);
        window.removeEventListener('message', iFrameMessageReceiveParent);
      }
      if (event?.data && event?.data === 'REFRESH ERROR') {
        iframe.current.contentWindow.postMessage(
          {
            userData: {},
            tokens: {
              accessToken: '',
              refreshToken: ''
            }
          },

          '*'
        );
        window.removeEventListener('message', iFrameMessageReceiveParent);
        dispatch(setUserData({}));
        dispatch(setUserSubscribers([]));
        dispatch(setUserSubscriptions([]));
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        window.location.href = '/';
      }
      if (event?.data && event?.data === 'REFRESHBLOCKED') {
        getBlockedUsersThunk();
        window.removeEventListener('message', iFrameMessageReceiveParent);
      }
      if (event?.data && event?.data === 'ACCDELETE') {
        dispatch(setIsOpenDeleteAccModal(true));
        window.removeEventListener('message', iFrameMessageReceiveParent);
      }
      if (event?.data && event?.data === '2FAENABLE') {
        dispatch(setIsOpenEnable2FAModal(true));
        window.removeEventListener('message', iFrameMessageReceiveParent);
      }
    }
    window.addEventListener('message', iFrameMessageReceiveParent, false);
  };
  useEffect(() => {
    window.addEventListener('message', iFrameMessageReceiveParent, false);
    // window.addEventListener('message', (event) => console.log(event));
    return () => window.removeEventListener('message', iFrameMessageReceiveParent);
    // eslint-disable-next-line
  }, []);

  return (
    <Page
      title="Налаштування акаунта"
      style={{
        display: 'flex',
        marginTop: '150px',
        alignItems: 'center',
        flexDirection: 'column',
        height: 'max-content',
        padding: '0px 100px'
      }}
    >
      <iframe
        id="accSettingsPage"
        ref={iframe}
        title="accSettingsPage"
        src="https://dev.bonfairplace.com/v1/settings/"
        // src="https://localhost:3001"
        style={{ width: '100%', height: '100vh', border: 'none', paddingTop: '' }}
      ></iframe>
    </Page>
  );
}
const mapStateToProps = (state) => {
  return {
    language: state.Intl.locale,
    userData: state.global.userData
  };
};

export default connect(mapStateToProps, {
  getBlockedUsersThunk: base.getBlockedUsersThunk
})(AccSettingsPage);
