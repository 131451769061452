import React, { useEffect, useState } from 'react';
import { useTranslate } from 'react-redux-multilingual';
import Page from '../../components/Page';
import { Spinner } from '../../components/Spinner';

//Material
import {
  Autocomplete,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Menu,
  Slider,
  Stack,
  TextField,
  Typography,
  styled
} from '@mui/material';
import TuneIcon from '@mui/icons-material/Tune';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import BigSwitcher from '../../components/BigSwitcher';
// import { useNavigate, useSearchParams } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import UserCard from '../../components/UserCard';
import PostCard from '../../components/PostCard';
import ServiceCard from '../../components/ServiceCard';

import { FreeBtn, LetsGoBtn2, ToTopBtn } from '../../components/Buttons';
// import { StyledTitle } from '../../components/StyledComponents';
import ApiService from '../../helpers/apiHelper';
import { useSearchParams } from 'react-router-dom';
import starRed from '../../assets/StarRed.png';
import { setIsOpenSearchBar } from '../../redux/mainReducer';
import { Element } from 'react-scroll';

const CssTextField = styled(TextField)({
  '& label.Mui-focused': {
    color: '#000000'
  },
  '& label': {
    color: '#A1A1A1',
    fontFamily: 'Kyiv-Sans-Light'
  },
  // '& .MuiInput-root.Mui-focused:after': {
  //   borderBottom: '2px #000000 solid'
  // },
  // '& .MuiInput-root:hover:not(.Mui-disabled):before': {
  //   borderBottom: '2px #000000 solid'
  // },
  // '& .MuiInput-root:not(.Mui-error):before': {
  //   borderBottom: '2px #000000 solid'
  // },
  margin: '5px 0px'
});

function SearchPage({ userData }) {
  const translate = useTranslate();
  const dispatch = useDispatch();
  const [pageScroll, setPageScroll] = useState(0);
  const [isLoading, setIsLoadiang] = useState(false);
  const [loadMore, setLoadMore] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);
  const [isAcceptFilters, setIsAcceptFilters] = useState(false);
  const [size, setSize] = useState(3); // for test
  const [count, setCount] = useState(0);
  const [params, setParams] = useSearchParams();

  const [anchorEl, setAnchorEl] = useState(null);
  const [bools, setBools] = useState([
    params.get('type') === 'services' ? true : false,
    params.get('type') === 'topics' ? true : false,
    params.get('type') === 'users' ? true : false
  ]);
  const [data, setData] = useState([]);
  const [filters, setFilters] = useState({
    posts: {
      count: 4,
      start: 0,
      sortDirection:
        params.get('sd') && params.get('type') === 'topics' ? params.get('sd') : 'DESC',
      fieldToSortBy:
        params.get('ftsb') && params.get('type') === 'topics'
          ? params.get('ftsb')
          : 'creationTimeForPost',
      searchString: params.get('question'),
      fieldsToSearchBy: ['description']
    },
    services: {
      count: 8,
      start: 0,
      sortDirection:
        params.get('sd') && params.get('type') === 'services' ? params.get('sd') : 'DESC',
      fieldToSortBy:
        params.get('ftsb') && params.get('type') === 'services' ? params.get('ftsb') : 'rating',
      searchString: params.get('question'),
      fieldsToSearchBy: ['nameService'],
      rangePriceFrom: params.get('rpf') ? params.get('rpf') : 0,
      rangePriceTo: params.get('rpt') ? params.get('rpt') : 99999,
      fieldWithPriceRange: true,
      withCategories: params.get('wc') ? params.get('wc') : false,
      categories: params.get('c') ? JSON.parse(params.get('c')) : [],
      withAvailability: params.get('wa') ? params.get('wa') : false,
      stringOfAvailability: params.get('soa') ? JSON.parse(params.get('soa')) : []
    },
    users: {
      count: 6,
      startWith: 0,
      searchString: params.get('question')
    }
  });

  const [exactFilters, setExactFilters] = useState({
    posts: {
      count: 4,
      start: 0,
      sortDirection:
        params.get('sd') && params.get('type') === 'topics' ? params.get('sd') : 'DESC',
      fieldToSortBy:
        params.get('ftsb') && params.get('type') === 'topics'
          ? params.get('ftsb')
          : 'creationTimeForPost',
      searchString: params.get('question'),
      fieldsToSearchBy: ['description']
    },
    services: {
      count: 8,
      start: 0,
      sortDirection:
        params.get('sd') && params.get('type') === 'services' ? params.get('sd') : 'DESC',
      fieldToSortBy:
        params.get('ftsb') && params.get('type') === 'services' ? params.get('ftsb') : 'rating',
      searchString: params.get('question'),
      fieldsToSearchBy: ['nameService'],
      rangePriceFrom: params.get('rpf') ? params.get('rpf') : 0,
      rangePriceTo: params.get('rpt') ? params.get('rpt') : 99999,
      fieldWithPriceRange: true,
      withCategories: params.get('wc') ? params.get('wc') : false,
      categories: params.get('c') ? JSON.parse(params.get('c')) : [],
      withAvailability: params.get('wa') ? params.get('wa') : false,
      stringOfAvailability: params.get('soa') ? JSON.parse(params.get('soa')) : []
    },
    users: {
      count: 6,
      startWith: 0,
      searchString: params.get('question')
    }
  });

  const initialFilters = {
    posts: {
      count: 4,
      start: 0,
      sortDirection: 'DESC',
      fieldToSortBy: 'creationTimeForPost',
      searchString: params.get('question'),
      fieldsToSearchBy: ['description']
    },
    services: {
      count: 8,
      start: 0,
      sortDirection: 'DESC',
      fieldToSortBy: 'rating',
      searchString: params.get('question'),
      fieldsToSearchBy: ['nameService'],
      rangePriceFrom: 0,
      rangePriceTo: 99999,
      fieldWithPriceRange: true,
      withCategories: false,
      categories: [],
      withAvailability: false,
      stringOfAvailability: []
    },
    users: {
      count: 6,
      startWith: 0,
      searchString: params.get('question')
    }
  };

  const apiPosts = new ApiService('posts/post/search');
  const apiServices = new ApiService('posts/service/search');
  const apiUsers = new ApiService('auth/search');
  // const apiPosts = new ApiService('post/search');
  // const apiServices = new ApiService('service/search');
  // const apiUsers = new ApiService('search');

  const postCommentsSwitcher = (postId) => {
    const switchedPostIndex = data.findIndex((post) => post?.id === postId);
    let newData = [...data];
    newData[switchedPostIndex] = {
      ...data[switchedPostIndex],
      turnOnComments: !data[switchedPostIndex]?.turnOnComments
    };
    setData([...newData]);
  };

  const handleSwitch = (isOnOne, isOnTwo, isOnThree, loadMore = false, isQuestion = false) => {
    if (firstLoad) {
      setFirstLoad(false);
    }
    const question = params.get('question');
    if (!loadMore) {
      setIsLoadiang(true);
    }

    if (isOnOne) {
      apiServices
        .getAll(
          !isQuestion
            ? exactFilters.services
            : {
                ...exactFilters.services,
                start: 0,
                count: exactFilters.services.start + 8,
                searchString: question !== null ? question : ''
              }
        )
        .then((res) => {
          if (exactFilters.services.start !== 0 && !isQuestion) {
            // const newData = [...data];
            // res.result.map((card) => newData.push(card));
            setData([...data, ...res.result]);
            setCount(res.count);
            setSize(3);
          } else {
            setCount(res.count);
            setSize(3);
            setData(res.result);
          }
          setIsLoadiang(false);
          setLoadMore(false);
          setIsAcceptFilters(false);
        })
        .catch((error) => {
          console.error(error);
          setIsLoadiang(false);
          setLoadMore(false);
          setIsAcceptFilters(false);
        });
    }
    if (isOnTwo) {
      apiPosts
        .getAll(
          !isQuestion
            ? exactFilters.posts
            : {
                ...exactFilters.posts,
                start: 0,
                count: exactFilters.posts.start + 4,
                searchString: question !== null ? question : ''
              }
        )
        .then((res) => {
          if (exactFilters.posts.start !== 0 && !isQuestion) {
            const newData = [...data];
            res.result.map((card) => newData.push(card));
            setData([...newData]);
            setCount(res.count);
            setSize(12);
          } else {
            setCount(res.count);
            setSize(12);
            setData(res.result);
          }
          setIsLoadiang(false);
          setLoadMore(false);
          setIsAcceptFilters(false);
        })
        .catch((error) => {
          console.error(error);
          setIsLoadiang(false);
          setLoadMore(false);
          setIsAcceptFilters(false);
        });
    }
    if (isOnThree) {
      apiUsers
        .getAll(
          !isQuestion
            ? exactFilters.users
            : {
                ...exactFilters.users,
                startWith: 0,
                count: exactFilters.users.startWith + 6,
                searchString: question !== null ? question : ''
              }
        )
        .then((res) => {
          if (exactFilters.users.startWith !== 0 && !isQuestion) {
            const newData = [...data];
            res.result.map((card) => newData.push(card));
            setData([...newData]);
            setCount(res.count);
            setSize(4);
          } else {
            setCount(res.count);
            setSize(4);
            setData(res.result);
          }
          setIsLoadiang(false);
          setLoadMore(false);
          setIsAcceptFilters(false);
        })
        .catch((error) => {
          console.error(error);
          setIsLoadiang(false);
          setLoadMore(false);
          setIsAcceptFilters(false);
        });
    }
  };

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    const type = params.get('type');
    const ftsb = params.get('ftsb');
    const sd = params.get('sd');
    const question = params.get('question');

    switch (type) {
      case 'services':
        setExactFilters({
          ...exactFilters,
          services: {
            ...exactFilters.services,
            searchString: question !== null ? question : '',
            fieldToSortBy: ftsb ? ftsb : 'rating',
            sortDirection: sd ? sd : 'DESC'
          }
        });
        setBools([true, false, false]);
        setIsAcceptFilters(true);
        break;
      case 'topics':
        setExactFilters({
          ...exactFilters,
          services: {
            ...exactFilters.services,
            searchString: question !== null ? question : '',
            withCategories: false,
            categories: []
          },
          posts: {
            ...exactFilters.posts,
            searchString: question !== null ? question : '',
            fieldToSortBy: ftsb ? ftsb : 'creationTimeForPost',
            sortDirection: sd ? sd : 'DESC'
          }
        });
        setFilters({
          ...filters,
          services: {
            ...filters.services,
            searchString: question !== null ? question : '',
            withCategories: false,
            categories: []
          },
          posts: {
            ...filters.posts,
            searchString: question !== null ? question : '',
            fieldToSortBy: ftsb ? ftsb : 'creationTimeForPost',
            sortDirection: sd ? sd : 'DESC'
          }
        });
        setBools([false, true, false]);
        setIsAcceptFilters(true);
        break;
      case 'users':
        setExactFilters({
          ...exactFilters,
          services: {
            ...exactFilters.services,
            searchString: question !== null ? question : '',
            withCategories: false,
            categories: []
          },
          users: { ...exactFilters.users, searchString: question !== null ? question : '' }
        });
        setFilters({
          ...filters,
          services: {
            ...filters.services,
            searchString: question !== null ? question : '',
            withCategories: false,
            categories: []
          },
          users: { ...filters.users, searchString: question !== null ? question : '' }
        });
        setBools([false, false, true]);
        setIsAcceptFilters(true);
        break;
      default:
        break;
    }

    // eslint-disable-next-line
  }, [
    // eslint-disable-next-line
    params.get('ftsb'),
    // eslint-disable-next-line
    params.get('sd'),
    // eslint-disable-next-line
    params.get('type')
  ]);
  const handleClose = () => {
    setAnchorEl(null);
  };
  useEffect(() => {
    if (loadMore) handleSwitch(bools[0], bools[1], bools[2], true, false);
    if (isAcceptFilters) handleSwitch(bools[0], bools[1], bools[2]);

    // eslint-disable-next-line
  }, [loadMore, isAcceptFilters]);
  useEffect(() => {
    if (!firstLoad) {
      if (typeof params.get('question') !== 'object') {
        handleSwitch(bools[0], bools[1], bools[2], false, true);
      }
    }

    // eslint-disable-next-line
  }, [
    // eslint-disable-next-line
    params.get('question')
  ]);

  useEffect(() => {
    window.addEventListener('scroll', () => setPageScroll(window.scrollY));

    dispatch(setIsOpenSearchBar(true));
    return () => {
      window.removeEventListener('scroll', () => setPageScroll(window.scrollY));
      dispatch(setIsOpenSearchBar(false));
    };
  });
  return (
    <Page title={translate('bonfair_searchPageName')}>
      <Element name="topSearch" />
      <Stack id="searchStack" justifyContent="center" alignItems="center" sx={{ mt: 6 }}>
        {/* <StyledTitle className="bottomBlock-title">результати пошуку</StyledTitle> */}
        <Grid
          container
          style={{
            maxWidth: '1440px !important'
          }}
        >
          <Grid
            item
            sx={{
              display: 'flex',
              justifyContent: 'center',
              marginBottom: '30px',
              marginTop: '90px'
            }}
            xs={12}
          >
            <BigSwitcher
              bools={bools}
              isDisabled={isLoading}
              textOne="Послуги"
              textTwo="Пости"
              textThree="Люди"
              handleToggle={(isOnOne, isOnTwo, isOnThree) => {
                if (!isLoading) {
                  if (isOnOne) {
                    params.set('ftsb', 'rating');
                    params.set('sd', 'DESC');
                    params.set('type', 'services');
                    setParams(params);
                    setData([]);
                    setExactFilters(initialFilters);
                    setFilters(initialFilters);
                    // setExactFilters({
                    //   ...exactFilters,
                    //   services: {
                    //     ...exactFilters.services,
                    //     searchString: params.get('question'),
                    //     fieldToSortBy: params.get('ftsb') ? params.get('ftsb') : 'rating',
                    //     sortDirection: params.get('sd') ? params.get('sd') : 'DESC'
                    //   }
                    // });
                    setBools([isOnOne, isOnTwo, isOnThree]);
                    // handleSwitch(isOnOne, isOnTwo, isOnThree);
                  }
                  if (isOnTwo) {
                    params.set('ftsb', 'creationTimeForPost');
                    params.set('sd', 'DESC');
                    params.set('type', 'topics');
                    params.delete('wc');
                    params.delete('c');
                    params.delete('rpf');
                    params.delete('rpt');
                    setParams(params);
                    setData([]);
                    setExactFilters(initialFilters);
                    setFilters(initialFilters);
                    // setExactFilters({
                    //   ...exactFilters,

                    //   posts: {
                    //     ...exactFilters.posts,
                    //     searchString: params.get('question'),
                    //     fieldToSortBy: params.get('ftsb')
                    //       ? params.get('ftsb')
                    //       : 'creationTimeForPost',
                    //     sortDirection: params.get('sd') ? params.get('sd') : 'DESC'
                    //   }
                    // });
                    setBools([isOnOne, isOnTwo, isOnThree]);
                    // handleSwitch(isOnOne, isOnTwo, isOnThree);
                  }
                  if (isOnThree) {
                    params.delete('ftsb');
                    params.delete('sd');
                    params.delete('wc');
                    params.delete('rpf');
                    params.delete('rpt');
                    params.set('type', 'users');
                    setParams(params);
                    setData([]);
                    setExactFilters(initialFilters);
                    setFilters(initialFilters);
                    // setExactFilters({
                    //   ...exactFilters,

                    //   users: { ...exactFilters.users, searchString: params.get('question') }
                    // });
                    setBools([isOnOne, isOnTwo, isOnThree]);
                    // handleSwitch(isOnOne, isOnTwo, isOnThree);
                  }
                }
              }}
            />
          </Grid>

          <Grid
            item
            xs={12}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              alignItems: 'center'
            }}
          >
            {!bools[2] && (
              <Grid container spacing={2} sx={{ mb: 3, maxWidth: '1240px !important' }}>
                <Grid item xs={7} />
                <Grid
                  item
                  xs={1.5}
                  sx={{ display: 'flex', alignItems: 'center', justifyContent: 'end' }}
                />

                <Grid
                  item
                  xs={1.5}
                  sx={{ display: 'flex', alignItems: 'center', justifyContent: 'end' }}
                >
                  {!bools[1] && (
                    <div
                      onClick={handleClick}
                      style={{
                        cursor: 'pointer',
                        alignItems: 'center',
                        display: 'flex'
                      }}
                    >
                      <TuneIcon
                        sx={
                          open
                            ? { fontSize: '25px', marginRight: '10px', color: '#60A5DF' }
                            : {
                                fontSize: '25px',
                                marginRight: '10px'
                              }
                        }
                      />
                      {'Фільтрувати'}
                    </div>
                  )}
                </Grid>
                <Grid item xs={2} md={2} lg={2}>
                  <Autocomplete
                    disableClearable
                    onKeyPress={(e) => {
                      e.preventDefault();
                    }}
                    options={
                      bools[1]
                        ? ['За популярністю', 'Спочатку нові', 'Спочатку старіші']
                        : ['За рейтингом', 'Спочатку дешевші', 'Спочатку дорожчі']
                    }
                    sx={{ maxWidth: 300 }}
                    value={
                      bools[0]
                        ? (exactFilters.services.fieldToSortBy === 'price' &&
                            exactFilters.services.sortDirection === 'DESC' &&
                            'Спочатку дорожчі') ||
                          (exactFilters.services.fieldToSortBy === 'price' &&
                            exactFilters.services.sortDirection === 'ASC' &&
                            'Спочатку дешевші') ||
                          (exactFilters.services.fieldToSortBy === 'rating' && 'За рейтингом')
                        : (exactFilters.posts.fieldToSortBy === 'creationTimeForPost' &&
                            exactFilters.posts.sortDirection === 'DESC' &&
                            'Спочатку нові') ||
                          (exactFilters.posts.fieldToSortBy === 'creationTimeForPost' &&
                            exactFilters.posts.sortDirection === 'ASC' &&
                            'Спочатку старіші') ||
                          (exactFilters.posts.fieldToSortBy === 'valueLikes' && 'За популярністю')
                    }
                    onChange={(event, value) => {
                      if (value === 'За популярністю') {
                        params.set('ftsb', 'valueLikes');
                        params.set('sd', 'DESC');
                        setParams(params);
                        setExactFilters({
                          ...exactFilters,
                          posts: {
                            ...exactFilters.posts,
                            fieldToSortBy: 'valueLikes',
                            sortDirection: 'DESC',
                            start: 0,
                            count: data.length
                          }
                        });
                        setFilters({
                          ...exactFilters,
                          posts: {
                            ...exactFilters.posts,
                            fieldToSortBy: 'valueLikes',
                            sortDirection: 'DESC',
                            start: 0,
                            count: data.length
                          }
                        });
                      }
                      if (value === 'Спочатку нові') {
                        params.set('ftsb', 'creationTimeForPost');
                        params.set('sd', 'DESC');
                        setParams(params);
                        setExactFilters({
                          ...exactFilters,
                          posts: {
                            ...exactFilters.posts,
                            fieldToSortBy: 'creationTimeForPost',
                            sortDirection: 'DESC',
                            start: 0,
                            count: data.length
                          }
                        });
                        setFilters({
                          ...exactFilters,
                          posts: {
                            ...exactFilters.posts,
                            fieldToSortBy: 'creationTimeForPost',
                            sortDirection: 'DESC',
                            start: 0,
                            count: data.length
                          }
                        });
                      }
                      if (value === 'Спочатку старіші') {
                        params.set('ftsb', 'creationTimeForPost');
                        params.set('sd', 'ASC');
                        setParams(params);
                        setExactFilters({
                          ...exactFilters,
                          posts: {
                            ...exactFilters.posts,
                            fieldToSortBy: 'creationTimeForPost',
                            sortDirection: 'ASC',
                            start: 0,
                            count: data.length
                          }
                        });
                        setFilters({
                          ...exactFilters,
                          posts: {
                            ...exactFilters.posts,
                            fieldToSortBy: 'creationTimeForPost',
                            sortDirection: 'ASC',
                            start: 0,
                            count: data.length
                          }
                        });
                      }

                      if (value === 'За рейтингом') {
                        params.set('ftsb', 'rating');
                        params.set('sd', 'DESC');
                        setParams(params);
                        setExactFilters({
                          ...exactFilters,
                          services: {
                            ...exactFilters.services,
                            fieldToSortBy: 'rating',
                            sortDirection: 'DESC',
                            start: 0,
                            count: data.length
                          }
                        });
                        setFilters({
                          ...exactFilters,
                          services: {
                            ...exactFilters.services,
                            fieldToSortBy: 'rating',
                            sortDirection: 'DESC',
                            start: 0,
                            count: data.length
                          }
                        });
                      }
                      if (value === 'Спочатку дорожчі') {
                        params.set('ftsb', 'price');
                        params.set('sd', 'DESC');
                        setParams(params);
                        setExactFilters({
                          ...exactFilters,
                          services: {
                            ...exactFilters.services,
                            fieldToSortBy: 'price',
                            sortDirection: 'DESC',
                            start: 0,
                            count: data.length
                          }
                        });
                        setFilters({
                          ...exactFilters,
                          services: {
                            ...exactFilters.services,
                            fieldToSortBy: 'price',
                            sortDirection: 'DESC',
                            start: 0,
                            count: data.length
                          }
                        });
                      }
                      if (value === 'Спочатку дешевші') {
                        params.set('ftsb', 'price');
                        params.set('sd', 'ASC');
                        setParams(params);
                        setExactFilters({
                          ...exactFilters,
                          services: {
                            ...exactFilters.services,
                            fieldToSortBy: 'price',
                            sortDirection: 'ASC',
                            start: 0,
                            count: data.length
                          }
                        });
                        setFilters({
                          ...exactFilters,
                          services: {
                            ...exactFilters.services,
                            fieldToSortBy: 'price',
                            sortDirection: 'ASC',
                            start: 0,
                            count: data.length
                          }
                        });
                      }
                      if (!value) {
                        if (params.get('type') === 'services') {
                          params.set('ftsb', 'rating');
                          params.set('sd', 'DESC');
                          setParams(params);
                        }
                        if (params.get('type') === 'topics') {
                          params.set('ftsb', 'creationTimeForPost');
                          params.set('sd', 'DESC');
                          setParams(params);
                        }

                        setExactFilters({
                          ...exactFilters,
                          posts: {
                            ...exactFilters.posts,
                            fieldToSortBy: 'creationTimeForPost',
                            sortDirection: 'DESC',
                            start: 0,
                            count: data.length
                          },
                          services: {
                            ...exactFilters.services,
                            fieldToSortBy: 'rating',
                            sortDirection: 'DESC',
                            start: 0,
                            count: data.length
                          }
                        });
                        setFilters({
                          posts: {
                            ...exactFilters.posts,
                            fieldToSortBy: 'creationTimeForPost',
                            sortDirection: 'DESC',
                            start: 0,
                            count: data.length
                          },
                          services: {
                            ...exactFilters.services,
                            fieldToSortBy: 'price',
                            sortDirection: 'DESC',
                            start: 0,
                            count: data.length
                          }
                        });
                      }
                    }}
                    renderInput={(params) => <TextField {...params} variant="outlined" />}
                  />
                </Grid>
              </Grid>
            )}

            <Grid
              container
              spacing={3}
              sx={{
                position: 'relative',
                maxWidth: '1240px !important',
                paddingBottom: '190px',
                height: 'fit-content',
                minHeight: '700px',
                display: 'flex',
                alignItems: 'center'
              }}
            >
              <ToTopBtn
                visible={pageScroll > 500}
                sx={{ position: 'fixed', right: '-80px', bottom: '0' }}
                icon={<ArrowUpwardIcon sx={{ fontSize: '22px' }} />}
              />
              {!isLoading ? (
                <>
                  {data.length !== 0 ? (
                    data.map((el, index) => {
                      return (
                        <Grid
                          key={index}
                          item
                          xs={size}
                          sx={bools[1] ? { padding: '20px 250px !important' } : {}}
                        >
                          {bools[0] && (
                            <ServiceCard el={el} index={index} noWidth maxHeight="327px" />
                          )}
                          {bools[1] && (
                            <PostCard
                              el={el}
                              index={index}
                              dataSetter={postCommentsSwitcher}
                              noWidth
                            />
                          )}
                          {bools[2] && <UserCard el={el} index={index} noWidth />}
                        </Grid>
                      );
                    })
                  ) : (
                    <Typography style={{ fontSize: '24px', margin: '70px auto' }}>
                      На жаль, ми нічого не знайшли.
                    </Typography>
                  )}
                  {(data.length !== 0 && params.get('type') !== 'users' && data.length < count) ||
                  (data.length !== 0 &&
                    params.get('type') === 'users' &&
                    data.length < count - 1) ? (
                    <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                      <LetsGoBtn2
                        sx={{ margin: '30px auto !important' }}
                        text={loadMore ? <Spinner type="small" /> : 'Гайда дивитись більше!'}
                        onClick={() => {
                          if (bools[0]) {
                            setExactFilters({
                              ...exactFilters,
                              services: {
                                ...exactFilters.services,

                                start: exactFilters.services.start + exactFilters.services.count
                              }
                            });
                            setLoadMore(true);
                          }

                          if (bools[1]) {
                            setExactFilters({
                              ...exactFilters,
                              posts: {
                                ...exactFilters.posts,

                                start: exactFilters.posts.start + exactFilters.posts.count
                              }
                            });
                            setLoadMore(true);
                          }
                          if (bools[2]) {
                            setExactFilters({
                              ...exactFilters,
                              users: {
                                ...exactFilters.users,

                                startWith: exactFilters.users.startWith + exactFilters.users.count
                              }
                            });
                            setLoadMore(true);
                          }
                        }}
                      />
                    </Grid>
                  ) : null}
                </>
              ) : (
                <Spinner />
              )}
            </Grid>

            <Menu
              anchorEl={anchorEl}
              id="filter-search-menu"
              open={open}
              onClose={handleClose}
              // onClick={handleClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: 'visible',

                  boxShadow: 'none',
                  borderRadius: '5px',
                  border: '1px solid black',
                  paddingTop: '10px',
                  paddingLeft: '20px',
                  paddingRight: '20px',
                  width: '450px',
                  mt: 1.5,
                  '& .MuiAvatar-root': {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1
                  },
                  '&:before': {
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    top: 0,
                    right: 20,
                    width: 10,
                    height: 10,
                    borderTop: '1px solid black',
                    borderLeft: '1px solid black',
                    bgcolor: 'background.paper',
                    transform: 'translateY(-50%) rotate(45deg)',
                    zIndex: 0
                  }
                }
              }}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
              <Grid container>
                <Grid item xs={12}>
                  <Typography
                    style={{
                      fontWeight: 'bold',
                      fontFamily: 'Kyiv-Sans-Bold',
                      marginBottom: '10px'
                    }}
                  >
                    Ціна
                  </Typography>
                </Grid>
                <Grid item xs={1} sx={{ display: 'flex', alignItems: 'end', mr: 1 }}>
                  <Typography
                    style={{
                      fontWeight: 'bold',
                      fontFamily: 'Kyiv-Sans-Bold',
                      marginBottom: '10px'
                    }}
                  >
                    Від
                  </Typography>
                </Grid>
                <Grid item xs={2} sx={{ mr: 2 }}>
                  <CssTextField
                    variant="standard"
                    type="number"
                    fullWidth
                    size="normal"
                    inputProps={{ style: { textAlign: 'left' } }}
                    value={filters.services.rangePriceFrom}
                    onChange={(event, value) => {
                      event.preventDefault();
                      event.stopPropagation();
                      params.set('rpf', filters.services.rangePriceFrom);
                      setFilters({
                        ...filters,
                        services: {
                          ...filters.services,

                          rangePriceFrom: +event.target.value
                        }
                      });
                    }}
                  >
                    {filters.services.rangePriceFrom}
                  </CssTextField>
                </Grid>
                <Grid item xs={1} sx={{ display: 'flex', alignItems: 'end', mr: 1 }}>
                  <Typography
                    style={{
                      fontWeight: 'bold',
                      fontFamily: 'Kyiv-Sans-Bold',
                      marginBottom: '10px'
                    }}
                  >
                    До
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <CssTextField
                    variant="standard"
                    type="number"
                    fullWidth
                    size="normal"
                    inputProps={{ style: { textAlign: 'left' } }}
                    value={filters.services.rangePriceTo}
                    onChange={(event, value) => {
                      event.preventDefault();
                      event.stopPropagation();

                      params.set('rpt', filters.services.rangePriceTo);
                      setFilters({
                        ...filters,
                        services: {
                          ...filters.services,

                          rangePriceTo: +event.target.value
                        }
                      });
                    }}
                  >
                    {filters.services.rangePriceTo}
                  </CssTextField>
                </Grid>
                <Grid item xs={12}>
                  <Slider
                    sx={{
                      color: '#60a5df !important'
                    }}
                    max={99999}
                    min={0}
                    value={[filters.services.rangePriceFrom, filters.services.rangePriceTo]}
                    onChange={(event, value) => {
                      event.preventDefault();
                      event.stopPropagation();
                      params.set('rpf', filters.services.rangePriceFrom);
                      params.set('rpt', filters.services.rangePriceTo);
                      setParams(params);
                      setFilters({
                        ...filters,
                        services: {
                          ...filters.services,
                          rangePriceFrom: value[0],
                          rangePriceTo: value[1]
                        }
                      });
                    }}
                    valueLabelDisplay="auto"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    style={{
                      fontWeight: 'bold',
                      fontFamily: 'Kyiv-Sans-Bold',
                      marginBottom: '10px'
                    }}
                  >
                    Наявність
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <FormGroup sx={{ display: 'flex', flexDirection: 'row' }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          disableRipple
                          checked={filters.services.stringOfAvailability.includes('Є в наявності')}
                          onChange={(e) => {
                            if (e.target.checked) {
                              const availabilityChecked = [
                                ...filters.services.stringOfAvailability,
                                'Є в наявності'
                              ];
                              if (availabilityChecked.length === 0) {
                                params.set('wa', false);
                                params.set('soa', JSON.stringify(availabilityChecked));
                                setParams(params);
                              } else {
                                params.set('wa', true);
                                params.set('soa', JSON.stringify(availabilityChecked));
                                setParams(params);
                              }
                              setFilters({
                                ...filters,
                                services: {
                                  ...filters.services,
                                  withAvailability: availabilityChecked.length === 0 ? false : true,
                                  stringOfAvailability: availabilityChecked
                                }
                              });
                            } else {
                              const availabilityChecked = [
                                ...filters.services.stringOfAvailability
                              ].filter((el) => el !== 'Є в наявності');
                              if (availabilityChecked.length === 0) {
                                params.set('wa', false);
                                params.set('soa', JSON.stringify(availabilityChecked));
                                setParams(params);
                              } else {
                                params.set('wa', true);
                                params.set('soa', JSON.stringify(availabilityChecked));
                                setParams(params);
                              }
                              setFilters({
                                ...filters,
                                services: {
                                  ...filters.services,
                                  withAvailability: availabilityChecked.length === 0 ? false : true,
                                  stringOfAvailability: availabilityChecked
                                }
                              });
                            }
                          }}
                        />
                      }
                      label="Є в наявності"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          disableRipple
                          checked={filters.services.stringOfAvailability.includes('Під замовлення')}
                          onChange={(e) => {
                            if (e.target.checked) {
                              const availabilityChecked = [
                                ...filters.services.stringOfAvailability,
                                'Під замовлення'
                              ];
                              if (availabilityChecked.length === 0) {
                                params.set('wa', false);
                                params.set('soa', JSON.stringify(availabilityChecked));
                                setParams(params);
                              } else {
                                params.set('wa', true);
                                params.set('soa', JSON.stringify(availabilityChecked));
                                setParams(params);
                              }
                              setFilters({
                                ...filters,
                                services: {
                                  ...filters.services,
                                  withAvailability: availabilityChecked.length === 0 ? false : true,
                                  stringOfAvailability: availabilityChecked
                                }
                              });
                            } else {
                              const availabilityChecked = [
                                ...filters.services.stringOfAvailability
                              ].filter((el) => el !== 'Під замовлення');
                              if (availabilityChecked.length === 0) {
                                params.set('wa', false);
                                params.set('soa', JSON.stringify(availabilityChecked));
                                setParams(params);
                              } else {
                                params.set('wa', true);
                                params.set('soa', JSON.stringify(availabilityChecked));
                                setParams(params);
                              }
                              setFilters({
                                ...filters,
                                services: {
                                  ...filters.services,
                                  withAvailability: availabilityChecked.length === 0 ? false : true,
                                  stringOfAvailability: availabilityChecked
                                }
                              });
                            }
                          }}
                        />
                      }
                      label="Під замовлення"
                    />
                  </FormGroup>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    style={{
                      fontWeight: 'bold',
                      fontFamily: 'Kyiv-Sans-Bold',
                      marginBottom: '10px'
                    }}
                  >
                    Категорія
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          disableRipple
                          checked={filters.services.categories.includes('Прикраси')}
                          onChange={(e) => {
                            if (e.target.checked) {
                              const categoryChecked = [...filters.services.categories, 'Прикраси'];
                              if (categoryChecked.length === 0) {
                                params.set('wc', false);
                                params.set('c', JSON.stringify(categoryChecked));
                                setParams(params);
                              } else {
                                params.set('wc', true);
                                params.set('c', JSON.stringify(categoryChecked));
                                setParams(params);
                              }
                              setFilters({
                                ...filters,
                                services: {
                                  ...filters.services,
                                  withCategories: categoryChecked.length === 0 ? false : true,
                                  categories: categoryChecked
                                }
                              });
                            } else {
                              const categoryChecked = [...filters.services.categories].filter(
                                (el) => el !== 'Прикраси'
                              );
                              if (categoryChecked.length === 0) {
                                params.set('wc', false);
                                params.set('c', JSON.stringify(categoryChecked));
                                setParams(params);
                              } else {
                                params.set('wc', true);
                                params.set('c', JSON.stringify(categoryChecked));
                                setParams(params);
                              }
                              setFilters({
                                ...filters,
                                services: {
                                  ...filters.services,
                                  withCategories: categoryChecked.length === 0 ? false : true,
                                  categories: categoryChecked
                                }
                              });
                            }
                          }}
                        />
                      }
                      label="Прикраси"
                    />

                    <FormControlLabel
                      control={
                        <Checkbox
                          disableRipple
                          checked={filters.services.categories.includes('Товари для дому')}
                          onChange={(e) => {
                            if (e.target.checked) {
                              const categoryChecked = [
                                ...filters.services.categories,
                                'Товари для дому'
                              ];
                              if (categoryChecked.length === 0) {
                                params.set('wc', false);
                                params.set('c', JSON.stringify(categoryChecked));
                                setParams(params);
                              } else {
                                params.set('wc', true);
                                params.set('c', JSON.stringify(categoryChecked));
                                setParams(params);
                              }
                              setFilters({
                                ...filters,
                                services: {
                                  ...filters.services,
                                  withCategories: categoryChecked.length === 0 ? false : true,
                                  categories: categoryChecked
                                }
                              });
                            } else {
                              const categoryChecked = [...filters.services.categories].filter(
                                (el) => el !== 'Товари для дому'
                              );
                              if (categoryChecked.length === 0) {
                                params.set('wc', false);
                                params.set('c', JSON.stringify(categoryChecked));
                                setParams(params);
                              } else {
                                params.set('wc', true);
                                params.set('c', JSON.stringify(categoryChecked));
                                setParams(params);
                              }
                              setFilters({
                                ...filters,
                                services: {
                                  ...filters.services,
                                  withCategories: categoryChecked.length === 0 ? false : true,
                                  categories: categoryChecked
                                }
                              });
                            }
                          }}
                        />
                      }
                      label="Товари для дому"
                    />

                    <FormControlLabel
                      control={
                        <Checkbox
                          disableRipple
                          checked={filters.services.categories.includes('Одяг')}
                          onChange={(e) => {
                            if (e.target.checked) {
                              const categoryChecked = [...filters.services.categories, 'Одяг'];
                              if (categoryChecked.length === 0) {
                                params.set('wc', false);
                                params.set('c', JSON.stringify(categoryChecked));
                                setParams(params);
                              } else {
                                params.set('wc', true);
                                params.set('c', JSON.stringify(categoryChecked));
                                setParams(params);
                              }
                              setFilters({
                                ...filters,
                                services: {
                                  ...filters.services,
                                  withCategories: categoryChecked.length === 0 ? false : true,
                                  categories: categoryChecked
                                }
                              });
                            } else {
                              const categoryChecked = [...filters.services.categories].filter(
                                (el) => el !== 'Одяг'
                              );
                              if (categoryChecked.length === 0) {
                                params.set('wc', false);
                                params.set('c', JSON.stringify(categoryChecked));
                                setParams(params);
                              } else {
                                params.set('wc', true);
                                params.set('c', JSON.stringify(categoryChecked));
                                setParams(params);
                              }
                              setFilters({
                                ...filters,
                                services: {
                                  ...filters.services,
                                  withCategories: categoryChecked.length === 0 ? false : true,
                                  categories: categoryChecked
                                }
                              });
                            }
                          }}
                        />
                      }
                      label="Одяг"
                    />

                    <FormControlLabel
                      control={
                        <Checkbox
                          disableRipple
                          checked={filters.services.categories.includes('Аксесуари')}
                          onChange={(e) => {
                            if (e.target.checked) {
                              const categoryChecked = [...filters.services.categories, 'Аксесуари'];
                              if (categoryChecked.length === 0) {
                                params.set('wc', false);
                                params.set('c', JSON.stringify(categoryChecked));
                                setParams(params);
                              } else {
                                params.set('wc', true);
                                params.set('c', JSON.stringify(categoryChecked));
                                setParams(params);
                              }
                              setFilters({
                                ...filters,
                                services: {
                                  ...filters.services,
                                  withCategories: categoryChecked.length === 0 ? false : true,
                                  categories: categoryChecked
                                }
                              });
                            } else {
                              const categoryChecked = [...filters.services.categories].filter(
                                (el) => el !== 'Аксесуари'
                              );
                              if (categoryChecked.length === 0) {
                                params.set('wc', false);
                                params.set('c', JSON.stringify(categoryChecked));
                                setParams(params);
                              } else {
                                params.set('wc', true);
                                params.set('c', JSON.stringify(categoryChecked));
                                setParams(params);
                              }
                              setFilters({
                                ...filters,
                                services: {
                                  ...filters.services,
                                  withCategories: categoryChecked.length === 0 ? false : true,
                                  categories: categoryChecked
                                }
                              });
                            }
                          }}
                        />
                      }
                      label="Аксесуари"
                    />

                    <FormControlLabel
                      control={
                        <Checkbox
                          disableRipple
                          checked={filters.services.categories.includes('Косметика')}
                          onChange={(e) => {
                            if (e.target.checked) {
                              const categoryChecked = [...filters.services.categories, 'Косметика'];
                              if (categoryChecked.length === 0) {
                                params.set('wc', false);
                                params.set('c', JSON.stringify(categoryChecked));
                                setParams(params);
                              } else {
                                params.set('wc', true);
                                params.set('c', JSON.stringify(categoryChecked));
                                setParams(params);
                              }
                              setFilters({
                                ...filters,
                                services: {
                                  ...filters.services,
                                  withCategories: categoryChecked.length === 0 ? false : true,
                                  categories: categoryChecked
                                }
                              });
                            } else {
                              const categoryChecked = [...filters.services.categories].filter(
                                (el) => el !== 'Косметика'
                              );
                              if (categoryChecked.length === 0) {
                                params.set('wc', false);
                                params.set('c', JSON.stringify(categoryChecked));
                                setParams(params);
                              } else {
                                params.set('wc', true);
                                params.set('c', JSON.stringify(categoryChecked));
                                setParams(params);
                              }
                              setFilters({
                                ...filters,
                                services: {
                                  ...filters.services,
                                  withCategories: categoryChecked.length === 0 ? false : true,
                                  categories: categoryChecked
                                }
                              });
                            }
                          }}
                        />
                      }
                      label="Косметика"
                    />
                  </FormGroup>
                </Grid>
                <Grid item xs={6}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          disableRipple
                          checked={filters.services.categories.includes('Сувеніри')}
                          onChange={(e) => {
                            if (e.target.checked) {
                              const categoryChecked = [...filters.services.categories, 'Сувеніри'];
                              if (categoryChecked.length === 0) {
                                params.set('wc', false);
                                params.set('c', JSON.stringify(categoryChecked));
                                setParams(params);
                              } else {
                                params.set('wc', true);
                                params.set('c', JSON.stringify(categoryChecked));
                                setParams(params);
                              }
                              setFilters({
                                ...filters,
                                services: {
                                  ...filters.services,
                                  withCategories: categoryChecked.length === 0 ? false : true,
                                  categories: categoryChecked
                                }
                              });
                            } else {
                              const categoryChecked = [...filters.services.categories].filter(
                                (el) => el !== 'Сувеніри'
                              );
                              if (categoryChecked.length === 0) {
                                params.set('wc', false);
                                params.set('c', JSON.stringify(categoryChecked));
                                setParams(params);
                              } else {
                                params.set('wc', true);
                                params.set('c', JSON.stringify(categoryChecked));
                                setParams(params);
                              }
                              setFilters({
                                ...filters,
                                services: {
                                  ...filters.services,
                                  withCategories: categoryChecked.length === 0 ? false : true,
                                  categories: categoryChecked
                                }
                              });
                            }
                          }}
                        />
                      }
                      label="Сувеніри"
                    />

                    <FormControlLabel
                      control={
                        <Checkbox
                          disableRipple
                          checked={filters.services.categories.includes('Їжа та напої')}
                          onChange={(e) => {
                            if (e.target.checked) {
                              const categoryChecked = [
                                ...filters.services.categories,
                                'Їжа та напої'
                              ];
                              if (categoryChecked.length === 0) {
                                params.set('wc', false);
                                params.set('c', JSON.stringify(categoryChecked));
                                setParams(params);
                              } else {
                                params.set('wc', true);
                                params.set('c', JSON.stringify(categoryChecked));
                                setParams(params);
                              }
                              setFilters({
                                ...filters,
                                services: {
                                  ...filters.services,
                                  withCategories: categoryChecked.length === 0 ? false : true,
                                  categories: categoryChecked
                                }
                              });
                            } else {
                              const categoryChecked = [...filters.services.categories].filter(
                                (el) => el !== 'Їжа та напої'
                              );
                              if (categoryChecked.length === 0) {
                                params.set('wc', false);
                                params.set('c', JSON.stringify(categoryChecked));
                                setParams(params);
                              } else {
                                params.set('wc', true);
                                params.set('c', JSON.stringify(categoryChecked));
                                setParams(params);
                              }
                              setFilters({
                                ...filters,
                                services: {
                                  ...filters.services,
                                  withCategories: categoryChecked.length === 0 ? false : true,
                                  categories: categoryChecked
                                }
                              });
                            }
                          }}
                        />
                      }
                      label="Їжа та напої"
                    />

                    <FormControlLabel
                      control={
                        <Checkbox
                          disableRipple
                          checked={filters.services.categories.includes('Для тварин')}
                          onChange={(e) => {
                            if (e.target.checked) {
                              const categoryChecked = [
                                ...filters.services.categories,
                                'Для тварин'
                              ];
                              if (categoryChecked.length === 0) {
                                params.set('wc', false);
                                params.set('c', JSON.stringify(categoryChecked));
                                setParams(params);
                              } else {
                                params.set('wc', true);
                                params.set('c', JSON.stringify(categoryChecked));
                                setParams(params);
                              }
                              setFilters({
                                ...filters,
                                services: {
                                  ...filters.services,
                                  withCategories: categoryChecked.length === 0 ? false : true,
                                  categories: categoryChecked
                                }
                              });
                            } else {
                              const categoryChecked = [...filters.services.categories].filter(
                                (el) => el !== 'Для тварин'
                              );
                              if (categoryChecked.length === 0) {
                                params.set('wc', false);
                                params.set('c', JSON.stringify(categoryChecked));
                                setParams(params);
                              } else {
                                params.set('wc', true);
                                params.set('c', JSON.stringify(categoryChecked));
                                setParams(params);
                              }
                              setFilters({
                                ...filters,
                                services: {
                                  ...filters.services,
                                  withCategories: categoryChecked.length === 0 ? false : true,
                                  categories: categoryChecked
                                }
                              });
                            }
                          }}
                        />
                      }
                      label="Для тварин"
                    />

                    <FormControlLabel
                      control={
                        <Checkbox
                          disableRipple
                          checked={filters.services.categories.includes('Канцелярія')}
                          onChange={(e) => {
                            if (e.target.checked) {
                              const categoryChecked = [
                                ...filters.services.categories,
                                'Канцелярія'
                              ];
                              if (categoryChecked.length === 0) {
                                params.set('wc', false);
                                params.set('c', JSON.stringify(categoryChecked));
                                setParams(params);
                              } else {
                                params.set('wc', true);
                                params.set('c', JSON.stringify(categoryChecked));
                                setParams(params);
                              }
                              setFilters({
                                ...filters,
                                services: {
                                  ...filters.services,
                                  withCategories: categoryChecked.length === 0 ? false : true,
                                  categories: categoryChecked
                                }
                              });
                            } else {
                              const categoryChecked = [...filters.services.categories].filter(
                                (el) => el !== 'Канцелярія'
                              );
                              if (categoryChecked.length === 0) {
                                params.set('wc', false);
                                params.set('c', JSON.stringify(categoryChecked));
                                setParams(params);
                              } else {
                                params.set('wc', true);
                                params.set('c', JSON.stringify(categoryChecked));
                                setParams(params);
                              }
                              setFilters({
                                ...filters,
                                services: {
                                  ...filters.services,
                                  withCategories: categoryChecked.length === 0 ? false : true,
                                  categories: categoryChecked
                                }
                              });
                            }
                          }}
                        />
                      }
                      label="Канцелярія"
                    />

                    <FormControlLabel
                      control={
                        <Checkbox
                          disableRipple
                          checked={filters.services.categories.includes('Інше')}
                          onChange={(e) => {
                            if (e.target.checked) {
                              const categoryChecked = [...filters.services.categories, 'Інше'];
                              if (categoryChecked.length === 0) {
                                params.set('wc', false);
                                params.set('c', JSON.stringify(categoryChecked));
                                setParams(params);
                              } else {
                                params.set('wc', true);
                                params.set('c', JSON.stringify(categoryChecked));
                                setParams(params);
                              }
                              setFilters({
                                ...filters,
                                services: {
                                  ...filters.services,
                                  withCategories: categoryChecked.length === 0 ? false : true,
                                  categories: categoryChecked
                                }
                              });
                            } else {
                              const categoryChecked = [...filters.services.categories].filter(
                                (el) => el !== 'Інше'
                              );
                              if (categoryChecked.length === 0) {
                                params.set('wc', false);
                                params.set('c', JSON.stringify(categoryChecked));
                                setParams(params);
                              } else {
                                params.set('wc', true);
                                params.set('c', JSON.stringify(categoryChecked));
                                setParams(params);
                              }
                              setFilters({
                                ...filters,
                                services: {
                                  ...filters.services,
                                  withCategories: categoryChecked.length === 0 ? false : true,
                                  categories: categoryChecked
                                }
                              });
                            }
                          }}
                        />
                      }
                      label="Інше"
                    />
                  </FormGroup>
                </Grid>

                <Grid
                  item
                  xs={
                    !bools[1] && JSON.stringify(filters) !== JSON.stringify(initialFilters) ? 6 : 12
                  }
                  sx={{ display: 'flex', justifyContent: 'center', mt: 3, mb: 1 }}
                >
                  <FreeBtn
                    onClick={() => {
                      setExactFilters(filters);
                      setIsAcceptFilters(true);
                      handleClose();
                    }}
                    text="Застосувати фільтри"
                  />
                </Grid>
                {!bools[1] && JSON.stringify(filters) !== JSON.stringify(initialFilters) && (
                  <Grid
                    item
                    xs={6}
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      mt: 3,
                      mb: 1,
                      alignItems: 'center'
                    }}
                  >
                    <Typography
                      fontSize={14}
                      variant="body"
                      sx={{
                        textDecoration: 'underline',
                        margin: '0px',
                        fontWeight: '700',
                        cursor: 'pointer',
                        zIndex: '99',
                        ':hover': {
                          fontWeight: '1000'
                        }
                      }}
                      onClick={() => {
                        setExactFilters(initialFilters);
                        setFilters(initialFilters);
                        params.delete('rpf');
                        params.delete('rpt');
                        params.delete('wc');
                        params.delete('c');
                        params.delete('wa');
                        params.delete('soa');
                        setParams(params);
                        setIsAcceptFilters(true);
                      }}
                    >
                      Очистити фільтри
                    </Typography>
                  </Grid>
                )}
              </Grid>

              <img
                style={{ position: 'absolute', zIndex: '0', bottom: '0px', right: '-20px' }}
                src={starRed}
                alt="star-red"
              />
            </Menu>
          </Grid>
        </Grid>
      </Stack>
    </Page>
  );
}
const mapStateToProps = (state) => {
  return {
    language: state.Intl.locale,
    userData: state.global.userData
  };
};
export default connect(mapStateToProps)(SearchPage);
