// eslint-disable-next-line
export default {
  ua: {
    locale: 'ua',
    messages: {
      test: 'тест',
      helmetTitle: 'BONFAIR',
      //api messages
      Invalid_email_or_password: 'Невірний логін або пароль!',
      error_sending_contacts: 'Нажаль сталася помилка!',
      success_sending_contacts: 'Ваш запит надіслано успішно.',
      Subject_should_not_be_empty: 'Поле Тема, не може бути пустим.',
      Name_should_not_be_empty: "Поле Ім'я, не може бути пустим.",
      Contact_should_not_be_empty: 'Поле Контакту, не може бути пустим.',
      Message_should_not_be_empty: 'Поле Повідомлення, не може бути пустим.',
      Surname_is_not_correct: 'Поле заповнено не коректно.',
      Name_is_not_correct: 'Поле заповнено не коректно.',
      activation_code_is_not_valid: 'Ви ввели не коректний код.',
      Account_is_not_activated: 'Цей акаунт, ще не активовано!',
      There_is_an_account_with_that_email_address: 'Акаунт з такою поштою вже існує',
      There_is_an_account_with_that_nickname: 'Акаунт з таким ніком вже існує',
      //input errors
      error_required: "Це поле обов'язкове",
      error_regExp_email: 'Введіть коректну електронну пошту',
      error_password_match: 'Паролі не співпадають',
      error_agreement_alert: 'Ви маєте прочитати і прийняти користувацьку угоду',
      error_regExp_password:
        'Пароль має включати тільки латинські літери, 1 велику літеру, 1 цифру, та 1 маленьку літеру.',
      error_regExp_nickname: 'Нікнейм має включати тільки латинські літери',
      error_regExp_telegram: "Введіть коректне ім'я користувача (@username)",
      error_input_lenght_min_2: 'Мінімально 2 символи',
      error_input_lenght_min_10: 'Мінімально 10 символів',
      error_input_lenght_max_35: 'Максимально 35 символів',
      error_input_lenght_max_256: 'Максимально 256 символів',
      //navigation
      bonfair_language: 'Мова',
      bonfair_language_title: 'Оберіть мову',
      bonfair_search: 'Пошук',
      bonfair_tape: 'Стрічка',
      bonfair_notification: 'Сповіщення',
      bonfair_correspondence: 'Листування',
      bonfair_account_enter: 'Увійти',
      bonfair_account: 'Мій аккаунт',
      //main
      bonfair_pageName: 'BONFAIR',
      bonfair_slogun:
        'Відкрийте для себе креативні рішення для оптимізації програмного забезпечення',
      bonfair_contact_button: 'Зв’язатися!',
      bonfair_searchPageName: 'Пошук',
      bonfair_myPage: 'Моя сторінка',

      //expertise
      bonfair_expretice: 'НАШ ДОСВІД',
      bonfair_expretice_theme_1: 'Розробка програмного забезпечення',
      bonfair_expretice_theme_2: 'Перевірка якості',
      bonfair_expretice_theme_3: 'DevOps супровід',
      bonfair_expretice_theme_4: 'Надання архітектурних рішень в IT сфері',
      bonfair_expretice_desc_1:
        'Створення, специфікація, проектування, програмування, документування, тестування, виправлення помилок та підтримка програм.',
      bonfair_expretice_desc_2:
        'Розробка вимог, розробка дизайну програмного забезпечення, кодування, перегляд коду, контроль вихідного коду, керування конфігурацією програмного забезпечення, тестування, перевірка та валідація, керування випусками та інтеграція програмного забезпечення.',
      bonfair_expretice_desc_3:
        '- використання практик, які поєднують розробку програмного забезпечення (Dev) та ІТ-операції (Ops).\n- скорочення життєвого циклу розробки систем і забезпечення безперервного постачання з високою якістю програмного забезпечення.\n- автоматизація процесів.\n- постійна інтеграція.\n- постійна доставка.\n- інфраструктура як код.',
      bonfair_expretice_desc_4:
        '- пошук і визначення рішення в контексті проекту чи ініціативи.\n- поєднання вказівок з різних архітектурних точок зору (ділової, інформаційної та технічної).\n- визначення та передача спільного технічного та архітектурного бачення.',
      //about
      bonfair_about: 'Хто ми є',
      bonfair_aboutUs_1:
        'ТОВ Б&Б Рішення – є молода  українська перспективна IT компанія, що надає комплексні рішення для автоматизації та побудови процесу розробки, розгортання та супроводу інфраструктури продуктів.',
      bonfair_aboutUs_2:
        ' Наші рішення базуються на багаторічному досвіді втілення сучасних практик, пошуку нових креативних рішень та імплементації результатів досліджень.',
      bonfair_whyUs: 'Чому ми?',
      bonfair_whyUs_l: 'Завжди обирайте професіоналів',
      bonfair_whyUs_item_title_1: 'Багатий досвід у ІТ',
      bonfair_whyUs_item_desc_1:
        'Наша креативна та досвідчена команда знайде рішення для найскладніших завдань щодо розробки програмного забезпечення.',
      bonfair_whyUs_item_title_2: 'Швидкий початок',
      bonfair_whyUs_item_desc_2: 'Ми завжди намагаємось почати пошук рішень якомога швидше.',
      bonfair_whyUs_item_title_3: 'Індивідуальний підхід',
      bonfair_whyUs_item_desc_3:
        'На основі ваших запитів та нашого досвіду, формуємо та надаємо рішення, які будуть максимально оптимальними саме для вас.',
      bonfair_whyUs_item_title_4: 'Навіть з нуля',
      bonfair_whyUs_item_desc_4:
        'Ми залюбки побудуємо процес розробки для вас з нуля або оптимізуємо вже існуючий.',
      //FAQ
      bonfair_FAQ: 'маєте ПИТАННЯ?',
      bonfair_FAQ_l:
        "Якщо у вас виникнуть додаткові питання, ви можете надіслати запит нам і наш менеджер зв'яжеться з вами найближчим часом.",
      bonfair_FAQ_question_1: 'Як ви можете допомогти моєму бізнесу?',
      bonfair_FAQ_answer_1:
        'Ми постійно шукаємо інновації та свіжі рішення для бізнесу наших клієнтів. Наша команда розробить новітнє та найефективніше рішення, яке допоможе оптимальніше досягти цілей розробки.',
      bonfair_FAQ_question_2: 'З яким видом бізнесу ви працюєте?',
      bonfair_FAQ_answer_2:
        'Ми маємо великий досвід у сфері ІТ-процесів, який можна застосувати до будь-якого типу бізнесу.',
      bonfair_FAQ_question_3: 'А якщо я з іншої країни?',
      bonfair_FAQ_answer_3:
        "Для нас це не є проблемою. Ви можете зв'язатися з нами, і ми побудуємо процес роботи дистанційно.",
      //Contact
      //footer
      bonfair_contact_address: 'Адреса',
      bonfair_contact_address_text: 'Одеса, Україна',
      bonfair_contact_contacts: 'Контакти',
      //modal
      bonfair_auth_modal: 'Увійти',
      bonfair_auth_modal_reg: 'Створити обліковий запис',
      bonfair_auth_modal_reg_confirm: 'Підтвердження електронної пошти',
      bonfair_auth_modal_reg_confirm_l:
        'На вказану Вами електронну пошту, було надіслано листа з підвердженням реєстрації. Будь ласка, перевірте Вашу поштову скриньку.',
      bonfair_auth_modal_restore: 'Відновлення паролю',
      bonfair_auth_modal_l: 'Введіть Вашу електронну пошту, куди буде надіслано код.',
      bonfair_auth_modal_l_2: 'Введіть код, який був надісланий на вашу пошту.',
      bonfair_contact_modal_inputType: 'Спочатку оберіть зручний спосіб спілкування:',
      bonfair_auth_modal_inputType_email: 'E-mail*',
      bonfair_auth_modal_inputType_code: 'Одноразовий код*',
      bonfair_contact_modal_inputType_telegram: 'Telegram',
      bonfair_contact_modal_input_name: 'Ім’я*',
      bonfair_contact_modal_input_secondName: 'Прізвище*',
      bonfair_contact_modal_input_nikname: 'Нікнейм*',
      bonfair_auth_modal_input_password: 'Пароль*',
      bonfair_auth_modal_input_confirmPassword: 'Підтвердження паролю*',
      bonfair_contact_modal_input_email: 'E-mail*',
      bonfair_contact_modal_input_telegram: 'Ваш нікнейм Telegram',
      bonfair_contact_modal_input_message: 'Повідомлення',
      bonfair_contact_modal_button_send: 'Відправити'
    }
  },
  en: {
    locale: 'en',
    messages: {
      test: 'test',
      helmetTitle: 'BONFAIR',
      //api messages
      Invalid_email_or_password: 'Invalid email or password!',
      error_sending_contacts: 'Unfortunately, an error occurred!',
      success_sending_contacts: 'Your request has been sent successfully.',
      Subject_should_not_be_empty: 'Subject should not be empty.',
      Name_should_not_be_empty: 'Name should not be empty.',
      Contact_should_not_be_empty: 'Contact should not be empty.',
      Message_should_not_be_empty: 'Message should not be empty.',
      activation_code_is_not_valid: 'You entered an incorrect code.',
      Account_is_not_activated: 'Account is not activated',
      There_is_an_account_with_that_email_address: 'There is an account with that email address',
      There_is_an_account_with_that_nickname: 'There is an account with that nickname',
      //input errors
      error_required: 'This field is required',
      error_regExp_email: 'Please enter a valid email address',
      error_regExp_password:
        'The password must include only Latin letters, 1 uppercase letter, 1 number, and 1 lowercase letter.',
      error_regExp_nickname: 'Nickname must include only Latin letters',
      error_password_match: 'Password must match',
      error_agreement_alert: 'You must read and accept the user agreement',
      error_regExp_telegram: 'Please enter a valid Telegram username (@username)',
      error_input_lenght_min_2: 'The minimum field length is 2 characters',
      error_input_lenght_min_10: 'The minimum field length is 10 characters',
      error_input_lenght_max_35: 'The maximum field length is 35 characters',
      error_input_lenght_max_256: 'The maximum field length is 256 characters',
      //navigation
      bonfair_language: 'Language',
      bonfair_language_title: 'Choose a language',
      bonfair_search: 'Search',
      bonfair_tape: 'Tape',
      bonfair_notification: 'Notifications',
      bonfair_correspondence: 'Сorrespondence',
      bonfair_account_enter: 'Enter',
      bonfair_account: 'My account',
      //main
      bonfair_pageName: 'BONFAIR',
      bonfair_slogun: 'Discover creative optimization solutions for your software business',
      bonfair_contact_button: 'Get in touch!',
      //search
      bonfair_searchPageName: 'Search',
      bonfair_myPage: 'My page',

      //expertise
      bonfair_expretice: 'what we do',
      bonfair_expretice_theme_1: 'Software Development',
      bonfair_expretice_theme_2: 'Quality Assurance',
      bonfair_expretice_theme_3: 'DevOps Support',
      bonfair_expretice_theme_4: 'Provide IT Architecture Solutions',
      bonfair_expretice_desc_1:
        'Conceiving, specifying, designing, programming, documenting, testing, bug fixing, and maintaining applications.',
      bonfair_expretice_desc_2:
        'Requirements engineering, software design, coding, code reviews, source code control, software configuration management, testing, verification and validation, release management and software integration.',
      bonfair_expretice_desc_3:
        '- practices that combine software development (Dev) and IT operations (Ops).\n-	make the systems development life cycle shorter and provide continuous delivery with high software quality.\n-	automation processes.\n-	continuous integration.\n -	continuous delivery.\n-	infrastructure as a code.',
      bonfair_expretice_desc_4:
        '- seeking and defining a solution within the context of a project or initiative.\n-	combining guidance from different architectural viewpoints (business, information and technical).\n-	defining and communicating a shared technical and architectural vision.',
      //about
      bonfair_about: 'Who we are',
      bonfair_aboutUs_1:
        'LLC B&B Solutions is a perspective Ukrainian IT start-up company that provides complex solutions for automating and building the developing process, deploying and supporting product infrastructure as well.',
      bonfair_aboutUs_2:
        'Our solutions are based on a wide range of experience in implementing modern practices, discovering new creative solutions and applying research results.',
      bonfair_whyUs: 'Why Us?',
      bonfair_whyUs_l: 'ALWAYS CHOOSE PROFESSIONALS',
      bonfair_whyUs_item_title_1: 'IT EXTENSIVE EXPERIENCE',
      bonfair_whyUs_item_desc_1:
        'Our creative and experienced team will find out real solutions for the hardest software development challenges.',
      bonfair_whyUs_item_title_2: 'QUICK START',
      bonfair_whyUs_item_desc_2:
        'We always try to start figuring out solutions as soon as possible.',
      bonfair_whyUs_item_title_3: 'INDIVIDUAL APPROACH',
      bonfair_whyUs_item_desc_3:
        'Based on your requests and our experience, we establish and provide solutions that will be as optimal as possible for you.',
      bonfair_whyUs_item_title_4: 'EVEN FROM SCRATCH',
      bonfair_whyUs_item_desc_4:
        'We will be glad to build a development process from scratch for you or optimize an already existing one.',

      //FAQ
      bonfair_FAQ: 'Do you have questions?',
      bonfair_FAQ_l:
        'In case you have more questions, you can get in touch with us and our manager will contact you shortly. ',
      bonfair_FAQ_question_1: 'How can you help my business?',
      bonfair_FAQ_answer_1:
        "We are constantly looking for innovations and fresh solutions for our client's businesses. Our team will design the newest and the most effective solution that will help you reach your software processes goals.",
      bonfair_FAQ_question_2: 'What kind of businesses do you work with?',
      bonfair_FAQ_answer_2:
        'We have strong expertise in IT processes that could be applied to any type of business.',
      bonfair_FAQ_question_3: 'What if I am from another country?',
      bonfair_FAQ_answer_3:
        'It is not a problem for us. You can contact us, and we will support you remotely.',
      //Contact
      //footer
      bonfair_contact_address: 'Address',
      bonfair_contact_address_text: 'Odessa, Ukraine',
      bonfair_contact_contacts: 'Contacts',
      //modal
      bonfair_auth_modal: 'Enter',
      bonfair_auth_modal_reg: 'Create an account',
      bonfair_auth_modal_restore: 'Restore password',

      bonfair_auth_modal_l: 'Enter your email address where the code will be sent.',
      bonfair_auth_modal_l_2: 'Enter the code that was sent to your email.',
      bonfair_contact_modal_inputType: 'First, choose the preferred method of contact:',
      bonfair_auth_modal_inputType_email: 'E-mail*',
      bonfair_auth_modal_inputType_code: 'One-time code*',
      bonfair_contact_modal_inputType_telegram: 'Telegram',
      bonfair_contact_modal_input_name: 'Name*',
      bonfair_contact_modal_input_secondName: 'Second name*',
      bonfair_auth_modal_input_password: 'Password*',
      bonfair_auth_modal_input_confirmPassword: 'Password confirmation*',
      bonfair_contact_modal_input_nikname: 'Nikname*',
      bonfair_contact_modal_input_email: 'E-mail*',
      bonfair_contact_modal_input_telegram: 'Your Telegram username',
      bonfair_contact_modal_input_message: 'Message',
      bonfair_contact_modal_button_send: 'Send'
    }
  }
};
