import React from 'react';

//Material
import { Box, Grid, Typography } from '@mui/material';

//Components

import { Stack } from '@mui/system';
// import CardCarousel from '../../../components/CardCarousel';
import Slider from 'react-slick';
//images
// import folkFlowers from '../../../assets/folkFlowers.png';
import ornamentDot from '../../../assets/ornamentDot.png';
import firstWing from '../../../assets/wingColor.png';
import secondWing from '../../../assets/wingImage.png';

// import DefaultIMG from '../../../assets/DefaultIMG.png';

import vector1 from '../../../assets/Slider/Vector1.png';
import vector2 from '../../../assets/Slider/Vector2.png';
import vector3 from '../../../assets/Slider/Vector3.png';
import vector4 from '../../../assets/Slider/Vector4.png';
import vector5 from '../../../assets/Slider/Vector5.png';
import vector6 from '../../../assets/Slider/Vector6.png';
// import folkFlowers2 from '../../../assets/folkFlowers2.png';

// import { useState } from 'react';
// import { LetsGoBtn, LetsGoBtn2 } from '../../../components/Buttons';
import { LetsGoBtn } from '../../../components/Buttons';
import { useDispatch, useSelector } from 'react-redux';
import { setIsOpenAuthModal } from '../../../redux/mainReducer';
import { StyledTitle } from '../../../components/StyledComponents';
import { useNavigate } from 'react-router-dom';

export default function TopBlock() {
  // const [current, setCurrent] = useState(0);
  // const titles=[
  //   'Створи аккаунт і використай всі можливості сайту'
  // ]
  const userData = useSelector((state) => state.global.userData);
  const language = useSelector((state) => state.Intl.locale);
  const dispatch = useDispatch();
  const navigate=useNavigate()

  // useEffect(() => {
  //   const element = document.getElementById('questionsBlock');
  // }, []);
  // useEffect(() => {
  //   if (window.screen.width <= 900) {
  //     setScreenType('mobile');
  //   }
  // }, []);
  const settings = {
    autoplay: true,
    autoplaySpeed: 5000,
    dots: true,
    infinite: true,
    speed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    useCSS: true,
    arrows: false

    // beforeChange: (current, next) => setActiveSlide(next)a
  };
  const cards = [
    {
      vector: vector1,
      title: 'Підтримуй та плекай українське – воно душу й серце гріє.'
    },
    {
      vector: vector2,
      title: 'Знайом зі своїм продуктом, бо він того вартий.'
    },
    {
      vector: vector3,
      title: 'Створюй зв’язки та прозорі стосунки, адже це основа довіри.'
    },
    {
      vector: vector4,
      title: 'Розвивай свій бізнес та отримуй прибуток.'
    },
    {
      vector: vector5,
      title: 'Отримуй миттєві сповіщення, щоб бути завжди в курсі подій.'
    },
    {
      vector: vector6,
      title: 'Ділись своїми новинами – нам це дійсно цікаво.'
    }
  ];

  return (
    <Grid xs={12} item sx={{ mt: 15 }}>
      <Box className="topBlock-main-info">
        <Stack marginTop={8} marginRight={2}>
          <Typography
            variant="h1"
            style={{ fontSize: '112px', lineJeight: '124px', position: 'relative' }}
          >
            BONFAIR
            <img
              style={{ position: 'absolute', left: '392px', top: '-8px' }}
              src={ornamentDot}
              alt="ornament"
            />
          </Typography>
          <Typography
            variant="h4"
            marginLeft={40}
            textTransform="uppercase"
            maxWidth="600px"
            style={{ fontSize: '48px', lineHeight: 'normal' }}
          >
            - ЯРМАРОК НОВОГО ПОКОЛІННЯ
          </Typography>
          <Typography
            marginLeft={40}
            maxWidth="500px"
            style={{
              fontStyle: 'normal',
              fontSize: '24px',
              fontWeight: '400',
              lineHeight: '29px',
              letterSpacing: '0em',
              textAlign: 'left'
            }}
          >
            Ми – українська Social Selling платформа, що поєднала торговий майданчик та соціальну
            мережу в одному місці через створення довірчих відносин між виробником і покупцем.
          </Typography>
          <LetsGoBtn
          text='Нумо гляньмо!'
            width="250px"
            margin="30px 0px 0px 315px"
            onClick={() => {
              if(userData?.id){
                navigate(`/${language}/ribbon`);
              }else{
                dispatch(setIsOpenAuthModal(true))
              }
            }}
          />
        </Stack>
        <Stack className="topBlock-main-info-wings">
          <img alt="first-wing" className="topBlock-main-info-firstWing" src={firstWing} />
          <img
            className="topBlock-main-info-secondWing"
            alt="second-wing"
            style={{ position: 'absolute', bottom: '190px' }}
            src={secondWing}
          />
        </Stack>
      </Box>
      <Box className="topBlock-main-carousel">
        {/* <Stack
          style={{
            position: 'relative',
            width: '100%',
            justifyContent: 'space-between',
            display: 'flex',
            flexDirection: 'row',
            paddingLeft: '100px'
          }}
        >
          <img alt="folk-flowers" style={{ position: 'absolute', left: '0', top:'40px' }} src={folkFlowers} />
          <Stack style={{ display: 'flex', justifyContent: 'center' }}>
            <Typography variant="h4" style={{ maxWidth: '550px', fontFamily: 'Kyiv-Sans-Bold', fontSize: '32px',
fontWeight: '500',
lineHeight: '38px',
letterSpacing: '0em',
textAlign: 'left' }}>
              {titles[current]}
            </Typography>
            <LetsGoBtn2 onClick={()=>{
          if(userData?.id){
            console.log('test')
          }else{
            dispatch(setIsOpenAuthModal(true))
          }
        }
        }/>
            
          </Stack>

          <CardCarousel
            autoplayWay="next"
            setCurrent={setCurrent}
            photo={[DefaultIMG]}
          />
        </Stack> */}
        <StyledTitle
          maxWidth={800}
          sx={{ lineHeight: '58px !important' }}
          className="bottomBlock-title"
        >
          Отримуй всі переваги від користування BONFAIR
        </StyledTitle>
        <div className="topBlock-main-carousel-wrap" style={{ width: '1440px' }}>
          <Slider {...settings}>
            {cards.map((el, index) => {
              return (
                <div key={index} style={{ display: 'flex', flexDirection: 'row', height: '495px' }}>
                  <div
                    style={{ cursor: 'pointer' }}
                    className="slideStyle"
                    // onMouseEnter={() => {
                    //   handleOpenDesc(el.desc, screenType === 'mobile' && index + 1, index);
                    // }}
                    // onMouseLeave={handleCloseDesc}
                  >
                    <img
                      style={{
                        minWidth: '60px',
                        width: '260px',
                        height: '260px',
                        marginLeft: '20px',
                        marginRight: '20px'
                      }}
                      src={el.vector}
                      alt="vector"
                    />

                    <Typography
                      component="span"
                      fontSize={24}
                      fontWeight={700}
                      textAlign="center"
                      sx={{ maxWidth: '300px' }}
                      variant="body1"
                    >
                      {el.title}
                    </Typography>
                  </div>
                </div>
              );
            })}
          </Slider>
        </div>
      </Box>
    </Grid>
  );
}
