import React from 'react';

//Material
import { Grid } from '@mui/material';

//Components

import { Stack } from '@mui/system';
import BannerMain from '../../../components/Banners/BannerMain';


export default function TariffsBlock() {


  return (
    <Grid item xs={12} sx={{ mb: 5, p: '0px 100px' }}>
      <Stack spacing={1} sx={{display:'flex', flexDirection:'row'}}>
       
       <BannerMain/>
      </Stack>
    </Grid>
  );
}
