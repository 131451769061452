import React from 'react';

//Material
import CloseIcon from '@mui/icons-material/Close';

import { Box, Grid, Modal, Typography } from '@mui/material';

import { connect, useDispatch } from 'react-redux';
import { LetsGoBtn3 } from '../../components/Buttons';
import { StyledLabel } from '../../components/StyledComponents';
import { setIsOpenSupportModal } from '../../redux/mainReducer';

import starBlue from '../../assets/StarBlue.png';
import starGreen from '../../assets/StarGreen.png';

import { useNavigate } from 'react-router-dom';
import './CancelModal.css';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',

  maxWidth: 900,
  bgcolor: 'background.paper',
  borderRadius: '20px',
  border: 'none',
  boxShadow: 24,
  padding: '50px 80px 50px 80px',
  overflow: 'scroll',
  height: 'inherit',
  maxHeight: '100vh',
  scrollbarWidth: 'none',
  '&::-webkit-scrollbar': {
    display: 'none'
  }
};

function SendSupportModal({ isOpenModal }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <div>
      <Modal
        open={isOpenModal}
        onClose={(event, reason) => {
          if (reason === 'backdropClick') {
            return;
          }

          dispatch(setIsOpenSupportModal(false));
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="bonfair_cancel_modal"
      >
        <Box sx={style} className="bonfair_cancel_modal-box">
          <img
            style={{ position: 'fixed', zIndex: '0', top: '0', left: '0' }}
            src={starGreen}
            alt="star-green"
          />
          <img
            style={{ position: 'fixed', zIndex: '0', bottom: '40px', right: '0' }}
            src={starBlue}
            alt="star-green"
          />
          <CloseIcon
            className="bonfair_cancel_modal-close"
            style={{ position: 'absolute', right: '10px', top: '10px', cursor: 'pointer' }}
            onClick={() => {
              dispatch(setIsOpenSupportModal(false));
            }}
          />

          <Grid container spacing={2}>
            <Grid item xs={12} md={12} lg={12}>
              <StyledLabel className="cancelModal-title-text">
                {/* {translate('bonfair_service_modal')} */}
                {'Запит надіслано!'}
              </StyledLabel>
            </Grid>
            <Grid item xs={12} md={12} lg={12} sx={{ display: 'flex', justifyContent: 'center' }}>
              <Typography
                variant="subtitle2"
                sx={{
                  mb: 2,
                  fontSize: 16,
                  fontFamily: 'Kyiv-Sans-Bold',
                  textAlign: 'center',
                  maxWidth: '70%'
                }}
              >
                {`Наша команда підтримки обробить його
 та зв’яжеться з вами.`}
              </Typography>
            </Grid>
            <Grid item xs={12} md={12} lg={12} sx={{ display: 'flex', justifyContent: 'center' }}>
              <Typography
                variant="subtitle2"
                sx={{
                  mb: 2,
                  fontSize: 16,
                  fontFamily: 'Kyiv-Sans-Bold',
                  textAlign: 'center',
                  maxWidth: '70%'
                }}
              >
                {`Дякуємо за зворотній зв’язок!`}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                margin: '20px auto 0px',
                maxWidth: '257px'
              }}
            >
              <LetsGoBtn3
                margin={'0 auto'}
                width={257}
                onClick={() => {
                  dispatch(setIsOpenSupportModal(false));
                  navigate('/', { replace: true });
                }}
                text="Накивати п’ятами"
              />
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    language: state.Intl.locale
  };
};
export default connect(mapStateToProps)(SendSupportModal);
