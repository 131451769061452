import PropTypes from 'prop-types';

// material
// import { alpha, styled } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import { Box, Toolbar } from '@mui/material';

// components

//
import './Footer.css';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

// ----------------------------------------------------------------------

const APPBAR_MOBILE = 84;
const APPBAR_DESKTOP = 230;

const RootStyle = styled('footer')(({ theme }) => ({
  boxShadow: 'none',
  // position: 'absolute',
  bottom: '0',
  // backdropFilter: 'blur(6px)',
  // WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  // backgroundColor: alpha(theme.palette.background.paper, 1),
  backgroundColor: 'transparent',
  width: '100%',
  padding: '0px 100px'

  // background:
  //   'linear-gradient(0deg, rgba(255,255,255,0.2987570028011205) 0%, rgba(9,121,13,0.5032387955182073) 50%, rgba(255,255,255,0.3) 100%);'
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up('xs')]: {
    alignItems: 'start',
    minHeight: APPBAR_MOBILE,
    padding: 0
  },
  [theme.breakpoints.up('md')]: {
    minHeight: APPBAR_DESKTOP,
    alignItems: 'center',
    padding: 0
  },
  [theme.breakpoints.up('lg')]: {
    minHeight: APPBAR_DESKTOP,
    alignItems: 'center',
    padding: 0
  }
}));

// ----------------------------------------------------------------------

Footer.propTypes = {
  onOpenSidebar: PropTypes.func
};

export default function Footer() {
  const navigate = useNavigate();
  const language = useSelector((state) => state.Intl.locale);
  const userData = useSelector((state) => state.global.userData);
  return (
    <RootStyle>
      <div className="visit-card-footer-bg">
        <ToolbarStyle>
          <div
            className="footer-logo"
            onClick={() => {
              navigate(`/`);
            }}
          ></div>
          <div className="footer-address-container">
            <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'end' }}>
              {!userData?.id && !window.location.href.includes('support') && (
                <a
                  className="footer-address-text"
                  href={`/${language}/support`}
                  rel="noreferrer"
                  style={{ fontSize: '16px' }}
                >
                  Підтримка
                </a>
              )}
              {/* {!userData?.id && !window.location.href.includes('tariffs') && (
                <a
                  className="footer-address-text"
                  href={`/${language}/tariffs`}
                  rel="noreferrer"
                  style={{ fontSize: '16px' }}
                >
                  Тарифи
                </a>
              )} */}
              <a
                className="footer-address-text"
                href={`/${language}/agreement`}
                rel="noreferrer"
                style={{ fontSize: '16px' }}
              >
                Користувальницька угода
              </a>
            </Box>
          </div>
        </ToolbarStyle>
      </div>
    </RootStyle>
  );
}
