import React, { useEffect, useState } from 'react';

//Material
import BookmarkIcon from '@mui/icons-material/Bookmark';
import BookmarkBorderOutlinedIcon from '@mui/icons-material/BookmarkBorderOutlined';
import GridViewIcon from '@mui/icons-material/GridView';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import { Avatar, Grid, Paper, Skeleton, Stack, Typography } from '@mui/material';
// import { Stack } from '@mui/system';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import basePhoto from '../assets/DefaultIMG.png';
import DefaultAvatar from '../assets/defaultPostImg.jpg';
import ApiService from '../helpers/apiHelper';
import { dateFormattingByDate } from '../helpers/date-helpers';
import '../pages/MainPage/index.css';
import {
  setIsOpenAuthModal,
  setIsOpenResendingModal,
  setUserSavedServices
} from '../redux/mainReducer';
import { LetsGoBtn } from './Buttons';
// import  ImageGallery  from 'react-image-gallery';

// const useStyles = makeStyles({

//   button: {
//     '&:hover': {
//       boxShadow: '-5px 5px 3px black !important',
// width: '155px !important'
//   },
// }})
function ServiceRibbonCard({
  index,
  el,
  noWidth = false,
  maxHeight = 'unset',
  language,
  blockedUsers,
  usersWhoBlocked,

  ...props
}) {
  const apiSavedService = new ApiService('posts/saved-service');
  const apiPhoto = new ApiService('photo/get-photo');
  // const apiSavedService = new ApiService('saved-service');
  // const apiPhoto = new ApiService('get-photo');

  // const classes = useStyles()
  const [photos, setPhotos] = useState([]);
  const [isLoadingPhotos, setIsLoadingPhotos] = useState(false);
  const userData = useSelector((state) => state.global.userData);
  const userSavedServices = useSelector((state) => state.global.userSavedServices);
  const dispatch = useDispatch();
  const isHoverSaved = window.location.href.includes('saved');
  const isHoverSearch =
    window.location.href.includes('search') || window.location.href.includes('ribbon');
  const isHoverMy = window.location.href.includes('user-page');
  const navigate = useNavigate();


  useEffect(() => {
    let mounted = true;

    if (el?.encodedPhotos && el?.encodedPhotos.length !== 0 && mounted) {
      setIsLoadingPhotos(true);
      apiPhoto
        .getItemById(el.encodedPhotos[0])
        .then((res) => {
          let newPhotos=[]
          newPhotos.push(res.result)
          setIsLoadingPhotos(false);
          setPhotos(newPhotos);
        })
        .catch((error) => {console.error(error)
        });
      return () => (mounted = false);
    }
    // eslint-disable-next-line
  }, []);
  return (
    <Paper
      {...props}
      key={index}
      className="serviceCard"
      sx={{
        width: '100%',
        maxWidth:'809px',
        margin: '0px',
        textAlign: 'center',
        overflow: 'hidden',
        alignItems: 'center',
        backgroundColor: 'white',
        borderRadius: '5px',
        padding: '30px ',
        border: '1px solid black !important',

        '&:hover':
          isHoverMy || isHoverSearch || isHoverSaved
            ? {}
            : { boxShadow: '-10px 10px 3px black', transform: 'scale(1.025)', transition: 'all 1s' }
      }}
    >
      <Grid
        container
        spacing={0}
        sx={{
          width: '100%',
          margin: '0px !important'
        }}
      >
        <Grid item xs={5} style={{ display: 'flex', flexDirection: 'column' }}>
          <div
            style={{
              borderRadius: '5px',
              border: '1px solid black !important',
              overflow: 'hidden'
            }}
          >
            {el !== null &&
              el &&
              el?.encodedPhotos &&
              el?.encodedPhotos.length !== 0 &&
              el?.encodedPhotos.length === 1&&photos.length!==0&&!isLoadingPhotos ?
              photos.map(
                (photo, index) =>
                  index === 0 && (
                    <img
                      key={`photo${index}`}
                      src={`data:${photo.contentType};base64, ${photo.encodedContent}`}
                      alt={photo.nameFile}
                      style={
                        maxHeight !== 'unset'
                          ? {
                              margin: '0px auto',
                              objectFit: 'contain',
                              maxHeight: '240px',
                              minHeight: '240px'
                            }
                          : {
                              margin: '0px auto',
                              objectFit: 'contain',
                              maxHeight: '320px',
                              minHeight: '320px'
                            }
                      }
                    />
                  )
              ):null}
              {isLoadingPhotos&&<Skeleton sx={{ height: 320 }} animation="wave" variant="rectangular" />}
              {(el !== null && el && !el?.encodedPhotos&&!isLoadingPhotos) ||
            (el !== null && el && el?.encodedPhotos.length === 0&&!isLoadingPhotos) ? (
              <img
                src={basePhoto}
                alt="defaultImg"
                style={{
                  margin: '0px auto',
                  objectFit: 'contain',
                  maxHeight: '320px',
                  minHeight: '320px'
                }}
              />
            ) : null}
            {/* {el !== null &&
              el &&
              el?.encodedPhotos &&
              el?.encodedPhotos.length !== 0 &&
              el?.encodedPhotos.length > 1 &&
              el?.encodedPhotos.map(
                (photo, index) =>
                  index === 0 && (
                    <img
                      key={`photo${index}`}
                      src={`data:${photo.contentType};base64, ${photo.encodedContent}`}
                      alt={photo.nameFile}
                      style={
                        maxHeight !== 'unset'
                          ? {
                              margin: '0px auto',
                              objectFit: 'contain',
                              maxHeight: '240px',
                              minHeight: '240px'
                            }
                          : {
                              margin: '0px auto',
                              objectFit: 'contain',
                              maxHeight: '320px',
                              minHeight: '320px'
                            }
                      }
                    />
                  )
              )} */}
            
          </div>
        </Grid>
        <Grid
          item
          xs={7}
          sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', pl: '20px' }}
        >
          <Typography sx={{ color: '#868686', fontSize: '12px' }} variant="subtitle">
            {dateFormattingByDate(new Date(el.creationTimeForService))}
          </Typography>
          <Typography
            variant="h5"
            style={{ fontSize: '20px', margin: '0px 10px 10px 0px', wordBreak: 'break-word' }}
          >
            {el.nameService}
          </Typography>
          <Typography
            variant="subtitle"
            component={'p'}
            style={{
              fontSize: '16px',
              margin: '0px 10px 10px 0px',
              wordBreak: 'break-word',
              display: 'flex',
              alignItems: 'center'
            }}
          >
            <GridViewIcon
              sx={{
                fontSize: '20px',
                color: '#60A5DF',
                marginRight: '8px',
                marginBottom: '2px'
              }}
            />
            {el.category}
          </Typography>
          <Typography
            variant="h3"
            sx={{
              margin: '0px 0px 30px 0px ',
              fontFamily: 'Kyiv-Sans-Regular',
              color: '#60A5DF',
              wordBreak: 'break-all !important'
            }}
          >
            {`${el.price} ${el.currency}`}
          </Typography>
          <Typography
            variant="subtitle"
            component={'p'}
            sx={{
              fontSize: '14px',
              wordBreak: 'break-word',
              display: '-webkit-box',
              WebkitLineClamp: 3,
              WebkitBoxOrient: 'vertical',
              overflowY: 'hidden',
              textOverflow: 'ellipsis',
              width: '440px',
              textAlign: 'left'
            }}
          >
            {`${el.description} `}
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{ borderTop: '1px solid #60a5df', mt: '20px', pt: '20px' }}>
          <Grid container>
            <Grid item xs={1}>
              <Avatar
                onClick={() => {
                  navigate(`/${language}/user-page/${el?.userRibbonDTO?.id}?type=posts`);
                }}
                src={
                  el?.userRibbonDTO?.encodedMediaDTO
                    ? `data:${el?.userRibbonDTO?.encodedMediaDTO.contentType};base64, ${el?.userRibbonDTO?.encodedMediaDTO.encodedContent}`
                    : DefaultAvatar
                }
                sx={{
                  width: 50,
                  height: 50,
                  border: '1px solid #60A5DF',
                  cursor: 'pointer',
                  boxShadow: blockedUsers.find(
                    (user) => user.id === el?.userRibbonDTO?.id
                  )
                    ? '0px 0px 2px 2px rgba(255,0,0,1)'
                    : 'none',
                }}
              />
            </Grid>
            <Grid item xs={3}>
              <Stack sx={{ width: '100%' }}>
                {el?.userRibbonDTO?.name&&el?.userRibbonDTO.surname&&<Typography
                  variant="subtitle"
                  sx={{
                    fontFamily: 'Public Sans,sans-serif !important',
                    fontSize: '18px',
                    textAlign: 'left',
                    cursor: 'pointer'
                  }}
                  onClick={() => {
                    navigate(`/${language}/user-page/${el?.userRibbonDTO?.id}?type=posts`);
                  }}
                  // src={
                  //   user?.encodedMediaDTO
                  //     ? `data:${user?.encodedMediaDTO.contentType};base64, ${user?.encodedMediaDTO.encodedContent}`
                  //     : DefaultAvatar
                  // }
                >
                  {`${el?.userRibbonDTO?.name} ${el?.userRibbonDTO.surname}`}
                </Typography>}
                
                <Typography
                  variant="subtitle"
                  sx={{
                    fontFamily: 'Public Sans,sans-serif !important',
                    fontSize: el?.userRibbonDTO?.name&&el?.userRibbonDTO.surname?'14px':'18px',
                    textAlign: 'left',
                    cursor: 'pointer'
                  }}
                  onClick={() => {
                    navigate(`/${language}/user-page/${el?.userRibbonDTO.id}?type=posts`);
                  }}
                >
                  {`@${el?.userRibbonDTO?.nickname}`}
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={5} sx={{ display: 'flex', justifyContent: 'flex-end', pr: '20px' }}>
              <Stack direction="row" spacing={2} sx={{ alignItems: 'center' }}>
                {userData &&
                userSavedServices &&
                userSavedServices.length !== 0 &&
                userSavedServices.includes(el.id) ? (
                  <BookmarkIcon
                    onClick={() => {
                      apiSavedService.deleteItemQuery({ postId: el.id }).then((res) => {
                        // dataSetter();
                        const newArray = userSavedServices.filter((id) => el.id !== id);
                        dispatch(setUserSavedServices([...newArray]));
                      });
                    }}
                    sx={{
                      color: 'rgb(239, 201, 56)',
                      marginTop:'5px !important',
                      ':hover': {
                        color: 'rgb(239, 201, 56)',
                        cursor: 'pointer'
                      }
                    }}
                  />
                ) : (
                  <BookmarkBorderOutlinedIcon
                    onClick={() => {
                      apiSavedService.updateDataQueryPost({ serviceId: el.id }).then((res) => {
                        // dataSetter();
                        dispatch(setUserSavedServices([...userSavedServices, el.id]));
                      });
                    }}
                    sx={{
                      marginTop:'5px !important',
                      ':hover': {
                        color: 'rgb(239, 201, 56)',
                        cursor: 'pointer'
                      }
                    }}
                  />
                )}

                <SendOutlinedIcon
                  onClick={() => {
                    if (userData?.id) {
                      dispatch(
                        setIsOpenResendingModal({ isOpen: true, id: el.id, type: 'service' })
                      );
                    } else {
                      dispatch(setIsOpenAuthModal(true));
                    }
                  }}
                  sx={{
                    ':hover': {
                      color: 'rgb(96, 165, 223)',
                      cursor: 'pointer'
                    },
                    transform: 'rotate(315deg)',
                    width: '23px',
                    height: '22px',
                  }}
                />
              </Stack>
            </Grid>
            <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              {' '}
              <LetsGoBtn
                className="service-card-btn"
                width="200px"
                text="Детальніше"
                onClick={() => {
                  // navigate(`/${language}/service/${el.id}`)
                  if (userData?.id) {
                    window.open(`${window.location.origin}/${language}/service/${el.id}`);
                    window.scrollTo({ top: 0 });
                  } else {
                    dispatch(setIsOpenAuthModal(true));
                  }
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
}
const mapStateToProps = (state) => {
  return {
    language: state.Intl.locale,
    blockedUsers:state.global.blockedUsers,
    usersWhoBlocked: state.global.usersWhoBlocked
  };
};
export default connect(mapStateToProps)(ServiceRibbonCard);
