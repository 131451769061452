import Banner0 from './Banner0';
import Banner1 from './Banner1';
import Banner2 from './Banner2';
import Banner3 from './Banner3';
import Banner4 from './Banner4';
import Banner5 from './Banner5';

export const renderBanner = (type) => {
  switch (type) {
    case 0:
      return <Banner0 />;
    case 1:
      return <Banner1 />;
    case 2:
      return <Banner2 />;
    case 3:
      return <Banner3 />;
    case 4:
      return <Banner4 />;
    case 5:
      return <Banner5 />;
    default:
      return;
  }
};
