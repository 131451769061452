import React, { useEffect, useRef, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';

//Components MUi
import AddIcon from '@mui/icons-material/Add';

import AddAPhotoOutlinedIcon from '@mui/icons-material/AddAPhotoOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import EastIcon from '@mui/icons-material/East';
import EmojiEmotionsOutlinedIcon from '@mui/icons-material/EmojiEmotionsOutlined';
import ReplyIcon from '@mui/icons-material/Reply';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import StarIcon from '@mui/icons-material/Star';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import { TabContext, TabPanel } from '@mui/lab';
import {
  Box,
  Grid,
  LinearProgress,
  Stack,
  Tab,
  TextField,
  Typography,
  styled
} from '@mui/material';
import { Spinner } from '../../components/Spinner';
import { StyledSubTabs } from '../../components/SubTabs.styles';
// Additional components
import { LetsGoBtn2, LetsGoBtn3 } from '../../components/Buttons';
import Page from '../../components/Page';
import { StyledLabel } from '../../components/StyledComponents';
//Chat components
import ChatMessage from '../../components/ChatMessage';
import UserListCardChat from '../../components/UserListCardChat';
import UserListCardChatTop from '../../components/UserListCardChatTop';
//API
import ApiService from '../../helpers/apiHelper';
//REDUX
import { useSearchParams } from 'react-router-dom';
import { timeDiffMessagesDay } from '../../helpers/date-helpers';
import {
  setIsNewMessage,
  setIsOpenAuthModal,
  setIsOpenImageCropModal,
  setIsOpenResendingModal,
  setIsOpenUserContactsModal,
  setResendingText,
  setSocketMsg,
  setStompClient
  // setUsersForResending
} from '../../redux/mainReducer';
import { base } from '../../redux/thunk';
//assets
import EmojiPicker from 'emoji-picker-react';
import NewMessageAudio from '../../assets/newMessage.wav';
import CropModal from '../modals/CropModal';
import FullscreenPicture from '../modals/FullscreenPicture';
import UserContactsModal from '../modals/UserContactsModal';
import './index.css';

// import { useSelector } from 'react-redux';
const CssTextField = styled(TextField)({
  '& label.Mui-focused': {
    color: '#000000'
  },
  '& label': {
    color: '#A1A1A1',
    fontFamily: 'Kyiv-Sans-Light'
  },
  fontFamily: 'Kyiv-Sans-Regular !important',
  margin: '5px 0px'
});
function MessagesPage({
  language,
  userData,
  stompClient,
  getUserDataThunk,
  blockedUsers,
  usersWhoBlocked,
  ...props
}) {
  const [params] = useSearchParams();
  const [isLoadingMessages, setIsLoadingMessages] = useState(false);
  const [isLoadingFreshMessages, setIsLoadingFreshMessages] = useState(false);
  const [isShowEmoji, setIsShowEmoji] = useState(false);

  const [messagesPage, setMessagesPage] = useState(0);
  // eslint-disable-next-line
  const [messagesCount, setMessagescount] = useState(20);
  const [showedImg, setShowedImg] = useState([]);
  const [sliderStartIdx, setSliderStartIdx] = useState(0);
  const [imgs, setImgs] = useState([]);
  const [newImgs, setNewImgs] = useState([]);
  const [imgsToDelete, setImgsToDelete] = useState([]);
  const [file, setFile] = useState(null);
  const [isSendingPic, setIsSendingPic] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [tabIdx, setTabIdx] = useState('1');
  const [text, setText] = useState('');
  const [messageSelected, setMessageSelected] = useState('');
  const [msgToScroll, setMsgToScroll] = useState('');
  const [chats, setChats] = useState([]);
  const [favoriteChats, setFavoriteChats] = useState([]);

  const [selectedChat, setSelectedChat] = useState(null);
  const [selectedChatPrev, setSelectedChatPrev] = useState(null);
  const [selectedChatMessages, setSelectedChatMessages] = useState([]);
  const [selectedChatMessagesCount, setSelectedChatMessagesCount] = useState(0);
  const messagesRef = useRef(null);
  const [isEditMessage, setIsEditMessage] = useState(false);
  const [isReplyMessage, setIsReplyMessage] = useState(false);
  const [isResendMessage, setIsResendMessage] = useState(false);
  const [usersForResending, setUsersForResending] = useState([]);
  // const [userSubscriptions, setUserSubscriptions] = useState([]);
  const dispatch = useDispatch();

  const userSubscriptions = useSelector((state) => state.global.userSubscriptions);
  const isOpenUserContactsModal = useSelector((state) => state.global.isOpenUserContactsModal);
  const isOpenImageCropModal = useSelector((state) => state.global.isOpenImageCropModal);
  const isFullscreenPic = useSelector((state) => state.global.isFullscreenPic);
  const socketMsg = useSelector((state) => state.global.socketMsg);
  const socketInfoMsg = useSelector((state) => state.global.socketInfoMsg);
  // const usersForResending = useSelector((state) => state.global.usersForResending);
  const resendingText = useSelector((state) => state.global.resendingText);
  const stompSubscribeRef = useRef();

  const apiChats = new ApiService('notification/chats');
  const apiChatsMessages = new ApiService('notification/messages');
  const apiMessages = new ApiService('notification/messages');
  const apiMessagesUpdate = new ApiService('notification/messages/update');

  // const apiChats = new ApiService('chats');
  // const apiChatsMessages = new ApiService('messages');
  // const apiMessages = new ApiService('messages');
  // const apiMessagesUpdate = new ApiService('/messages/update');

  function playNewMessage() {
    var sound = document.getElementById('audio');
    sound.currentTime = 0;
    sound.muted = false;
    sound.play();
  }
  const replaceArrayElement = (elemId) => {
    const arr = chats;
    const elem = chats.find((el) => el.chatId === elemId);
    const fromIndex = arr.indexOf(elem);
    const toIndex = 0;
    const element = arr.splice(fromIndex, 1)[0];
    arr.splice(toIndex, 0, element);
    setChats(arr);
  };
  const StyledTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
    textTransform: 'none'
  }));
  function getFileFromBase64(string64, fileName) {
    const trimmedString = string64.replace('dataimage/jpegbase64', '');
    const imageContent = atob(trimmedString);
    const buffer = new ArrayBuffer(imageContent.length);
    const view = new Uint8Array(buffer);

    for (let n = 0; n < imageContent.length; n++) {
      view[n] = imageContent.charCodeAt(n);
    }
    const type = 'image/jpeg';
    const blob = new Blob([buffer], { type });
    return new File([blob], fileName, { lastModified: new Date().getTime(), type });
  }

  const getSelectedChatMessages = (selectedChat, page = null) => {
    // if (!selectedChatPrev) {
    //   console.log('SEND');
    //   stompClient.publish({
    //     destination: '/queue/chat-info-message',
    //     body: JSON.stringify({
    //       chatId: selectedChat.chatId,
    //       serviceMessageRecipientId: selectedChat.userDTO.id
    //     }),
    //     headers: {
    //       Authorization: localStorage.getItem('accessToken')
    //     }
    //   });
    // }
    apiChatsMessages
      .getAll({
        chatId: selectedChat?.chatId,
        page: page !== null ? page : messagesPage,
        size: messagesCount
      })
      .then((messages) => {
        setSelectedChatMessages([...messages.result.reverse()]);
        setSelectedChatMessagesCount(messages.count);
        setIsLoadingMessages(false);
        setIsLoadingFreshMessages(false);
        if (page !== null) {
          setMessagesPage(0);
        }
        if (
          selectedChat?.lastMessage &&
          selectedChat?.lastMessage.senderId !== userData.id &&
          !selectedChat.lastMessage.messageIsRead
        ) {
          const newChatData = selectedChat;
          newChatData.lastMessage = { ...selectedChat.lastMessage, messageIsRead: true };
        }
        if (
          !selectedChatPrev &&
          selectedChat?.lastMessage &&
          selectedChat.lastMessage.senderId !== userData.id
        ) {
          stompClient.publish({
            destination: '/queue/chat-info-message',
            body: JSON.stringify({
              chatId: selectedChat.chatId,
              serviceMessageRecipientId: selectedChat.userInfoDTO.id,
              senderId: userData.id
            }),
            headers: {
              Authorization: localStorage.getItem('accessToken')
            }
          });
          setSelectedChatPrev(selectedChat);
        }
      })
      .catch((error) => console.error(error));
  };
  const getChats = async (isNewChat = false) => {
    if (!isNewChat) {
      setIsLoading(true);
    }
    await apiChats
      .getAll()
      .then((chats) => {
        if (isNewChat) {
          setChats([...chats.result]);
          const unreadedChat = chats.result.find(
            (chat) =>
              chat?.lastMessage &&
              !chat?.lastMessage.messageIsRead &&
              chat?.lastMessage?.senderId !== userData?.id
          );
          if (!unreadedChat) {
            dispatch(setIsNewMessage(false));
          }
        } else {
          setChats(chats.result);
          const unreadedChat = chats.result.find(
            (chat) =>
              chat?.lastMessage &&
              !chat?.lastMessage.messageIsRead &&
              chat?.lastMessage?.senderId !== userData?.id
          );
          if (!unreadedChat) {
            dispatch(setIsNewMessage(false));
          }
        }

        // setChats([
        //   ...chats.result,
        //   {
        //     chatId: '438dd95b-fec4-479b-a914-481e7cfaf487',
        //     isImportantForUser: false,
        //     lastTimeMessage: '2023-08-30T10:50:23.773901Z',
        //     userDTO: {
        //       id: '1c2a8293-fcca-4a56-849c-1700b5bd69c0',
        //       name: 'Andy',
        //       surname: 'Andyvich',
        //       nickname: 'gapasa7198@quipas.com',
        //       email: 'gapasa7198@quipas.com',
        //       city: 'Київ',
        //       country: 'Україна',
        //       description: '',
        //       number: null,
        //       encodedMediaDTO: {
        //         id: null,
        //         nameFile: '123.jpeg',
        //         originNameFile: null,
        //         contentType: null,
        //         encodedContent: null
        //       }
        //     },
        //     lastMessage: null
        //   }
        // ]);
        setIsLoading(false);
        setFavoriteChats(chats.result.filter((chat) => chat.isImportantForUser));
        if (chats.result.length !== 0) {
          if (isResendMessage) {
            if (stompClient.connected) {
              // setTimeout(() => {
              stompClient.publish({
                destination: '/queue/chat-message',
                body: JSON.stringify({
                  senderId: userData.id,
                  chatIds:
                    usersForResending.length !== 0 ? usersForResending : [chats.result[0].chatId],
                  appliedTo: messageSelected,
                  typeMessage: 'text',
                  includeId: '',
                  text: resendingText
                }),
                headers: {
                  Authorization: localStorage.getItem('accessToken')
                }
              });
              setText('');
              dispatch(setResendingText(''));
              setIsResendMessage(false);
              // dispatch(setUsersForResending([]));
              setUsersForResending([]);
              // }, 2000);
            }
          }
          if (usersForResending.length !== 0) {
            setSelectedChat(chats.result.find((chat) => chat.chatId === usersForResending[0]));
          } else if (params.get('chatId')) {
            setSelectedChat(chats.result.find((chat) => chat.chatId === params.get('chatId')));
            window.history.replaceState(null, 'Листування', `/${language}/messages`);
          } else if (isNewChat && selectedChat) {
            return;
          } else {
            if (!isNewChat) {
              setSelectedChat(chats.result[0]);
              if (stompClient.connected) {
                stompClient.publish({
                  destination: '/queue/chat-info-message',
                  body: JSON.stringify({
                    chatId: chats.result[0].chatId,
                    serviceMessageRecipientId: chats.result[0].userInfoDTO.id,
                    senderId: userData.id
                  }),
                  headers: {
                    Authorization: localStorage.getItem('accessToken')
                  }
                });
              }
            }
          }

          setText('');
        }
      })
      .catch((error) => console.error(error));
  };

  const handleChangeTab = (event, newValue) => {
    setTabIdx(newValue);
    setMessagesPage(0);
    setIsLoadingMessages(true);
    if (newValue === '2') {
      setSelectedChat(favoriteChats[0] ? favoriteChats[0] : null);
    } else {
      setSelectedChat(chats[0] ? chats[0] : null);
    }
  };

  // const getUserSubscriptions = () => {
  //   apiSubscriptions.getAll({ userId: userData.id }).then((res) => {
  //     setUserSubscriptions(res.result);
  //   });
  // };

  //UPDATE CHATS LIST LAST MESSAGE

  const updateChatsList = (message, isService = false, isNewChat = false) => {
    if (!isNewChat) {
      const newChats = chats;
      if (message && !isService) {
        newChats.forEach((chat) => {
          if (chat.chatId === message.chatId) {
            chat.lastMessage = message;
          }
        });
      }
      if (isService) {
        newChats.forEach((chat) => {
          if (chat.chatId === message.chatId) {
            chat.lastMessage = { ...chat.lastMessage, messageIsRead: true };
          }
        });
      }

      setChats([...newChats]);
    } else {
      getChats(true);
    }
  };

  //SELECTED CHAT READ MY NESSAGES

  const recipientReader = (message) => {
    // const newChatData = selectedChat;
    // newChatData.lastMessage = { ...newChatData.lastMessage, messageIsRead: true };
    const newMessages = [...selectedChatMessages.map((msg) => ({ ...msg, messageIsRead: true }))];
    // setSelectedChat({ ...newChatData });

    setSelectedChatMessages([...newMessages]);
    updateChatsList(message, true);
  };
  // ADD MESSAGES TO SELECTED CHAT
  const updateSelectedChat = (message, isDelete) => {
    if (message) {
      if (message?.senderId && message?.senderId === userData.id && !message?.temp) {
        updateChatsList(message);
        // setSelectedChat({ ...selectedChat, lastMessage: message });
        const filtered = selectedChatMessages.filter((message) => message?.id);
        setSelectedChatMessages([...filtered, message]);
      }
      if (message?.senderId && message?.senderId === userData.id && message?.temp) {
        updateChatsList(message);
        // setSelectedChat({ ...selectedChat, lastMessage: message });
      }
      if (message?.senderId && message?.senderId !== userData.id) {
        updateChatsList({ ...message, messageIsRead: true });
        // setSelectedChat({ ...selectedChat, lastMessage: { ...message, messageIsRead: true } });
        const filtered = selectedChatMessages.filter((message) => message?.id);
        setSelectedChatMessages([...filtered, message]);
        stompClient.publish({
          destination: '/queue/chat-info-message',
          body: JSON.stringify({
            chatId: selectedChat.chatId,
            serviceMessageRecipientId: selectedChat.userInfoDTO.id,
            senderId: userData.id
          }),
          headers: {
            Authorization: localStorage.getItem('accessToken')
          }
        });
      }
    }

    if (isDelete) {
      const newChatData = selectedChat;
      newChatData.lastMessage = selectedChatMessages[selectedChatMessages.length - 2];
      // setSelectedChat({ ...newChatData });
      updateChatsList(selectedChatMessages[selectedChatMessages.length - 2]);
    }
  };
  const toBase64 = (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.addEventListener('load', () => resolve(reader.result), false);
      reader.readAsDataURL(file);
    });
  };
  // console.log(selectedChatMessages);
  // const secondTry = async(message)=>{
  //   if (stompClient.connected) {
  //     stompClient.publish({
  //       destination: '/queue/chat-message',
  //       body: JSON.stringify({
  //         senderId: message.senderId,
  //         chatIds: [selectedChat.chatId],
  //         appliedTo: '',
  //         typeMessage: message.type,
  //         includeId: '',
  //         photos: message?.photos.length!==0?message?.photos.map(photo=>photo.encodedContent):null
  //          ,
  //         text: message
  //       }),
  //       headers: {
  //         Authorization: localStorage.getItem('accessToken')
  //       }
  //     });
  //     setIsSendingPic(false);
  //     setImgs([]);
  //     setText('');
  //   } else {
  //     dispatch(setStompClient(null));
  //   }
  // }
  const sendMessage = async () => {
    if (stompClient.connected) {
      stompClient.publish({
        destination: '/queue/chat-message',
        // destination: '/queue/time',
        body: JSON.stringify({
          senderId: userData.id,
          chatIds: [selectedChat.chatId],
          appliedTo: '',
          typeMessage: imgs.length > 0 ? 'photo' : 'text',
          includeId: '',
          photos:
            imgs.length > 0
              ? await Promise.all([...imgs.map((file) => toBase64(file))]).then((result) => {
                  const newResult = result.map((string) => {
                    const coma = string.indexOf(',');
                    const newBase = string.substring(coma + 1);
                    return newBase;
                  });
                  return newResult;
                })
              : [],
          text: text
        }),
        headers: {
          Authorization: localStorage.getItem('accessToken')
        }
      });
      setIsSendingPic(false);
      setImgs([]);
      setText('');
      const messages = [...selectedChatMessages];
      messages.push({
        appliedTo: '',
        chatId: selectedChat.chatId,
        senderId: userData.id,
        text: text,
        type: imgs.length > 0 ? 'photo' : 'text',
        photos:
          imgs.length > 0
            ? await Promise.all([...imgs.map((file) => toBase64(file))]).then((result) => {
                const newResult = result.map((string) => {
                  const coma = string.indexOf(',');
                  const newBase = string.substring(coma + 1);
                  return newBase;
                });
                const newPhotos = newResult.map((result) => {
                  return {
                    nameFile: 'photo',
                    originNameFile: null,
                    contentType: null,
                    encodedContent: result
                  };
                });
                return newPhotos;
              })
            : [],
        messageIsRead: false,
        sendingTime: new Date().toISOString(),
        forwardedMessage: null,
        temp: true
      });
      updateSelectedChat({
        appliedTo: '',
        chatId: selectedChat.chatId,
        senderId: userData.id,
        text: text,
        type: imgs.length > 0 ? 'photo' : 'text',
        photos:
          imgs.length > 0
            ? await Promise.all([...imgs.map((file) => toBase64(file))]).then((result) => {
                const newResult = result.map((string) => {
                  const coma = string.indexOf(',');
                  const newBase = string.substring(coma + 1);
                  return newBase;
                });
                const newPhotos = newResult.map((result) => {
                  return {
                    nameFile: 'photo',
                    originNameFile: null,
                    contentType: null,
                    encodedContent: result
                  };
                });
                return newPhotos;
              })
            : [],
        messageIsRead: false,
        sendingTime: new Date().toISOString(),
        forwardedMessage: null,
        temp: true
      });
      // replaceArrayElement(selectedChat.chatId);
      setSelectedChatMessages(messages);
    } else {
      dispatch(setStompClient(null));
    }
  };

  const editMessage = async () => {
    const message = selectedChatMessages.find((message) => message.id === messageSelected);
    apiMessagesUpdate
      .updateData({
        senderId: userData.id,
        messageId: messageSelected,
        newText: text,
        typeMessage: newImgs.length !== 0 ? 'photo' : message.type,
        idsPhotoToDelete: imgsToDelete.length !== 0 ? imgsToDelete : [],
        newPhotos:
          newImgs.length !== 0
            ? await Promise.all([...newImgs.map((file) => toBase64(file))]).then((result) => {
                const newResult = result.map((string) => {
                  const coma = string.indexOf(',');
                  const newBase = string.substring(coma + 1);
                  return newBase;
                });
                return newResult;
              })
            : []
      })
      .then(async (res) => {
        if (message.photos) {
          let editedArray = [...message.photos];
          let newImgsArray = await Promise.all([...newImgs.map((file) => toBase64(file))]).then(
            (result) => {
              const newResult = result.map((string) => {
                const coma = string.indexOf(',');
                const newBase = string.substring(coma + 1);
                return { nameFile: 'newFile', encodedContent: newBase };
              });
              return newResult;
            }
          );

          if (imgsToDelete.length !== 0) {
            imgsToDelete.forEach((id) => {
              const INDEXOF = editedArray.find((img, index) => img.id === id && index);
              if (INDEXOF) {
                editedArray.splice(INDEXOF, 1);
              }
            });
          }

          const newSelectedChatMessages = selectedChatMessages.map((message) => {
            if (message.id === messageSelected) {
              return {
                ...message,
                photos: [...editedArray, ...newImgsArray],
                text: text
              };
            } else {
              return message;
            }
          });
          setSelectedChatMessages([...newSelectedChatMessages]);
        } else {
          const newSelectedChatMessages = selectedChatMessages.map((message) => {
            if (message.id === messageSelected) {
              return {
                ...message,

                text: text
              };
            } else {
              return message;
            }
          });
          setSelectedChatMessages([...newSelectedChatMessages]);
        }

        setText('');
        setMsgToScroll(messageSelected);
        setMessageSelected('');
        setIsEditMessage(false);
        setIsSendingPic(false);
        setImgsToDelete([]);
        setNewImgs([]);
        setImgs([]);
        // getSelectedChatMessages(selectedChat, 0);
      });
  };

  const replyOnMessage = async () => {
    if (stompClient.connected) {
      stompClient.publish({
        destination: '/queue/chat-message',
        // destination: '/queue/time',
        body: JSON.stringify({
          senderId: userData.id,
          chatIds: [selectedChat.chatId],
          appliedTo: messageSelected,
          typeMessage: 'text',
          includeId: '',
          text: text
        }),
        headers: {
          Authorization: localStorage.getItem('accessToken')
        }
      });
      setIsSendingPic(false);
      setImgs([]);
      setText('');
      setIsEditMessage(false);
      setIsReplyMessage(false);
      const messages = [...selectedChatMessages];
      messages.push({
        appliedTo: messageSelected,
        chatId: selectedChat.chatId,
        senderId: userData.id,
        text: text,
        type: imgs.length > 0 ? 'photo' : 'text',
        photos:
          imgs.length > 0
            ? await Promise.all([...imgs.map((file) => toBase64(file))]).then((result) => {
                const newResult = result.map((string) => {
                  const coma = string.indexOf(',');
                  const newBase = string.substring(coma + 1);
                  return newBase;
                });
                const newPhotos = newResult.map((result) => {
                  return {
                    nameFile: 'photo',
                    originNameFile: null,
                    contentType: null,
                    encodedContent: result
                  };
                });
                return newPhotos;
              })
            : [],
        messageIsRead: false,
        sendingTime: new Date().toISOString(),
        forwardedMessage: null,
        temp: true
      });
      updateSelectedChat({
        appliedTo: messageSelected,
        chatId: selectedChat.chatId,
        senderId: userData.id,
        text: text,
        type: imgs.length > 0 ? 'photo' : 'text',
        photos:
          imgs.length > 0
            ? await Promise.all([...imgs.map((file) => toBase64(file))]).then((result) => {
                const newResult = result.map((string) => {
                  const coma = string.indexOf(',');
                  const newBase = string.substring(coma + 1);
                  return newBase;
                });
                const newPhotos = newResult.map((result) => {
                  return {
                    nameFile: 'photo',
                    originNameFile: null,
                    contentType: null,
                    encodedContent: result
                  };
                });
                return newPhotos;
              })
            : [],
        messageIsRead: false,
        sendingTime: new Date().toISOString(),
        forwardedMessage: null,
        temp: true
      });
      setSelectedChatMessages(messages);
    } else {
      dispatch(setStompClient(null));
    }
  };

  const deleteMessage = () => {
    apiMessages.deleteItemQuery({ messageId: messageSelected }).then((res) => {
      getSelectedChatMessages(selectedChat);
      setMessageSelected('');
      updateSelectedChat(null, true);
    });
  };
  const deleteChat = () => {
    apiChats.deleteItemQuery({ chatId: selectedChat.chatId }).then((res) => {
      setSelectedChatMessages([]);
      setSelectedChat(null);
      getChats();
    });
  };

  const onMessageReceived = (message) => {
    if (selectedChat && message) {
      if (message?.message && message?.message.includes('Delete message with')) {
        updateChatsList(message, true);
      }
      if (
        message?.message !== 'Authorization failed' &&
        !message?.serviceMessageRecipientId &&
        !message?.message?.includes('Delete message with') &&
        message?.message !== 'New message' &&
        message?.message !== 'isNewMessage is false'
      ) {
        replaceArrayElement(message?.chatId);
      }
      if (message?.chatId && message?.chatId === selectedChat.chatId) {
        if (message?.serviceMessageRecipientId && message.serviceMessageRecipientId.length !== 0) {
          recipientReader(message);
        }
        if (!message?.serviceMessageRecipientId) {
          updateSelectedChat({
            ...message
            // sendingTime:
            //   typeof message.sendingTime === 'string'
            //     ? message.sendingTime
            //     : new Date(message.sendingTime * 1000).toISOString()
          });
        }
        if (
          message?.senderId !== userData.id &&
          !Object.keys(message).includes('serviceMessageRecipientId')
        ) {
          playNewMessage();
        }
      } else {
        if (message?.message && message?.message === 'Authorization failed') {
          if (userData) {
            // getUserDataThunk();
            const apiRefresh = new ApiService('auth/refresh');
            apiRefresh
              .refreshRequest({ refreshToken: localStorage.getItem('refreshToken') }, {})
              .then((res) => {
                localStorage.setItem('accessToken', `Bearer ${res.accessToken}`);
                localStorage.setItem('refreshToken', `${res.refreshToken}`);
                dispatch(setSocketMsg(null));
                dispatch(setStompClient(null));
              });
          } else {
            dispatch(setIsOpenAuthModal(true));
          }
        }
        if (message?.message !== 'Authorization failed') {
          if (!message?.serviceMessageRecipientId && message?.message !== 'isNewMessage is false') {
            updateChatsList(
              {
                ...message
                // sendingTime:
                //   typeof message.sendingTime === 'string'
                //     ? message.sendingTime
                //     : new Date(message.sendingTime * 1000).toISOString()
              },
              false,
              true
            );
          } else {
            updateChatsList(message, true);
          }
        }
      }
    } else {
      updateChatsList(
        {
          ...message
          // sendingTime:
          //   typeof message.sendingTime === 'string'
          //     ? message.sendingTime
          //     : new Date(message.sendingTime * 1000).toISOString()
        },
        false,
        true
      );
    }
  };

  const handleSend = () => {
    if (text.length !== 0) {
      if (isEditMessage && !isReplyMessage && !isResendMessage) {
        editMessage();
      } else if (isReplyMessage) {
        replyOnMessage();
      } else if (isResendMessage) {
        dispatch(
          setIsOpenResendingModal({
            isOpen: true,
            id: null,
            type: null
          })
        );
      } else {
        sendMessage();
      }
    }
    if (text.length === 0 && isSendingPic && !isEditMessage) {
      sendMessage();
    }
    if (text.length === 0 && isSendingPic && isEditMessage) {
      editMessage();
    }
    if (text.length === 0 && isEditMessage) {
      const selectedChatMessageCurrent = selectedChatMessages.find(
        (msg) => msg.id === messageSelected
      );
      if (
        selectedChatMessageCurrent &&
        (selectedChatMessageCurrent.type === 'post' ||
          selectedChatMessageCurrent.type === 'service' ||
          selectedChatMessageCurrent.type === 'user' ||
          selectedChatMessageCurrent.type === 'photo' ||
          selectedChatMessageCurrent?.forwardedMessage?.abstractMessageWithDTO?.type === 'post' ||
          selectedChatMessageCurrent?.forwardedMessage?.abstractMessageWithDTO?.type ===
            'service' ||
          selectedChatMessageCurrent?.forwardedMessage?.abstractMessageWithDTO?.type === 'user' ||
          selectedChatMessageCurrent?.forwardedMessage?.abstractMessageWithDTO?.type === 'photo')
      ) {
        editMessage();
      }
    }
  };

  //MESSAGE FIELD FOCUS HANDLE AND CHAT MESSAGES FETCH

  useEffect(() => {
    let mounted = true;
    const messageField = document.getElementById('messageField');
    if (mounted && selectedChat && stompClient && stompClient?.connected) {
      setIsLoadingMessages(true);
      setIsLoadingFreshMessages(true);

      getSelectedChatMessages(selectedChat);
      setIsSendingPic(false);
      setImgs([]);
      setImgsToDelete([]);
      messageField.click();
    }
    return () => (mounted = false);
    // eslint-disable-next-line
  }, [selectedChat]);

  // MESSAGE TEXT FIELD HANDLER

  useEffect(() => {
    let mounted = true;
    if (mounted && isEditMessage && !isReplyMessage && !isResendMessage) {
      const message = selectedChatMessages.find((message) => message.id === messageSelected);
      if (message) {
        if (message.type === 'photo') {
          setImgs([
            ...message.photos.map((photo) =>
              getFileFromBase64(photo.encodedContent, photo.nameFile)
            )
          ]);
          setText(message.text);
        } else {
          setText(message.text);
        }
      }
    }
    return () => (mounted = false);
    // eslint-disable-next-line
  }, [isEditMessage]);

  // CHAT SCROLLER
  const handleScroll = (e) => {
    let element = e.target;
    if (
      element.scrollTop === 0 &&
      !isLoadingMessages &&
      selectedChatMessages.length < selectedChatMessagesCount
    ) {
      setIsLoadingMessages(true);
      apiChatsMessages
        .getAll({
          chatId: selectedChat?.chatId,
          page: messagesPage + 1,
          size: messagesCount
        })
        .then((messages) => {
          const reversed = messages.result.reverse();
          const newArray = [...reversed, ...selectedChatMessages];

          setSelectedChatMessages([...newArray]);
          setMessagesPage(messagesPage + 1);
          // setPrevMessagesPage(messagesPage);

          setIsLoadingMessages(false);
        })
        .catch((error) => console.error(error));
    }
  };

  useEffect(() => {
    const chatContainer = document.getElementById('chatContainer');
    const wrapper = chatContainer && chatContainer.getElementsByClassName('messagesWrapper');

    // const messageScroller = document.getElementById('messageScroller');
    //hhh

    const fixpos = () => {
      messagesRef.current = selectedChatMessages;
      for (let item of wrapper) {
        const br = item.getBoundingClientRect();
        if (br.top < 0 && br.bottom > 0) {
          item.children[0].classList.add('stickyMessageTime');
        }
      }
    };

    if (chatContainer) {
      chatContainer.addEventListener('scroll', fixpos);
    }

    if (messagesPage === 0 && chatContainer) {
      if (msgToScroll.length === 0) {
        window.setTimeout(() => {
          chatContainer.scrollTop = chatContainer.scrollHeight;
        }, 100);
      }

      const element = document.getElementById(msgToScroll);
      if (element) {
        element.scrollIntoView({ block: 'start' });
        setMsgToScroll('');
      }
    } else {
      if (messagesRef.current && msgToScroll.length === 0) {
        const element = document.getElementById(messagesRef.current[0]?.id);
        if (element) {
          element.scrollIntoView({ block: 'start' });
          setMsgToScroll('');
        }
        // const element =
        // element.scrollIntoView({ block: 'start', inline: 'nearest' });
      } else {
        const element = document.getElementById(msgToScroll);
        if (element) {
          element.scrollIntoView({ block: 'start' });
          setMsgToScroll('');
        }
      }
    }
    // if (messagesPage !== 0 && chatContainer && wrapper) {
    //   window.setTimeout(() => {
    //     chatContainer.scrollTop = wrapper[0].offsetHeight;
    //   }, 200);
    //   setScrollHeight(chatContainer.scrollHeight);
    // }
    return () => document.removeEventListener('scroll', fixpos);
    // eslint-disable-next-line
  }, [selectedChatMessages]);

  //INITIAL FETCHES

  useEffect(() => {
    let mounted = true;
    if (mounted && stompClient && stompClient?.connected) {
      getChats();
      // if (userData.id) {
      //   getUserSubscriptions();
      // }
    }
    return () => (mounted = false);
    // eslint-disable-next-line
  }, [stompClient]);
  useEffect(() => {
    let mounted = true;
    if (mounted && usersForResending.length !== 0 && stompClient && stompClient?.connected) {
      getChats();
    }
    return () => (mounted = false);
    // eslint-disable-next-line
  }, [usersForResending, stompClient]);
  // useEffect(() => {
  //   let mounted = true;
  //   if (mounted) {
  //     getChats();
  //     if (userData.id) {
  //       getUserSubscriptions();
  //     }
  //   }
  //   return () => (mounted = false);
  //   // eslint-disable-next-line
  // }, [usersForResending]);
  // console.log(usersForResending);
  // INITIAL READING MESSAGES

  // useEffect(() => {
  //   if (selectedChat && !selectedChatPrev) {
  //     stompClient.publish({
  //       destination: '/queue/chat-info-message',
  //       body: JSON.stringify({
  //         chatId: selectedChat.chatId,
  //         serviceMessageRecipientId: selectedChat.userDTO.id
  //       }),
  //       headers: {
  //         Authorization: localStorage.getItem('accessToken')
  //       }
  //     });
  //   }
  //   // eslint-disable-next-line
  // }, []);

  // ON MESSAGE RECEIVED REDUX

  useEffect(() => {
    if (socketMsg) onMessageReceived(socketMsg);
    // eslint-disable-next-line
  }, [socketMsg]);
  useEffect(() => {
    if (socketInfoMsg) onMessageReceived(socketInfoMsg);
    // eslint-disable-next-line
  }, [socketInfoMsg]);

  //STOMP SUBSCRIBER

  useEffect(() => {
    let subscribe = null;
    const trySendUnsended = () => {
      if (selectedChatMessages.find((el) => !el?.id)) {
        const message = selectedChatMessages.find((el) => !el?.id);
        stompClient.publish({
          destination: '/queue/chat-message',
          // destination: '/queue/time',
          body: JSON.stringify({
            senderId: userData.id,
            chatIds: [message.chatId],
            appliedTo: message.appliedTo,
            typeMessage: imgs.length > 0 ? 'photo' : 'text',
            includeId: '',
            photos:
              message.photos.length !== 0
                ? [...message.photos.map((photo) => photo.encodedContent)]
                : [],
            text: message.text
          }),
          headers: {
            Authorization: localStorage.getItem('accessToken')
          }
        });
      }
    };
    //sss
    if (stompClient && stompClient?.connected) {
      //sss
      subscribe = stompClient.subscribe(
        `/queue/${userData.id}`,
        (message) => {
          dispatch(setSocketMsg(JSON.parse(message.body)));
        },
        { 'auto-delete': true }
      );
      stompSubscribeRef.current = subscribe.id;

      window.setTimeout(trySendUnsended, 2000);
    } else {
      dispatch(setSocketMsg(null));
      dispatch(setStompClient(null));
    }

    return () => {
      if (stompClient && stompClient.connected) {
        stompClient.unsubscribe(stompSubscribeRef.current);
        stompClient.deactivate();
        window.clearTimeout(trySendUnsended, 2000);
      }
    };
    // eslint-disable-next-line
  }, [stompClient]);

  useEffect(() => {
    const fileInput = document.getElementById('button-file-post');
    if (fileInput) {
      fileInput.addEventListener('change', (event) => {
        if (imgs.length !== 10) {
          setFile(event.target.files[0]);
          dispatch(setIsOpenImageCropModal(true));
        }
        event.target.value = '';
      });
    }
  });
  //simple useEffect for handling tab changing
  useEffect(() => {
    setIsLoadingMessages(false);
  }, [tabIdx]);

  return (
    <Page
      title="Листування"
      style={{
        display: 'flex',
        marginTop: '150px',
        alignItems: 'center',
        flexDirection: 'column',
        height: 'max-content',
        padding: '0px 100px'
      }}
    >
      <audio src={NewMessageAudio} muted={true} autostart="false" width="0" height="0" id="audio" />
      {isFullscreenPic && (
        <FullscreenPicture
          isOpenModal={isFullscreenPic}
          pic={showedImg}
          setShowedImg={setShowedImg}
          sliderStartIdx={sliderStartIdx}
        />
      )}
      <UserContactsModal
        text={text}
        setText={setText}
        isResendMessage={isResendMessage}
        setIsResendMessage={setIsResendMessage}
        setSelectedChat={setSelectedChat}
        isOpenModal={isOpenUserContactsModal}
        data={userSubscriptions}
        setter={(ids) => {
          // getUserSubscriptions();
          getChats(ids);
        }}
        getUserSubscriptionsThunk={props.getUserSubscriptionsThunk}
      />
      <CropModal
        isOpenModal={isOpenImageCropModal}
        file={file}
        setter={(file) => {
          if (isEditMessage) {
            const newImgsArray = [...newImgs];
            newImgsArray.push(file);
            setNewImgs([...newImgsArray]);
          }
          const newArray = [...imgs];
          newArray.push(file);
          setImgs([...newArray]);
        }}
      />
      <Stack
        spacing={1}
        justifyContent="center"
        alignItems="center"
        width="100%"
        height={isLoading ? '80vh' : '100%'}
      >
        {!isLoading ? (
          <Grid
            container
            style={{
              maxWidth: '1440px'
            }}
          >
            <TabContext value={tabIdx}>
              <Grid
                item
                xs={4}
                sx={{
                  p: 1,
                  minHeight: '90px',
                  borderRight: '1px solid #60A5DF !important',
                  borderBottom: '1px solid #60A5DF !important'
                }}
              >
                <Typography
                  style={{
                    fontSize: '20px',
                    fontWeight: '600',
                    wordBreak: 'break-word',
                    marginBottom: '10px'
                  }}
                >
                  Листування
                </Typography>

                <StyledSubTabs onChange={handleChangeTab}>
                  <StyledTab value="1" label="Основні" />
                  <StyledTab value="2" label="Важливі" />
                </StyledSubTabs>
              </Grid>
              <Grid
                item
                xs={8}
                sx={{ p: 1, minHeight: '90px', borderBottom: '1px solid #60A5DF !important' }}
              >
                {selectedChat && (
                  <UserListCardChatTop
                    deleteChat={deleteChat}
                    user={selectedChat}
                    setChats={getChats}
                    clearMessages={setSelectedChatMessages}
                    clearSelectedChat={setSelectedChat}
                    setMessagesPage={setMessagesPage}
                  />
                )}
              </Grid>
              <Grid
                item
                xs={4}
                sx={{
                  p: 1,
                  minHeight: '60vh',
                  maxHeight: '61vh',
                  overflow: 'scroll',
                  borderRight: '1px solid #60A5DF !important',
                  '&::-webkit-scrollbar': { display: 'none' },
                  msOverflowStyle: 'none',
                  scrollbarWidth: 'none'
                }}
              >
                <TabPanel value="1">
                  {chats && chats.length !== 0 ? (
                    chats.map((user, index) => (
                      <React.Fragment key={`chat${index}`}>
                        <UserListCardChat
                          stompClient={stompClient}
                          clearMessages={setSelectedChatMessages}
                          getSelectedChatMessages={getSelectedChatMessages}
                          setSelectedChatPrev={setSelectedChatPrev}
                          dataSetter={getChats}
                          selectedChat={selectedChat}
                          chatSetter={setSelectedChat}
                          textSetter={setText}
                          setMessagesPage={setMessagesPage}
                          user={user}
                          index={index}
                        />
                      </React.Fragment>
                    ))
                  ) : (
                    <>
                      <StyledLabel
                        sx={{
                          marginTop: '68px !important',
                          marginBottom: '10px !important',
                          lineHeight: '44px !important',
                          textAlign: ' center !important',
                          textTransform: 'inherit !important',
                          fontSize: '32px !important'
                        }}
                      >
                        {/* {translate('bonfair_service_modal')} */}
                        {'Ваші листування'}
                      </StyledLabel>
                      <Typography
                        variant="subtitle2"
                        sx={{
                          mb: 2,
                          fontSize: 16,
                          width: '70%',
                          textAlign: 'center',
                          margin: '0 auto'
                        }}
                      >
                        Тут будуть відображатися ваші листування з користувачами.
                      </Typography>
                    </>
                  )}
                </TabPanel>
                <TabPanel value="2">
                  {favoriteChats && favoriteChats.length !== 0 ? (
                    favoriteChats.map((user, index) => (
                      <React.Fragment key={`chat${index}`}>
                        <UserListCardChat
                          stompClient={stompClient}
                          // clearMessages={setSelectedChatMessages}
                          getSelectedChatMessages={getSelectedChatMessages}
                          setSelectedChatPrev={setSelectedChatPrev}
                          dataSetter={getChats}
                          selectedChat={selectedChat}
                          chatSetter={setSelectedChat}
                          setMessagesPage={setMessagesPage}
                          textSetter={setText}
                          user={user}
                          index={index}
                        />
                      </React.Fragment>
                    ))
                  ) : (
                    <>
                      <StyledLabel
                        sx={{
                          marginTop: '68px !important',
                          marginBottom: '10px !important',
                          lineHeight: '44px !important',
                          textAlign: ' center !important',
                          textTransform: 'inherit !important',
                          fontSize: '32px !important'
                        }}
                      >
                        {/* {translate('bonfair_service_modal')} */}
                        {'Важливі'}
                      </StyledLabel>
                      <Typography
                        variant="subtitle2"
                        sx={{
                          mb: 2,
                          fontSize: 16,
                          width: '70%',
                          textAlign: 'center',
                          margin: '0 auto'
                        }}
                      >
                        Тут будуть відображатися ваші листування позначені зірочкою.
                      </Typography>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          marginTop: '32px'
                        }}
                      >
                        <StarOutlineIcon sx={{ fontSize: '47px', color: '#D6D6D6' }} />
                        <EastIcon sx={{ margin: '0px 5px' }} />
                        <StarIcon sx={{ fontSize: '47px', color: '#FFD643' }} />
                      </Box>
                    </>
                  )}
                </TabPanel>
              </Grid>
              <Grid
                item
                xs={8}
                sx={{
                  p: 1,
                  pb: 0,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  maxHeight: '61.5vh'
                }}
              >
                <Grid
                  onScroll={handleScroll}
                  id="chatContainer"
                  item
                  sx={{
                    // width: '100%',
                    // minHeight: '50vh',

                    flexDirection: 'column',

                    maxHeight: '51vh',
                    overflow: 'scroll',
                    '&::-webkit-scrollbar': { display: 'none' },
                    msOverflowStyle: 'none',
                    scrollbarWidth: 'none'
                  }}
                >
                  {isLoadingMessages && !isLoadingFreshMessages && selectedChat ? (
                    <LinearProgress />
                  ) : null}

                  {selectedChat !== null ? (
                    <>
                      {selectedChatMessages.length !== 0
                        ? Object.entries(
                            selectedChatMessages.reduce((acc, elem) => {
                              (acc[timeDiffMessagesDay(elem.sendingTime)] ??= []).push(elem);

                              return acc;
                            }, {})
                          )
                            // .reverse()
                            .map((item, index) => {
                              return (
                                <Box
                                  id="messageWrapper"
                                  className="messagesWrapper"
                                  key={index}
                                  sx={{
                                    position: 'relative'
                                  }}
                                >
                                  {/* {item[0] !== 'Сьогодні' && ( */}
                                  <Grid
                                    className="messageTime"
                                    key={`messageTime${index}`}
                                    item
                                    xs={12}
                                    sx={{
                                      textAlign: 'center',
                                      p: '2px',
                                      alignItems: 'center',
                                      display: 'flex',
                                      justifyContent: 'center'
                                    }}
                                  >
                                    <Typography
                                      variant="subtitle"
                                      sx={{
                                        fontFamily: 'Public Sans,sans-serif !important',
                                        fontSize: '16px',
                                        textAlign: 'left',
                                        cursor: 'pointer',
                                        color: '#A1A1A1'
                                      }}
                                    >
                                      {item[0]}
                                    </Typography>
                                  </Grid>
                                  {/* )} */}
                                  {item[1].map((message, index) => (
                                    <React.Fragment
                                      key={`message${message?.id ? message.id : index}`}
                                    >
                                      <ChatMessage
                                        setUsersForResending={setUsersForResending}
                                        setText={setText}
                                        setSliderStartIdx={setSliderStartIdx}
                                        setShowedImg={setShowedImg}
                                        setIsResendMessage={setIsResendMessage}
                                        setIsReplyMessage={setIsReplyMessage}
                                        deleteMessage={deleteMessage}
                                        setIsEditMessage={setIsEditMessage}
                                        setIsSendingPic={setIsSendingPic}
                                        setMessageSelected={setMessageSelected}
                                        message={message}
                                        index={index}
                                        selectedChat={selectedChat}
                                        userData={userData}
                                      />
                                    </React.Fragment>
                                  ))}
                                </Box>
                              );
                            })
                        : null}
                    </>
                  ) : (
                    <>
                      <StyledLabel
                        sx={{
                          marginTop: '68px !important',
                          marginBottom: '10px !important',
                          lineHeight: '44px !important',
                          textAlign: ' center !important',
                          textTransform: 'inherit !important',
                          fontSize: '32px !important'
                        }}
                      >
                        {/* {translate('bonfair_service_modal')} */}
                        {'Ваші повідомлення'}
                      </StyledLabel>
                      <Typography
                        variant="subtitle2"
                        sx={{
                          mb: 2,
                          fontSize: 16,
                          width: '70%',
                          textAlign: 'center',
                          margin: '0 auto'
                        }}
                      >
                        {tabIdx === '1'
                          ? 'У вас поки що немає повідомлень. Знайдіть кому написати та почніть спілкування.'
                          : 'У вас поки що немає повідомлень у важливих.'}
                      </Typography>
                      {tabIdx !== '2' && (
                        <LetsGoBtn3
                          size="large"
                          onClick={(event) => {
                            event.preventDefault();
                            event.stopPropagation();
                            dispatch(setIsOpenUserContactsModal(true));
                          }}
                          width={255}
                          margin="32px auto !important"
                          style={{ textTransform: 'inherit' }}
                          text="Агов люди!"
                        />
                      )}
                    </>
                  )}
                </Grid>

                {selectedChat && (
                  <div
                    style={{
                      position: 'relative',
                      width: '100%',
                      display: 'flex',
                      alignItems: 'start',
                      flexDirection: 'column'
                    }}
                  >
                    {blockedUsers.find((el) => el?.id === selectedChat?.userInfoDTO?.id) ? (
                      <Typography
                        sx={{
                          fontSize: '14px',
                          color: 'red',
                          fontFamily: 'Kyiv-Sans-Light'
                        }}
                      >
                        Ви заблокували цього користувача.
                      </Typography>
                    ) : null}
                    {usersWhoBlocked.find((el) => el === selectedChat?.userInfoDTO?.id) ? (
                      <Typography
                        sx={{
                          fontSize: '14px',
                          color: 'red',
                          fontFamily: 'Kyiv-Sans-Light'
                        }}
                      >
                        Цей користувач Вас заблокував.
                      </Typography>
                    ) : null}
                    {isSendingPic && (
                      <div
                        style={{
                          width: '100%',
                          display: 'flex',
                          flexDirection: 'row',
                          flexWrap: 'wrap',
                          // justifyContent:'space-between',
                          marginBottom: '0px',
                          marginLeft: 'auto',
                          marginRight: 'auto'
                        }}
                      >
                        {imgs.length !== 0 &&
                          imgs.map((file, index) => (
                            <div
                              key={'image' + index}
                              className="uploaded-img-chat"
                              // style={{ marginLeft: '14px', marginTop: '14px' }}
                              onClick={() => {
                                const newArray = imgs;
                                const newImgsToDelete = imgsToDelete;
                                const message = selectedChatMessages.find(
                                  (message) => message.id === messageSelected
                                );
                                if (isEditMessage && imgs.length !== 0) {
                                  newImgsToDelete.push(
                                    message.photos.find((file, i) => index === i).id
                                  );
                                }

                                newArray.splice(index, 1);
                                setImgs([...newArray]);
                                setImgsToDelete([...newImgsToDelete]);
                              }}
                            >
                              <img
                                style={{ cursor: 'pointer' }}
                                alt={'img' + index}
                                width={60}
                                src={URL.createObjectURL(file)}
                              />
                            </div>
                          ))}
                        <input
                          accept="image/*"
                          style={{ display: 'none' }}
                          id="button-file-post"
                          disabled={imgs.length === 10 ? true : false}
                          // onChange={(e) => {
                          //   if (imgs.length !== 10) {
                          //     setFile(e.target.files[0]);
                          //     // dispatch(setIsOpenImageCropModal(true));
                          //   } else e.target.value = '';
                          // }}
                          type="file"
                        />
                        {imgs.length !== 10 && (
                          <label
                            htmlFor="button-file-post"
                            style={{
                              width: '50px',
                              height: '50px',
                              marginLeft: imgs.length === 0 ? '0px' : '8px',
                              cursor: 'pointer'
                            }}
                          >
                            <div
                              component="span"
                              variant="outlined"
                              style={{
                                width: '50px',
                                height: '50px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                border: '1px solid #A1A1A1',
                                borderRadius: '5px',
                                cursor: 'pointer'
                              }}
                            >
                              <AddIcon
                                // style={{ color: 'rgba(96, 165, 223, 1)' }}
                                sx={{
                                  color: 'rgba(96, 165, 223, 1)',
                                  fontSize: '18px'
                                }}
                              />
                            </div>
                          </label>
                        )}
                      </div>
                    )}
                    {isEditMessage && (
                      <>
                        <Typography
                          sx={{
                            fontSize: '12px',
                            color: '#A1A1A1',
                            fontFamily: 'Kyiv-Sans-Light'
                          }}
                        >
                          {isReplyMessage && 'Відповідь на повідомлення:'}
                          {isEditMessage &&
                            !isReplyMessage &&
                            !isResendMessage &&
                            ' Редагування повідомлення'}
                          {isResendMessage && ''}
                        </Typography>
                        <Box
                          sx={{
                            padding: '10px',
                            borderRadius: '10px 10px 10px 0px',
                            background: '#deecf8',
                            fontSize: '12px',
                            display: 'flex',
                            alignItems: 'center',
                            cursor: 'pointer',
                            width: 'max-content',
                            maxWidth: '80%'
                          }}
                        >
                          {isReplyMessage || isResendMessage ? (
                            <Grid container sx={{ width: '100%' }} spacing={0}>
                              <Grid item xs={2} sx={{ display: 'flex' }}>
                                {isResendMessage && (
                                  <SendOutlinedIcon
                                    sx={{ color: 'rgba(33, 107, 170, 1)', fontSize: '24px' }}
                                  />
                                )}
                                {isReplyMessage && (
                                  <ReplyIcon
                                    sx={{ color: 'rgba(33, 107, 170, 1)', fontSize: '24px' }}
                                  />
                                )}
                              </Grid>
                              <Grid item xs={10} sx={{ display: 'flex' }}>
                                <Typography
                                  variant="body2"
                                  sx={{ fontSize: '14px', fontWeight: 'bold !important' }}
                                >{`@${
                                  selectedChatMessages.length !== 0 &&
                                  selectedChatMessages.find(
                                    (message) => message.id === messageSelected
                                  )?.senderId === userData.id
                                    ? userData.nickname
                                    : selectedChat.userInfoDTO.nickname
                                }`}</Typography>
                              </Grid>
                              <Grid item xs={2} sx={{ width: '41px' }} />
                              <Grid
                                item
                                xs={10}
                                sx={{
                                  fontSize: '12px',
                                  // pb: 1,
                                  // mb: 1,
                                  wordBreak: 'break-word !important'
                                }}
                              >
                                {selectedChatMessages.length !== 0 &&
                                  selectedChatMessages.find(
                                    (message) => message.id === messageSelected
                                  )?.text}
                              </Grid>
                            </Grid>
                          ) : (
                            <Grid
                              item
                              xs={10}
                              sx={{
                                fontSize: '12px',
                                // borderBottom: '1px solid #60A5DF',
                                // pb: 1,
                                // mb: 1,
                                maxWidth: '100% !important',
                                flexBasis: 'unset !important',
                                wordBreak: 'break-word !important'
                              }}
                            >
                              {selectedChatMessages.length !== 0 &&
                                selectedChatMessages.find(
                                  (message) => message.id === messageSelected
                                ).text}
                            </Grid>
                          )}

                          <CloseOutlinedIcon
                            onClick={() => {
                              setText('');

                              setImgsToDelete([]);
                              setNewImgs([]);
                              setImgs([]);

                              setIsEditMessage(false);
                              setIsSendingPic(false);
                              setIsReplyMessage(false);
                              setIsResendMessage(false);
                              setMessageSelected('');
                            }}
                            sx={{
                              ml: 1,
                              cursor: 'pointer',
                              ':hover': { color: '#60A5DF' },
                              zIndex: '99',
                              fontSize: '16px'
                            }}
                          />
                        </Box>
                      </>
                    )}
                    <div
                      style={{
                        width: '100%',
                        display: 'flex',
                        alignItems: 'end'
                      }}
                    >
                      <CssTextField
                        disabled={
                          blockedUsers.find((el) => el?.id === selectedChat?.userInfoDTO?.id) ||
                          usersWhoBlocked.find((el) => el === selectedChat?.userInfoDTO?.id)
                        }
                        id="messageField"
                        placeholder="Про що хочеш потеревенити?"
                        variant="standard"
                        fullWidth
                        size="normal"
                        inputProps={{ style: { textAlign: 'left' } }}
                        InputProps={{
                          endAdornment: (
                            <>
                              <p
                                style={{
                                  margin: '0 10px',
                                  color: text.length <= 255 ? 'gray' : 'red',
                                  textAlign: 'left',
                                  fontSize: '14px'
                                  // paddingLeft: '10px'
                                }}
                              >{`${text.length}/255`}</p>

                              <EmojiEmotionsOutlinedIcon
                                onClick={() => {
                                  if (
                                    !blockedUsers.find(
                                      (el) => el?.id === selectedChat?.userInfoDTO?.id
                                    ) &&
                                    !usersWhoBlocked.find(
                                      (el) => el === selectedChat?.userInfoDTO?.id
                                    )
                                  ) {
                                    setIsShowEmoji(!isShowEmoji);
                                  }
                                }}
                                sx={{
                                  color: '#60A5DF',
                                  cursor: 'pointer',
                                  fontSize: '20px',
                                  mr: 1
                                }}
                              />
                              <AddAPhotoOutlinedIcon
                                onClick={() => {
                                  if (
                                    !blockedUsers.find(
                                      (el) => el?.id === selectedChat?.userInfoDTO?.id
                                    ) &&
                                    !usersWhoBlocked.find(
                                      (el) => el === selectedChat?.userInfoDTO?.id
                                    )
                                  ) {
                                    setIsSendingPic(!isSendingPic);
                                    if (isSendingPic) {
                                      setImgs([]);
                                    }
                                  }
                                }}
                                sx={{ color: '#60A5DF', cursor: 'pointer', fontSize: '20px' }}
                              />
                            </>
                          )
                        }}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter' && text.length <= 255) {
                            handleSend();
                            setIsShowEmoji(false);
                          }
                        }}
                        onChange={(event) => {
                          setText(event.target.value);
                        }}
                        value={!isResendMessage ? text : ''}
                      />
                      <LetsGoBtn2
                        disabled={
                          blockedUsers.find((el) => el?.id === selectedChat?.userInfoDTO?.id) ||
                          usersWhoBlocked.find((el) => el === selectedChat?.userInfoDTO?.id)
                        }
                        text={
                          isEditMessage && !isReplyMessage && !isResendMessage
                            ? 'Редагувати'
                            : 'Відправити'
                        }
                        width="190px"
                        marginTop="0px"
                        sx={{ ml: 2, mr: 1 }}
                        onClick={() => {
                          if (text.length <= 255) {
                            handleSend();
                            setIsShowEmoji(false);
                          }
                        }}
                      />
                    </div>
                    {isShowEmoji && (
                      <EmojiPicker
                        onEmojiClick={(emojiData, event) => {
                          let newStr = `${text}${emojiData.emoji}`;
                          setText(newStr);
                        }}
                        skinTonesDisabled
                        searchDisabled
                        previewConfig={{ showPreview: false }}
                        style={{
                          position: 'absolute',
                          bottom: '40px',
                          right: '213px',
                          zIndex: '99'
                        }}
                        width={'60%'}
                        height={'300px'}
                      />
                    )}
                  </div>
                )}
              </Grid>
            </TabContext>
          </Grid>
        ) : (
          <Spinner />
        )}
      </Stack>
    </Page>
  );
}
const mapStateToProps = (state) => {
  return {
    language: state.Intl.locale,
    userData: state.global.userData,
    subscriptions: state.global.userSubscriptions,
    stompClient: state.global.stompClient,
    blockedUsers: state.global.blockedUsers,
    usersWhoBlocked: state.global.usersWhoBlocked
  };
};

export default connect(mapStateToProps, {
  getUserSubscriptionsThunk: base.getUserSubscriptionsThunk,
  getUserDataThunk: base.getUserDataThunk
})(MessagesPage);
