import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

//Material
import { Avatar, Checkbox, Grid, Menu, MenuItem, Tooltip, Typography } from '@mui/material';

import { Stack } from '@mui/system';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import AddIcon from '@mui/icons-material/Add';
import DoneIcon from '@mui/icons-material/Done';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import { connect, useDispatch, useSelector } from 'react-redux';
import { setIsOpenUserContactsModal } from '../redux/mainReducer';
import DefaultAvatar from '../assets/defaultPostImg.jpg';
import { base } from '../redux/thunk';
import ApiService from '../helpers/apiHelper';
import { FreeBtn } from './Buttons';

function UserCard({
  isResendMessage,
  selectedUsers,
  setSelectedUsers,
  user,
  language,
  userData,
  getUserSubscriptionsThunk,
  getUserSubscribersThunk,
  getBlockedUsersThunk,
  subscriptions,
  setter,
  blockedUsers
}) {
  const apiSubscribe = new ApiService('auth/subscribe');
  const apiUnSubscribe = new ApiService('auth/unsubscribe');
  const apiChats = new ApiService('notification/chats');
  // const apiBU = new ApiService('/blocked-users');
  const apiBU = new ApiService('auth/blocked-users');
  const isOpenResendingModal = useSelector((state) => state.global.isOpenResendingModal);
  // const apiChats = new ApiService('chats');
  const params = useParams();
  const [anchorEl, setAnchorEl] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleChange = (event) => {
    if (selectedUsers.includes(user.id)) {
      setSelectedUsers(selectedUsers.filter((id) => user.id !== id));
    } else {
      setSelectedUsers([...selectedUsers, user.id]);
    }
  };

  return (
    <Grid
      container
      style={{
        borderTop:
          !window.location.href.includes('ribbon') ||
          (window.location.href.includes('ribbon') && isOpenResendingModal.isOpen)
            ? '1px solid #60a5df'
            : 'none',
        borderBottom:
          !window.location.href.includes('ribbon') ||
          (window.location.href.includes('ribbon') && isOpenResendingModal.isOpen)
            ? '1px solid #60a5df'
            : 'none',
        padding: '5px',
        marginBottom: !window.location.href.includes('ribbon') ? '10px' : '5px',
        justifyContent: 'space-between'
      }}
    >
      <Grid item xs={2}>
        <Avatar
          onClick={() => {
            dispatch(setIsOpenUserContactsModal(false));
            navigate(`/${language}/user-page/${user.id}?type=posts`);
          }}
          src={
            user?.encodedMediaDTO
              ? `data:${user?.encodedMediaDTO.contentType};base64, ${user?.encodedMediaDTO.encodedContent}`
              : DefaultAvatar
          }
          sx={{
            width: 70,
            height: 70,
            border: '1px solid #60A5DF',
            cursor: 'pointer',
            boxShadow: blockedUsers.find(
              (el) => el.id === user?.id
            )
              ? '0px 0px 2px 2px rgba(255,0,0,1)'
              : 'none',
          }}
        />
      </Grid>
      <Grid item xs={!window.location.href.includes('ribbon') ? 4 : 6}>
        <Stack sx={{ width: '100%', marginTop: '10px' }}>
          {user?.name&&user?.surname&&<Typography
            variant="subtitle"
            sx={{
              fontFamily: 'Public Sans,sans-serif !important',
              fontSize: '18px',
              textAlign: 'left',
              cursor: 'pointer'
            }}
            onClick={() => {
              dispatch(setIsOpenUserContactsModal(false));
              navigate(`/${language}/user-page/${user.id}?type=posts`);
            }}
            // src={
            //   user?.encodedMediaDTO
            //     ? `data:${user?.encodedMediaDTO.contentType};base64, ${user?.encodedMediaDTO.encodedContent}`
            //     : DefaultAvatar
            // }
          >
            {`${user?.name} ${user?.surname}`}
          </Typography>}
          
          <Typography
            variant="subtitle"
            sx={{
              fontFamily: 'Public Sans,sans-serif !important',
              fontSize: user?.name&&user.surname?'14px':'18px',
              textAlign: 'left',
              cursor: 'pointer'
            }}
            onClick={() => {
              dispatch(setIsOpenUserContactsModal(false));
              navigate(`/${language}/user-page/${user.id}?type=posts`);
            }}
            src={
              user?.encodedMediaDTO
                ? `data:${user?.encodedMediaDTO.contentType};base64, ${user?.encodedMediaDTO.encodedContent}`
                : DefaultAvatar
            }
          >
            {`@${user?.nickname}`}
          </Typography>
        </Stack>
      </Grid>
      <Grid
        item
        xs={!window.location.href.includes('ribbon') ? 6 : 3}
        sx={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}
      >
        {params.id === userData.id || !params.id ? (
          <>
            {isResendMessage ? (
              <Checkbox
              disableRipple
                checked={selectedUsers.includes(user.id)}
                onChange={handleChange}
                sx={{
                  width: '25px',
                  height: '25px',
                  '&.Mui-checked': {
                    color: 'rgba(96, 165, 223, 1) !important'
                  }
                }}
              />
            ) : (
              <>
                {!window.location.href.includes('ribbon') ? (
                  <Tooltip title="Написати">
                    <MailOutlineIcon
                      onClick={() => {
                        apiChats
                          .postDataQuery({
                            userId: userData.id,
                            recipientId: user.id
                          })
                          .then((res) => {
                            dispatch(setIsOpenUserContactsModal(false));
                            navigate(`/${language}/messages`);
                            setter();
                          })
                          .catch((error) => {
                            if (error?.message.includes('There is a chat between these users')) {
                              const id = `${error.message.slice(
                                error.message.indexOf('[') + 1,
                                error.message.length - 1
                              )}`;

                              navigate(`/${language}/messages?chatId=${id}`);
                              setter();
                              dispatch(setIsOpenUserContactsModal(false));
                            }
                          });
                      }}
                      sx={{ color: '#60A5DF', cursor: 'pointer', zIndex: 99 }}
                    />
                  </Tooltip>
                ) : (
                  <Tooltip title="Додати">
                    <FreeBtn
                      padding="11px 23px"
                      text=""
                      disabled={subscriptions.find((subscription) => subscription.id === user.id)}
                      onClick={() => {
                        apiSubscribe.updateDataQuery({ subscriptionId: user.id }).then((res) => {
                          getUserSubscriptionsThunk(userData.id);
                        });
                      }}
                      icon={
                        subscriptions.find((subscription) => subscription.id === user.id) ? (
                          <DoneIcon style={{ marginRight: '0px', color: '#A1A1A1' }} />
                        ) : (
                          <AddIcon style={{ marginRight: '0px', color: '#60A5DF' }} />
                        )
                      }
                    />
                  </Tooltip>
                )}
              </>
            )}

            {params.id && (
              <MoreVertIcon
                onClick={(event) => handleClick(event)}
                sx={{ ml: 1, cursor: 'pointer', ':hover': { color: '#60A5DF' }, zIndex: '99' }}
              />
            )}

            <Menu
              anchorEl={anchorEl}
              id="action-menu"
              open={open}
              onClose={handleClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: 'hidden',
                  boxShadow: 'none',
                  borderRadius: '5px',
                  border: '1px solid black',
                  paddingTop: '10px',
                  paddingLeft: '20px',
                  paddingRight: '20px',
                  width: '310px',
                  mt: 1
                }
              }}
              autoFocus={false}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
              <MenuItem
                onClick={(event) => {
                  event.stopPropagation();
                  event.preventDefault();

                  apiUnSubscribe.deleteItemQuery({ subscriptionId: user.id }).then((res) => {
                    getUserSubscriptionsThunk(userData.id);
                    handleClose();
                  });
                }}
                sx={{ padding: 0, mb: 2 }}
              >
                <Typography component="p" fontSize={16} lineHeight="18px" fontWeight={400}>
                  Видалити з контактів
                </Typography>
              </MenuItem>
              <MenuItem sx={{ padding: 0, mb: 2 }}>
                <Typography component="p" fontSize={16} lineHeight="18px" fontWeight={400}>
                  Вимкнути сповіщення
                </Typography>
              </MenuItem>
              {!blockedUsers.find(el=>el.id===user.id)&&<MenuItem
                sx={{ padding: 0, mb: 1 }}
                onClick={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                  apiBU.updateDataQueryPost({ blockedUserId: user?.id }).then((res) => {
                    getBlockedUsersThunk();
                    getUserSubscriptionsThunk(userData?.id)
                    getUserSubscribersThunk(userData?.id)
                    handleClose()
                  });
                }}
              >
                <Typography component="p" fontSize={16} lineHeight="18px" fontWeight={400}>
                  Заблокувати
                </Typography>
              </MenuItem>}
              
            </Menu>
          </>
        ) : (
          <>
            {user.id !== userData.id && (
              <FreeBtn
                text={
                  subscriptions.find((subscription) => subscription.id === user.id)
                    ? 'Ви підписані'
                    : 'Додати'
                }
                disabled={subscriptions.find((subscription) => subscription.id === user.id)}
                onClick={() => {
                  apiSubscribe.updateDataQuery({ subscriptionId: user.id }).then((res) => {
                    getUserSubscriptionsThunk(userData.id);
                  });
                }}
                icon={
                  subscriptions.find((subscription) => subscription.id === user.id) ? null : (
                    <AddIcon style={{ marginRight: '10px', color: '#60A5DF' }} />
                  )
                }
              />
            )}
          </>
        )}
      </Grid>
    </Grid>
  );
}
const mapStateToProps = (state) => {
  return {
    language: state.Intl.locale,
    userData: state.global.userData,
    subscriptions: state.global.userSubscriptions,
    blockedUsers:state.global.blockedUsers
  };
};
export default connect(mapStateToProps, {
  getUserSubscribersThunk: base.getUserSubscribersThunk,
  getUserSubscriptionsThunk: base.getUserSubscriptionsThunk,
  getBlockedUsersThunk: base.getBlockedUsersThunk
})(UserCard);
