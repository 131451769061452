import React from 'react';

//Material
import CloseIcon from '@mui/icons-material/Close';

import { Box, Grid, Modal, Typography } from '@mui/material';

import { connect, useDispatch } from 'react-redux';
import { StyledLabel } from '../../components/StyledComponents';
import { setIsOpenDeleteAttentionModal } from '../../redux/mainReducer';

import starBlue from '../../assets/StarBlue.png';
import starGreen from '../../assets/StarGreen.png';
import logoSmall from '../../assets/logoSmall.png';

import './DeleteModal.css';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',

  maxWidth: 900,
  bgcolor: 'background.paper',
  borderRadius: '20px',
  border: 'none',
  boxShadow: 24,
  padding: '50px 80px 50px 80px',
  overflow: 'scroll',
  height: 'inherit',
  maxHeight: '100vh',
  scrollbarWidth: 'none',
  '&::-webkit-scrollbar': {
    display: 'none'
  }
};

function DeleteAttentionModal({ isOpenModal }) {
  const dispatch = useDispatch();
  return (
    <div>
      <Modal
        open={isOpenModal}
        onClose={(event, reason) => {
          if (reason === 'backdropClick') {
            return;
          }

          dispatch(setIsOpenDeleteAttentionModal(false));
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="bonfair_delete_modal"
      >
        <Box sx={style} className="bonfair_delete_modal-box">
          <img
            style={{ position: 'fixed', zIndex: '0', top: '0', left: '0' }}
            src={starGreen}
            alt="star-green"
          />
          <img
            style={{ position: 'fixed', zIndex: '0', bottom: '40px', right: '0' }}
            src={starBlue}
            alt="star-green"
          />
          <CloseIcon
            className="bonfair_delete_modal-close"
            style={{ position: 'absolute', right: '10px', top: '10px', cursor: 'pointer' }}
            onClick={() => {
              dispatch(setIsOpenDeleteAttentionModal(false));
            }}
          />

          <Grid container spacing={1}>
            <Grid item xs={12} md={12} lg={12}>
              <StyledLabel className="deleteModal-title-text">
                {'От халепа'}
              </StyledLabel>
            </Grid>
            <Grid item xs={12} md={12} lg={12} sx={{ display: 'flex', justifyContent: 'center' }}>
              <Typography
                variant="subtitle2"
                sx={{
                  mb: 2,
                  fontSize: 16,
                  fontFamily: 'Kyiv-Sans-Light',
                  textAlign: 'center',
                  maxWidth: '85%'
                }}
              >
                {`Схоже, ця послуга знаходиться в активному замовленні. Нажаль, зараз її видалення неможливе.`}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems:'center',
                margin: '0px auto',
                maxWidth: '400px'
              }}

            >
              <img src={logoSmall} alt='logoSmall' width={27} height={19}/>
                <Typography
                  variant="subtitle2"
                  sx={{
                    fontSize: 12,
                    fontFamily: 'Kyiv-Sans-Light',
                    textAlign: 'center',
                    maxWidth: '100%'
                  }}
                >
                  {`Якщо ти хочеш приховати цю послугу - постав статус “Немає в наявності” у вікні Редагування послуги.`}
                </Typography>



            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    language: state.Intl.locale
  };
};
export default connect(mapStateToProps)(DeleteAttentionModal);
