import React, { useEffect, useState } from 'react';

//Material
import { Box, Paper, Skeleton, Typography } from '@mui/material';

// import { Stack } from '@mui/system';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import basePhoto from '../assets/DefaultIMG.png';
import { dateFormattingByDate } from '../helpers/date-helpers';
import '../pages/MainPage/index.css';
import { setIsOpenAuthModal } from '../redux/mainReducer';
import { LetsGoBtn } from './Buttons';
import ApiService from '../helpers/apiHelper';
// import  ImageGallery  from 'react-image-gallery';

// const useStyles = makeStyles({

//   button: {
//     '&:hover': {
//       boxShadow: '-5px 5px 3px black !important',
// width: '155px !important'
//   },
// }})
function ServiceCard({ index, el, noWidth = false, maxHeight = 'unset',  ...props }) {
  // const classes = useStyles()
  const apiPhoto = new ApiService('photo/get-photo');
  // const apiPhoto = new ApiService('get-photo');
  const [photos, setPhotos] = useState([]);
  const [isLoadingPhotos, setIsLoadingPhotos] = useState(false);
  const userData = useSelector((state) => state.global.userData);
  const language = useSelector((state) => state.Intl.locale);
  const dispatch = useDispatch();
  const isHoverSaved = window.location.href.includes('saved');
  const isHoverSearch =
    window.location.href.includes('search') || window.location.href.includes('ribbon');
  const isHoverMy = window.location.href.includes('user-page');
  // eslint-disable-next-line
  const navigate = useNavigate();


  useEffect(() => {
    let mounted = true;

    if (el?.encodedPhotos && el?.encodedPhotos.length !== 0 && mounted) {
      setIsLoadingPhotos(true);
      apiPhoto
        .getItemById(el.encodedPhotos[0])
        .then((res) => {
          let newPhotos=[]
          newPhotos.push(res.result)
          setIsLoadingPhotos(false);
          setPhotos(newPhotos);
        })
        .catch((error) => {console.error(error)
        });
      return () => (mounted = false);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Paper
      {...props}
      key={index}
      className="serviceCard"
      sx={{
        width: !noWidth ? '24%' : '100%',
        margin: '0px 0.5%',
        textAlign: 'center',
        overflow: 'hidden',
        alignItems: 'center',
        backgroundColor: 'white',
        borderRadius: '5px',
        padding: '20px 5px 20px',
        maxHeight: maxHeight,
        minHeight: '450px',
        '&:hover':
          isHoverMy || isHoverSearch || isHoverSaved
            ? {}
            : { boxShadow: '-10px 10px 3px black', transform: 'scale(1.025)', transition: 'all 1s' }
      }}
    >
      <Box
        style={
          maxHeight !== 'unset'
            ? {
                padding: '0',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                height: '410px'
              }
            : {
                padding: '0',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                height: 'unset'
              }
        }
      >
        <Box style={{ display: 'flex', flexDirection: 'column' }}>
          <Box style={{ minHeight: '250px' }}>
            {el !== null &&
              el &&
              el?.encodedPhotos &&
              el?.encodedPhotos.length !== 0 &&
              el?.encodedPhotos.length === 1 &&photos.length!==0&&!isLoadingPhotos?
              photos.map(
                (photo, index) =>
                  index === 0 && (
                    <img
                      key={`photo${index}`}
                      src={`data:${photo.contentType};base64, ${photo.encodedContent}`}
                      alt={photo.nameFile}
                      style={
                        maxHeight !== 'unset'
                          ? {
                              margin: '0px auto',
                              objectFit: 'contain',
                              maxHeight: '240px',
                              minHeight: '240px'
                            }
                          : {
                              margin: '0px auto',
                              objectFit: 'contain',
                              maxHeight: '370px',
                              minHeight: '370px'
                            }
                      }
                    />
                  )
              ):null}
              {isLoadingPhotos&&<Skeleton sx={
                maxHeight !== 'unset'
                ? { height: 240, width:240, margin:'0 auto' }
                : { height: 370, width:370, margin:'0 auto' }
                
                } animation="wave" variant="rectangular" />}
            {el !== null &&
              el &&
              el?.encodedPhotos &&
              el?.encodedPhotos.length !== 0 &&
              el?.encodedPhotos.length > 1&&photos.length!==0&&!isLoadingPhotos&&
              photos.map(
                (photo, index) =>
                  index === 0 && (
                    <img
                      key={`photo${index}`}
                      src={`data:${photo.contentType};base64, ${photo.encodedContent}`}
                      alt={photo.nameFile}
                      style={
                        maxHeight !== 'unset'
                          ? {
                              margin: '0px auto',
                              objectFit: 'contain',
                              maxHeight: '240px',
                              minHeight: '240px'
                            }
                          : {
                              margin: '0px auto',
                              objectFit: 'contain',
                              maxHeight: '370px',
                              minHeight: '370px'
                            }
                      }
                    />
                  )
              )}
            {(el !== null && !el?.encodedPhotos) ||
              (el !== null && el?.encodedPhotos && el?.encodedPhotos.length === 0) ? (
                <img
                  src={basePhoto}
                  alt="defaultImg"
                  style={
                    maxHeight !== 'unset'
                      ? {
                          margin: '0px auto',
                          objectFit: 'contain',
                          maxHeight: '240px',
                          minHeight: '240px'
                        }
                      : {
                          margin: '0px auto',
                          objectFit: 'contain',
                          maxHeight: '370px',
                          minHeight: '370px'
                        }
                  }
                />
              ):null}
          </Box>

          {/* {el!==null&&el &&el?.encodedPhotos&&
          el?.encodedPhotos.length !== 0&&el?.encodedPhotos.length>1 &&  <ImageGallery style={{marginTop:0}} showPlayButton={false} showBullets={false} showFullscreenButton={false} items={el.encodedPhotos.map(photo=>{
            return {
              original:`data:${photo.contentType};base64, ${photo.encodedContent}`,
              thumbnail:`data:${photo.contentType};base64, ${photo.encodedContent}`,
              originalHeight:300,
              thumbnailHeight:65,
            }
           })}/>} */}
          <Typography sx={{ color: '#868686', fontSize: '16px' }} variant="subtitle">
            {dateFormattingByDate(new Date(el.creationTimeForService))}
          </Typography>
          <Typography
            variant="h5"
            style={{ fontSize: '16px', margin: '0px 10px 10px', wordBreak: 'break-word' }}
          >
            {el.nameService}
          </Typography>
          <Typography style={{ fontSize: '16px', margin: '0px ', fontFamily: 'Kyiv-Sans-Regular' }}>
            {`${el.price} ${el.currency}`}
          </Typography>
        </Box>

        <LetsGoBtn
          className="service-card-btn"
          width="200px"
          margin="10px auto"
          text="Детальніше"
          onClick={() => {
            // navigate(`/${language}/service/${el.id}`)
            if (userData?.id) {
              // navigate(`/${language}/service/${el.id}`);
              window.open(`${window.location.origin}/${language}/service/${el.id}`);
              window.scrollTo({ top: 0 });
            } else {
              dispatch(setIsOpenAuthModal(true));
            }
          }}
        />
      </Box>
    </Paper>
  );
}
// const mapStateToProps = (state) => {
//   return {
//     language: state.Intl.locale
//   };
// };
export default ServiceCard
