import React, { useState } from 'react';

//Material
import { Box, Collapse, Grid, IconButton, Typography } from '@mui/material';
//Material icons
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';
import ArrowDropUpOutlinedIcon from '@mui/icons-material/ArrowDropUpOutlined';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';

import { connect, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const DraggableItem = ({ event, index, open, setOpen }) => {
  const navigate = useNavigate();
  const language = useSelector((state) => state.Intl.locale);
  return (
    <Box
      data-event={`{"id": "${event.id}","service": "${
        event?.service ? event.service : ''
      }","title": "${event.title}","paymentType": "${event.paymentType}","deliveryType": "${
        event.deliveryType
      }","comment": "${event.comment}","contacts": "${event.contacts}",
        "color": "${event.color}","startHour": "${event.startHour}","endHour": "${
        event.endHour
      }","date": "${event.date}","createdAt": "${event.createdAt}","createdBy": "${
        event.createdBy
      }","qty": "${event.qty}","serviceID": "${event.serviceID}"}`}
      id="dragItem"
      className="draggableWrapper"
      sx={{
        display: 'flex',
        alignItems: 'center',
        border: '1px solid #60A5DF',
        background: '#FFF',
        padding: '0px 10px',
        marginBottom: '2px',
        borderRadius: '5px',
        cursor: 'pointer'
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'start', height: '100%' }}>
        <DragIndicatorIcon sx={{ color: 'black', fontSize: '30px', mt: 2.8, mb: 2.8 }} />
        <Typography sx={{ fontSize: '14px', mr: 1.1, mt: 3.4, mb: 2.8 }}>№{event.title}</Typography>
      </Box>

      <Box sx={{ display: 'flex', flexDirection: 'column', mt: 1, mb: 1 }}>
        <Typography
          onClick={() => navigate(`/${language}/service/${event.serviceID}`)}
          sx={{
            fontFamily: 'Kyiv-Sans-Light',
            fontSize: '16px',
            textDecoration: 'underline',
            cursor: 'pointer',
            width: 'fit-content',
            mr: 1.1
          }}
        >
          {event?.service && event?.service.length !== 0 ? event.service : ''}
        </Typography>
        <Box id={`openMenu${index}`} sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography
            sx={{
              fontFamily: 'Kyiv-Sans-Light',
              color: '#757575',
              fontSize: '16px',
              cursor: 'pointer',
              width: 'fit-content'
            }}
            onClick={() => setOpen(!open)}
          >
            {open ? 'Згорнути деталі' : 'Деталі замовлення'}
          </Typography>
          <IconButton
            disableRipple
            disableFocusRipple
            aria-label="expand row"
            size="small"
            sx={{
              ':hover': {
                backgroundColor: 'unset !important'
              }
            }}
            onClick={() => setOpen(!open)}
          >
            {open ? <ArrowDropUpOutlinedIcon /> : <ArrowDropDownOutlinedIcon />}
          </IconButton>
        </Box>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <Grid container>
            <Grid
              item
              xs={12}
              sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'start' }}
            >
              <Typography sx={{ fontSize: '14px', fontWeight: '600' }}>Про замовлення:</Typography>
              <Typography sx={{ fontSize: '14px' }}>{`${
                event?.service && event.service.length !== 0 ? event?.service : ''
              }, ${event?.qty} од.`}</Typography>
              <Typography sx={{ fontSize: '14px', fontWeight: '600' }}>Контактні дані:</Typography>
              <Typography sx={{ fontSize: '14px' }}>{event.contacts}</Typography>
              <Typography sx={{ fontSize: '14px', fontWeight: '600' }}>Спосіб доставки:</Typography>
              <Typography sx={{ fontSize: '14px' }}>{event.deliveryType}</Typography>
              <Typography sx={{ fontSize: '14px', fontWeight: '600' }}>Спосіб оплати:</Typography>
              <Typography sx={{ fontSize: '14px' }}>{event.paymentType}</Typography>
              {event?.comment.length !== 0 && (
                <>
                  <Typography sx={{ fontSize: '14px', fontWeight: '600' }}>
                    Коментар до замовлення:
                  </Typography>
                  <Typography sx={{ fontSize: '14px' }}>{event?.comment}</Typography>
                </>
              )}
            </Grid>
          </Grid>
        </Collapse>
      </Box>
    </Box>
  );
};

function OrderDraggable({ dataSetter, event, index }) {
  const [open, setOpen] = useState(false);

  return <DraggableItem event={event} index={index} open={open} setOpen={setOpen} />;
}
const mapStateToProps = (state) => {
  return {
    language: state.Intl.locale,
    userData: state.global.userData
  };
};
export default connect(mapStateToProps)(OrderDraggable);
