import React from 'react';

//Material
import { Grid, Paper, Stack, Typography } from '@mui/material';
// import { Stack } from '@mui/system';
import { connect, useDispatch } from 'react-redux';
import Illustration1 from '../assets/Banners/Banner0/illustration1.png';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';

import '../pages/MainPage/index.css';
import { LetsGoBtn } from './Buttons';
import { setIsOpenAuthModal } from '../redux/mainReducer';
import { makePayment } from '../helpers/payments';
// import  ImageGallery  from 'react-image-gallery';

// const useStyles = makeStyles({

//   button: {
//     '&:hover': {
//       boxShadow: '-5px 5px 3px black !important',
// width: '155px !important'
//   },
// }})
function FreeTariffCard({userData}) {
  const dispatch = useDispatch()
  return (
    <Paper
      // {...props}
      // key={}
      // className="serviceCard"
      sx={{
        width: '100%',
        margin: '0px',
        textAlign: 'center',
        overflow: 'hidden',
        alignItems: 'center',
        height: '250px',
        borderRadius: '5px',
        padding: '10px 20px ',
        border: '1px solid #60A5DF !important',
        boxShadow: '0px 0px 15px 0px rgba(96,165,223,1)',
        backgroundColor: 'white',
        position: 'relative'
      }}
    >
      <Grid
        container
        spacing={0}
        sx={{
          width: '100%',
          margin: '0px !important',
          height: '100%'
        }}
      >
        <Grid
          item
          xs={5}
          sx={{
            display: 'flex',
            justifyContent: 'start',
            flexDirection: 'column',
            alignItems: 'start'
          }}
        >
          <Typography
            variant="p"
            sx={{
              margin: '0px',
              wordBreak: 'break-word',
              fontFamily: 'Kyiv-Sans-Regular !important',
              fontSize: '28px',
              textAlign: 'left'
            }}
          >
            Розширте можливості свого акаунту перший місяць
            <br />
            <Typography
              variant="p"
              sx={{
                fontFamily: 'Kyiv-Sans-Regular !important',
                fontSize: '30px',
                color: '#60A5DF'
              }}
            >
              {'всього за 1 грн '}
            </Typography>
            з Преміум!
          </Typography>

          <LetsGoBtn
            className="service-card-btn"
            width="200px"
            margin="25px 0px 0px 0px"
            text="Спробувати зараз"
            onClick={() => {
             
              if (userData?.id) {
               makePayment(dispatch, 'FREE_TRIAL_PREMIUM')
              } else {
                dispatch(setIsOpenAuthModal(true));
              }
            }}
          />
        </Grid>

        <Grid
          item
          xs={5}
          sx={{
            display: 'flex',
            // justifyContent: 'start',
            // flexDirection: 'column',
            // alignItems: 'start',
            flexWrap: 'wrap'
          }}
        >
          <Stack
            sx={{
              maxWidth: '50%',
              flexDirection: 'column',
              justifyContent: 'space-around',
              p: '20px 0px'
            }}
          >
            <Typography
              sx={{
                alignItems: 'center',
                display: 'flex',
                fontFamily: 'Kyiv-Sans-Regular !important',
                fontSize: '14px',
                lineHeight: '16px'
              }}
            >
              <CheckCircleOutlinedIcon sx={{ fontSize: '22px', mr: 1, color: '#60A5DF' }} />5 послуг
              для створення
            </Typography>
            <Typography
              sx={{
                alignItems: 'center',
                display: 'flex',
                fontFamily: 'Kyiv-Sans-Regular !important',
                fontSize: '14px',
                lineHeight: '16px'
              }}
            >
              <CheckCircleOutlinedIcon sx={{ fontSize: '22px', mr: 1, color: '#60A5DF' }} />
              Доступ до бустерів
            </Typography>
            <Typography
              sx={{
                alignItems: 'center',
                textAlign: 'left',
                display: 'flex',
                fontFamily: 'Kyiv-Sans-Regular !important',
                fontSize: '14px',
                lineHeight: '16px'
              }}
            >
              <CheckCircleOutlinedIcon sx={{ fontSize: '22px', mr: 1, color: '#60A5DF' }} />
              Можливість просування акаунту та послуг
            </Typography>
            <Typography
              sx={{
                alignItems: 'center',
                display: 'flex',
                fontFamily: 'Kyiv-Sans-Regular !important',
                fontSize: '14px',
                lineHeight: '16px'
              }}
            >
              <CheckCircleOutlinedIcon sx={{ fontSize: '22px', mr: 1, color: '#60A5DF' }} />
              Аналітика та звітність
            </Typography>
          </Stack>
          <Stack
            sx={{
              maxWidth: '50%',
              flexDirection: 'column',
              justifyContent: 'flex-start',
              p: '20px 0px'
            }}
          >
            <Typography
              sx={{
                alignItems: 'center',
                display: 'flex',
                fontFamily: 'Kyiv-Sans-Regular !important',
                fontSize: '14px',
                lineHeight: '16px',
                mt:'10px'
              }}
            >
              <CheckCircleOutlinedIcon sx={{ fontSize: '22px', mr: 1, color: '#60A5DF' }} />
              Календар замовлень
            </Typography>

            <Typography
              sx={{
                alignItems: 'center',
                display: 'flex',
                fontFamily: 'Kyiv-Sans-Regular !important',
                fontSize: '14px',
                lineHeight: '16px',
                mt:'25px'
              }}
            >
              <CheckCircleOutlinedIcon sx={{ fontSize: '22px', mr: 1, color: '#60A5DF' }} />
              Історія замовлень
            </Typography>
            {/* <Typography
              sx={{
                alignItems: 'center',
                textAlign: 'left',
                display: 'flex',
                fontFamily: 'Kyiv-Sans-Regular !important',
                fontSize: '14px',
                lineHeight: '16px'
              }}
            >
              <CheckCircleOutlinedIcon sx={{ fontSize: '22px', mr: 1, color: '#60A5DF' }} />
              Автоматизоване завантаження створених послуг
            </Typography> */}
            <Typography
              sx={{
                alignItems: 'center',
                display: 'flex',
                fontFamily: 'Kyiv-Sans-Regular !important',
                fontSize: '14px',
                lineHeight: '16px',
                mt:'25px'
              }}
            >
              <CheckCircleOutlinedIcon sx={{ fontSize: '22px', mr: 1, color: '#60A5DF' }} />
              Преміум підтримка 24/7
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <img width={180} src={Illustration1} alt="Illustration1" />
        </Grid>
      </Grid>
    </Paper>
  );
}
const mapStateToProps = (state) => {
  return {
    language: state.Intl.locale,
    userData: state.global.userData
  };
};
export default connect(mapStateToProps)(FreeTariffCard);
