import React, { useEffect } from 'react';

//Material
import CloseIcon from '@mui/icons-material/Close';

import { Box, Grid, Modal, TextField, Typography } from '@mui/material';

import { connect, useDispatch } from 'react-redux';
import { LetsGoBtn3 } from '../../components/Buttons';
import { StyledLabel } from '../../components/StyledComponents';
import { setIsOpenComplainModal } from '../../redux/mainReducer';


import styled from '@emotion/styled';
import { useState } from 'react';
import './DeleteModal.css';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',

  maxWidth: 500,
  bgcolor: 'background.paper',
  borderRadius: '20px',
  border: 'none',
  boxShadow: 24,
  padding: '50px',
  overflow: 'scroll',
  height: 'inherit',
  maxHeight: '100vh',
  scrollbarWidth: 'none',
  '&::-webkit-scrollbar': {
    display: 'none'
  }
};
const CssTextField = styled(TextField)({
  '& label.Mui-focused': {
    color: '#000000'
  },
  '& label': {
    color: '#A1A1A1',
    fontFamily: 'Kyiv-Sans-Light',
    fontSize: "14px"
  },
 
  // '& .MuiInput-root.Mui-focused:after': {
  //   borderBottom: '2px #000000 solid'
  // },
  // '& .MuiInput-root:hover:not(.Mui-disabled):before': {
  //   borderBottom: '2px #000000 solid'
  // },
  // '& .MuiInput-root:not(.Mui-error):before': {
  //   borderBottom: '2px #000000 solid'
  // },
  margin: '5px 0px'
});

function ComplainModal({ isOpenModal, func }) {
  const [selectedChoice, setSelectedChoce] = useState('');
  const [otherText, setOtherText] = useState('');
  const dispatch = useDispatch();
  const choices = [
    { text: 'Неприйнятний контент', enum: 'UNACCEPTABLE_CONTENT' },
    { text: 'Дискримінація', enum: 'DISCRIMINATION' },
    { text: 'Дезінформація', enum: 'DISINFORMATION' },
    { text: 'Шахрайство та обман', enum: 'FRAUD_AND_DECEPTION' },
    { text: 'Порушення конфіденційності', enum: 'VIOLATION_OF_CONFIDENTIALITY' },
    { text: 'Спам', enum: 'SPAM' },
    { text: 'Кібербулінг', enum: 'CYBERBULLYING' },
    { text: 'Авторське право', enum: 'COPYRIGHT' },
    { text: 'Незаконний контент', enum: 'ILLEGAL_CONTENT' },
    { text: 'Пропаганда насильства', enum: 'ADVOCACY_OF_VIOLENCE' },
    { text: 'Інше', enum: 'OTHER' }
  ];
useEffect(()=>{
setSelectedChoce('')
setOtherText('')
return ()=>{
  setSelectedChoce('')
setOtherText('')
}
},[])
  return (
    <div>
      <Modal
        open={isOpenModal}
        onClose={(event, reason) => {
          if (reason === 'backdropClick') {
            return;
          }

          dispatch(setIsOpenComplainModal({ isOpen: false, func: null }));
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="bonfair_delete_modal"
      >
        <Box sx={style} className="bonfair_delete_modal-box">
          {/* <img
            style={{ position: 'fixed', zIndex: '0', top: '0', left: '0' }}
            src={starGreen}
            alt="star-green"
          />
          <img
            style={{ position: 'fixed', zIndex: '0', bottom: '40px', right: '0' }}
            src={starBlue}
            alt="star-green"
          /> */}
          <CloseIcon
            className="bonfair_delete_modal-close"
            style={{ position: 'absolute', right: '10px', top: '10px', cursor: 'pointer' }}
            onClick={() => {
              dispatch(setIsOpenComplainModal({ isOpen: false, func: null}));
            }}
          />

          <Grid container spacing={2} sx={{ width: '300px' }}>
            <Grid item xs={12} md={12} lg={12}>
              <StyledLabel className="deleteModal-title-text">
                {/* {translate('bonfair_service_modal')} */}
                {'Поскаржитись:'}
              </StyledLabel>
            </Grid>
            <Grid item xs={12} md={12} lg={12} sx={{ display: 'flex', flexDirection: 'column' }}>
              {choices.map((choice,index) => (
                <Box
                key={`choice${index}`}
                  onClick={() =>{ setSelectedChoce(choice?.enum)
                  setOtherText('')
                  }}
                  sx={
                    choice?.enum === selectedChoice
                      ? {
                          borderRadius: '50px',
                          border: '1px solid #CFE9FF',
                          p: '5px 10px',
                          mb: '5px',
                          width: '100%',
                          backgroundColor: '#CFE9FF',
                          cursor: 'pointer',
                          zIndex:'99',
                          ':hover': {
                            backgroundColor: '#CFE9FF'
                          }
                        }
                      : {
                          borderRadius: '50px',
                          border: '1px solid #CFE9FF',
                          p: '5px 10px',
                          mb: '5px',
                          width: '100%',
                          backgroundColor: 'white',
                          cursor: 'pointer',
                          zIndex:'99',
                          ':hover': {
                            backgroundColor: '#CFE9FF'
                          }
                        }
                  }
                >
                  <Typography sx={{ fontFamily: 'Kyiv-Sans-Regular' }}>{choice?.text}</Typography>
                </Box>
              ))}
            </Grid>
            {selectedChoice === 'OTHER' ? (
              <Grid item xs={12} md={12} lg={12}>
                <CssTextField
                  label={
                    <div style={{ display: 'flex' }}>
                      Коротко опишіть проблему
                    </div>
                  }
                  InputProps={{
                    endAdornment: (
                      <p style={{fontFamily: 'Kyiv-Sans-Regular',fontSize:'14px',margin:'0'}}>{`${otherText.length}/100`}</p>
                      
                        
                    )
                  }}
                  variant="standard"
                  fullWidth
                  size="normal"
                  inputProps={{ style: { textAlign: 'left' } }}
                  // error={Boolean(touched.email && errors.email)}

                  value={otherText}
                  onChange={(event) => {
                    if (event?.target?.value.length <= 100) {
                      setOtherText(event?.target?.value);
                    }
                  }}
                >
                  {otherText}
                </CssTextField>
              </Grid>
            ) : null}

            <Grid
              item
              xs={12}
              style={{
                display: 'flex',
                justifyContent: 'center',
                margin: '20px auto 0px',
                maxWidth: '350px'
              }}
            >
              <LetsGoBtn3
                size="large"
                disabled={selectedChoice.length === 0||(selectedChoice==='OTHER'&&otherText.length===0) ? true : false}
                onClick={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                  func(selectedChoice,otherText);
                  dispatch(
                    setIsOpenComplainModal({ isOpen: false, func: null })
                  );
                }}
                width={255}
                style={{ textTransform: 'inherit' }}
                text="Відправити"
              />
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    language: state.Intl.locale
  };
};
export default connect(mapStateToProps)(ComplainModal);
