import { Container } from '@mui/material';

import { useSnackbar } from 'notistack';
import { useEffect, useRef } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import './App.css';
import ApiService from './helpers/apiHelper';

import { Client } from '@stomp/stompjs';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import CancelModal from './pages/modals/CancelModal';
import DeleteModal from './pages/modals/DeleteModal';
import ResendingModal from './pages/modals/ResendingModal';
import SendSupportModal from './pages/modals/SendSupportModal';
import {
  setIsNewMessage,
  setIsNewNotification,
  setIsOpenAuthModal,
  setSocketInfoMsg,
  setSocketMsg,
  setStompClient,
  setUserData,
  setUserSubscribers,
  setUserSubscriptions
} from './redux/mainReducer';
import { base } from './redux/thunk';
import RouterComponent from './routes';
import ThemeConfig from './theme';
import DeleteModalAttention from './pages/modals/DeleteModalAttention';
import DeleteAccModal from './pages/modals/DeleteAccModal';
import ChangemailModal from './pages/modals/ChangemailModal';
import DeleteAccAttention from './pages/modals/DeleteAccAttention';
import Enable2FAModal from './pages/modals/Enable2FAModal';
import ComplainModal from './pages/modals/ComplainModal';
import PostModal from './pages/modals/PostModal';
import PaymentModal from './pages/modals/PaymentModal';
import PremiumCancelModal from './pages/modals/PremiumCancelModal';
import PremiumChooseModal from './pages/modals/PremiumChooseModal';

// import { io } from 'socket.io-client';

const api = new ApiService('auth/user/enable-activation');
const apiChangeEmail = new ApiService('auth/user/update-email');
// const SOCKET_URL = `${window.config.RABBIT_APP_HOST}`;
const SOCKET_URL = `wss://rabbitmq.bandb.com.ua/ws`;
// const SOCKET_URL = `wss://dev.bonfairplace.com/ws`;

// eslint-disable-next-line

function App({ userData, stompClient, getUserDataThunk, isNewMessage, isNewNotification }) {
  const stompSubscribeRef = useRef();
  const { enqueueSnackbar } = useSnackbar();
  const search = useLocation().search;
  const isActive = new URLSearchParams(search).get('isActive');
  const isEmail = new URLSearchParams(search).get('email');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isOpenDeleteModal = useSelector((state) => state.global.isOpenDeleteModal);
  const isOpenDeleteAttentionModal = useSelector(
    (state) => state.global.isOpenDeleteAttentionModal
  );
  const isOpenCancelModal = useSelector((state) => state.global.isOpenCancelModal);
  const isOpenResendingModal = useSelector((state) => state.global.isOpenResendingModal);
  const isOpenSupportModal = useSelector((state) => state.global.isOpenSupportModal);
  const isOpenDeleteAccModal = useSelector((state) => state.global.isOpenDeleteAccModal);
  const isOpenChangeMailModal = useSelector((state) => state.global.isOpenChangeMailModal);
  const isOpenEnable2FAModal = useSelector((state) => state.global.isOpenEnable2FAModal);
  const isOpenPostModal = useSelector((state) => state.global.isOpenPostModal);
  const isOpenComplainModal = useSelector((state) => state.global.isOpenComplainModal);
  const isOpenDeleteAccAttentionModal = useSelector(
    (state) => state.global.isOpenDeleteAccAttentionModal
  );
  const isOpenPremiumCancelModal = useSelector((state) => state.global.isOpenPremiumCancelModal);
  const isOpenPremiumModal = useSelector((state) => state.global.isOpenPremiumModal);
  const isOpenPaymentModal = useSelector((state) => state.global.isOpenPaymentModal);
  const socketInfoMsg = useSelector((state) => state.global.socketInfoMsg);
  useEffect(() => {
    const client = new Client({
      brokerURL: SOCKET_URL,
      onConnect: () => {
        dispatch(setStompClient(client));
      },
      onDisconnect: (e) => {
        console.log('STOMP DISCONNECTED', e);
        dispatch(setSocketMsg(null));
        dispatch(setStompClient(null));
      },
      onWebSocketClose: (e) => {
        console.log('WEBSOCKET CLOSED', e);
        dispatch(setStompClient(null));
      },
      connectionTimeout: 5000,
      connectHeaders: {
        // login: window.config.RABBIT_APP_LOGIN,
        login: 'user',
        // passcode: window.config.RABBIT_APP_PASSCODE,
        passcode: 'FdEsAxGZTDSkJV8t',
        Authorization: localStorage.getItem('accessToken')
      }
    });
    if (!stompClient) {
      client.activate();
    }
    return () => {
      if (stompClient) {
        stompClient.deactivate();
      }
    };
    // eslint-disable-next-line
  }, [stompClient]);
  useEffect(() => {
    isActive &&
      api
        .sendRequest({
          email: atob(isActive)
        })
        .then((res) => {
          enqueueSnackbar('Акаунт успішно активовано', { variant: 'success' });
          navigate('/');
          dispatch(setIsOpenAuthModal(true));
        })
        .catch((error) => enqueueSnackbar('Акаунт вже було активовано', { variant: 'error' }));
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    isEmail &&
      apiChangeEmail
        .updateDataQueryPostBody(
          { oldEmail: JSON.parse(atob(isEmail)).oldMail },
          {
            email: JSON.parse(atob(isEmail)).newMail
          }
        )
        .then((res) => {
          enqueueSnackbar('Електронну адресу успішно змінено', { variant: 'success' });
          navigate('/');
          localStorage.removeItem('refreshToken');
          localStorage.removeItem('accessToken');
          dispatch(setUserData({}));
          dispatch(setUserSubscribers([]));
          dispatch(setUserSubscriptions([]));
          dispatch(setIsOpenAuthModal(true));
        })
        .catch((error) => enqueueSnackbar('Акаунт вже було активовано', { variant: 'error' }));
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    const token = localStorage.getItem('accessToken');

    if ((!userData && token) || (Object.keys(userData).length === 0 && token)) {
      getUserDataThunk();

      // .catch((error) => enqueueSnackbar('Потрібно авторизуватись', { variant: 'error' }));
    }

    // eslint-disable-next-line
  }, [userData]);
  useEffect(() => {
    let subscribe = null;
    //sss
    if (stompClient && stompClient?.connected && userData?.id) {
      //sss
      subscribe = stompClient.subscribe(
        `/queue/info-${userData.id}`,
        (message) => {
          dispatch(setSocketInfoMsg(JSON.parse(message.body)));
        },
        { 'auto-delete': true }
      );
      stompSubscribeRef.current = subscribe.id;
    } else {
      dispatch(setSocketInfoMsg(null));
    }

    return () => {
      if (stompClient && stompClient.connected && stompSubscribeRef?.current) {
        stompClient.unsubscribe(stompSubscribeRef.current);
        // stompClient.deactivate();
      }
    };
    // eslint-disable-next-line
  }, [stompClient, userData]);

  const onMessageReceived = (message) => {
    if (!isNewMessage && message.message === 'New message') {
      dispatch(setIsNewMessage(true));
    }
    if (isNewMessage && message.message === 'isNewMessage is false') {
      dispatch(setIsNewMessage(false));
    }
    if (!isNewNotification && message === 'New notification') {
      dispatch(setIsNewNotification(true));
    }
    if (isNewNotification && message === 'No new notifications') {
      dispatch(setIsNewNotification(false));
    }
  };
  useEffect(() => {
    if (socketInfoMsg) {
      onMessageReceived(socketInfoMsg);
    }
    // eslint-disable-next-line
  }, [socketInfoMsg]);
  return (
    <Container
      className="mainPage-container"
      style={{
        maxWidth: '1440px',
        margin: '0 auto',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        padding: '0',
        overflow: 'hidden'
      }}
    >
      <ThemeConfig>
        {isOpenDeleteModal.isOpen && (
          <DeleteModal
            isOpenModal={isOpenDeleteModal.isOpen}
            text={isOpenDeleteModal.text}
            func={isOpenDeleteModal.func}
            id={isOpenDeleteModal.id}
          />
        )}
        {isOpenDeleteAttentionModal && (
          <DeleteModalAttention isOpenModal={isOpenDeleteAttentionModal} />
        )}
        {isOpenPaymentModal.isOpen && (
          <PaymentModal
            isOpenModal={isOpenPaymentModal.isOpen}
            data={isOpenPaymentModal.data}
            signature={isOpenPaymentModal.signature}
          />
        )}

        {isOpenCancelModal?.isOpen && (
          <CancelModal
            isOpenModal={isOpenCancelModal.isOpen}
            text={isOpenCancelModal.text}
            func={isOpenCancelModal.func}
            id={isOpenCancelModal.id}
          />
        )}
        {isOpenResendingModal.isOpen && (
          <ResendingModal
            isOpenModal={isOpenResendingModal.isOpen}
            type={isOpenResendingModal.type}
            id={isOpenResendingModal.id}
            func={isOpenResendingModal.func}
          />
        )}
        {isOpenPostModal && (
          <PostModal
            isOpenModal={isOpenPostModal.isOpen}
            setter={isOpenPostModal.func}
            data={isOpenPostModal?.data}
          />
        )}
        {isOpenSupportModal && <SendSupportModal isOpenModal={isOpenSupportModal} />}
        {isOpenDeleteAccModal && <DeleteAccModal isOpenModal={isOpenDeleteAccModal} />}
        {isOpenEnable2FAModal && <Enable2FAModal isOpenModal={isOpenEnable2FAModal} />}
        {isOpenComplainModal.isOpen && (
          <ComplainModal isOpenModal={isOpenComplainModal.isOpen} func={isOpenComplainModal.func} />
        )}

        {isOpenChangeMailModal && <ChangemailModal isOpenModal={isOpenChangeMailModal} />}
        {isOpenDeleteAccAttentionModal && (
          <DeleteAccAttention isOpenModal={isOpenDeleteAccAttentionModal} />
        )}
        {isOpenPremiumCancelModal && <PremiumCancelModal isOpenModal={isOpenPremiumCancelModal} />}
        {isOpenPremiumModal && <PremiumChooseModal isOpenModal={isOpenPremiumModal} />}

        <RouterComponent />
      </ThemeConfig>
    </Container>
  );
}

const mapStateToProps = (state) => {
  return {
    language: state.Intl.locale,
    userData: state.global.userData,
    isNewMessage: state.global.isNewMessage,
    isNewNotification: state.global.isNewNotification,
    stompClient: state.global.stompClient
  };
};
export default connect(mapStateToProps, {
  getUserDataThunk: base.getUserDataThunk
})(App);
