import React, { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useTranslate } from 'react-redux-multilingual';
import Page from '../../components/Page';
import { base } from '../../redux/thunk';
// import { useNavigate } from 'react-router-dom';

//Material
import AddIcon from '@mui/icons-material/Add';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import PushPinOutlinedIcon from '@mui/icons-material/PushPinOutlined';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
// import PersonOffOutlinedIcon from '@mui/icons-material/PersonOffOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import NotificationsOffOutlinedIcon from '@mui/icons-material/NotificationsOffOutlined';
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import NotInterestedOutlinedIcon from '@mui/icons-material/NotInterestedOutlined';
import ReportGmailerrorredOutlinedIcon from '@mui/icons-material/ReportGmailerrorredOutlined';

import ReplyIcon from '@mui/icons-material/Reply';
import {
  Avatar,
  Box,
  Grid,
  Menu,
  MenuItem,
  Paper,
  Stack,
  Tooltip,
  Typography
} from '@mui/material';
import DefaultAvatar from '../../assets/defaultPostImg.jpg';
import BigSwitcher from '../../components/BigSwitcher';
import { FreeBtn, LetsGoBtn2, LetsGoBtn3, ToTopBtn } from '../../components/Buttons';
import ApiService from '../../helpers/apiHelper';
import {
  setIsOpenComplainModal,
  setIsOpenImageCropModal,
  setIsOpenPostModal,
  setIsOpenResendingModal,
  setIsOpenServiceModal,
  setIsOpenUserContactsModal,
  setIsOpenUserEditModal,
  setIsOpenUserReadersModal
} from '../../redux/mainReducer';
import '../MainPage/index.css';
import ServiceModal from '../modals/ServiceModal';
import UserContactsModal from '../modals/UserContactsModal';
import UserEditModal from '../modals/UserEditModal';
import UserReadersModal from '../modals/UserReadersModal';

import { useSnackbar } from 'notistack';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Element } from 'react-scroll';
import PostCard from '../../components/PostCard';
import ServiceCard from '../../components/ServiceCard';
import { Spinner } from '../../components/Spinner';
import CropModal from '../modals/CropModal';
// import { Spinner } from '../../components/Spinner';

function UserPage(props) {
  const translate = useTranslate();
  const params = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const [userData, setUserData] = useState({});
  const [startWith, setStartWith] = useState(0);
  const [pageScroll, setPageScroll] = useState(0);
  const [loadMore, setLoadMore] = useState(false);
  const [contentMaxCount, setContentMaxCount] = useState(0);

  const apiUser = new ApiService('auth/user/page');
  const apiPosts = new ApiService('posts/post/userPost');
  const apiServices = new ApiService('posts/service/userServices');
  const apiAvatar = new ApiService('auth/add-photo');
  const apiSubscribe = new ApiService('auth/subscribe');
  const apiSubscribers = new ApiService('auth/get-subscribers');
  const apiSubscriptions = new ApiService('auth/get-subscriptions');
  const apiChats = new ApiService('notification/chats');
  const apiComplaint = new ApiService('auth/user/complaint-user');
  // const apiUser = new ApiService('/user/page');
  // const apiPosts = new ApiService('post/userPost');
  // const apiServices = new ApiService('service/userServices');
  // const apiAvatar = new ApiService('add-photo');
  // const apiSubscribe = new ApiService('subscribe');
  // const apiSubscribers = new ApiService('get-subscribers');
  // const apiSubscriptions = new ApiService('get-subscriptions');
  // const apiChats = new ApiService('chats');
  // const apiComplaint = new ApiService('/user/complaint-user');

  const apiMuteUser = new ApiService('auth/muted-users/add-muted-user');
  const apiUnmuteUser = new ApiService('auth/muted-users/remove-muted-user');

  const apiUnSubscribe = new ApiService('auth/unsubscribe');
  // const apiBU = new ApiService('/blocked-users');
  const apiBU = new ApiService('auth/blocked-users');

  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const [contentSwitched, setContentSwitched] = useState(false);
  const [file, setFile] = useState(null);
  const [imgs, setImgs] = useState([]);
  const [userSubscribers, setUserSubscribers] = useState([]);
  const [userSubscriptions, setUserSubscriptions] = useState([]);
  const [isSendDataRequest, setIsSendDataRequest] = useState(false);

  const [content, setContent] = useState([]);
  const isOpenServiceModal = useSelector((state) => state.global.isOpenServiceModal);
  // const isOpenPostModal = useSelector((state) => state.global.isOpenPostModal);
  const isOpenUserEditModal = useSelector((state) => state.global.isOpenUserEditModal);
  const isOpenUserContactsModal = useSelector((state) => state.global.isOpenUserContactsModal);
  const isOpenUserReadersModal = useSelector((state) => state.global.isOpenUserReadersModal);
  const isOpenImageCropModal = useSelector((state) => state.global.isOpenImageCropModal);
  const mutedUsers = useSelector((state) => state.global.mutedUsers);
  const [isLoading, setIsLoadiang] = useState(false);
  const [isLoadingUserData, setIsLoadiangUserData] = useState(false);
  // const [isCreatePost, setIsCreatePost] = useState(false);
  // const [params] = useSearchParams();
  // const navigate = useNavigate();
  // console.log(contentSwitched);
  // const testUsers = ['1', '2', '3'];
  function getFileFromBase64(string64, fileName) {
    const trimmedString = string64.replace('dataimage/jpegbase64', '');
    const imageContent = atob(trimmedString);
    const buffer = new ArrayBuffer(imageContent.length);
    const view = new Uint8Array(buffer);

    for (let n = 0; n < imageContent.length; n++) {
      view[n] = imageContent.charCodeAt(n);
    }
    const type = 'image/jpeg';
    const blob = new Blob([buffer], { type });
    return new File([blob], fileName, { lastModified: new Date().getTime(), type });
  }
  const sendComplaint = (type, text) => {
    apiComplaint
      .updateDataID(userData?.id, {
        complaintType: type,
        complaintDescription: text.length !== 0 ? text : null
      })
      .then((res) => {
        dispatch(setIsOpenComplainModal({ isOpen: false, func: null }));
        enqueueSnackbar('Скаргу надіслано.', { variant: 'success' });
      })
      .catch((error) => {
        console.error(error);
        enqueueSnackbar('Сталась помилка.', { variant: 'error' });
      });
  };
  const userDataSetter = () => {
    if (params.id !== props.userData.id) {
      setIsLoadiangUserData(true);
      apiUser
        .getItemById(params.id)
        .then((res) => {
          setUserData(res.result);
          setIsLoadiangUserData(false);
          apiSubscribers.getAll({ userId: res.result.id }).then((res) => {
            setUserSubscribers(res.result);
          });

          apiSubscriptions.getAll({ userId: res.result.id }).then((res) => {
            setUserSubscriptions(res.result);
          });

          if (res.result.encodedMediaDTO) {
            setImgs([
              getFileFromBase64(
                res.result.encodedMediaDTO.encodedContent,
                res.result.encodedMediaDTO.nameFile
              )
            ]);
          }
        })
        .catch((error) => {
          if (error?.info?.user.includes(`User doesn't exists`)) {
            navigate(`/${props.language}/error`);
          }
          if (error?.result && error?.result.includes('cannot access page user with id')) {
            navigate(`/${props.language}/error`);
          }
          console.error(error.message);
        });
    } else {
      setUserData(props.userData);
      if (props.userData.encodedMediaDTO) {
        setImgs([
          getFileFromBase64(
            props.userData.encodedMediaDTO.encodedContent,
            props.userData.encodedMediaDTO.nameFile
          )
        ]);
      }
    }
  };
  useEffect(() => {
    userDataSetter();
    // eslint-disable-next-line
  }, []);
  const dataSetter = (contentSwitched) => {
    setIsLoadiang(true);
    if (!contentSwitched) {
      // setIsCreatePost(false);
      apiPosts
        .getItemById(params.id, { page: startWith, size: 8 })
        .then((res) => {
          setContent(res.result);
          setContentMaxCount(res.count);

          setIsLoadiang(false);
        })
        .catch((error) => {
          console.error(error);
          setIsLoadiang(false);
        });
    } else {
      apiServices
        .getItemById(params.id, { page: startWith, size: 8 })
        .then((res) => {
          setContent(res.result);
          setContentMaxCount(res.count);

          setIsLoadiang(false);
        })
        .catch((error) => {
          console.error(error);
          setIsLoadiang(false);
        });
    }
  };
  const handleLoadMore = () => {
    setLoadMore(true);

    if (contentSwitched) {
      apiServices
        .getItemById(params.id, { page: startWith + 1, size: 8 })
        .then((res) => {
          setContentMaxCount(res.count);
          setLoadMore(false);
          setContent([...content, ...res.result]);
          setStartWith(startWith + 1);
        })
        .catch((error) => console.error(error));
    } else {
      apiPosts
        .getItemById(params.id, { page: startWith + 1, size: 8 })
        .then((res) => {
          setContentMaxCount(res.count);
          setLoadMore(false);
          setContent([...content, ...res.result]);
          setStartWith(startWith + 1);
        })
        .catch((error) => console.error(error));
    }
  };
  const [bools, setBools] = useState([true, false]);

  const [isHideDesc, setIsHideDesc] = useState(true);
  // eslint-disable-next-line

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  useEffect(() => {
    const type = searchParams.get('type');
    if (type === 'posts') {
      setContentSwitched(false);
      setBools([true, false]);
      dataSetter(false);
    } else {
      setContentSwitched(true);
      setBools([false, true]);
      dataSetter(true);
    }
    // eslint-disable-next-line
  }, [searchParams.get('type')]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  useEffect(() => {
    window.addEventListener('scroll', () => setPageScroll(window.scrollY));
    return () => {
      window.removeEventListener('scroll', () => setPageScroll(window.scrollY));
    };
  }, []);

  return (
    <Page
      title={translate('bonfair_myPage')}
      style={{
        display: 'flex',
        marginTop: '150px',
        alignItems: 'center',
        flexDirection: 'column',
        height: 'max-content',
        padding: '0px 100px'
      }}
    >
      <Element name="topUserPage" />
      <Stack spacing={1} justifyContent="center" alignItems="center">
        <Grid
          container
          style={{
            maxWidth: '1440px',
            minWidth: '1265px'
          }}
          spacing={3}
        >
          {params.id !== props.userData.id && (
            <Grid
              item
              xs={12}
              sx={{
                display: 'flex',
                justifyContent: 'flex-start'
              }}
            >
              <Typography
                variant="subtitle"
                sx={{
                  display: 'inline-flex',
                  cursor: 'pointer',
                  color: 'black',
                  ':hover': { color: '#60A5DF' }
                }}
                onClick={() => navigate(-1)}
              >
                <ReplyIcon sx={{ mr: 1, color: 'black' }} />
                Повернутись на попередню сторінку
              </Typography>
            </Grid>
          )}
          <Grid item xs={4} />
          <Grid
            item
            xs={4}
            style={
              params.id !== props.userData.id
                ? { position: 'fixed', maxWidth: '421.66px', top: '193px' }
                : { position: 'fixed', maxWidth: '421.66px' }
            }
          >
            <Paper
              elevation={1}
              sx={{
                border: !props.blockedUsers.find((el) => el.id === userData.id)
                  ? '1px solid black'
                  : 'none',
                boxShadow: props?.blockedUsers.find((el) => el.id === userData?.id)
                  ? '0px 0px 2px 2px rgba(255,0,0,1)'
                  : 'none',
                padding: '23px',
                marginBottom: '100px',
                width: isLoadingUserData ? '397.56px !important' : 'unset',
                minHeight: isLoadingUserData ? '353px' : 'unset',
                display: isLoadingUserData ? 'flex' : 'block',
                alignItems: isLoadingUserData ? 'center' : 'unset',
                justifyContent: isLoadingUserData ? 'center' : 'unset'
              }}
            >
              <Grid
                container
                spacing={2}
                sx={isLoadingUserData ? { marginLeft: '0px !important' } : {}}
              >
                <CropModal
                  isOpenModal={isOpenImageCropModal}
                  file={file}
                  setter={(file) => {
                    apiAvatar
                      .sendRequest({ media: [file] }, true)
                      .then((res) => {
                        const newArray = [...imgs];
                        newArray.push(file);
                        setImgs([...newArray]);
                        props.getUserDataThunk();
                      })
                      .catch((error) => {
                        if (error === 'The selected photo is not in a supported format') {
                          enqueueSnackbar(
                            'Не вірний формат фото, використовуйте (.jpg .jpeg .png)',
                            { variant: 'error' }
                          );
                        }
                      });
                  }}
                />
                {!isLoadingUserData ? (
                  <>
                    {' '}
                    <Grid item xs={12} flexDirection="row" display="flex">
                      <div
                        style={{
                          width: '100%',
                          display: 'flex',
                          flexDirection: 'row',
                          flexWrap: 'wrap',
                          // justifyContent:'space-between',
                          paddingBottom: '14px',
                          marginBottom: '0px',
                          marginLeft: 'auto',
                          marginRight: 'auto'
                        }}
                      >
                        {imgs.length !== 0 ? (
                          imgs.map((file, index) => (
                            <div
                              key={'image' + index}
                              className={params.id === props.userData.id ? 'uploaded-avatar' : ''}
                              onClick={() => {
                                if (params.id === props.userData.id) {
                                  const newArray = imgs;
                                  newArray.splice(index, 1);
                                  setImgs([...newArray]);
                                }
                              }}
                            >
                              <Avatar
                                // src={userData?.avatar ? userData.avatar : DefaultAvatar}
                                src={URL.createObjectURL(file)}
                                sx={{
                                  width: 150,
                                  height: 150,
                                  border: !props.blockedUsers.find((el) => el.id === userData?.id)
                                    ? '1px solid black'
                                    : 'none',
                                  boxShadow: props?.blockedUsers.find(
                                    (el) => el.id === userData?.id
                                  )
                                    ? '0px 0px 2px 2px rgba(255,0,0,1)'
                                    : 'none',
                                  borderRadius: '5px'
                                }}
                                variant="square"
                              />
                            </div>
                          ))
                        ) : (
                          <div
                            key={'image'}
                            className={params.id === props.userData.id ? 'upload-avatar' : ''}
                            onClick={() => {
                              if (params.id === props.userData.id) {
                                const btn = document.getElementById('button-file-avatar');
                                btn.click();
                              }
                            }}
                          >
                            <input
                              accept="image/*"
                              style={{ display: 'none' }}
                              id="button-file-avatar"
                              onChange={(e) => {
                                setFile(e.target.files[0]);
                                dispatch(setIsOpenImageCropModal(true));
                                e.target.value = '';
                              }}
                              type="file"
                            />
                            <label
                              htmlFor="button-file-avatar"
                              style={{
                                width: '150px',
                                height: '150px',
                                cursor: 'pointer'
                                // zIndex:'99'
                              }}
                            >
                              <Avatar
                                src={DefaultAvatar}
                                sx={{
                                  width: 150,
                                  height: 150,
                                  borderRadius: '5px',
                                  border: !props.blockedUsers.find((el) => el.id === userData?.id)
                                    ? '1px solid black'
                                    : 'none',
                                  boxShadow: props?.blockedUsers.find(
                                    (el) => el.id === userData?.id
                                  )
                                    ? '0px 0px 2px 2px rgba(255,0,0,1)'
                                    : 'none'
                                }}
                                variant="square"
                              />
                            </label>
                          </div>
                        )}
                      </div>

                      <Stack sx={{ width: '100%', position: 'relative' }}>
                        {userData?.name && userData?.surname && (
                          <Typography fontSize={18} lineHeight="20px" fontWeight={400}>
                            {`${userData?.name} ${userData?.surname}`}
                          </Typography>
                        )}

                        {params.id === props.userData.id ? (
                          <div
                            style={{
                              position: 'absolute',
                              color: '#60A5DF',
                              right: '10px',
                              top: '-1px',
                              fontSize: '26px',
                              cursor: 'pointer'
                            }}
                          >
                            <Tooltip title="Редагувати дані">
                              <EditOutlinedIcon
                                onClick={() => {
                                  dispatch(setIsOpenUserEditModal(true));
                                }}
                                style={{
                                  position: 'absolute',
                                  color: '#60A5DF',
                                  right: '-25px',
                                  top: '-1px',
                                  fontSize: '26px',
                                  cursor: 'pointer',
                                  // border: '1px solid black',
                                  borderRadius: '0',
                                  boxShadow: 'none'
                                }}
                              />
                            </Tooltip>
                          </div>
                        ) : (
                          <div
                            style={{
                              position: 'absolute',

                              right: '-10px',
                              top: '-5px',
                              fontSize: '26px',
                              cursor: 'pointer'
                            }}
                          >
                            <MoreVertIcon
                              onClick={(event) => handleClick(event)}
                              sx={{
                                ml: 1,
                                cursor: 'pointer',
                                ':hover': { color: '#60A5DF' },
                                fontSize: '26px'
                              }}
                            />
                          </div>
                        )}
                        <Menu
                          anchorEl={anchorEl}
                          id="action-menu"
                          open={open}
                          onClose={handleClose}
                          PaperProps={{
                            elevation: 0,
                            sx: {
                              overflow: 'hidden',
                              boxShadow: 'none',
                              borderRadius: '5px',
                              border: '1px solid black',
                              paddingTop: '10px',
                              paddingLeft: '20px',
                              paddingRight: '20px',
                              width: '355px',
                              mt: 1
                            }
                          }}
                          autoFocus={false}
                          transformOrigin={{ horizontal: 'center', vertical: 'top' }}
                          anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
                        >
                          <MenuItem
                            sx={{ padding: 0, mb: 1 }}
                            onClick={() => {
                              dispatch(
                                setIsOpenResendingModal({
                                  isOpen: true,
                                  id: params.id,
                                  type: 'user'
                                })
                              );
                              handleClose();
                            }}
                          >
                            <SendOutlinedIcon
                              sx={{
                                width: '18px',
                                height: '30px',
                                color: '#60A5DF',
                                margin: '0px 10px 0px  !important',
                                transform: 'rotate(315deg)'
                              }}
                            />
                            <Typography
                              component="p"
                              fontSize={16}
                              lineHeight="20px"
                              fontWeight={400}
                            >
                              Відправити профіль в повідомленні
                            </Typography>
                          </MenuItem>
                          {props.subscriptions.find(
                            (subscription) => subscription.id === userData.id
                          ) ? (
                            <MenuItem
                              sx={{ padding: 0, mb: 1 }}
                              onClick={() => {
                                if (!mutedUsers.find((el) => el === userData?.id)) {
                                  apiMuteUser
                                    .postDataQuery({ mutedUserId: userData?.id })
                                    .then((res) => {
                                      props.getMutedUsersThunk();
                                    });
                                } else {
                                  apiUnmuteUser
                                    .deleteItemQuery({ mutedUserId: userData?.id })
                                    .then((res) => {
                                      props.getMutedUsersThunk();
                                    });
                                }
                              }}
                            >
                              {!mutedUsers.find((el) => el === userData.id) ? (
                                <NotificationsOffOutlinedIcon
                                  sx={{
                                    width: '18px',
                                    height: '30px',
                                    color: '#60A5DF',
                                    margin: '0px 10px 0px  !important'
                                  }}
                                />
                              ) : (
                                <NotificationsOutlinedIcon
                                  sx={{
                                    width: '18px',
                                    height: '30px',
                                    color: '#60A5DF',
                                    margin: '0px 10px 0px  !important'
                                  }}
                                />
                              )}

                              <Typography
                                component="p"
                                fontSize={16}
                                lineHeight="20px"
                                fontWeight={400}
                              >
                                {!mutedUsers.find((el) => el === userData?.id)
                                  ? ' Вимкнути сповіщення'
                                  : ' Увімкнути сповіщення'}
                              </Typography>
                            </MenuItem>
                          ) : null}

                          <MenuItem
                            sx={{ padding: 0, mb: 1 }}
                            onClick={(event) => {
                              event.preventDefault();
                              event.stopPropagation();
                              if (!props.blockedUsers.find((el) => el.id === userData.id)) {
                                apiBU
                                  .updateDataQueryPost({ blockedUserId: userData.id })
                                  .then((res) => {
                                    handleClose();
                                    props.getBlockedUsersThunk();
                                    props.getUserSubscribersThunk(props.userData?.id);
                                    props.getUserSubscriptionsThunk(props.userData?.id);
                                  });
                              } else {
                                apiBU
                                  .deleteItemQuery({ blockedUserId: userData.id })
                                  .then((res) => {
                                    handleClose();
                                    props.getBlockedUsersThunk();
                                    props.getUserSubscribersThunk(props.userData?.id);
                                    props.getUserSubscriptionsThunk(props.userData?.id);
                                  });
                              }
                            }}
                          >
                            <NotInterestedOutlinedIcon
                              sx={{
                                width: '18px',
                                height: '30px',
                                color: '#60A5DF',
                                margin: '0px 10px 0px  !important'
                              }}
                            />
                            <Typography
                              component="p"
                              fontSize={16}
                              lineHeight="20px"
                              fontWeight={400}
                            >
                              {!props.blockedUsers.find((el) => el.id === userData.id)
                                ? 'Заблокувати'
                                : 'Розблокувати'}
                            </Typography>
                          </MenuItem>

                          <MenuItem
                            sx={{ padding: 0, mb: 1 }}
                            onClick={() => {
                              dispatch(
                                setIsOpenComplainModal({ isOpen: true, func: sendComplaint })
                              );
                              handleClose();
                            }}
                          >
                            <ReportGmailerrorredOutlinedIcon
                              sx={{
                                width: '18px',
                                height: '30px',
                                color: '#60A5DF',
                                margin: '0px 10px 0px  !important'
                              }}
                            />
                            <Typography
                              component="p"
                              fontSize={16}
                              lineHeight="20px"
                              fontWeight={400}
                            >
                              Поскаржитись
                            </Typography>
                          </MenuItem>
                        </Menu>
                        <Typography
                          component="p"
                          fontSize={userData?.name && userData?.surname ? 14 : 18}
                          lineHeight="16px"
                          fontWeight={400}
                          sx={{ wordBreak: 'break-all', maxWidth: '90%', mt: 1 }}
                        >
                          {`@${userData?.nickname}`}
                        </Typography>

                        {userData?.city &&
                          userData?.city.length !== 0 &&
                          userData?.country &&
                          userData?.country.length !== 0 &&
                          userData?.region &&
                          userData?.region.length !== 0 && (
                            <Typography
                              sx={{ paddingLeft: '25px', mt: '14px' }}
                              fontSize={16}
                              lineHeight="20px"
                              fontWeight={400}
                            >
                              <PushPinOutlinedIcon
                                style={{
                                  position: 'absolute',
                                  left: '-1px',
                                  color: '#60A5DF',
                                  fontSize: '22px',
                                  transform: 'rotate(-45deg)'
                                }}
                              />
                              {`${userData?.city ? userData?.city : ''}${
                                userData?.city &&
                                userData?.city.length !== 0 &&
                                userData?.region &&
                                userData?.region.length !== 0
                                  ? ', '
                                  : ''
                              }${userData?.region ? `${userData.region} обл.` : ''}${
                                userData?.city &&
                                userData?.city.length !== 0 &&
                                userData?.country &&
                                userData?.country.length !== 0
                                  ? ', '
                                  : ''
                              }${userData?.country ? userData.country : ''}`}
                            </Typography>
                          )}
                        {userData?.number &&
                          userData?.number.length === 13 &&
                          userData?.number !== '+380' && (
                            <Typography
                              sx={{ paddingLeft: '25px', mt: '14px' }}
                              fontSize={16}
                              lineHeight="20px"
                              fontWeight={400}
                            >
                              <PhoneAndroidIcon
                                style={{
                                  position: 'absolute',
                                  left: '-1px',
                                  color: '#60A5DF',
                                  fontSize: '22px'
                                }}
                              />
                              {`${userData.number}`}
                            </Typography>
                          )}
                      </Stack>

                      <UserEditModal data={userData} isOpenModal={isOpenUserEditModal} />
                    </Grid>
                    {userData?.description && userData?.description.length !== 0 && (
                      <Grid item xs={12} sx={{ pt: '0px !important' }}>
                        <Typography
                          sx={
                            isHideDesc
                              ? {
                                  paddingLeft: '0px',
                                  mt: '10px',
                                  display: '-webkit-box',
                                  WebkitLineClamp: 2,
                                  WebkitBoxOrient: 'vertical',
                                  overflowY: 'hidden',
                                  textOverflow: 'ellipsis',
                                  wordBreak: 'break-all'
                                }
                              : {
                                  paddingLeft: '0px',
                                  mt: '10px',
                                  display: '-webkit-box',
                                  WebkitLineClamp: 'unset',
                                  WebkitBoxOrient: 'vertical',
                                  overflowY: 'hidden',
                                  textOverflow: 'ellipsis',
                                  wordBreak: 'break-all'
                                }
                          }
                          fontSize={15}
                          lineHeight="20px"
                          fontWeight={400}
                        >
                          {`${userData.description}`}
                        </Typography>
                        {userData.description.length > 100 && (
                          <Typography
                            onClick={() => setIsHideDesc(!isHideDesc)}
                            sx={{
                              // paddingLeft: '70%'
                              textDecoration: 'underline',
                              color: '#60A5DF',
                              cursor: 'pointer',
                              width: 'fit-content',
                              marginLeft: 'auto',
                              wordBreak: 'break-all'
                            }}
                            fontSize={16}
                            lineHeight="20px"
                            fontWeight={400}
                          >
                            {isHideDesc ? 'Читати більше' : 'Сховати'}
                          </Typography>
                        )}
                      </Grid>
                    )}
                    {/* {params.id !== props.userData.id &&
                  userData?.commonsContacts &&
                  userData?.commonsContacts.length !== 0 && (
                    <Grid
                      item
                      xs={12}
                      flexDirection="row"
                      display="flex"
                      justifyContent="space-between"
                      style={{ maxWidth: '70%', paddingTop: '10px' }}
                    >
                      <Typography
                        // onClick={() => dispatch(setIsOpenUserContactsModal(true))}
                        variant="body"
                        sx={{
                          textDecoration: 'underline',
                          fontWeight: '700',
                          color: '#A1A1A1'
                          // cursor: 'pointer',
                          // ':hover': { color: '#60A5DF', fontWeight: '400' }
                        }}
                      >{`${userData.commonsContacts.length} спільних контактів`}</Typography>
                    </Grid>
                  )} */}
                    {params.id !== props.userData.id && (
                      <Grid
                        item
                        xs={12}
                        flexDirection="row"
                        display="flex"
                        justifyContent="space-between"
                        sx={{ paddingTop: '10px' }}
                      >
                        <Box
                          sx={{
                            width: '72%',
                            maxWidth: '80%',
                            display: 'flex',
                            justifyContent: 'space-between'
                          }}
                        >
                          <Typography
                            onClick={() => dispatch(setIsOpenUserContactsModal(true))}
                            variant="body"
                            sx={{
                              textUnderlineOffset: '2px',
                              textDecoration: 'underline',
                              fontWeight: '600',
                              cursor: 'pointer',
                              ':hover': { color: '#60A5DF', fontWeight: '600' }
                            }}
                          >{`(${userData.numberSubscriptions}) Контактів`}</Typography>
                          <Typography
                            onClick={() => dispatch(setIsOpenUserReadersModal(true))}
                            variant="body"
                            sx={{
                              textUnderlineOffset: '2px',
                              textDecoration: 'underline',
                              fontWeight: '600',
                              cursor: 'pointer',
                              ':hover': { color: '#60A5DF', fontWeight: '600' }
                            }}
                          >{`(${userData.numberSubscribers}) Підписників`}</Typography>
                        </Box>
                      </Grid>
                    )}
                    <Grid
                      item
                      xs={12}
                      flexDirection="row"
                      display="flex"
                      justifyContent="space-between"
                    >
                      {params.id === props.userData.id ? (
                        <FreeBtn
                          onClick={() => dispatch(setIsOpenUserContactsModal(true))}
                          text="Контакти"
                          icon={
                            <PermIdentityIcon style={{ marginRight: '10px', color: '#60A5DF' }} />
                          }
                        />
                      ) : (
                        <FreeBtn
                          disabled={props.blockedUsers.find((user) => user.id === userData?.id)}
                          text={
                            props.subscriptions.find(
                              (subscription) => subscription.id === userData.id
                            )
                              ? 'Видалити з контактів'
                              : 'Додати до Контактів'
                          }
                          onClick={() => {
                            if (
                              props.subscriptions.find(
                                (subscription) => subscription.id === userData.id
                              )
                            ) {
                              apiUnSubscribe
                                .deleteItemQuery({ subscriptionId: userData.id })
                                .then((res) => {
                                  handleClose();
                                  userDataSetter();
                                  props.getUserSubscriptionsThunk(props.userData.id);
                                });
                            } else {
                              apiSubscribe
                                .updateDataQuery({ subscriptionId: userData.id })
                                .then((res) => {
                                  userDataSetter();
                                  props.getUserSubscriptionsThunk(props.userData.id);
                                });
                            }
                          }}
                          icon={
                            props.subscriptions.find(
                              (subscription) => subscription.id === userData.id
                            ) ? (
                              <DeleteOutlineOutlinedIcon
                                sx={{
                                  marginRight: '10px',
                                  color: '#60A5DF'
                                }}
                              />
                            ) : (
                              <AddIcon
                                style={{
                                  marginRight: '10px',
                                  color: props.blockedUsers.find((user) => user.id === userData?.id)
                                    ? 'lightGray'
                                    : '#60A5DF'
                                }}
                              />
                            )
                          }
                        />
                      )}

                      <UserContactsModal
                        isSendDataRequest={isSendDataRequest}
                        setIsSendDataRequest={setIsSendDataRequest}
                        sendingData={{ type: 'user', id: params.id }}
                        nickname={userData.nickname}
                        isOpenModal={isOpenUserContactsModal}
                        data={
                          params.id !== props.userData.id ? userSubscriptions : props.subscriptions
                        }
                        setter={userDataSetter}
                        getUserSubscriptionsThunk={props.getUserSubscriptionsThunk}
                      />
                      {params.id === props.userData.id ? (
                        <FreeBtn
                          onClick={() => dispatch(setIsOpenUserReadersModal(true))}
                          text="Підписники"
                          icon={
                            <PermIdentityIcon style={{ marginRight: '10px', color: '#60A5DF' }} />
                          }
                        />
                      ) : (
                        <FreeBtn
                          disabled={props.blockedUsers.find((user) => user.id === userData?.id)}
                          onClick={() => {
                            apiChats
                              .postDataQuery({
                                userId: props.userData.id,
                                recipientId: params.id
                              })
                              .then((res) => {
                                navigate(`/${props.language}/messages`);
                              })
                              .catch((error) => {
                                if (
                                  error?.message.includes('There is a chat between these users')
                                ) {
                                  const id = `${error.message.slice(
                                    error.message.indexOf('[') + 1,
                                    error.message.length - 1
                                  )}`;

                                  navigate(`/${props.language}/messages?chatId=${id}`);
                                }
                              });
                          }}
                          text=""
                          icon={
                            <MailOutlineIcon
                              style={{
                                color: props.blockedUsers.find((user) => user.id === userData?.id)
                                  ? 'lightgray'
                                  : '#60A5DF'
                              }}
                            />
                          }
                        />
                      )}

                      <UserReadersModal
                        nickname={userData.nickname}
                        isOpenModal={isOpenUserReadersModal}
                        data={params.id !== props.userData.id ? userSubscribers : props.subscribers}
                      />
                    </Grid>
                  </>
                ) : (
                  <Spinner />
                )}
              </Grid>
            </Paper>
            {/* <Typography fontSize={16} fontWeight={700} fontFamily="Kyiv-Sans-Bold" sx={{ mb: 3 }}>
              Користувачі, які можуть вас зацікавити:
            </Typography> */}
            {/* {testUsers.map((userData) => (
              <Grid key={userData} container spacing={2} sx={{ mb: 1 }}>
                <Grid item xs={3}>
                  <Avatar
                    src={userData?.avatar ? userData.avatar : DefaultAvatar}
                    sx={{
                      width: 70,
                      height: 70,
                      border: '1px solid #60A5DF'
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Stack sx={{ width: '100%' }}>
                    <Typography
                      fontSize={24}
                      lineHeight="29px"
                      fontWeight={400}
                      sx={{ position: 'relative' }}
                    >
                      @TEST
                    </Typography>
                    <Typography fontSize={16} lineHeight="20px" fontWeight={400}>
                      Name Surname
                    </Typography>
                  </Stack>
                </Grid>
                <Grid item xs={3}>
                  <PlusBtn />
                </Grid>
              </Grid>
            ))} */}
          </Grid>
          <Grid item xs={8} sx={{ paddingBottom: '80px' }}>
            <BigSwitcher
              bools={bools}
              isDisabled={isLoading}
              textOne="Пости"
              textTwo="Послуги"
              handleToggle={(isOnOne, isOnTwo) => {
                setIsLoadiang(true);
                // setContentSwitched(!contentSwitched);
                // setBools([isOnOne, isOnTwo]);
                setStartWith(0);
                const switchToOne = () => {
                  searchParams.set('type', 'posts');
                  setSearchParams(searchParams);
                  setContentSwitched(false);
                  setContent([]);
                  clearTimeout(switchToOne);
                };

                if (isOnOne) {
                  setTimeout(switchToOne, [500]);
                } else {
                  searchParams.set('type', 'services');
                  setSearchParams(searchParams);
                  setContentSwitched(true);
                  setContent([]);
                }
                // if (!contentSwitched) {
                //   apiServices
                //     .getItemById(params.id)
                //     .then((res) => {
                //       setContent(res.result);
                //       setIsLoadiang(false);
                //     })
                //     .catch((error) => {
                //       console.error(error);
                //       setIsLoadiang(false);
                //     });
                // } else {
                //   apiPosts
                //     .getItemById(params.id)
                //     .then((res) => {
                //       setContent(res.result);
                //       setIsLoadiang(false);
                //     })
                //     .catch((error) => {
                //       console.error(error);
                //       setIsLoadiang(false);
                //     });
                // }
              }}
            />
            <ToTopBtn
              to="topUserPage"
              visible={pageScroll > 500}
              sx={{ position: 'fixed', right: '8px !important', bottom: '0' }}
              icon={<ArrowUpwardIcon sx={{ fontSize: '22px' }} />}
            />
            {content.length === 0 ? (
              <>
                {isLoading ? (
                  <Spinner />
                ) : (
                  <Stack spacing={5} alignItems="center">
                    {!contentSwitched ? (
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <Typography variant="h3" textAlign="center">
                          Постів ще нема
                        </Typography>
                        {params.id === props.userData.id ? (
                          <Typography textAlign="center" sx={{ maxWidth: '640px' }}>
                            Віримо в тебе. Створи перший пост тицьнувши на велику жовту кнопку. А
                            якщо немає ідей - завжди є котики. Котиків люблять всі.
                          </Typography>
                        ) : (
                          <Typography textAlign="center" sx={{ maxWidth: '640px' }}>
                            У цього користувача поки що немає постів. Радимо заглянути сюди пізніше.
                          </Typography>
                        )}

                        {params.id === props.userData.id && (
                          <LetsGoBtn3
                            padding="12.5px 42.5px "
                            text="Нумо створювати!"
                            width="250px"
                            margin="10px auto"
                            onClick={() => {
                              dispatch(
                                setIsOpenPostModal({ isOpen: true, func: dataSetter, data: null })
                              );
                              // setIsCreatePost(true);
                            }}
                          />
                        )}
                      </div>
                    ) : (
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <Typography variant="h3" textAlign="center">
                          Послуг ще нема
                        </Typography>
                        {params.id === props.userData.id ? (
                          <Typography textAlign="center" sx={{ maxWidth: '640px' }}>
                            Створи свою першу послугу на майданчику. Знай, що на кожен товар є свій
                            покупець.
                          </Typography>
                        ) : (
                          <Typography textAlign="center" sx={{ maxWidth: '640px' }}>
                            У цього користувача поки що немає послуг. Радимо заглянути сюди пізніше.
                          </Typography>
                        )}

                        {params.id === props.userData.id && (
                          <LetsGoBtn3
                            padding="12.5px 42.5px "
                            width="250px"
                            margin="10px auto"
                            text="Нумо створювати!"
                            onClick={() => dispatch(setIsOpenServiceModal(true))}
                          />
                        )}
                      </div>
                    )}
                  </Stack>
                )}
              </>
            ) : (
              <Grid container spacing={1}>
                <Grid
                  item
                  xs={12}
                  sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'end', mb: 2 }}
                >
                  {params.id === props.userData.id && (
                    <FreeBtn
                      onClick={() => {
                        if (contentSwitched) {
                          dispatch(setIsOpenServiceModal(true));
                        } else {
                          dispatch(
                            setIsOpenPostModal({ isOpen: true, func: dataSetter, data: null })
                          );
                          // setIsCreatePost(true);
                        }
                      }}
                      text={contentSwitched ? 'Створити послугу' : 'Створити пост'}
                      icon={<EditOutlinedIcon style={{ marginRight: '10px', color: '#60A5DF' }} />}
                    />
                  )}
                </Grid>
                {content.map((el, index) => {
                  if (contentSwitched) {
                    return (
                      <Grid key={`service${index}`} item xs={6}>
                        <ServiceCard
                          noWidth
                          sx={{ marginBottom: '10px !important' }}
                          key={index}
                          index={index}
                          el={el}
                        />
                      </Grid>
                    );
                  }
                  return (
                    <Grid key={`post${index}`} item xs={12}>
                      <PostCard
                        noWidth
                        sx={{ marginBottom: '10px !important' }}
                        key={index}
                        index={index}
                        el={el}
                        dataSetter={dataSetter}
                      />
                    </Grid>
                  );
                })}
                {contentMaxCount > startWith + 1 && (
                  <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                    <LetsGoBtn2
                      sx={{ margin: '30px auto !important' }}
                      text={loadMore ? <Spinner type="small" /> : 'Завантажити більше'}
                      onClick={handleLoadMore}
                    />
                  </Grid>
                )}
              </Grid>
            )}
            {/* {isOpenPostModal && isCreatePost && (
              <PostModal setter={dataSetter} isOpenModal={isOpenPostModal} />
            )} */}
            {isOpenServiceModal && (
              <ServiceModal setter={dataSetter} isOpenModal={isOpenServiceModal} />
            )}
          </Grid>
        </Grid>
      </Stack>
    </Page>
  );
}
const mapStateToProps = (state) => {
  return {
    language: state.Intl.locale,
    userData: state.global.userData,
    subscribers: state.global.userSubscribers,
    subscriptions: state.global.userSubscriptions,
    blockedUsers: state.global.blockedUsers
  };
};
export default connect(mapStateToProps, {
  getUserSubscriptionsThunk: base.getUserSubscriptionsThunk,
  getUserSubscribersThunk: base.getUserSubscribersThunk,
  getBlockedUsersThunk: base.getBlockedUsersThunk,
  getMutedUsersThunk: base.getMutedUsersThunk,
  getUserDataThunk: base.getUserDataThunk
})(UserPage);
