import React from 'react';
import { useTranslate } from 'react-redux-multilingual';
//Material
import { Grid, Stack } from '@mui/material';

//Components
import Page from '../../components/Page';

//Blocks

import './index.css';
import 'animate.css';
import TopBlock from './Blocks/TopBlock';
// import AboutUsBlock from './Blocks/AboutUsBlock';
// import ContentBlock from './Blocks/ContentBlock';
import BottomBlock from './Blocks/BottomBlock';
import { connect } from 'react-redux';
import TariffsBlock from './Blocks/TariffsBlock';

// import { useSelector } from 'react-redux';

function MainPage({ language, userData }) {
  const translate = useTranslate();

  return (
    <Page title={translate('bonfair_pageName')}>
      <Stack spacing={1} justifyContent="center" alignItems="center" width="100%">
        <Grid
          container
          style={{
            maxWidth: '1440px'
          }}
        >
          <TopBlock />
          <TariffsBlock />
          {/* {userData?.id && <ContentBlock />} */}

          <BottomBlock />
        </Grid>
      </Stack>
    </Page>
  );
}
const mapStateToProps = (state) => {
  return {
    language: state.Intl.locale,
    userData: state.global.userData
  };
};

export default connect(mapStateToProps)(MainPage);
