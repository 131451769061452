import React, { useEffect, useState } from 'react';

//Material
import CloseIcon from '@mui/icons-material/Close';

import {
  Box,
  Grid,
  IconButton,
  InputAdornment,
  Modal,
  TextField,
  Typography,
  styled
} from '@mui/material';

import { StyledLabel } from '../../components/StyledComponents';
import { connect, useDispatch } from 'react-redux';
import { LetsGoBtn2, LetsGoBtn3 } from '../../components/Buttons';
import {
  setIsOpenDeleteAccAttentionModal,
  setIsOpenDeleteAccModal,
  setUserData
} from '../../redux/mainReducer';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';

import starBlue from '../../assets/StarBlue.png';
import starGreen from '../../assets/StarGreen.png';

import './DeleteModal.css';
import ApiService from '../../helpers/apiHelper';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { decryptData, encryptData, generateKeys } from '../../helpers/cryptoHelper';
import { passwordRegExp } from '../../services/regularExp';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',

  maxWidth: 900,
  bgcolor: 'background.paper',
  borderRadius: '20px',
  border: 'none',
  boxShadow: 24,
  padding: '50px 80px 50px 80px',
  overflow: 'scroll',
  height: 'inherit',
  maxHeight: '100vh',
  scrollbarWidth: 'none',
  '&::-webkit-scrollbar': {
    display: 'none'
  }
};
const CssTextField = styled(TextField)({
  '& label.Mui-focused': {
    color: '#000000'
  },
  '& label': {
    color: '#A1A1A1',
    fontFamily: 'Kyiv-Sans-Light'
  },

  margin: '5px 0px',
  width: '400px'
});
function DeleteAccModal({ isOpenModal, userData }) {
  const [passwordShow, setPasswordShow] = useState(false);
  const [password, setPassword] = useState('');
  const [publicKey, setPublicKey] = useState('');
  const [privateKey, setPrivateKey] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const apiUser = new ApiService('auth/user');
  const apiHash = new ApiService('auth/hash');
  const handleClickShowPassword = () => {
    setPasswordShow((passwordShow) => !passwordShow);
  };
  useEffect(() => {
    let mounted = true;
    if (mounted) {
      const keys = generateKeys();
      if (keys) {
        setPublicKey(keys?.publicKey);
        setPrivateKey(keys?.privateKey);
      }
    }
    return () => (mounted = false);
  }, []);
  return (
    <div>
      <Modal
        open={isOpenModal}
        onClose={(event, reason) => {
          if (reason === 'backdropClick') {
            return;
          }

          dispatch(setIsOpenDeleteAccModal(false));
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="bonfair_delete_modal"
      >
        <Box sx={style} className="bonfair_delete_modal-box">
          <img
            style={{ position: 'fixed', zIndex: '0', top: '0', left: '0' }}
            src={starGreen}
            alt="star-green"
          />
          <img
            style={{ position: 'fixed', zIndex: '0', bottom: '40px', right: '0' }}
            src={starBlue}
            alt="star-green"
          />
          <CloseIcon
            className="bonfair_delete_modal-close"
            style={{ position: 'absolute', right: '10px', top: '10px', cursor: 'pointer' }}
            onClick={() => {
              dispatch(setIsOpenDeleteAccModal(false));
            }}
          />

          <Grid container spacing={2}>
            <Grid item xs={12} md={12} lg={12}>
              <StyledLabel className="deleteModal-title-text">
                {/* {translate('bonfair_service_modal')} */}
                {'Повне видалення аккаунту'}
              </StyledLabel>
            </Grid>
            <Grid item xs={12} md={12} lg={12} sx={{ display: 'flex', justifyContent: 'center' }}>
              <Typography
                variant="subtitle2"
                sx={{ mb: 2, fontSize: 16, fontFamily: 'Kyiv-Sans-Light' }}
              >
                {`Ви впевнені? Ця дія незворотна.`}
              </Typography>
            </Grid>
            <Grid item xs={12} md={12} lg={12} sx={{ display: 'flex', justifyContent: 'center' }}>
              <CssTextField
                label="Пароль*"
                variant="standard"
                fullWidth
                size="normal"
                inputProps={{ style: { textAlign: 'left' } }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => handleClickShowPassword('st')}
                        // onMouseDown={handleMouseDownPassword}
                      >
                        {passwordShow ? <VisibilityOffOutlinedIcon /> : <VisibilityOutlinedIcon />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
                onChange={(event) => setPassword(event.target.value)}
                value={password}
                type={passwordShow ? 'text' : 'password'}
              >
                {password}
              </CssTextField>
            </Grid>
            <Grid
              item
              xs={12}
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                margin: '20px auto 0px',
                maxWidth: '550px'
              }}
            >
              <LetsGoBtn3
              disabled={password.length===0||!passwordRegExp.test(password)}
                size="large"
                onClick={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                  apiHash
                    .sendRequest({ email: userData?.email, key: publicKey })
                    .then(async (res) => {
                      const decryptedKey = decryptData(res.result, privateKey);
                      // console.log('decryptedKey', decryptedKey);
                      const encryptedPassword = await encryptData(password, decryptedKey);
                      // console.log('encryptedPassword', encryptedPassword);
                      apiUser
                        .deleteItemQuery({ encodedPassword: encryptedPassword })
                        .then((res) => {
                          dispatch(setUserData({}));
                          dispatch(setIsOpenDeleteAccModal(false));
                          localStorage.removeItem('accessToken');
                          localStorage.removeItem('refreshToken');
                          navigate('/');
                          enqueueSnackbar('Акаунт успішно видалено', { variant: 'success' });
                        })
                        .catch((error) => {
                          console.error(error);
                          if (error === 'User with active orders cannot delete account') {
                            dispatch(setIsOpenDeleteAccModal(false));
                            dispatch(setIsOpenDeleteAccAttentionModal(true));
                          }
                          if(error==='Не вірний пароль!'){
                            enqueueSnackbar(error, { variant: 'error' });  
                          }
                        });
                    })
                    .catch((error) => {                     
                      enqueueSnackbar('Сталась помилка!', { variant: 'error' });                     
                    });
                }}
                width={257}
                style={{ textTransform: 'inherit' }}
                text="Видалити"
              />
              <LetsGoBtn2
                marginTop="0"
                size="large"
                onClick={(event) => {
                  event.preventDefault();
                  event.stopPropagation();

                  dispatch(setIsOpenDeleteAccModal(false));
                }}
                width={257}
                style={{ textTransform: 'inherit' }}
                text="Відмінити"
              />
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    language: state.Intl.locale,
    userData: state.global.userData
  };
};
export default connect(mapStateToProps)(DeleteAccModal);
