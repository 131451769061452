import React from 'react';

//Material
import { Grid, Paper, Typography } from '@mui/material';
// import { Stack } from '@mui/system';
import { connect } from 'react-redux';
import Illustration1 from '../../assets/Banners/Banner1/illustration1.png';

import '../../pages/MainPage/index.css';
import { LetsGoBtn } from '../Buttons';
import { useNavigate } from 'react-router-dom';
// import  ImageGallery  from 'react-image-gallery';

// const useStyles = makeStyles({

//   button: {
//     '&:hover': {
//       boxShadow: '-5px 5px 3px black !important',
// width: '155px !important'
//   },
// }})
function Banner1() {
  const navigate = useNavigate();
  return (
    <Paper
      // {...props}
      // key={}
      // className="serviceCard"
      sx={{
        width: '100%',
        maxWidth: '809px',
        margin: '0px',
        textAlign: 'center',
        overflow: 'hidden',
        alignItems: 'center',
        height: '300px',
        borderRadius: '5px',
        padding: '20px ',
        border: '1px solid black !important',
        backgroundColor: 'white',

        position: 'relative'
      }}
    >
      <Grid
        container
        spacing={0}
        sx={{
          width: '100%',
          margin: '0px !important',
          height: '100%'
        }}
      >
        <Grid
          item
          xs={7}
          sx={{
            display: 'flex',
            justifyContent: 'start',
            flexDirection: 'column',
            alignItems: 'left'
          }}
        >
          <Typography
            variant="p"
            sx={{
              margin: '0px',
              wordBreak: 'break-word',
              fontFamily: 'Kyiv-Sans-Medium !important',
              fontSize: '30px',
              textAlign: 'left',
              mt: 2
            }}
          >
            Відкрийте нові можливості
            <Typography
              variant="p"
              sx={{ fontFamily: 'Kyiv-Sans-Medium !important', fontSize: '30px', color: '#60A5DF' }}
            >
              {' '}
              з Преміум акаунтом!
            </Typography>
          </Typography>
          <Typography
            variant="body2"
            sx={{ fontSize: '14px', textAlign: 'left', mt: 2, maxWidth: '80%' }}
          >
            Максимум можливостей без зайвих турбот
          </Typography>
          <LetsGoBtn
            className="service-card-btn"
            width="200px"
            margin="45px 0px 0px 0px"
            text="Спробувати Преміум"
            onClick={() => {
              navigate(`/ua/tariffs`);
            }}
          />
        </Grid>
        <Grid item xs={5} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <img width={280} src={Illustration1} alt="Illustration1" />
        </Grid>
      </Grid>
    </Paper>
  );
}
const mapStateToProps = (state) => {
  return {
    language: state.Intl.locale
  };
};
export default connect(mapStateToProps)(Banner1);
