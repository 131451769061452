import React from 'react';
import { useNavigate } from 'react-router-dom';

//Material
import { Avatar, Grid, Tooltip, Typography } from '@mui/material';

import { Stack } from '@mui/system';

// import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
// import PersonOffOutlinedIcon from '@mui/icons-material/PersonOffOutlined';

import { connect, useDispatch } from 'react-redux';

import DefaultAvatar from '../assets/defaultPostImg.jpg';
import { base } from '../redux/thunk';

import { setIsOpenDeleteModal } from '../redux/mainReducer';
// import ApiService from '../helpers/apiHelper';

function UserListCardChatTop({
  deleteChat,
  index,
  user,
  language,
  userData,
  getUserSubscriptionsThunk,
  subscriptions,
  blockedUsers
}) {

  const navigate = useNavigate();
  const dispatch = useDispatch();
 

  return (
    <Grid
      container
      style={{
        padding: '5px',
        justifyContent: 'space-between'
      }}
    >
      <Grid item xs={0.7}>
        <Avatar
          onClick={() => {
            navigate(`/${language}/user-page/${user?.userInfoDTO?.id}?type=posts`);
          }}
          src={
            user.userInfoDTO?.encodedMediaDTO
              ? `data:${user.userInfoDTO?.encodedMediaDTO.contentType};base64, ${user.userInfoDTO?.encodedMediaDTO.encodedContent}`
              : DefaultAvatar
          }
          sx={{
            width: 70,
            height: 70,
            border: '1px solid #60A5DF',
            cursor: 'pointer',
            boxShadow: blockedUsers.find(
              (el) => el.id === user?.userInfoDTO?.id
            )
              ? '0px 0px 2px 2px rgba(255,0,0,1)'
              : 'none',
          }}
        />
      </Grid>
      <Grid item xs={8}>
        <Stack sx={{ width: '100%', marginTop: '10px' }}>
          {user.userInfoDTO?.name&&user.userInfoDTO?.surname&&<Typography
            variant="subtitle"
            sx={{
              fontFamily: 'Public Sans,sans-serif !important',
              fontSize: '18px',
              textAlign: 'left',
              cursor: 'pointer'
            }}
            onClick={() => {
              navigate(`/${language}/user-page/${user.userInfoDTO.id}?type=posts`);
            }}
            src={
              user.userInfoDTO?.encodedMediaDTO
                ? `data:${user.userInfoDTO?.encodedMediaDTO.contentType};base64, ${user.userInfoDTO?.encodedMediaDTO.encodedContent}`
                : DefaultAvatar
            }
          >
            {`${user.userInfoDTO?.name} ${user.userInfoDTO?.surname}`}
          </Typography>}
          
          <Typography
            variant="subtitle"
            sx={{
              fontFamily: 'Public Sans,sans-serif !important',
              fontSize: user.userInfoDTO?.name&&user.userInfoDTO?.surname?'14px':'18px',
              textAlign: 'left',
              cursor: 'pointer'
            }}
            onClick={() => {
              navigate(`/${language}/user-page/${user.userInfoDTO.id}?type=posts`);
            }}
            src={
              user?.encodedMediaDTO
                ? `data:${user.userInfoDTO?.encodedMediaDTO.contentType};base64, ${user.userInfoDTO?.encodedMediaDTO.encodedContent}`
                : DefaultAvatar
            }
          >
            {`@${user.userInfoDTO?.nickname}`}
          </Typography>
        </Stack>
      </Grid>
      <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
      
       <Tooltip title={'Видалити чат'}>
        <DeleteOutlineOutlinedIcon onClick={(event) => {
              event.stopPropagation();
              event.preventDefault();
              dispatch(
                setIsOpenDeleteModal({
                  isOpen: true,
                  text: 'цей чат?',
                  func: deleteChat,
                  id: user.chatId
                })
              );
            }} sx={{ color: '#60A5DF', mr: 1, cursor:'pointer' }} /></Tooltip>
        
      </Grid>
    </Grid>
  );
}
const mapStateToProps = (state) => {
  return {
    language: state.Intl.locale,
    userData: state.global.userData,
    subscriptions: state.global.userSubscriptions,
    blockedUsers: state.global.blockedUsers,
  };
};
export default connect(mapStateToProps, {
  getUserSubscriptionsThunk: base.getUserSubscriptionsThunk
})(UserListCardChatTop);
