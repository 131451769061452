import React, { useEffect, useRef, useState } from 'react';
import './index.css';

const BigSwitcher = ({
  bools = [true, false, false],
  handleToggle,
  textOne,
  textTwo,
  textThree = null,
  isDisabled
}) => {
  const [isOnOne, setIsOnOne] = useState(true);
  const [isOnTwo, setIsOnTwo] = useState(false);
  const [isOnThree, setIsOnThree] = useState(false);
  const [activeClass, setActiveClass] = useState('btnOne');

  const btnOne = useRef(null);
  const btnTwo = useRef(null);
  const btnThree = useRef(null);

  useEffect(() => {
    if (textThree) {
      bools[0] && setActiveClass('btnOne');
      bools[0] && setIsOnTwo(false);
      bools[0] && setIsOnThree(false);
      bools[0] && setIsOnOne(true);
      bools[1] && setActiveClass('btnTwo');
      bools[1] && setIsOnOne(false);
      bools[1] && setIsOnThree(false);
      bools[1] && setIsOnTwo(true);
      bools[2] && setActiveClass('btnThree');
      bools[2] && setIsOnTwo(false);
      bools[2] && setIsOnOne(false);
      bools[2] && setIsOnThree(true);
    } else {
      bools[0] && setActiveClass('btnOneBasic');
      bools[0] && setIsOnOne(true);
      bools[0] && setIsOnTwo(false);

      bools[1] && setActiveClass('btnTwoBasic');
      bools[1] && setIsOnTwo(true);
      bools[1] && setIsOnOne(false);
    }
  }, [bools, textThree]);

  useEffect(() => {
    const btn = document.getElementById('switcherBtn');
    if (isDisabled) {
      btn.setAttribute('style', 'background-color:lightgray');
      if (btnOne) {
        btnOne.current.classList.remove('on-hover');
        btnOne.current.classList.add('no-hover');
      }
      if (btnTwo) {
        btnTwo.current.classList.remove('on-hover');
        btnTwo.current.classList.add('no-hover');
      }
      if (textThree && btnThree) {
        btnThree.current.classList.remove('on-hover');
        btnThree.current.classList.add('no-hover');
      }
    } else {
      btn.setAttribute('style', 'background-color:#60a5df');
      if (btnOne) {
        btnOne.current.classList.remove('no-hover');
        btnOne.current.classList.add('on-hover');
      }
      if (btnTwo) {
        btnTwo.current.classList.remove('no-hover');
        btnTwo.current.classList.add('on-hover');
      }
      if (textThree && btnThree) {
        btnThree.current.classList.remove('no-hover');
        btnThree.current.classList.add('on-hover');
      }
    }
  }, [isDisabled, textThree]);

  return (
    <label className={!textThree ? `react-switch-label` : `react-switch-label textThree-label`}>
      <button
        disabled={isDisabled}
        id="switcherBtn"
        className={
          !textThree
            ? `react-switch-switcher ${activeClass}`
            : `react-switch-switcher ${activeClass} textThree-switcher`
        }
      >
        {isOnOne && textOne}
        {isOnTwo && textTwo}
        {isOnThree && textThree}
      </button>
      <div className="react-switch-labels">
        <span
          ref={btnOne}
          className={!textThree ? `react-switch-span` : `react-switch-span textThree-span`}
          onClick={(e) => {
            if (!isDisabled && !isOnOne) {
              setActiveClass(textThree ? 'btnOne' : 'btnOneBasic');
              setIsOnOne(true);
              setIsOnTwo(false);
              setIsOnThree(false);
              handleToggle(true, false, false);
            }
          }}
        >
          {textOne}
        </span>
        <span
          ref={btnTwo}
          className={!textThree ? `react-switch-span` : `react-switch-span textThree-span`}
          onClick={(e) => {
            if (!isDisabled && !isOnTwo) {
              setActiveClass(textThree ? 'btnTwo' : 'btnTwoBasic');
              setIsOnOne(false);
              setIsOnTwo(true);
              setIsOnThree(false);
              handleToggle(false, true, false);
            }
          }}
        >
          {textTwo}
        </span>
        {textThree && (
          <span
            ref={btnThree}
            className={!textThree ? `react-switch-span` : `react-switch-span textThree-span`}
            onClick={(e) => {
              if (!isDisabled && !isOnThree) {
                setActiveClass('btnThree');
                setIsOnOne(false);
                setIsOnTwo(false);
                setIsOnThree(true);
                handleToggle(false, false, true);
              }
            }}
          >
            {textThree}
          </span>
        )}
      </div>
    </label>
  );
};

export default BigSwitcher;
