import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
// import { useNavigate } from 'react-router-dom';

//Material

import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { Grid, Stack, Typography } from '@mui/material';

import { useSearchParams } from 'react-router-dom';
import { Element } from 'react-scroll';
import BigSwitcher from '../../components/BigSwitcher';
import { LetsGoBtn2, ToTopBtn } from '../../components/Buttons';
import Page from '../../components/Page';
import PostCard from '../../components/PostCard';
import ServiceCard from '../../components/ServiceCard';
import { Spinner } from '../../components/Spinner';
import { StyledTitle } from '../../components/StyledComponents';
import ApiService from '../../helpers/apiHelper';
import '../MainPage/index.css';

function SavedPage(props) {
  // const apiSavedPosts = new ApiService('saved-post');
  // const apiSavedServices = new ApiService('saved-service');
  const apiSavedPosts = new ApiService('posts/saved-post');
  const apiSavedServices = new ApiService('posts/saved-service');

  const [content, setContent] = useState([]);
  const [page, setPage] = useState(0);
  const [params, setParams] = useSearchParams();
  const [contentMaxCount, setContentMaxCount] = useState(0);
  const [contentSwitched, setContentSwitched] = useState(false);
  const [isLoading, setIsLoadiang] = useState(false);
  const [pageScroll, setPageScroll] = useState(0);
  const [loadMore, setLoadMore] = useState(false);
  const [bools, setBools] = useState([true, false]);

  const dataSetter = (contentSwitched) => {
    setIsLoadiang(true);
    if (!contentSwitched) {
      apiSavedPosts
        .getAll({ page, size: 8 })
        .then((res) => {
          setContentMaxCount(res.count);
          setIsLoadiang(false);
          setContent(res.result);
        })
        .catch((error) => console.error(error));
    } else {
      apiSavedServices
        .getAll({ page, size: 8 })
        .then((res) => {
          setContentMaxCount(res.count);
          setIsLoadiang(false);
          setContent(res.result);
        })
        .catch((error) => console.error(error));
    }
  };
  const handleLoadMore = () => {
    setLoadMore(true);

    if (!contentSwitched) {
      apiSavedPosts
        .getAll({ page: page + 1, size: 8 })
        .then((res) => {
          setContentMaxCount(res.count);
          setLoadMore(false);
          setContent([...content, ...res.result]);
          setPage(page + 1);
        })
        .catch((error) => console.error(error));
    } else {
      apiSavedServices
        .getAll({ page: page + 1, size: 8 })
        .then((res) => {
          setContentMaxCount(res.count);
          setLoadMore(false);
          setContent([...content, ...res.result]);
          setPage(page + 1);
        })
        .catch((error) => console.error(error));
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', () => setPageScroll(window.scrollY));
    return () => {
      window.removeEventListener('scroll', () => setPageScroll(window.scrollY));
    };
  });
  useEffect(() => {
    const type = params.get('type');
    if (type === 'services') {
      setContentSwitched(true);
      setBools([false, true]);
      dataSetter(true);
    } else {
      setContentSwitched(false);
      setBools([true, false]);
      dataSetter(false);
    }
    // eslint-disable-next-line
  }, [params.get('type')]);

  return (
    <Page
      title={'Збережене'}
      style={{
        display: 'flex',
        marginTop: '150px',
        alignItems: 'center',
        flexDirection: 'column',
        height: 'max-content',
        padding: '0px 100px'
      }}
    >
      <Element name="topSaved" />
      <Stack spacing={1} justifyContent="center" alignItems="center">
        <Grid
          container
          style={{
            maxWidth: '1440px',
            minWidth: '1265px'
          }}
          spacing={3}
        >
          <Grid item xs={12} sx={{ paddingBottom: '80px' }}>
            <StyledTitle
              maxWidth={800}
              sx={{
                lineHeight: '38px !important',
                fontSize: '32px !important',
                textTransform: 'initial !important',
                margin: '0px auto 40px !important'
              }}
              className="bottomBlock-title"
            >
              Збережені
            </StyledTitle>
            <BigSwitcher
              bools={bools}
              isDisabled={isLoading}
              textOne="Пости"
              textTwo="Послуги"
              handleToggle={(isOnOne, isOnTwo) => {
                // setContent([]);
                setIsLoadiang(true);
                // setContentSwitched(!contentSwitched);
                // setBools([isOnOne, isOnTwo]);
                setPage(0);
                const switchToOne = () => {
                  params.set('type', 'posts');
                  setParams(params);
                  setContent([]);
                  clearTimeout(switchToOne);
                };
                if (isOnOne) {
                  setTimeout(switchToOne, [500]);
                } else {
                  params.set('type', 'services');
                  setParams(params);
                  setContent([]);
                }

                // if (!contentSwitched) {
                //   apiServices
                //     .getItemById(params.id)
                //     .then((res) => {
                //       setContent(res.result);
                //       setIsLoadiang(false);
                //     })
                //     .catch((error) => {
                //       console.error(error);
                //       setIsLoadiang(false);
                //     });
                // } else {
                //   apiPosts
                //     .getItemById(params.id)
                //     .then((res) => {
                //       setContent(res.result);
                //       setIsLoadiang(false);
                //     })
                //     .catch((error) => {
                //       console.error(error);
                //       setIsLoadiang(false);
                //     });
                // }
              }}
            />
            <ToTopBtn
              to="topSaved"
              visible={pageScroll > 500}
              sx={{ position: 'fixed', right: '-80px', bottom: '0' }}
              icon={<ArrowUpwardIcon sx={{ fontSize: '22px' }} />}
            />
            {content.length === 0 ? (
              <>
                {isLoading ? (
                  <Spinner marginTop="184px !important" />
                ) : (
                  <Stack spacing={5} alignItems="center">
                    {!contentSwitched ? (
                      <div
                        style={{
                          display: 'flex',

                          flexDirection: 'column'
                        }}
                      >
                        <Typography
                          variant="body"
                          textAlign="center"
                          sx={{
                            fontSize: '24px',
                            fontFamily: 'Public Sans,sans-serif !important',
                            mt: '185px'
                          }}
                        >
                          Ви поки що нічого не зберегли.
                        </Typography>
                      </div>
                    ) : (
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <Typography
                          variant="body"
                          textAlign="center"
                          sx={{
                            fontSize: '24px',
                            fontFamily: 'Public Sans,sans-serif !important',
                            mt: '185px'
                          }}
                        >
                          Ви поки що нічого не зберегли.
                        </Typography>
                      </div>
                    )}
                  </Stack>
                )}
              </>
            ) : (
              <Grid container spacing={1}>
                {content.map((el, index) => {
                  if (!contentSwitched) {
                    return (
                      <Grid
                        key={`postSaved${index}`}
                        item
                        xs={12}
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          padding: '20px 300px !important'
                        }}
                      >
                        <PostCard
                          noWidth
                          sx={{ marginBottom: '10px !important' }}
                          key={index}
                          index={index}
                          el={el}
                          dataSetter={dataSetter}
                        />
                      </Grid>
                    );
                  }
                  return (
                    <Grid key={`serviceSaved${index}`} item xs={3}>
                      <ServiceCard
                        noWidth
                        maxHeight="327px"
                        sx={{ marginBottom: '10px !important' }}
                        key={index}
                        index={index}
                        el={el}
                      />
                    </Grid>
                  );
                })}
                {contentMaxCount > content.length && (
                  <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                    <LetsGoBtn2
                      sx={{ margin: '30px auto !important' }}
                      text={loadMore ? <Spinner type="small" /> : 'Завантажити більше'}
                      onClick={handleLoadMore}
                    />
                  </Grid>
                )}
              </Grid>
            )}
          </Grid>
        </Grid>
      </Stack>
    </Page>
  );
}
const mapStateToProps = (state) => {
  return {
    language: state.Intl.locale,
    userData: state.global.userData
  };
};
export default connect(mapStateToProps)(SavedPage);
