import { Button, Typography, styled } from '@mui/material';

export const StyledButton = styled(Button)(({ theme }) => ({
  color: theme.palette.common.white,
  textTransform: 'uppercase',
  fontFamily: 'Noto Sans',
  fontStyle: 'normal',
  fontWeight: '700',
  fontSize: '20px',
  lineHeight: '20px',
  border: 'none',
  borderRadius: '50px',
  maxWidth: 'fit-content',
  minWidth: '200px',
  padding: '30px 50px',
  '&:hover': { backgroundColor: 'transparent', boxShadow: '10px 1px 41px rgb(42 124 85 / 40%)' },
  boxShadow: '0px 7px 10px 1px rgb(145 158 171 / 30%)',
  background:
    'linear-gradient(353.54deg, #043B1F 3.03%, #0D7834 37.41%, #0E8D3D 67.77%, #0B9552 104.82%)',
  [theme.breakpoints.up('xs')]: {
    fontSize: '14px',
    minWidth: '180px',
    padding: '20px 30px'
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '18px',
    minWidth: '190px',
    padding: '20px 30px'
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: '20px',
    minWidth: '200px',
    padding: '30px 50px'
  }
}));
export const StyledLabel = styled(Typography)(({ theme }) => ({
  color: theme.palette.common.black,
  fontWeight: '700',
  fontSize: '46px',
  lineHeight: '60px',
  fontFamily: 'Kyiv-Sans-Regular',
  marginBottom: '35px',
  textTransform: 'uppercase',
  [theme.breakpoints.up('xs')]: {
    fontSize: '28px',
    lineHeight: '35px',
    textAlign: 'center'
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '34px',
    textAlign: 'left'
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: '46px',
    lineHeight: '44px',
    textAlign: 'left'
  }
}));
export const StyledTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.common.black,
  fontWeight: '700',
  fontSize: '38px',
  lineHeight: '46px',
  fontFamily: 'Kyiv-Sans-Bold !important',
  marginBottom: '35px',
  textTransform: 'uppercase',
  [theme.breakpoints.up('xs')]: {
    fontSize: '28px',
    lineHeight: '35px',
    textAlign: 'center'
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '34px',
    textAlign: 'center'
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: '48px',
    textAlign: 'center'
  }
}));
