import React from 'react';

//Material
import CloseIcon from '@mui/icons-material/Close';

import { Box, Grid, Modal, Typography } from '@mui/material';

import { connect, useDispatch } from 'react-redux';
import { StyledLabel } from '../../components/StyledComponents';
import { setIsOpenDeleteAccAttentionModal } from '../../redux/mainReducer';

import starBlue from '../../assets/StarBlue.png';
import starGreen from '../../assets/StarGreen.png';

import './DeleteModal.css';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',

  maxWidth: 900,
  bgcolor: 'background.paper',
  borderRadius: '20px',
  border: 'none',
  boxShadow: 24,
  padding: '50px 60px 50px 60px',
  overflow: 'scroll',
  height: 'inherit',
  maxHeight: '100vh',
  scrollbarWidth: 'none',
  '&::-webkit-scrollbar': {
    display: 'none'
  }
};

function DeleteAccAttention({ isOpenModal }) {
  const dispatch = useDispatch();
  return (
    <div>
      <Modal
        open={isOpenModal}
        onClose={(event, reason) => {
          if (reason === 'backdropClick') {
            return;
          }

          dispatch(setIsOpenDeleteAccAttentionModal(false));
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="bonfair_delete_modal"
      >
        <Box sx={style} className="bonfair_delete_modal-box">
          <img
            style={{ position: 'fixed', zIndex: '0', top: '0', left: '0' }}
            src={starGreen}
            alt="star-green"
          />
          <img
            style={{ position: 'fixed', zIndex: '0', bottom: '40px', right: '0' }}
            src={starBlue}
            alt="star-green"
          />
          <CloseIcon
            className="bonfair_delete_modal-close"
            style={{ position: 'absolute', right: '10px', top: '10px', cursor: 'pointer' }}
            onClick={() => {
              dispatch(setIsOpenDeleteAccAttentionModal(false));
            }}
          />

          <Grid container spacing={1}>
            <Grid item xs={12} md={12} lg={12}>
              <StyledLabel
                sx={{ maxWidth: '400px', m: '0px auto' }}
                className="deleteModal-title-text"
              >
                {'От халепа'}
              </StyledLabel>
            </Grid>
            <Grid item xs={12} md={12} lg={12} sx={{ display: 'flex', justifyContent: 'center' }}>
              <Typography
                variant="subtitle2"
                sx={{
                  mb: 2,
                  fontSize: 16,
                  fontFamily: 'Kyiv-Sans-Light',
                  textAlign: 'center',
                  maxWidth: '69%'
                }}
              >
                {`Схоже, на твоєму акаунті є послуги в активному замовленні. Нажаль, зараз видалення акаунту неможливе.`}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    language: state.Intl.locale
  };
};
export default connect(mapStateToProps)(DeleteAccAttention);
