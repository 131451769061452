import { Outlet, useNavigate, useParams } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
//
import Navbar from './Header/Navbar';
import Footer from './Footer/Footer';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { IntlActions } from 'react-redux-multilingual';
import './index.css';
// ----------------------------------------------------------------------

const RootStyle = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100%'
  // paddingLeft: '100px',
  // paddingRight: '100px'
});

// ----------------------------------------------------------------------

function MainLayout() {
  const params = useParams();
  const navigate = useNavigate();
  const { lng } = params;

  const dispatch = useDispatch();
  useEffect(() => {
    if (lng === 'ua' || lng === 'en') {
      dispatch(IntlActions.setLocale(lng));
    } else navigate('/');
    // eslint-disable-next-line
  }, [lng]);

  return (
    <RootStyle className="layout-root">
      <Navbar />
      <div className="layout-root-cover">
        <Outlet />
      </div>
      {!window.location.href.includes('messages') && <Footer />}
    </RootStyle>
  );
}

export default MainLayout;
