import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CancelScheduleSendOutlinedIcon from '@mui/icons-material/CancelScheduleSendOutlined';
import DoneIcon from '@mui/icons-material/Done';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
// import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PushPinOutlinedIcon from '@mui/icons-material/PushPinOutlined';
import ReplyIcon from '@mui/icons-material/Reply';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import { Avatar, Box, Grid, Menu, MenuItem, Paper, Typography } from '@mui/material';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
// import PersonOffOutlinedIcon from '@mui/icons-material/PersonOffOutlined';
import React, { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Decor from '../assets/decor.png';
import DefaultAvatar from '../assets/defaultPostImg.jpg';
// import { useDispatch, useSelector } from 'react-redux';

import { useNavigate } from 'react-router-dom';
import { timeFormattingByDate } from '../helpers/date-helpers';
import {
  setIsOpenCancelModal,
  setIsOpenFullscreenPicture,
  setIsOpenResendingModal
} from '../redux/mainReducer';
import { LetsGoBtn } from './Buttons';
import { StyledTitle } from './StyledComponents';

//Material
function srcset(image, size, rows = 1, cols = 1) {
  return {
    src: `${URL.createObjectURL(image)}`,
    srcSet: `${URL.createObjectURL(image)}`
    //?w=${size * cols}&h=${
    // size * rows
    // }&fit=crop&auto=format&dpr=2 2x
  };
}

function ChatMessage({
  imgs,
  setText,
  setShowedImg,
  setSliderStartIdx,
  deleteMessage,
  selectedChat,
  message,
  setMessageSelected,
  setIsEditMessage,
  setIsSendingPic,
  setIsReplyMessage,
  setIsResendMessage,
  index,
  userData,
  setUsersForResending
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [isShowDots, setIsShowDots] = useState(false);
  const [itemData, setItemData] = useState([]);
  const [includedObject, setIncludedObject] = useState(null);
  // const isOpenDeleteModal = useSelector((state) => state.global.isOpenDeleteModal);
  const dispatch = useDispatch();
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const language = useSelector((state) => state.Intl.locale);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setMessageSelected(message.id);
    setIsEditMessage(false);
    setText('');
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  // eslint-disable-next-line

  function getFileFromBase64(string64, fileName) {
    const trimmedString = string64.replace('dataimage/jpegbase64', '');
    const imageContent = atob(trimmedString);
    const buffer = new ArrayBuffer(imageContent.length);
    const view = new Uint8Array(buffer);

    for (let n = 0; n < imageContent.length; n++) {
      view[n] = imageContent.charCodeAt(n);
    }
    const type = 'image/jpeg';
    const blob = new Blob([buffer], { type });
    return new File([blob], fileName, { lastModified: new Date().getTime(), type });
  }
  const setPhotoPosition = (index, length) => {
    if (length === 2) {
      return 3;
    } else if (length === 3) {
      return 2;
    } else if (length === 4) {
      return 3;
    } else if (length === 5) {
      switch (index) {
        case 0:
          return 2;
        case 1:
          return 2;
        case 2:
          return 2;
        case 3:
          return 3;
        case 4:
          return 3;
        default:
          return;
      }
    } else if (length === 6) {
      return 2;
    } else if (length === 7) {
      switch (index) {
        case 0:
          return 3;
        case 1:
          return 3;
        case 2:
          return 2;
        case 3:
          return 2;
        case 4:
          return 2;
        case 5:
          return 3;
        case 6:
          return 3;
        default:
          return;
      }
    } else if (length === 8) {
      switch (index) {
        case 0:
          return 2;
        case 1:
          return 2;
        case 2:
          return 2;
        case 3:
          return 3;
        case 4:
          return 3;
        case 5:
          return 2;
        case 6:
          return 2;
        case 7:
          return 2;
        default:
          return;
      }
    } else if (length === 9) {
      return 2;
    } else if (length === 10) {
      switch (index) {
        case 0:
          return 3;
        case 1:
          return 3;
        case 2:
          return 2;
        case 3:
          return 2;
        case 4:
          return 2;
        case 5:
          return 3;
        case 6:
          return 3;
        case 7:
          return 2;
        case 8:
          return 2;
        case 9:
          return 2;
        default:
          return;
      }
    } else {
      return 6;
    }
  };

  useEffect(() => {
    if (message && message?.photos && message?.photos.length !== 0) {
      setItemData([
        ...message.photos.map((photo, index) => ({
          img: getFileFromBase64(photo.encodedContent, photo.nameFile),
          title: 'Breakfast',
          rows: setPhotoPosition(index, message.photos.length),
          cols: setPhotoPosition(index, message.photos.length)
        }))
      ]);
    }
    if (message?.forwardedMessage && message?.forwardedMessage?.abstractMessageWithDTO?.photos) {
      setItemData([
        ...message?.forwardedMessage?.abstractMessageWithDTO?.photos.map((photo, index) => ({
          img: getFileFromBase64(photo.encodedContent, photo.nameFile),
          title: 'Breakfast',
          rows: setPhotoPosition(
            index,
            message?.forwardedMessage.abstractMessageWithDTO.photos.length
          ),
          cols: setPhotoPosition(
            index,
            message?.forwardedMessage.abstractMessageWithDTO.photos.length
          )
        }))
      ]);
    }
    if (message?.type === 'user') {
      setIncludedObject(message.userDTO);
    }
    if (message?.type === 'service') {
      setIncludedObject(message.servicePostDTO);
    }
    if (message?.type === 'post') {
      setIncludedObject(message.postDTO);
    }
    return () => setItemData([]);
    // eslint-disable-next-line
  }, [message]);

  return (
    <React.Fragment key={`chatMessage${index}container`}>
      {message?.senderId === userData?.id ? (
        <Grid container key={`chatMessage${index}`} id={message.id}>
          <Grid item xs={6} />
          <Grid
            item
            xs={6}
            sx={{ p: 1, display: 'flex', alignItems: 'end', justifyContent: 'end' }}
          >
            <Box
              onMouseEnter={() => {
                if (message?.id) {
                  setIsShowDots(true);
                } else {
                  return;
                }
              }}
              onMouseLeave={() => {
                if (message?.id) {
                  setIsShowDots(false);
                } else {
                  return;
                }
              }}
              sx={{
                padding: '10px',
                borderRadius: '10px 10px 0px 10px',
                background: '#A2CEF4',
                fontSize: '14px',
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
                flexDirection: 'column'
              }}
            >
              {includedObject ? (
                <>
                  {message.type === 'user' ? (
                    <Paper
                      sx={{
                        width: '279px',
                        border: '1px solid #60A5DF',
                        p: '12px',
                        position: 'relative'
                      }}
                    >
                      <img
                        src={Decor}
                        alt="decor"
                        style={{ position: 'absolute', right: '0px', top: '45px' }}
                      />
                      <Grid container>
                        <Grid item xs={6}>
                          <Avatar
                            // src={userData?.avatar ? userData.avatar : DefaultAvatar}
                            src={
                              includedObject?.encodedMediaDTO
                                ? URL.createObjectURL(
                                    getFileFromBase64(
                                      includedObject.encodedMediaDTO.encodedContent,
                                      includedObject.encodedMediaDTO.nameFile
                                    )
                                  )
                                : DefaultAvatar
                            }
                            sx={{
                              width: 106,
                              height: 106,
                              border: '1px solid #60A5DF',
                              borderRadius: '5px'
                            }}
                            variant="square"
                          />
                        </Grid>
                        <Grid item xs={6}>
                          {includedObject?.name && includedObject?.surname && (
                            <Typography
                              fontSize={14}
                              lineHeight="normal"
                              fontWeight={400}
                              sx={{ mt: 1 }}
                            >
                              {`${includedObject.name} ${includedObject.surname}`}
                            </Typography>
                          )}

                          <Typography
                            component="p"
                            fontSize={includedObject?.name && includedObject?.surname ? 12 : 14}
                            lineHeight="normal"
                            fontWeight={400}
                            sx={{ wordBreak: 'break-all', maxWidth: '90%', mt: 1 }}
                          >
                            {`@${includedObject?.nickname}`}
                          </Typography>

                          {includedObject?.city &&
                            includedObject?.city.length !== 0 &&
                            includedObject?.country &&
                            includedObject?.country.length !== 0 &&
                            includedObject?.region &&
                            includedObject?.region.length !== 0 && (
                              <Typography
                                sx={{ alignItems: 'center', mt: '2px' }}
                                fontSize={12}
                                lineHeight="normal"
                                fontWeight={400}
                              >
                                <PushPinOutlinedIcon
                                  style={{
                                    color: '#60A5DF',
                                    fontSize: '14px',
                                    transform: 'rotate(-45deg)'
                                  }}
                                />
                                {`${includedObject?.city ? includedObject.city : ''}${
                                  includedObject?.city &&
                                  includedObject?.city.length !== 0 &&
                                  includedObject?.region &&
                                  includedObject?.region.length !== 0
                                    ? ', '
                                    : ''
                                }${includedObject?.region ? `${includedObject.region} обл.` : ''}${
                                  includedObject?.city &&
                                  includedObject?.city.length !== 0 &&
                                  includedObject?.country &&
                                  includedObject?.country.length !== 0
                                    ? ', '
                                    : ''
                                }${includedObject?.country ? includedObject.country : ''}`}
                              </Typography>
                            )}
                        </Grid>
                        <Grid item xs={12}>
                          <LetsGoBtn
                            className="service-card-btn"
                            width="100%"
                            margin="10px auto"
                            text="Детальніше"
                            onClick={() => {
                              navigate(`/${language}/user-page/${includedObject.id}?type=posts`);
                              // if (userData?.id) {
                              //   navigate(`/${language}/service/${el.id}`);
                              //   window.scrollTo({ top: 0 });
                              // } else {
                              //   dispatch(setIsOpenAuthModal(true));
                              // }
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Paper>
                  ) : null}
                  {message.type === 'service' ? (
                    <Paper sx={{ width: '279px', border: '1px solid #60A5DF', p: '12px' }}>
                      <Grid container>
                        <Grid item xs={12}>
                          <Avatar
                            // src={userData?.avatar ? userData.avatar : DefaultAvatar}
                            src={
                              includedObject?.encodedPhotos.length !== 0
                                ? URL.createObjectURL(
                                    getFileFromBase64(
                                      includedObject.encodedPhotos[0].encodedContent,
                                      includedObject.encodedPhotos[0].nameFile
                                    )
                                  )
                                : DefaultAvatar
                            }
                            sx={{
                              width: '100%',
                              height: 255,
                              border: '1px solid #60A5DF',
                              borderRadius: '5px'
                            }}
                            variant="square"
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Typography
                            variant="h5"
                            style={{
                              fontSize: '16px',
                              margin: '0px 10px 10px',
                              wordBreak: 'break-word'
                            }}
                            sx={{ mt: 2, textAlign: 'center' }}
                          >
                            {`${includedObject.nameService}`}
                          </Typography>
                          <Typography
                            style={{
                              fontSize: '16px',
                              margin: '0px 0px 5px 0px',
                              fontFamily: 'Kyiv-Sans-Regular',
                              textAlign: 'center'
                            }}
                          >
                            {`${includedObject.price} ${includedObject.currency}`}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <LetsGoBtn
                            className="service-card-btn"
                            width="100%"
                            margin="10px auto"
                            text="Детальніше"
                            onClick={() => {
                              navigate(`/${language}/service/${includedObject.id}`);
                              // if (userData?.id) {
                              //   navigate(`/${language}/service/${el.id}`);
                              //   window.scrollTo({ top: 0 });
                              // } else {
                              //   dispatch(setIsOpenAuthModal(true));
                              // }
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Paper>
                  ) : null}
                  {message.type === 'post' ? (
                    <Paper sx={{ width: '279px', border: '1px solid #60A5DF', p: '12px' }}>
                      <Grid container>
                        <Grid item xs={12}>
                          <Typography
                            style={{
                              fontSize: '12px',
                              margin: '0px 0px 5px 0px',
                              textAlign: 'left',
                              wordBreak: 'break-word',
                              display: '-webkit-box',
                              WebkitLineClamp: 3,
                              WebkitBoxOrient: 'vertical',
                              overflowY: 'hidden',
                              textOverflow: 'ellipsis'
                            }}
                            children={
                              <>
                                {includedObject.description
                                  .split('\r\n')
                                  .filter((el) => el.length !== 0)
                                  .map((string, index) => {
                                    return (
                                      <React.Fragment key={string + index}>
                                        <span style={{ margin: '0' }}>
                                          {string}

                                          <br />
                                        </span>
                                      </React.Fragment>
                                    );
                                  })}
                              </>
                            }
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Avatar
                            // src={userData?.avatar ? userData.avatar : DefaultAvatar}
                            src={
                              includedObject?.encodedPhotos.length !== 0
                                ? URL.createObjectURL(
                                    getFileFromBase64(
                                      includedObject.encodedPhotos[0].encodedContent,
                                      includedObject.encodedPhotos[0].nameFile
                                    )
                                  )
                                : DefaultAvatar
                            }
                            sx={{
                              width: '100%',
                              height: 255,
                              border: '1px solid #60A5DF',
                              borderRadius: '5px',
                              mb: 1
                            }}
                            variant="square"
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <LetsGoBtn
                            className="service-card-btn"
                            width="100%"
                            margin="10px auto"
                            text="Детальніше"
                            onClick={() => {
                              navigate(`/${language}/post/${includedObject.id}`);
                              // if (userData?.id) {
                              //   navigate(`/${language}/service/${el.id}`);
                              //   window.scrollTo({ top: 0 });
                              // } else {
                              //   dispatch(setIsOpenAuthModal(true));
                              // }
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Paper>
                  ) : null}
                </>
              ) : (
                <>
                  {message.type === 'post' ? (
                    <Paper sx={{ width: '279px', border: '1px solid #60A5DF', p: '12px' }}>
                      <Grid container>
                        <Grid
                          item
                          xs={12}
                          sx={{
                            height: 350,
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center'
                          }}
                        >
                          <>
                            <StyledTitle
                              // maxWidth={800}
                              sx={{
                                lineHeight: '38px !important',
                                fontSize: '32px !important',
                                textTransform: 'initial !important',
                                margin: '0px auto !important'
                              }}
                              className="messageError-title"
                            >
                              йой!
                            </StyledTitle>
                            <Typography
                              sx={{
                                fontSize: '14px',
                                margin: '5px auto 0px',
                                width: '50%',
                                textAlign: 'center'
                              }}
                            >
                              На жаль, посту більше не існує
                            </Typography>
                          </>
                        </Grid>
                      </Grid>
                    </Paper>
                  ) : null}
                  {message.type === 'service' ? (
                    <Paper sx={{ width: '279px', border: '1px solid #60A5DF', p: '12px' }}>
                      <Grid container>
                        <Grid
                          item
                          xs={12}
                          sx={{
                            height: 350,
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center'
                          }}
                        >
                          <>
                            <StyledTitle
                              // maxWidth={800}
                              sx={{
                                lineHeight: '38px !important',
                                fontSize: '32px !important',
                                textTransform: 'initial !important',
                                margin: '0px auto !important'
                              }}
                              className="messageError-title"
                            >
                              йой!
                            </StyledTitle>
                            <Typography
                              sx={{
                                fontSize: '14px',
                                margin: '5px auto 0px',
                                width: '50%',
                                textAlign: 'center'
                              }}
                            >
                              На жаль, послуги більше не існує
                            </Typography>
                          </>
                        </Grid>
                      </Grid>
                    </Paper>
                  ) : null}
                  {message.type === 'user' ? (
                    <Paper sx={{ width: '279px', border: '1px solid #60A5DF', p: '12px' }}>
                      <Grid container>
                        <Grid
                          item
                          xs={12}
                          sx={{
                            height: 197,
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center'
                          }}
                        >
                          <>
                            <StyledTitle
                              // maxWidth={800}
                              sx={{
                                lineHeight: '38px !important',
                                fontSize: '32px !important',
                                textTransform: 'initial !important',
                                margin: '0px auto !important'
                              }}
                              className="messageError-title"
                            >
                              йой!
                            </StyledTitle>
                            <Typography
                              sx={{
                                fontSize: '14px',
                                margin: '5px auto 0px',
                                width: '50%',
                                textAlign: 'center'
                              }}
                            >
                              На жаль, профіль більше не існує
                            </Typography>
                          </>
                        </Grid>
                      </Grid>
                    </Paper>
                  ) : null}
                </>
              )}
              {itemData.length !== 0 && (
                <>
                  {message?.forwardedMessage && (
                    <Grid container sx={{ width: '100%' }} spacing={1}>
                      <Grid item sx={{ display: 'flex' }}>
                        {message.forwardedMessage?.abstractMessageWithDTO?.chatId !==
                        message?.chatId ? (
                          <SendOutlinedIcon
                            sx={{ color: 'rgba(33, 107, 170, 1)', fontSize: '24px' }}
                          />
                        ) : (
                          <ReplyIcon sx={{ color: 'rgba(33, 107, 170, 1)', fontSize: '24px' }} />
                        )}
                      </Grid>
                      <Grid item xs={10} sx={{ display: 'flex' }}>
                        <Typography
                          onClick={() =>
                            navigate(`/${language}/user-page/${message.forwardedMessage?.userId}?type=posts`)
                          }
                          variant="body2"
                          sx={{
                            fontSize: '14px',
                            fontWeight: 'bold !important',
                            cursor: 'pointer'
                          }}
                        >{`@${message.forwardedMessage?.nickname}`}</Typography>
                      </Grid>

                      {/* <Grid item sx={{ width: '41px' }} /> */}
                    </Grid>
                  )}
                  <ImageList
                    sx={{ width: '300px', height: 'max-content', mb: '0px' }}
                    variant="quilted"
                    cols={6}
                    rowHeight={50}
                  >
                    {itemData.map((item, index) => (
                      <ImageListItem
                        key={`photo${index + message?.id}`}
                        onClick={() => {
                          setSliderStartIdx(index);
                          setShowedImg(itemData);
                          dispatch(setIsOpenFullscreenPicture(true));
                        }}
                        // key={`photo${item.img.lastModified}${message.id}`}
                        cols={item.cols || 1}
                        rows={item.rows || 1}
                      >
                        <img
                          style={{ borderRadius: '5px' }}
                          {...srcset(item.img, 50, item.rows, item.cols)}
                          alt={item.title}
                        />
                      </ImageListItem>
                    ))}
                  </ImageList>
                </>
              )}
              {message?.forwardedMessage?.abstractMessageWithDTO?.type === 'user' ||
              message?.forwardedMessage?.abstractMessageWithDTO?.type === 'service' ||
              message?.forwardedMessage?.abstractMessageWithDTO?.type === 'post' ? (
                <>
                  <Grid container sx={{ width: '100%' }} spacing={1}>
                    <Grid item sx={{ display: 'flex' }}>
                      {message.forwardedMessage?.abstractMessageWithDTO?.chatId !==
                      message?.chatId ? (
                        <SendOutlinedIcon
                          sx={{ color: 'rgba(33, 107, 170, 1)', fontSize: '24px' }}
                        />
                      ) : (
                        <ReplyIcon sx={{ color: 'rgba(33, 107, 170, 1)', fontSize: '24px' }} />
                      )}
                    </Grid>
                    <Grid item xs={10} sx={{ display: 'flex' }}>
                      <Typography
                        onClick={() =>
                          navigate(`/${language}/user-page/${message.forwardedMessage?.userId}?type=posts`)
                        }
                        variant="body2"
                        sx={{
                          fontSize: '14px',
                          fontWeight: 'bold !important',
                          cursor: 'pointer'
                        }}
                      >{`@${message.forwardedMessage?.nickname}`}</Typography>
                    </Grid>

                    {/* <Grid item sx={{ width: '41px' }} /> */}
                  </Grid>
                  {message?.forwardedMessage?.abstractMessageWithDTO?.type === 'user' && (
                    // <Grid item xs={10}>
                    <Paper
                      sx={{
                        width: '279px',
                        border: '1px solid #60A5DF',
                        p: '12px',
                        position: 'relative',
                        mt: 1
                      }}
                    >
                      {message?.forwardedMessage?.abstractMessageWithDTO?.userDTO ? (
                        <img
                          src={Decor}
                          alt="decor"
                          style={{ position: 'absolute', right: '0px', top: '45px' }}
                        />
                      ) : null}

                      <Grid container>
                        <Grid
                          item
                          xs={!message?.forwardedMessage?.abstractMessageWithDTO?.userDTO ? 12 : 6}
                          sx={
                            !message?.forwardedMessage?.abstractMessageWithDTO?.userDTO
                              ? {
                                  height: 197,
                                  display: 'flex',
                                  flexDirection: 'column',
                                  justifyContent: 'center'
                                }
                              : {}
                          }
                        >
                          {message?.forwardedMessage?.abstractMessageWithDTO?.userDTO ? (
                            <Avatar
                              // src={userData?.avatar ? userData.avatar : DefaultAvatar}
                              src={
                                message?.forwardedMessage?.abstractMessageWithDTO?.userDTO
                                  ?.encodedMediaDTO
                                  ? URL.createObjectURL(
                                      getFileFromBase64(
                                        message?.forwardedMessage?.abstractMessageWithDTO?.userDTO
                                          ?.encodedMediaDTO.encodedContent,
                                        message?.forwardedMessage?.abstractMessageWithDTO?.userDTO
                                          ?.encodedMediaDTO.nameFile
                                      )
                                    )
                                  : DefaultAvatar
                              }
                              sx={{
                                width: 106,
                                height: 106,
                                border: '1px solid #60A5DF',
                                borderRadius: '5px'
                              }}
                              variant="square"
                            />
                          ) : (
                            <>
                              <StyledTitle
                                // maxWidth={800}
                                sx={{
                                  lineHeight: '38px !important',
                                  fontSize: '32px !important',
                                  textTransform: 'initial !important',
                                  margin: '0px auto !important'
                                }}
                                className="messageError-title"
                              >
                                йой!
                              </StyledTitle>
                              <Typography
                                sx={{
                                  fontSize: '14px',
                                  margin: '5px auto 0px',
                                  width: '50%',
                                  textAlign: 'center'
                                }}
                              >
                                На жаль, профіль більше не існує
                              </Typography>
                            </>
                          )}
                        </Grid>
                        {message?.forwardedMessage?.abstractMessageWithDTO?.userDTO ? (
                          <Grid item xs={6}>
                            {message?.forwardedMessage?.abstractMessageWithDTO?.userDTO?.name &&
                              message?.forwardedMessage?.abstractMessageWithDTO?.userDTO
                                ?.surname && (
                                <Typography
                                  fontSize={14}
                                  lineHeight="normal"
                                  fontWeight={400}
                                  sx={{ mt: 1 }}
                                >
                                  {`${message?.forwardedMessage?.abstractMessageWithDTO?.userDTO?.name} ${message?.forwardedMessage?.abstractMessageWithDTO?.userDTO?.surname}`}
                                </Typography>
                              )}

                            <Typography
                              component="p"
                              fontSize={
                                message?.forwardedMessage?.abstractMessageWithDTO?.userDTO?.name &&
                                message?.forwardedMessage?.abstractMessageWithDTO?.userDTO?.surname
                                  ? 12
                                  : 14
                              }
                              lineHeight="normal"
                              fontWeight={400}
                              sx={{ wordBreak: 'break-all', maxWidth: '90%', mt: 1 }}
                            >
                              {`@${message?.forwardedMessage?.abstractMessageWithDTO?.userDTO?.nickname}`}
                            </Typography>

                            {message?.forwardedMessage?.abstractMessageWithDTO?.userDTO?.city &&
                              message?.forwardedMessage?.abstractMessageWithDTO?.userDTO?.city
                                .length !== 0 &&
                              message?.forwardedMessage?.abstractMessageWithDTO?.userDTO?.country &&
                              message?.forwardedMessage?.abstractMessageWithDTO?.userDTO?.country
                                .length !== 0 &&
                              message?.forwardedMessage?.abstractMessageWithDTO?.userDTO?.region &&
                              message?.forwardedMessage?.abstractMessageWithDTO?.userDTO?.region
                                .length !== 0 && (
                                <Typography
                                  sx={{ alignItems: 'center', mt: '2px' }}
                                  fontSize={12}
                                  lineHeight="normal"
                                  fontWeight={400}
                                >
                                  <PushPinOutlinedIcon
                                    style={{
                                      color: '#60A5DF',
                                      fontSize: '14px',
                                      transform: 'rotate(-45deg)'
                                    }}
                                  />
                                  {`${
                                    message?.forwardedMessage &&
                                    message?.forwardedMessage?.abstractMessageWithDTO?.userDTO?.city
                                      ? message?.forwardedMessage &&
                                        message?.forwardedMessage?.abstractMessageWithDTO?.userDTO
                                          ?.city
                                      : ''
                                  }${
                                    message?.forwardedMessage &&
                                    message?.forwardedMessage?.abstractMessageWithDTO?.userDTO
                                      ?.city &&
                                    message?.forwardedMessage?.abstractMessageWithDTO?.userDTO?.city
                                      .length !== 0 &&
                                    message?.forwardedMessage?.abstractMessageWithDTO?.userDTO
                                      ?.region &&
                                    message?.forwardedMessage?.abstractMessageWithDTO?.userDTO
                                      ?.region.length !== 0
                                      ? ', '
                                      : ''
                                  }${
                                    message?.forwardedMessage &&
                                    message?.forwardedMessage?.abstractMessageWithDTO?.userDTO
                                      ?.region
                                      ? `${
                                          message?.forwardedMessage &&
                                          message?.forwardedMessage?.abstractMessageWithDTO?.userDTO
                                            ?.region
                                        } обл.`
                                      : ''
                                  }${
                                    message?.forwardedMessage &&
                                    message?.forwardedMessage?.abstractMessageWithDTO?.userDTO?.city
                                      .length !== 0 &&
                                    message?.forwardedMessage?.abstractMessageWithDTO?.userDTO
                                      ?.country.length !== 0
                                      ? ', '
                                      : ''
                                  }${
                                    message?.forwardedMessage &&
                                    message?.forwardedMessage?.abstractMessageWithDTO?.userDTO
                                      ?.country
                                  }`}
                                </Typography>
                              )}
                          </Grid>
                        ) : null}
                        {message?.forwardedMessage?.abstractMessageWithDTO?.userDTO ? (
                          <Grid item xs={12}>
                            <LetsGoBtn
                              className="service-card-btn"
                              width="100%"
                              margin="10px auto"
                              text="Детальніше"
                              onClick={() => {
                                navigate(
                                  `/${language}/user-page/${message?.forwardedMessage?.abstractMessageWithDTO?.userDTO?.id}?type=posts`
                                );
                                // if (userData?.id) {
                                //   navigate(`/${language}/service/${el.id}`);
                                //   window.scrollTo({ top: 0 });
                                // } else {
                                //   dispatch(setIsOpenAuthModal(true));
                                // }
                              }}
                            />
                          </Grid>
                        ) : null}
                      </Grid>
                    </Paper>
                  )}
                  {message?.forwardedMessage?.abstractMessageWithDTO?.type === 'service' && (
                    <Paper sx={{ width: '279px', border: '1px solid #60A5DF', p: '12px', mt: 1 }}>
                      <Grid container>
                        <Grid
                          item
                          xs={12}
                          sx={
                            !message?.forwardedMessage?.abstractMessageWithDTO?.servicePostDTO
                              ? {
                                  height: 350,
                                  display: 'flex',
                                  flexDirection: 'column',
                                  justifyContent: 'center'
                                }
                              : {}
                          }
                        >
                          {message?.forwardedMessage?.abstractMessageWithDTO?.servicePostDTO ? (
                            <Avatar
                              // src={userData?.avatar ? userData.avatar : DefaultAvatar}
                              src={
                                message?.forwardedMessage?.abstractMessageWithDTO?.servicePostDTO
                                  ?.encodedPhotos.length !== 0
                                  ? URL.createObjectURL(
                                      getFileFromBase64(
                                        message?.forwardedMessage?.abstractMessageWithDTO
                                          ?.servicePostDTO?.encodedPhotos[0].encodedContent,
                                        message?.forwardedMessage?.abstractMessageWithDTO
                                          ?.servicePostDTO?.encodedPhotos[0].nameFile
                                      )
                                    )
                                  : DefaultAvatar
                              }
                              sx={{
                                width: '100%',
                                height: 255,
                                border: '1px solid #60A5DF',
                                borderRadius: '5px'
                              }}
                              variant="square"
                            />
                          ) : (
                            <>
                              <StyledTitle
                                // maxWidth={800}
                                sx={{
                                  lineHeight: '38px !important',
                                  fontSize: '32px !important',
                                  textTransform: 'initial !important',
                                  margin: '0px auto !important'
                                }}
                                className="messageError-title"
                              >
                                йой!
                              </StyledTitle>
                              <Typography
                                sx={{
                                  fontSize: '14px',
                                  margin: '5px auto 0px',
                                  width: '50%',
                                  textAlign: 'center'
                                }}
                              >
                                На жаль, послуги більше не існує
                              </Typography>
                            </>
                          )}
                        </Grid>
                        {message?.forwardedMessage?.abstractMessageWithDTO?.postDTO ? (
                          <Grid item xs={12}>
                            <Typography
                              variant="h5"
                              style={{
                                fontSize: '16px',
                                margin: '0px 10px 10px',
                                wordBreak: 'break-word'
                              }}
                              sx={{ mt: 2, textAlign: 'center' }}
                            >
                              {`${message?.forwardedMessage?.abstractMessageWithDTO?.servicePostDTO?.nameService}`}
                            </Typography>
                            <Typography
                              style={{
                                fontSize: '16px',
                                margin: '0px 0px 5px 0px',
                                fontFamily: 'Kyiv-Sans-Regular',
                                textAlign: 'center'
                              }}
                            >
                              {`${message?.forwardedMessage?.abstractMessageWithDTO?.servicePostDTO?.price} ${message?.forwardedMessage?.abstractMessageWithDTO?.servicePostDTO?.currency}`}
                            </Typography>
                          </Grid>
                        ) : null}
                        {message?.forwardedMessage?.abstractMessageWithDTO?.postDTO ? (
                          <Grid item xs={12}>
                            <LetsGoBtn
                              className="service-card-btn"
                              width="100%"
                              margin="10px auto"
                              text="Детальніше"
                              onClick={() => {
                                navigate(
                                  `/${language}/service/${message?.forwardedMessage?.abstractMessageWithDTO?.servicePostDTO?.id}`
                                );
                                // if (userData?.id) {
                                //   navigate(`/${language}/service/${el.id}`);
                                //   window.scrollTo({ top: 0 });
                                // } else {
                                //   dispatch(setIsOpenAuthModal(true));
                                // }
                              }}
                            />
                          </Grid>
                        ) : null}
                      </Grid>
                    </Paper>
                  )}
                  {message?.forwardedMessage?.abstractMessageWithDTO?.type === 'post' && (
                    <Paper sx={{ width: '279px', border: '1px solid #60A5DF', p: '12px', mt: 1 }}>
                      <Grid container>
                        <Grid item xs={12}>
                          <Typography
                            style={{
                              fontSize: '12px',
                              margin: '0px 0px 5px 0px',
                              textAlign: 'left',
                              wordBreak: 'break-word',
                              display: '-webkit-box',
                              WebkitLineClamp: 3,
                              WebkitBoxOrient: 'vertical',
                              overflowY: 'hidden',
                              textOverflow: 'ellipsis'
                            }}
                            children={
                              <>
                                {message?.forwardedMessage?.abstractMessageWithDTO?.postDTO?.description
                                  .split('\r\n')
                                  .filter((el) => el.length !== 0)
                                  .map((string, index) => {
                                    return (
                                      <React.Fragment key={string + index}>
                                        <span style={{ margin: '0' }}>
                                          {string}

                                          <br />
                                        </span>
                                      </React.Fragment>
                                    );
                                  })}
                              </>
                            }
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sx={
                            !message?.forwardedMessage?.abstractMessageWithDTO?.postDTO
                              ? {
                                  height: 350,
                                  display: 'flex',
                                  flexDirection: 'column',
                                  justifyContent: 'center'
                                }
                              : {}
                          }
                        >
                          {message?.forwardedMessage?.abstractMessageWithDTO?.postDTO ? (
                            <Avatar
                              // src={userData?.avatar ? userData.avatar : DefaultAvatar}
                              src={
                                message?.forwardedMessage?.abstractMessageWithDTO?.postDTO
                                  ?.encodedPhotos.length !== 0
                                  ? URL.createObjectURL(
                                      getFileFromBase64(
                                        message?.forwardedMessage?.abstractMessageWithDTO?.postDTO
                                          .encodedPhotos[0].encodedContent,
                                        message?.forwardedMessage?.abstractMessageWithDTO?.postDTO
                                          .encodedPhotos[0].nameFile
                                      )
                                    )
                                  : DefaultAvatar
                              }
                              sx={{
                                width: '100%',
                                height: 255,
                                border: '1px solid #60A5DF',
                                borderRadius: '5px',
                                mb: 1
                              }}
                              variant="square"
                            />
                          ) : (
                            <>
                              <StyledTitle
                                // maxWidth={800}
                                sx={{
                                  lineHeight: '38px !important',
                                  fontSize: '32px !important',
                                  textTransform: 'initial !important',
                                  margin: '0px auto !important'
                                }}
                                className="messageError-title"
                              >
                                йой!
                              </StyledTitle>
                              <Typography
                                sx={{
                                  fontSize: '14px',
                                  margin: '5px auto 0px',
                                  width: '50%',
                                  textAlign: 'center'
                                }}
                              >
                                На жаль, посту більше не існує
                              </Typography>
                            </>
                          )}
                        </Grid>
                        {message?.forwardedMessage?.abstractMessageWithDTO?.postDTO ? (
                          <Grid item xs={12}>
                            <LetsGoBtn
                              className="service-card-btn"
                              width="100%"
                              margin="10px auto"
                              text="Детальніше"
                              onClick={() => {
                                navigate(
                                  `/${language}/post/${message?.forwardedMessage?.abstractMessageWithDTO?.postDTO?.id}`
                                );
                                // if (userData?.id) {
                                //   navigate(`/${language}/service/${el.id}`);
                                //   window.scrollTo({ top: 0 });
                                // } else {
                                //   dispatch(setIsOpenAuthModal(true));
                                // }
                              }}
                            />
                          </Grid>
                        ) : null}
                      </Grid>
                    </Paper>
                  )}
                </>
              ) : null}
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  width: '100%'

                  // flexDirection:itemData.length!==0?'column':'row'
                }}
              >
                <Grid container>
                  {message.forwardedMessage && (
                    <Grid
                      container
                      sx={{ width: '100%', pt: '10px' }}
                      spacing={!message?.forwardedMessage?.abstractMessageWithDTO?.photos ? 1 : 0}
                    >
                      {!message?.forwardedMessage?.abstractMessageWithDTO?.photos &&
                        message?.forwardedMessage?.abstractMessageWithDTO?.type !== 'user' &&
                        message?.forwardedMessage?.abstractMessageWithDTO?.type !== 'service' &&
                        message?.forwardedMessage?.abstractMessageWithDTO?.type !== 'post' && (
                          <>
                            {}
                            <Grid item sx={{ display: 'flex' }}>
                              {message.forwardedMessage.abstractMessageWithDTO.chatId !==
                              message.chatId ? (
                                <SendOutlinedIcon
                                  sx={{ color: 'rgba(33, 107, 170, 1)', fontSize: '24px' }}
                                />
                              ) : (
                                <ReplyIcon
                                  sx={{ color: 'rgba(33, 107, 170, 1)', fontSize: '24px' }}
                                />
                              )}
                            </Grid>
                            <Grid item xs={10} sx={{ display: 'flex' }}>
                              <Typography
                                onClick={() =>
                                  navigate(
                                    `/${language}/user-page/${message.forwardedMessage.userId}?type=posts`
                                  )
                                }
                                variant="body2"
                                sx={{
                                  fontSize: '14px',
                                  fontWeight: 'bold !important',
                                  cursor: 'pointer'
                                }}
                              >{`@${message.forwardedMessage.nickname}`}</Typography>
                            </Grid>{' '}
                            <Grid item sx={{ width: '41px' }} />
                          </>
                        )}

                      <Grid
                        item
                        xs={10}
                        sx={{
                          wordBreak: 'break-word',
                          fontSize: '12px',
                          borderBottom: '1px solid #60A5DF',
                          // message.forwardedMessage.abstractMessageWithDTO.text.length !== 0
                          //   ? '1px solid #60A5DF'
                          //   : 'none',
                          pb: 1,
                          mb: 1,
                          pl:
                            message?.forwardedMessage?.abstractMessageWithDTO?.type === 'user' ||
                            message?.forwardedMessage?.abstractMessageWithDTO?.type === 'service' ||
                            message?.forwardedMessage?.abstractMessageWithDTO?.type === 'post'
                              ? '1px !important'
                              : '8px',
                          ml:
                            message?.forwardedMessage?.abstractMessageWithDTO?.type === 'user' ||
                            message?.forwardedMessage?.abstractMessageWithDTO?.type === 'service' ||
                            message?.forwardedMessage?.abstractMessageWithDTO?.type === 'post'
                              ? 1
                              : 0,
                          mt:
                            message?.forwardedMessage?.abstractMessageWithDTO?.type === 'user' ||
                            message?.forwardedMessage?.abstractMessageWithDTO?.type === 'service' ||
                            message?.forwardedMessage?.abstractMessageWithDTO?.type === 'post'
                              ? 1
                              : 0
                        }}
                      >
                        {message.forwardedMessage.abstractMessageWithDTO.text}
                      </Grid>
                    </Grid>
                  )}
                  {message?.forwardedMessage?.abstractMessageWithDTO?.type !== 'user' &&
                    message?.forwardedMessage?.abstractMessageWithDTO?.type !== 'service' &&
                    message?.forwardedMessage?.abstractMessageWithDTO?.type !== 'post' && (
                      <Grid
                        item
                        sx={!message.forwardedMessage ? { display: 'none' } : { width: '41px' }}
                      />
                    )}

                  <Grid item xs={message.forwardedMessage ? 10 : 12} sx={{ mr: 2 }}>
                    <Typography
                      variant="body2"
                      sx={
                        message.forwardedMessage
                          ? {
                              fontSize: '14px',
                              ml:
                                !message.forwardedMessage.abstractMessageWithDTO.photos &&
                                message?.forwardedMessage?.abstractMessageWithDTO?.type !==
                                  'user' &&
                                message?.forwardedMessage?.abstractMessageWithDTO?.type !==
                                  'service' &&
                                message?.forwardedMessage?.abstractMessageWithDTO?.type !== 'post'
                                  ? '41px'
                                  : '0px',
                              wordBreak: 'break-word'
                            }
                          : { fontSize: '14px', wordBreak: 'break-word' }
                      }
                    >
                      {message.text}
                    </Typography>
                  </Grid>
                </Grid>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    margin: 'auto',
                    alignItems: 'end'
                  }}
                >
                  <Typography
                    variant="body2"
                    sx={{
                      fontSize: '12px',
                      fontFamily: 'Kyiv-Sans-Light',
                      color: 'rgba(33, 107, 170, 1)',
                      marginLeft: 'auto',
                      marginTop: '0'
                    }}
                  >
                    {timeFormattingByDate(new Date(message.sendingTime))}
                  </Typography>
                  {message?.id ? (
                    <>
                      {!message?.messageIsRead ? (
                        <DoneIcon sx={{ fontSize: '19px', color: '#216BAA' }} />
                      ) : (
                        <DoneAllIcon sx={{ fontSize: '19px', color: '#216BAA' }} />
                      )}
                    </>
                  ) : (
                    <AccessTimeIcon sx={{ color: '#c37b7b', fontSize: '19px' }} />
                  )}
                </div>

                {isShowDots && (
                  <>
                    <MoreVertIcon
                      // key={`${message.text}${Math.random()}`}
                      onClick={(event) => handleClick(event)}
                      sx={{
                        ml: 1,
                        cursor: 'pointer',
                        ':hover': { color: '#60A5DF' },
                        zIndex: '99',
                        fontSize: '20px'
                      }}
                    />
                    <Menu
                      anchorEl={anchorEl}
                      id="action-menu"
                      open={open}
                      onClose={handleClose}
                      onMouseLeave={handleClose}
                      PaperProps={{
                        elevation: 0,
                        sx: {
                          overflow: 'hidden',
                          boxShadow: 'none',
                          borderRadius: '5px',
                          border: '1px solid black',
                          paddingTop: '10px',
                          paddingLeft: '20px',
                          paddingRight: '20px',
                          mt: 1
                        }
                      }}
                      autoFocus={false}
                      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                    >
                      <MenuItem
                        onClick={(event) => {
                          event.stopPropagation();
                          event.preventDefault();
                          setIsEditMessage(true);
                          setIsSendingPic(false);
                          setIsReplyMessage(true);
                          setIsResendMessage(false);
                          handleClose();
                        }}
                        sx={{ padding: 0, mb: 1 }}
                      >
                        <ReplyIcon sx={{ color: '#60A5DF', mr: 1 }} />
                        <Typography component="p" fontSize={16} lineHeight="18px" fontWeight={400}>
                          Відповісти
                        </Typography>
                      </MenuItem>
                      <MenuItem
                        onClick={(event) => {
                          event.stopPropagation();
                          event.preventDefault();
                          dispatch(
                            setIsOpenResendingModal({
                              isOpen: true,
                              id: null,
                              type: null,
                              func: setUsersForResending
                            })
                          );
                          setIsEditMessage(false);
                          setIsSendingPic(false);
                          setIsResendMessage(true);
                          setIsReplyMessage(false);
                          handleClose();
                        }}
                        sx={{ padding: 0, mb: 1 }}
                      >
                        <SendOutlinedIcon sx={{ color: '#60A5DF', mr: 1 }} />
                        <Typography component="p" fontSize={16} lineHeight="18px" fontWeight={400}>
                          Відправити
                        </Typography>
                      </MenuItem>
                      <MenuItem
                        onClick={(event) => {
                          event.stopPropagation();
                          event.preventDefault();
                          setIsEditMessage(true);
                          // THIS NEEDED IF MESSAGE HAD A PHOTOS !!!!!!!!!
                          if (message?.type && message?.type === 'photo') {
                            setIsSendingPic(true);
                          }
                          setIsReplyMessage(false);
                          setIsResendMessage(false);
                          handleClose();
                        }}
                        sx={{ padding: 0, mb: 1 }}
                      >
                        <EditOutlinedIcon sx={{ color: '#60A5DF', mr: 1 }} />
                        <Typography component="p" fontSize={16} lineHeight="18px" fontWeight={400}>
                          Редагувати
                        </Typography>
                      </MenuItem>
                      {!message?.messageIsRead && (
                        <MenuItem
                          onClick={(event) => {
                            event.stopPropagation();
                            event.preventDefault();
                            dispatch(
                              setIsOpenCancelModal({
                                isOpen: true,
                                func: deleteMessage,
                                id: message.id
                              })
                            );
                            handleClose();
                          }}
                          sx={{ padding: 0, mb: 1 }}
                        >
                          <CancelScheduleSendOutlinedIcon sx={{ color: '#60A5DF', mr: 1 }} />
                          <Typography
                            component="p"
                            fontSize={16}
                            lineHeight="18px"
                            fontWeight={400}
                          >
                            Відмінити відправку
                          </Typography>
                        </MenuItem>
                      )}
                    </Menu>
                  </>
                )}
              </Box>
            </Box>
          </Grid>
        </Grid>
      ) : (
        <Grid
          container
          id={message?.id}
          key={`chatMessage${message?.id}`}
          sx={{ overflowAnchor: 'auto' }}
        >
          <Grid item xs={6} sx={{ p: 1, display: 'flex', alignItems: 'end' }}>
            <Avatar
              onClick={() => {
                // navigate(`/${language}/user-page/${user.id}`);
              }}
              src={
                selectedChat?.userInfoDTO?.encodedMediaDTO
                  ? `data:${selectedChat.userInfoDTO?.encodedMediaDTO.contentType};base64, ${selectedChat.userInfoDTO?.encodedMediaDTO.encodedContent}`
                  : DefaultAvatar
              }
              sx={{
                width: 30,
                height: 30,
                border: '1px solid #60A5DF',
                cursor: 'pointer',
                mr: 2
              }}
            />
            <Box
              onMouseEnter={() => {
                if (message?.id) {
                  setIsShowDots(true);
                } else {
                  return;
                }
              }}
              onMouseLeave={() => {
                if (message?.id) {
                  setIsShowDots(false);
                } else {
                  return;
                }
              }}
              sx={{
                // alignItems:'center !important',
                padding: '10px',
                borderRadius: '10px 10px 10px 0px',
                background: '#deecf8',
                fontSize: '14px',
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
                flexDirection: 'column'
              }}
            >
              {includedObject ? (
                <>
                  {message.type === 'user' ? (
                    <Paper
                      sx={{
                        width: '279px',
                        border: '1px solid #60A5DF',
                        p: '12px',
                        position: 'relative'
                      }}
                    >
                      <img
                        src={Decor}
                        alt="decor"
                        style={{ position: 'absolute', right: '0px', top: '45px' }}
                      />
                      <Grid container>
                        <Grid item xs={6}>
                          <Avatar
                            // src={userData?.avatar ? userData.avatar : DefaultAvatar}
                            src={
                              includedObject?.encodedMediaDTO
                                ? URL.createObjectURL(
                                    getFileFromBase64(
                                      includedObject.encodedMediaDTO.encodedContent,
                                      includedObject.encodedMediaDTO.nameFile
                                    )
                                  )
                                : DefaultAvatar
                            }
                            sx={{
                              width: 106,
                              height: 106,
                              border: '1px solid #60A5DF',
                              borderRadius: '5px'
                            }}
                            variant="square"
                          />
                        </Grid>
                        <Grid item xs={6}>
                          {includedObject?.name && includedObject?.surname && (
                            <Typography
                              fontSize={14}
                              lineHeight="normal"
                              fontWeight={400}
                              sx={{ mt: 1 }}
                            >
                              {`${includedObject.name} ${includedObject.surname}`}
                            </Typography>
                          )}

                          <Typography
                            component="p"
                            fontSize={includedObject?.name && includedObject?.surname ? 12 : 14}
                            lineHeight="normal"
                            fontWeight={400}
                            sx={{ wordBreak: 'break-all', maxWidth: '90%', mt: 1 }}
                          >
                            {`@${includedObject?.nickname}`}
                          </Typography>

                          {includedObject?.city &&
                            includedObject?.city.length !== 0 &&
                            includedObject?.country &&
                            includedObject?.country.length !== 0 &&
                            includedObject?.region &&
                            includedObject?.region.length !== 0 && (
                              <Typography
                                sx={{ alignItems: 'center', mt: '2px' }}
                                fontSize={12}
                                lineHeight="normal"
                                fontWeight={400}
                              >
                                <PushPinOutlinedIcon
                                  style={{
                                    color: '#60A5DF',
                                    fontSize: '14px',
                                    transform: 'rotate(-45deg)'
                                  }}
                                />
                                {`${includedObject?.city ? includedObject?.city : ''}${
                                  includedObject?.city &&
                                  includedObject?.city.length !== 0 &&
                                  includedObject?.region &&
                                  includedObject?.region.length !== 0
                                    ? ', '
                                    : ''
                                }${includedObject?.region ? `${includedObject.region} обл.` : ''}${
                                  includedObject?.city &&
                                  includedObject?.city.length !== 0 &&
                                  includedObject?.country &&
                                  includedObject?.country.length !== 0
                                    ? ', '
                                    : ''
                                }${includedObject?.country ? includedObject?.country : ''}`}
                              </Typography>
                            )}
                        </Grid>
                        <Grid item xs={12}>
                          <LetsGoBtn
                            className="service-card-btn"
                            width="100%"
                            margin="10px auto"
                            text="Детальніше"
                            onClick={() => {
                              navigate(`/${language}/user-page/${includedObject.id}?type=posts`);
                              // if (userData?.id) {
                              //   navigate(`/${language}/service/${el.id}`);
                              //   window.scrollTo({ top: 0 });
                              // } else {
                              //   dispatch(setIsOpenAuthModal(true));
                              // }
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Paper>
                  ) : null}
                  {message.type === 'service' ? (
                    <Paper sx={{ width: '279px', border: '1px solid #60A5DF', p: '12px' }}>
                      <Grid container>
                        <Grid item xs={12}>
                          <Avatar
                            // src={userData?.avatar ? userData.avatar : DefaultAvatar}
                            src={
                              includedObject?.encodedPhotos.length !== 0
                                ? URL.createObjectURL(
                                    getFileFromBase64(
                                      includedObject.encodedPhotos[0].encodedContent,
                                      includedObject.encodedPhotos[0].nameFile
                                    )
                                  )
                                : DefaultAvatar
                            }
                            sx={{
                              width: '100%',
                              height: 255,
                              border: '1px solid #60A5DF',
                              borderRadius: '5px'
                            }}
                            variant="square"
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Typography
                            variant="h5"
                            style={{
                              fontSize: '16px',
                              margin: '0px 10px 10px',
                              wordBreak: 'break-word'
                            }}
                            sx={{ mt: 2, textAlign: 'center' }}
                          >
                            {`${includedObject.nameService}`}
                          </Typography>
                          <Typography
                            style={{
                              fontSize: '16px',
                              margin: '0px 0px 5px 0px',
                              fontFamily: 'Kyiv-Sans-Regular',
                              textAlign: 'center'
                            }}
                          >
                            {`${includedObject.price} ${includedObject.currency}`}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <LetsGoBtn
                            className="service-card-btn"
                            width="100%"
                            margin="10px auto"
                            text="Детальніше"
                            onClick={() => {
                              navigate(`/${language}/service/${includedObject.id}`);
                              // if (userData?.id) {
                              //   navigate(`/${language}/service/${el.id}`);
                              //   window.scrollTo({ top: 0 });
                              // } else {
                              //   dispatch(setIsOpenAuthModal(true));
                              // }
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Paper>
                  ) : null}
                  {message.type === 'post' ? (
                    <Paper sx={{ width: '279px', border: '1px solid #60A5DF', p: '12px' }}>
                      <Grid container>
                        <Grid item xs={12}>
                          <Typography
                            style={{
                              fontSize: '12px',
                              margin: '0px 0px 5px 0px',
                              textAlign: 'left',
                              wordBreak: 'break-word',
                              display: '-webkit-box',
                              WebkitLineClamp: 3,
                              WebkitBoxOrient: 'vertical',
                              overflowY: 'hidden',
                              textOverflow: 'ellipsis'
                            }}
                            children={
                              <>
                                {includedObject.description
                                  .split('\r\n')
                                  .filter((el) => el.length !== 0)
                                  .map((string, index) => {
                                    return (
                                      <React.Fragment key={string + index}>
                                        <span style={{ margin: '0' }}>
                                          {string}

                                          <br />
                                        </span>
                                      </React.Fragment>
                                    );
                                  })}
                              </>
                            }
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Avatar
                            // src={userData?.avatar ? userData.avatar : DefaultAvatar}
                            src={
                              includedObject?.encodedPhotos.length !== 0
                                ? URL.createObjectURL(
                                    getFileFromBase64(
                                      includedObject.encodedPhotos[0].encodedContent,
                                      includedObject.encodedPhotos[0].nameFile
                                    )
                                  )
                                : DefaultAvatar
                            }
                            sx={{
                              width: '100%',
                              height: 255,
                              border: '1px solid #60A5DF',
                              borderRadius: '5px',
                              mb: 1
                            }}
                            variant="square"
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <LetsGoBtn
                            className="service-card-btn"
                            width="100%"
                            margin="10px auto"
                            text="Детальніше"
                            onClick={() => {
                              navigate(`/${language}/post/${includedObject.id}`);
                              // if (userData?.id) {
                              //   navigate(`/${language}/service/${el.id}`);
                              //   window.scrollTo({ top: 0 });
                              // } else {
                              //   dispatch(setIsOpenAuthModal(true));
                              // }
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Paper>
                  ) : null}
                </>
              ) : (
                <>
                  {message.type === 'post' ? (
                    <Paper sx={{ width: '279px', border: '1px solid #60A5DF', p: '12px' }}>
                      <Grid container>
                        <Grid
                          item
                          xs={12}
                          sx={{
                            height: 350,
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center'
                          }}
                        >
                          <>
                            <StyledTitle
                              // maxWidth={800}
                              sx={{
                                lineHeight: '38px !important',
                                fontSize: '32px !important',
                                textTransform: 'initial !important',
                                margin: '0px auto !important'
                              }}
                              className="messageError-title"
                            >
                              йой!
                            </StyledTitle>
                            <Typography
                              sx={{
                                fontSize: '14px',
                                margin: '5px auto 0px',
                                width: '50%',
                                textAlign: 'center'
                              }}
                            >
                              На жаль, посту більше не існує
                            </Typography>
                          </>
                        </Grid>
                      </Grid>
                    </Paper>
                  ) : null}
                  {message.type === 'service' ? (
                    <Paper sx={{ width: '279px', border: '1px solid #60A5DF', p: '12px' }}>
                      <Grid container>
                        <Grid
                          item
                          xs={12}
                          sx={{
                            height: 350,
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center'
                          }}
                        >
                          <>
                            <StyledTitle
                              // maxWidth={800}
                              sx={{
                                lineHeight: '38px !important',
                                fontSize: '32px !important',
                                textTransform: 'initial !important',
                                margin: '0px auto !important'
                              }}
                              className="messageError-title"
                            >
                              йой!
                            </StyledTitle>
                            <Typography
                              sx={{
                                fontSize: '14px',
                                margin: '5px auto 0px',
                                width: '50%',
                                textAlign: 'center'
                              }}
                            >
                              На жаль, послуги більше не існує
                            </Typography>
                          </>
                        </Grid>
                      </Grid>
                    </Paper>
                  ) : null}
                  {message.type === 'user' ? (
                    <Paper sx={{ width: '279px', border: '1px solid #60A5DF', p: '12px' }}>
                      <Grid container>
                        <Grid
                          item
                          xs={12}
                          sx={{
                            height: 197,
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center'
                          }}
                        >
                          <>
                            <StyledTitle
                              // maxWidth={800}
                              sx={{
                                lineHeight: '38px !important',
                                fontSize: '32px !important',
                                textTransform: 'initial !important',
                                margin: '0px auto !important'
                              }}
                              className="messageError-title"
                            >
                              йой!
                            </StyledTitle>
                            <Typography
                              sx={{
                                fontSize: '14px',
                                margin: '5px auto 0px',
                                width: '50%',
                                textAlign: 'center'
                              }}
                            >
                              На жаль, профіль більше не існує
                            </Typography>
                          </>
                        </Grid>
                      </Grid>
                    </Paper>
                  ) : null}
                </>
              )}

              {itemData.length !== 0 && (
                <>
                  {message?.forwardedMessage && (
                    <Grid container sx={{ width: '100%' }} spacing={1}>
                      <Grid item sx={{ display: 'flex' }}>
                        {message.forwardedMessage?.abstractMessageWithDTO?.chatId !==
                        message?.chatId ? (
                          <SendOutlinedIcon
                            sx={{ color: 'rgba(33, 107, 170, 1)', fontSize: '24px' }}
                          />
                        ) : (
                          <ReplyIcon sx={{ color: 'rgba(33, 107, 170, 1)', fontSize: '24px' }} />
                        )}
                      </Grid>
                      <Grid item xs={10} sx={{ display: 'flex' }}>
                        <Typography
                          onClick={() =>
                            navigate(`/${language}/user-page/${message.forwardedMessage?.userId}?type=posts`)
                          }
                          variant="body2"
                          sx={{
                            fontSize: '14px',
                            fontWeight: 'bold !important',
                            cursor: 'pointer'
                          }}
                        >{`@${message.forwardedMessage?.nickname}`}</Typography>
                      </Grid>

                      {/* <Grid item sx={{ width: '41px' }} /> */}
                    </Grid>
                  )}{' '}
                  <ImageList
                    sx={{ width: '300px', height: 'max-content' }}
                    variant="quilted"
                    cols={6}
                    rowHeight={50}
                  >
                    {itemData.map((item, index) => (
                      <ImageListItem
                        onClick={() => {
                          setSliderStartIdx(index);
                          setShowedImg(itemData);
                          dispatch(setIsOpenFullscreenPicture(true));
                        }}
                        key={item.img + index}
                        cols={item.cols || 1}
                        rows={item.rows || 1}
                      >
                        <img
                          style={{
                            borderRadius: '5px',
                            maxHeight: '50% !important',
                            maxWidth: '50vh !important'
                          }}
                          {...srcset(item.img, 50, item.rows, item.cols)}
                          alt={item.title}
                        />
                      </ImageListItem>
                    ))}
                  </ImageList>
                </>
              )}
              {message?.forwardedMessage?.abstractMessageWithDTO?.type === 'user' ||
              message?.forwardedMessage?.abstractMessageWithDTO?.type === 'service' ||
              message?.forwardedMessage?.abstractMessageWithDTO?.type === 'post' ? (
                <>
                  <Grid container sx={{ width: '100%' }} spacing={1}>
                    <Grid item sx={{ display: 'flex' }}>
                      {message.forwardedMessage?.abstractMessageWithDTO?.chatId !==
                      message?.chatId ? (
                        <SendOutlinedIcon
                          sx={{ color: 'rgba(33, 107, 170, 1)', fontSize: '24px' }}
                        />
                      ) : (
                        <ReplyIcon sx={{ color: 'rgba(33, 107, 170, 1)', fontSize: '24px' }} />
                      )}
                    </Grid>
                    <Grid item xs={10} sx={{ display: 'flex' }}>
                      <Typography
                        onClick={() =>
                          navigate(`/${language}/user-page/${message.forwardedMessage?.userId}?type=posts`)
                        }
                        variant="body2"
                        sx={{
                          fontSize: '14px',
                          fontWeight: 'bold !important',
                          cursor: 'pointer'
                        }}
                      >{`@${message.forwardedMessage?.nickname}`}</Typography>
                    </Grid>

                    {/* <Grid item sx={{ width: '41px' }} /> */}
                  </Grid>
                  {message?.forwardedMessage?.abstractMessageWithDTO?.type === 'user' && (
                    // <Grid item xs={10}>
                    <Paper
                      sx={{
                        width: '279px',
                        border: '1px solid #60A5DF',
                        p: '12px',
                        position: 'relative',
                        mt: 1
                      }}
                    >
                      {message?.forwardedMessage?.abstractMessageWithDTO?.userDTO ? (
                        <img
                          src={Decor}
                          alt="decor"
                          style={{ position: 'absolute', right: '0px', top: '45px' }}
                        />
                      ) : null}

                      <Grid container>
                        <Grid
                          item
                          xs={!message?.forwardedMessage?.abstractMessageWithDTO?.userDTO ? 12 : 6}
                          sx={
                            !message?.forwardedMessage?.abstractMessageWithDTO?.userDTO
                              ? {
                                  height: 197,
                                  display: 'flex',
                                  flexDirection: 'column',
                                  justifyContent: 'center'
                                }
                              : {}
                          }
                        >
                          {message?.forwardedMessage?.abstractMessageWithDTO?.userDTO ? (
                            <Avatar
                              // src={userData?.avatar ? userData.avatar : DefaultAvatar}
                              src={
                                message?.forwardedMessage?.abstractMessageWithDTO?.userDTO
                                  ?.encodedMediaDTO
                                  ? URL.createObjectURL(
                                      getFileFromBase64(
                                        message?.forwardedMessage?.abstractMessageWithDTO?.userDTO
                                          ?.encodedMediaDTO.encodedContent,
                                        message?.forwardedMessage?.abstractMessageWithDTO?.userDTO
                                          ?.encodedMediaDTO.nameFile
                                      )
                                    )
                                  : DefaultAvatar
                              }
                              sx={{
                                width: 106,
                                height: 106,
                                border: '1px solid #60A5DF',
                                borderRadius: '5px'
                              }}
                              variant="square"
                            />
                          ) : (
                            <>
                              <StyledTitle
                                // maxWidth={800}
                                sx={{
                                  lineHeight: '38px !important',
                                  fontSize: '32px !important',
                                  textTransform: 'initial !important',
                                  margin: '0px auto !important'
                                }}
                                className="messageError-title"
                              >
                                йой!
                              </StyledTitle>
                              <Typography
                                sx={{
                                  fontSize: '14px',
                                  margin: '5px auto 0px',
                                  width: '50%',
                                  textAlign: 'center'
                                }}
                              >
                                На жаль, профіль більше не існує
                              </Typography>
                            </>
                          )}
                        </Grid>
                        {message?.forwardedMessage?.abstractMessageWithDTO?.userDTO ? (
                          <Grid item xs={6}>
                            {message?.forwardedMessage?.abstractMessageWithDTO?.userDTO?.name &&
                              message?.forwardedMessage?.abstractMessageWithDTO?.userDTO
                                ?.surname && (
                                <Typography
                                  fontSize={14}
                                  lineHeight="normal"
                                  fontWeight={400}
                                  sx={{ mt: 1 }}
                                >
                                  {`${message?.forwardedMessage?.abstractMessageWithDTO?.userDTO?.name} ${message?.forwardedMessage?.abstractMessageWithDTO?.userDTO?.surname}`}
                                </Typography>
                              )}

                            <Typography
                              component="p"
                              fontSize={
                                message?.forwardedMessage?.abstractMessageWithDTO?.userDTO?.name &&
                                message?.forwardedMessage?.abstractMessageWithDTO?.userDTO?.surname
                                  ? 12
                                  : 14
                              }
                              lineHeight="normal"
                              fontWeight={400}
                              sx={{ wordBreak: 'break-all', maxWidth: '90%', mt: 1 }}
                            >
                              {`@${message?.forwardedMessage?.abstractMessageWithDTO?.userDTO?.nickname}`}
                            </Typography>

                            {((message?.forwardedMessage?.abstractMessageWithDTO?.userDTO?.city &&
                              message?.forwardedMessage?.abstractMessageWithDTO?.userDTO?.city
                                .length !== 0) ||
                              (message?.forwardedMessage?.abstractMessageWithDTO?.userDTO
                                ?.country &&
                                message?.forwardedMessage?.abstractMessageWithDTO?.userDTO?.country
                                  .length !== 0) ||
                              (message?.forwardedMessage?.abstractMessageWithDTO?.userDTO?.region &&
                                message?.forwardedMessage?.abstractMessageWithDTO?.userDTO?.region
                                  .length !== 0)) && (
                              <Typography
                                sx={{ alignItems: 'center', mt: '2px' }}
                                fontSize={12}
                                lineHeight="normal"
                                fontWeight={400}
                              >
                                <PushPinOutlinedIcon
                                  style={{
                                    color: '#60A5DF',
                                    fontSize: '14px',
                                    transform: 'rotate(-45deg)'
                                  }}
                                />
                                {`${
                                  message?.forwardedMessage &&
                                  message?.forwardedMessage?.abstractMessageWithDTO?.userDTO?.city
                                    ? message?.forwardedMessage &&
                                      message?.forwardedMessage?.abstractMessageWithDTO?.userDTO
                                        ?.city
                                    : ''
                                }${
                                  message?.forwardedMessage &&
                                  message?.forwardedMessage?.abstractMessageWithDTO?.userDTO
                                    ?.city &&
                                  message?.forwardedMessage?.abstractMessageWithDTO?.userDTO?.city
                                    .length !== 0 &&
                                  message?.forwardedMessage?.abstractMessageWithDTO?.userDTO
                                    ?.region &&
                                  message?.forwardedMessage?.abstractMessageWithDTO?.userDTO?.region
                                    .length !== 0
                                    ? ', '
                                    : ''
                                }${userData?.region ? `${userData.region} обл.` : ''}${
                                  message?.forwardedMessage &&
                                  message?.forwardedMessage?.abstractMessageWithDTO?.userDTO
                                    ?.city &&
                                  message?.forwardedMessage?.abstractMessageWithDTO?.userDTO?.city
                                    .length !== 0 &&
                                  message?.forwardedMessage?.abstractMessageWithDTO?.userDTO
                                    ?.country &&
                                  message?.forwardedMessage?.abstractMessageWithDTO?.userDTO
                                    ?.country.length !== 0
                                    ? ', '
                                    : ''
                                }${
                                  message?.forwardedMessage &&
                                  message?.forwardedMessage?.abstractMessageWithDTO?.userDTO
                                    ?.country
                                    ? message?.forwardedMessage?.abstractMessageWithDTO?.userDTO
                                        ?.country
                                    : ''
                                }`}
                              </Typography>
                            )}
                          </Grid>
                        ) : null}
                        {message?.forwardedMessage?.abstractMessageWithDTO?.userDTO ? (
                          <Grid item xs={12}>
                            <LetsGoBtn
                              className="service-card-btn"
                              width="100%"
                              margin="10px auto"
                              text="Детальніше"
                              onClick={() => {
                                navigate(
                                  `/${language}/user-page/${message?.forwardedMessage?.abstractMessageWithDTO?.userDTO?.id}?type=posts`
                                );
                                // if (userData?.id) {
                                //   navigate(`/${language}/service/${el.id}`);
                                //   window.scrollTo({ top: 0 });
                                // } else {
                                //   dispatch(setIsOpenAuthModal(true));
                                // }
                              }}
                            />
                          </Grid>
                        ) : null}
                      </Grid>
                    </Paper>
                  )}
                  {message?.forwardedMessage?.abstractMessageWithDTO?.type === 'service' && (
                    <Paper sx={{ width: '279px', border: '1px solid #60A5DF', p: '12px', mt: 1 }}>
                      <Grid container>
                        <Grid
                          item
                          xs={12}
                          sx={
                            !message?.forwardedMessage?.abstractMessageWithDTO?.servicePostDTO
                              ? {
                                  height: 350,
                                  display: 'flex',
                                  flexDirection: 'column',
                                  justifyContent: 'center'
                                }
                              : {}
                          }
                        >
                          {message?.forwardedMessage?.abstractMessageWithDTO?.servicePostDTO ? (
                            <Avatar
                              // src={userData?.avatar ? userData.avatar : DefaultAvatar}
                              src={
                                message?.forwardedMessage?.abstractMessageWithDTO?.servicePostDTO
                                  ?.encodedPhotos.length !== 0
                                  ? URL.createObjectURL(
                                      getFileFromBase64(
                                        message?.forwardedMessage?.abstractMessageWithDTO
                                          ?.servicePostDTO?.encodedPhotos[0].encodedContent,
                                        message?.forwardedMessage?.abstractMessageWithDTO
                                          ?.servicePostDTO?.encodedPhotos[0].nameFile
                                      )
                                    )
                                  : DefaultAvatar
                              }
                              sx={{
                                width: '100%',
                                height: 255,
                                border: '1px solid #60A5DF',
                                borderRadius: '5px'
                              }}
                              variant="square"
                            />
                          ) : (
                            <>
                              <StyledTitle
                                // maxWidth={800}
                                sx={{
                                  lineHeight: '38px !important',
                                  fontSize: '32px !important',
                                  textTransform: 'initial !important',
                                  margin: '0px auto !important'
                                }}
                                className="messageError-title"
                              >
                                йой!
                              </StyledTitle>
                              <Typography
                                sx={{
                                  fontSize: '14px',
                                  margin: '5px auto 0px',
                                  width: '50%',
                                  textAlign: 'center'
                                }}
                              >
                                На жаль, послуги більше не існує
                              </Typography>
                            </>
                          )}
                        </Grid>
                        {message?.forwardedMessage?.abstractMessageWithDTO?.servicePostDTO ? (
                          <Grid item xs={12}>
                            <Typography
                              variant="h5"
                              style={{
                                fontSize: '16px',
                                margin: '0px 10px 10px',
                                wordBreak: 'break-word'
                              }}
                              sx={{ mt: 2, textAlign: 'center' }}
                            >
                              {`${message?.forwardedMessage?.abstractMessageWithDTO?.servicePostDTO?.nameService}`}
                            </Typography>
                            <Typography
                              style={{
                                fontSize: '16px',
                                margin: '0px 0px 5px 0px',
                                fontFamily: 'Kyiv-Sans-Regular',
                                textAlign: 'center'
                              }}
                            >
                              {`${message?.forwardedMessage?.abstractMessageWithDTO?.servicePostDTO?.price} ${message?.forwardedMessage?.abstractMessageWithDTO?.servicePostDTO?.currency}`}
                            </Typography>
                          </Grid>
                        ) : null}
                        {message?.forwardedMessage?.abstractMessageWithDTO?.servicePostDTO ? (
                          <Grid item xs={12}>
                            <LetsGoBtn
                              className="service-card-btn"
                              width="100%"
                              margin="10px auto"
                              text="Детальніше"
                              onClick={() => {
                                navigate(
                                  `/${language}/service/${message?.forwardedMessage?.abstractMessageWithDTO?.servicePostDTO?.id}`
                                );
                                // if (userData?.id) {
                                //   navigate(`/${language}/service/${el.id}`);
                                //   window.scrollTo({ top: 0 });
                                // } else {
                                //   dispatch(setIsOpenAuthModal(true));
                                // }
                              }}
                            />
                          </Grid>
                        ) : null}
                      </Grid>
                    </Paper>
                  )}
                  {message?.forwardedMessage?.abstractMessageWithDTO?.type === 'post' && (
                    <Paper sx={{ width: '279px', border: '1px solid #60A5DF', p: '12px', mt: 1 }}>
                      <Grid container>
                        <Grid item xs={12}>
                          <Typography
                            style={{
                              fontSize: '12px',
                              margin: '0px 0px 5px 0px',
                              textAlign: 'left',
                              wordBreak: 'break-word',
                              display: '-webkit-box',
                              WebkitLineClamp: 3,
                              WebkitBoxOrient: 'vertical',
                              overflowY: 'hidden',
                              textOverflow: 'ellipsis'
                            }}
                            children={
                              <>
                                {message?.forwardedMessage?.abstractMessageWithDTO?.postDTO?.description
                                  .split('\r\n')
                                  .filter((el) => el.length !== 0)
                                  .map((string, index) => {
                                    return (
                                      <React.Fragment key={string + index}>
                                        <span style={{ margin: '0' }}>
                                          {string}

                                          <br />
                                        </span>
                                      </React.Fragment>
                                    );
                                  })}
                              </>
                            }
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sx={
                            !message?.forwardedMessage?.abstractMessageWithDTO?.postDTO
                              ? {
                                  height: 350,
                                  display: 'flex',
                                  flexDirection: 'column',
                                  justifyContent: 'center'
                                }
                              : {}
                          }
                        >
                          {message?.forwardedMessage?.abstractMessageWithDTO?.postDTO ? (
                            <Avatar
                              // src={userData?.avatar ? userData.avatar : DefaultAvatar}
                              src={
                                message?.forwardedMessage?.abstractMessageWithDTO?.postDTO
                                  ?.encodedPhotos.length !== 0
                                  ? URL.createObjectURL(
                                      getFileFromBase64(
                                        message?.forwardedMessage?.abstractMessageWithDTO?.postDTO
                                          .encodedPhotos[0].encodedContent,
                                        message?.forwardedMessage?.abstractMessageWithDTO?.postDTO
                                          .encodedPhotos[0].nameFile
                                      )
                                    )
                                  : DefaultAvatar
                              }
                              sx={{
                                width: '100%',
                                height: 255,
                                border: '1px solid #60A5DF',
                                borderRadius: '5px',
                                mb: 1
                              }}
                              variant="square"
                            />
                          ) : (
                            <>
                              <StyledTitle
                                // maxWidth={800}
                                sx={{
                                  lineHeight: '38px !important',
                                  fontSize: '32px !important',
                                  textTransform: 'initial !important',
                                  margin: '0px auto !important'
                                }}
                                className="messageError-title"
                              >
                                йой!
                              </StyledTitle>
                              <Typography
                                sx={{
                                  fontSize: '14px',
                                  margin: '5px auto 0px',
                                  width: '50%',
                                  textAlign: 'center'
                                }}
                              >
                                На жаль, посту більше не існує
                              </Typography>
                            </>
                          )}
                        </Grid>
                        {message?.forwardedMessage?.abstractMessageWithDTO?.postDTO ? (
                          <Grid item xs={12}>
                            <LetsGoBtn
                              className="service-card-btn"
                              width="100%"
                              margin="10px auto"
                              text="Детальніше"
                              onClick={() => {
                                navigate(
                                  `/${language}/post/${message?.forwardedMessage?.abstractMessageWithDTO?.postDTO?.id}`
                                );
                                // if (userData?.id) {
                                //   navigate(`/${language}/service/${el.id}`);
                                //   window.scrollTo({ top: 0 });
                                // } else {
                                //   dispatch(setIsOpenAuthModal(true));
                                // }
                              }}
                            />
                          </Grid>
                        ) : null}
                      </Grid>
                    </Paper>
                  )}
                </>
              ) : null}
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  width: '100%'

                  // flexDirection:itemData.length!==0?'column':'row'
                }}
              >
                {' '}
                <Grid container>
                  {message.forwardedMessage && (
                    <Grid container sx={{ width: '100%' }} spacing={1}>
                      {!message?.forwardedMessage?.abstractMessageWithDTO?.photos &&
                        message?.forwardedMessage?.abstractMessageWithDTO?.type !== 'user' &&
                        message?.forwardedMessage?.abstractMessageWithDTO?.type !== 'service' &&
                        message?.forwardedMessage?.abstractMessageWithDTO?.type !== 'post' && (
                          <>
                            {' '}
                            <Grid item sx={{ display: 'flex' }}>
                              {message.forwardedMessage.abstractMessageWithDTO.chatId !==
                              message.chatId ? (
                                <SendOutlinedIcon
                                  sx={{ color: 'rgba(33, 107, 170, 1)', fontSize: '24px' }}
                                />
                              ) : (
                                <ReplyIcon
                                  sx={{ color: 'rgba(33, 107, 170, 1)', fontSize: '24px' }}
                                />
                              )}
                            </Grid>
                            <Grid item xs={10} sx={{ display: 'flex' }}>
                              <Typography
                                onClick={() =>
                                  navigate(
                                    `/${language}/user-page/${message.forwardedMessage.userId}?type=posts`
                                  )
                                }
                                variant="body2"
                                sx={{
                                  fontSize: '14px',
                                  fontWeight: 'bold !important',
                                  cursor: 'pointer'
                                }}
                              >{`@${message.forwardedMessage.nickname}`}</Typography>
                              <Grid item sx={{ width: '39px' }} />
                            </Grid>
                          </>
                        )}
                      {message.forwardedMessage.abstractMessageWithDTO.text.length !== 0 ? (
                        <Grid
                          item
                          xs={10}
                          sx={{
                            wordBreak: 'break-word',
                            fontSize: '12px',
                            borderBottom: '1px solid #60A5DF',
                            // message.forwardedMessage.abstractMessageWithDTO.text.length !== 0
                            //   ? '1px solid #60A5DF'
                            //   : 'none',
                            pb: 1,
                            mb: 1,
                            pl:
                              message?.forwardedMessage?.abstractMessageWithDTO?.type === 'user' ||
                              message?.forwardedMessage?.abstractMessageWithDTO?.type ===
                                'service' ||
                              message?.forwardedMessage?.abstractMessageWithDTO?.type === 'post'
                                ? '1px !important'
                                : '0px !important',
                            ml:
                              message?.forwardedMessage?.abstractMessageWithDTO?.type === 'user' ||
                              message?.forwardedMessage?.abstractMessageWithDTO?.type ===
                                'service' ||
                              message?.forwardedMessage?.abstractMessageWithDTO?.type === 'post' ||
                              message?.forwardedMessage?.abstractMessageWithDTO?.photos
                                ? 1
                                : 5,
                            mt:
                              message?.forwardedMessage?.abstractMessageWithDTO?.type === 'user' ||
                              message?.forwardedMessage?.abstractMessageWithDTO?.type ===
                                'service' ||
                              message?.forwardedMessage?.abstractMessageWithDTO?.type === 'post'
                                ? 1
                                : 0
                          }}
                        >
                          {message.forwardedMessage.abstractMessageWithDTO.text}
                        </Grid>
                      ) : null}
                    </Grid>
                  )}
                  <Grid
                    item
                    sx={
                      !message.forwardedMessage ||
                      (message?.forwardedMessage &&
                        message?.forwardedMessage?.abstractMessageWithDTO?.type === 'user') ||
                      message?.forwardedMessage?.abstractMessageWithDTO?.type === 'service' ||
                      message?.forwardedMessage?.abstractMessageWithDTO?.type === 'post' ||
                      message?.forwardedMessage?.abstractMessageWithDTO?.photos
                        ? { display: 'none' }
                        : { width: '30px' }
                    }
                  />
                  <Grid item xs={message.forwardedMessage ? 10 : 12}>
                    <Typography variant="body2" sx={{ fontSize: '14px', wordBreak: 'break-word' }}>
                      {message.text}
                    </Typography>
                  </Grid>
                  <Typography
                    variant="body2"
                    sx={{
                      fontSize: '12px',
                      fontFamily: 'Kyiv-Sans-Light',
                      color: 'rgba(33, 107, 170, 1)',
                      marginLeft: 'auto',
                      marginTop: '0'
                    }}
                  >
                    {timeFormattingByDate(new Date(message.sendingTime))}
                  </Typography>
                </Grid>
                {isShowDots && (
                  <>
                    <MoreVertIcon
                      onClick={(event) => handleClick(event)}
                      sx={{
                        ml: 1,
                        cursor: 'pointer',
                        ':hover': { color: '#60A5DF' },
                        zIndex: '99',
                        fontSize: '20px'
                      }}
                    />
                    <Menu
                      anchorEl={anchorEl}
                      id="action-menu"
                      open={open}
                      onClose={handleClose}
                      onMouseLeave={handleClose}
                      PaperProps={{
                        elevation: 0,
                        sx: {
                          overflow: 'hidden',
                          boxShadow: 'none',
                          borderRadius: '5px',
                          border: '1px solid black',
                          paddingTop: '10px',
                          paddingLeft: '20px',
                          paddingRight: '20px',
                          width: '200px',
                          mt: 1
                        }
                      }}
                      autoFocus={false}
                      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                    >
                      <MenuItem
                        onClick={(event) => {
                          event.stopPropagation();
                          event.preventDefault();
                          setIsEditMessage(true);
                          setIsReplyMessage(true);
                          setIsResendMessage(false);
                          handleClose();
                        }}
                        sx={{ padding: 0, mb: 1 }}
                      >
                        <ReplyIcon sx={{ color: '#60A5DF', mr: 1 }} />
                        <Typography component="p" fontSize={16} lineHeight="18px" fontWeight={400}>
                          Відповісти
                        </Typography>
                      </MenuItem>
                      <MenuItem
                        onClick={(event) => {
                          event.stopPropagation();
                          event.preventDefault();
                          dispatch(setIsOpenResendingModal({ isOpen: true, id: null, type: null }));
                          setIsEditMessage(false);
                          setIsResendMessage(true);
                          setIsReplyMessage(false);
                          handleClose();
                        }}
                        sx={{ padding: 0, mb: 1 }}
                      >
                        <SendOutlinedIcon sx={{ color: '#60A5DF', mr: 1 }} />
                        <Typography component="p" fontSize={16} lineHeight="18px" fontWeight={400}>
                          Відправити
                        </Typography>
                      </MenuItem>
                      {/* <MenuItem sx={{ padding: 0, mb: 1 }}>
                        <ErrorOutlineIcon sx={{ color: '#60A5DF', mr: 1 }} />
                        <Typography component="p" fontSize={16} lineHeight="18px" fontWeight={400}>
                          Поскаржитись
                        </Typography>
                      </MenuItem> */}
                      {/* <MenuItem
                    onClick={(event) => {
                      event.stopPropagation();
                      event.preventDefault();
                      dispatch(setIsOpenDeleteModal(true));
                    }}
                    sx={{ padding: 0, mb: 1 }}
                  >
                    <DeleteOutlineOutlinedIcon sx={{ color: '#60A5DF', mr: 1 }} />
                    <Typography component="p" fontSize={16} lineHeight="18px" fontWeight={400}>
                      Видалити
                    </Typography>
                  </MenuItem> */}
                    </Menu>
                  </>
                )}
              </Box>
            </Box>
          </Grid>
          <Grid item xs={6} />
        </Grid>
      )}
    </React.Fragment>
  );
}
export default memo(ChatMessage);
