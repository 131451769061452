import React from 'react';
import { useNavigate } from 'react-router-dom';

//Material
import { Paper, Typography } from '@mui/material';

import { Stack } from '@mui/system';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import PushPinOutlinedIcon from '@mui/icons-material/PushPinOutlined';
import { ContactsBtn } from './Buttons';
import { connect, useDispatch } from 'react-redux';
import { setIsOpenAuthModal } from '../redux/mainReducer';
import DefaultAvatar from '../assets/defaultPostImg.jpg';
import { base } from '../redux/thunk';
import ApiService from '../helpers/apiHelper';

function UserCard({
  index,
  el,
  noWidth = false,
  getUserSubscriptionsThunk,
  userData,
  language,
  subscriptions,
  blockedUsers,
  usersWhoBlocked
}) {
  const apiSubscribe = new ApiService('auth/subscribe');
  const apiUnSubscribe = new ApiService('auth/unsubscribe');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  return (
    <Paper
      key={index}
      className="userCard"
      style={{
        width: !noWidth ? '400px' : 'unset',
        height: '250px',
        margin: '0px 0.5%',
        textAlign: 'center',
        backgroundColor: 'white',
        borderRadius: '5px',
        padding: '15px',
        border: !blockedUsers.find((user) => user.id === el?.id) ? '1px solid black' : 'none',
        boxShadow: blockedUsers.find((user) => user.id === el?.id)
          ? '0px 0px 2px 2px rgba(255,0,0,1)'
          : 'none'
      }}
    >
      <Stack
        spacing={2}
        direction="column"
        justifyContent="space-between"
        style={{ padding: '0', height: '100%' }}
      >
        <Stack direction="row" spacing={2} style={{ alignItems: 'start' }}>
          <img
            src={
              el?.encodedMediaDTO
                ? `data:${el?.encodedMediaDTO.contentType};base64, ${el?.encodedMediaDTO.encodedContent}`
                : DefaultAvatar
            }
            alt={el.nikname}
            style={{ width: '150px', height: '150px' }}
          />
          <Stack>
            {el?.name&&el?.surname&&<Typography style={{ fontSize: '18px', textAlign: 'left' }}>
              {el.name + ' ' + el.surname}
            </Typography>}
            
            <Typography
              style={{ fontSize: el?.name&&el?.surname?'14px':'18px', textAlign: 'left' }}
              sx={{ wordBreak: 'break-all', maxWidth: '100%' }}
            >
              {`@${el.nickname}`}
            </Typography>

            {el?.number && el?.number.length !== 0 && (
              <div style={{ display: 'flex' }}>
                <Typography
                  sx={{ paddingLeft: '25px', mt: '14px', position: 'relative' }}
                  fontSize={16}
                  lineHeight="20px"
                  fontWeight={400}
                >
                  <PhoneAndroidIcon
                    style={{
                      position: 'absolute',
                      left: '-1px',
                      color: '#60A5DF',
                      fontSize: '22px'
                    }}
                  />
                  {`${el.number}`}
                </Typography>{' '}
              </div>
            )}

            {((el?.city && el?.city.length !== 0) &&
              (el?.country && el?.country.length !== 0) &&
              (el?.region && el?.region.length !== 0)) && (
              <div style={{ display: 'flex' }}>
                <Typography
                  sx={{ paddingLeft: '25px', mt: '14px', position: 'relative', textAlign: 'left' }}
                  fontSize={16}
                  lineHeight="20px"
                  fontWeight={400}
                >
                  <PushPinOutlinedIcon
                    style={{
                      position: 'absolute',
                      left: '-1px',
                      color: '#60A5DF',
                      fontSize: '22px',
                      transform: 'rotate(-45deg)'
                    }}
                  />
                  {`${el?.city ? el.city : ''}${
                    el?.city && el?.city.length !== 0 && el?.region && el?.region.length !== 0
                      ? ', '
                      : ''
                  }${el?.region ? `${el.region} обл.` : ''}${
                    el?.city && el?.city.length !== 0 && el?.country && el?.country.length !== 0
                      ? ', '
                      : ''
                  }${el?.country ? el.country : ''}`}
                </Typography>
              </div>
            )}
          </Stack>
        </Stack>
        <Stack
          direction="row"
          justifyContent={userData.id !== el.id&&!blockedUsers.find((user) => user.id === el?.id)&&!usersWhoBlocked.find(user=>user===el?.id) ? 'space-between' : 'end'}
          alignItems="center"
        >
          {userData.id !== el.id&&!blockedUsers.find((user) => user.id === el?.id)&&!usersWhoBlocked.find(user=>user===el?.id) && (
            <ContactsBtn
              width="max-content"
              isInContacts={subscriptions.find((subscription) => subscription.id === el.id)}
              onClick={() => {
                if (userData?.id) {
                  if (subscriptions.find((subscription) => subscription.id === el.id)) {
                    apiUnSubscribe.deleteItemQuery({ subscriptionId: el.id }).then((res) => {
                      getUserSubscriptionsThunk(userData.id);
                    });
                  } else {
                    apiSubscribe.updateDataQuery({ subscriptionId: el.id }).then((res) => {
                      getUserSubscriptionsThunk(userData.id);
                    });
                  }
                } else {
                  dispatch(setIsOpenAuthModal(true));
                }
              }}
            />
          )}

          {/* eslint-disable-next-line */}
          <p
            href="#"
            onClick={(event) => {
              event.stopPropagation();
              if (userData?.id) {
                navigate(`/${language}/user-page/${el.id}?type=posts`);
              } else {
                dispatch(setIsOpenAuthModal(true));
              }
            }}
            style={{
              fontSize: '14px',
              textAlign: 'left',
              textDecoration: 'underline',
              color: 'black',
              cursor: 'pointer'
            }}
          >
            Переглянути профіль
          </p>
        </Stack>
      </Stack>
    </Paper>
  );
}
const mapStateToProps = (state) => {
  return {
    language: state.Intl.locale,
    userData: state.global.userData,
    subscriptions: state.global.userSubscriptions,
    blockedUsers: state.global.blockedUsers,
    usersWhoBlocked: state.global.usersWhoBlocked,
  };
};
export default connect(mapStateToProps, {
  getUserSubscriptionsThunk: base.getUserSubscriptionsThunk
})(UserCard);
