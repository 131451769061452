import React, { useEffect, useState } from 'react';

//Material
import CloseIcon from '@mui/icons-material/Close';
import { Autocomplete, Box, Grid, Link, Modal, TextField, Typography } from '@mui/material';

import styled from '@emotion/styled';
import { Form, FormikProvider, useFormik } from 'formik';
import { useTranslate } from 'react-redux-multilingual';
import * as Yup from 'yup';
import './UserEditModal.css';
// import { useSnackbar } from 'notistack';
import { StyledLabel } from '../../components/StyledComponents';
// import ApiService from '../../helpers/apiHelper';
import { connect, useDispatch } from 'react-redux';
import { LetsGoBtn3 } from '../../components/Buttons';
import { setIsOpenUserEditModal, setUserData } from '../../redux/mainReducer';
// import { useSnackbar } from 'notistack';
// import {  encode as btoa, decode as atob } from 'base-64';
// import sha256 from 'crypto-js/sha256';

import starBlue from '../../assets/StarBlue.png';
import starGreen from '../../assets/StarGreen.png';
import ApiService from '../../helpers/apiHelper';
import { disableCyrillicRegExp, whiteSpaceRegExp } from '../../services/regularExp';
import InputMask from 'react-input-mask';
import { useSnackbar } from 'notistack';
import { UACities } from '../../services/UACIties';
import { useNavigate } from 'react-router-dom';
// import { UACities } from '../../services/UACIties';
// import { useNavigate } from 'react-router-dom';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  maxWidth: 900,
  bgcolor: 'background.paper',
  borderRadius: '20px',
  border: 'none',
  boxShadow: 24,
  padding: '50px 80px 50px 80px',
  overflow: 'scroll',
  height: 'inherit',
  maxHeight: '100vh',
  scrollbarWidth: 'none',
  '&::-webkit-scrollbar': {
    display: 'none'
  }
};

const CssTextField = styled(TextField)({
  '& label.Mui-focused': {
    color: '#000000'
  },
  '& label': {
    color: '#A1A1A1',
    fontFamily: 'Kyiv-Sans-Light'
  },

  margin: '5px 0px'
});

function UserEditModal({ isOpenModal, language, data }) {
  // const [imgs, setImgs] = useState([]);
  const [screenType, setScreenType] = useState('desktop');
  const [cities, setCities] = useState([]);
  const { enqueueSnackbar } = useSnackbar();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    if (window.screen.width <= 600) {
      setScreenType('mobile');
    }
  }, []);
  const translate = useTranslate();
  // const { enqueueSnackbar } = useSnackbar();

  const api = new ApiService('auth/user/update', translate);
  const schema = Yup.object().shape({
    nickname: Yup.string()
      .max(30, 'Максимально 30 символів')
      .min(2, 'Мінімально 2 символи')
      .required("Це поле обов'язкове")
      .matches(disableCyrillicRegExp, translate('error_regExp_nickname'))
      .test('Нікнейм не може включати пробіл', 'Нікнейм не може включати пробіл', (value) =>
        whiteSpaceRegExp.test(value)
      )
      .matches(whiteSpaceRegExp, translate('Нікнейм не може включати пробіл')),
    name: Yup.string()
      .max(100, 'Максимально 100 символів')
      .min(2, 'Мінімально 2 символи')
      .required("Це поле обов'язкове"),

    surname: Yup.string()
      .max(100, 'Максимально 100 символів')
      .min(2, 'Мінімально 2 символи')
      .required("Це поле обов'язкове"),
    city: Yup.string().max(50, 'Максимально 50 символів'),

    description: Yup.string().max(300, 'Максимально 300 символів')
    // number: Yup.string().min(13, 'Мінімально 13 символів' )
  });

  const formik = useFormik({
    initialValues: {
      name: data?.name ? data?.name : '',
      surname: data?.surname ? data?.surname : '',
      nickname: data?.nickname ? data?.nickname : '',
      country: data?.country ? data.country : 'Україна',
      region: data?.region ? UACities.find((region) => region.region === data.region) : '',
      city: data?.city ? data.city : '',
      number: data?.number ? data.number : '+380',
      description: data?.description ? data.description : ''
    },
    enableReinitialize: true,
    validationSchema: schema,

    onSubmit: (values, { resetForm, setErrors }) => {
      const newValues = { ...values };
      newValues.name = newValues.name[0].toUpperCase() + newValues.name.slice(1);
      newValues.surname = newValues.surname[0].toUpperCase() + newValues.surname.slice(1);
      newValues.region = newValues.region.region;

      api
        .updateData(newValues)
        .then(async (res) => {
          await dispatch(
            setUserData({
              ...newValues,
              email:data?.email,
              id: data.id,
              encodedMediaDTO: data?.encodedMediaDTO ? data?.encodedMediaDTO : null
            })
          );
          resetForm();
          dispatch(setIsOpenUserEditModal(false));
        })
        .catch((error) => enqueueSnackbar(error, { variant: 'error' }));
    }
  });

  const {
    errors,
    touched,
    values,
    handleSubmit,
    getFieldProps,
    submitForm,
    resetForm,
    setFieldValue
  } = formik;
  useEffect(() => {
    if (values.region !== '') setCities(values.region.cities);
  }, [values.region]);
  return (
    <div>
      <Modal
        open={isOpenModal}
        onClose={(event, reason) => {
          if (reason === 'backdropClick') {
            return;
          }
          dispatch(setIsOpenUserEditModal(false));

          resetForm();
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="bonfair_userEdit_modal"
      >
        <Box sx={style} className="bonfair_userEdit_modal-box">
          <img
            style={{ position: 'fixed', zIndex: '0', top: '0', left: '0' }}
            src={starGreen}
            alt="star-green"
          />
          <img
            style={{ position: 'fixed', zIndex: '0', bottom: '40px', right: '0' }}
            src={starBlue}
            alt="star-green"
          />
          <CloseIcon
            className="bonfair_userEdit_modal-close"
            style={{ position: 'absolute', right: '10px', top: '10px', cursor: 'pointer' }}
            onClick={() => {
              dispatch(setIsOpenUserEditModal(false));

              resetForm();
            }}
          />

          <FormikProvider value={formik}>
            <Form
              autoComplete="off"
              noValidate
              onSubmit={handleSubmit}
              style={{ display: 'flex', flexDirection: 'column', marginTop: '10px' }}
            >
              <Grid container spacing={screenType !== 'desktop' ? 1 : 2}>
                <Grid item xs={12} md={12} lg={12}>
                  <StyledLabel className="userEditModal-title-text">
                    {/* {translate('bonfair_service_modal')} */}
                    Редагування профілю
                  </StyledLabel>
                </Grid>
                <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'column' }}>
                  <CssTextField
                    label="Ім'я*"
                    variant="standard"
                    fullWidth
                    size="normal"
                    inputProps={{ style: { textAlign: 'left', textTransform: 'capitalize' } }}
                    error={Boolean(touched.name && errors.name)}
                    {...getFieldProps('name')}
                    value={values.name}
                  >
                    {values.name}
                  </CssTextField>
                  {touched.name && errors.name && (
                    <div
                      style={{
                        color: 'red',
                        margin: '5px 0px',
                        fontSize: '14px',
                        fontFamily: 'Kyiv-Sans-Regular'
                      }}
                    >
                      {errors.name}
                    </div>
                  )}
                </Grid>
                <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'column' }}>
                  <CssTextField
                    label="Прізвище*"
                    variant="standard"
                    fullWidth
                    size="normal"
                    inputProps={{ style: { textAlign: 'left', textTransform: 'capitalize' } }}
                    error={Boolean(touched.surname && errors.surname)}
                    {...getFieldProps('surname')}
                    value={values.surname}
                  >
                    {values.surname}
                  </CssTextField>
                  {touched.surname && errors.surname && (
                    <div
                      style={{
                        color: 'red',
                        margin: '5px 0px',
                        fontSize: '14px',
                        fontFamily: 'Kyiv-Sans-Regular'
                      }}
                    >
                      {errors.surname}
                    </div>
                  )}
                </Grid>
                <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'column' }}>
                  <CssTextField
                    label="Нікнейм*"
                    variant="standard"
                    fullWidth
                    size="normal"
                    inputProps={{ style: { textAlign: 'left' } }}
                    error={Boolean(touched.nickname && errors.nickname)}
                    {...getFieldProps('nickname')}
                    value={values.nickname}
                  >
                    {values.nickname}
                  </CssTextField>
                  {touched.nickname && errors.nickname && (
                    <div
                      style={{
                        color: 'red',
                        margin: '5px 0px',
                        fontSize: '14px',
                        fontFamily: 'Kyiv-Sans-Regular'
                      }}
                    >
                      {errors.nickname}
                    </div>
                  )}
                </Grid>

                <Grid item xs={12} md={12} lg={12}>
                  <Typography
                    variant="subtitle2"
                    sx={{ fontSize: 16, fontFamily: 'Kyiv-Sans-Bold' }}
                  >
                    Контактна інформація
                  </Typography>
                </Grid>
                <Grid item xs={6} md={6} lg={6}>
                  <Autocomplete
                    disableClearable
                    disabled
                    options={['Україна']}
                    defaultValue={'Україна'}
                    sx={{ maxWidth: 300 }}
                    value={values.country}
                    onChange={(event, value) =>
                      setFieldValue('country', value !== null ? value : '')
                    }
                    renderInput={(params) => (
                      <TextField {...params} label="Країна" variant="standard" />
                    )}
                  />
                </Grid>
                <Grid item xs={6} md={6} lg={6}>
                  <Autocomplete
                    disableClearable
                    options={UACities}
                    // defaultValue={'Україна'}
                    sx={{ maxWidth: 300 }}
                    value={values.region}
                    getOptionLabel={(option) => option?.region?option?.region:''}
                    onChange={(event, value) => {
                      setCities(value.cities);
                      setFieldValue('region', value);
                      setFieldValue('city', '');
                    }}
                    // inputValue={values.region?.region ? values.region?.region : ''}
                    renderInput={(params) => (
                      <TextField {...params} label="Область" variant="standard" />
                    )}
                  />
                </Grid>
                <Grid item xs={6} md={6} lg={6}>
                  <Autocomplete
                    disableClearable
                    disabled={cities.length === 0}
                    options={cities}
                    // defaultValue={'Україна'}
                    sx={{ maxWidth: 300 }}
                    value={values.city}
                    onChange={(event, value) => setFieldValue('city', value)}
                    renderInput={(params) => (
                      <TextField {...params} label="Населений пункт" variant="standard" />
                    )}
                  />
                </Grid>
                {/* <Grid item xs={6} md={6} lg={6} >
                  
                   
                      <CssTextField
                        label="Область"
                        variant="standard"                        
                        fullWidth
                        size="normal"
                        inputProps={{ style: { textAlign: 'left' } }}
                        error={Boolean(touched.region && errors.region)}
                        
                        
                      >
                        {values.region}
                      </CssTextField>
                   

                  {touched.region && errors.region && (
                    <div
                      style={{
                        color: 'red',
                        margin: '5px 0px',
                        fontSize: '14px',
                        fontFamily: 'Kyiv-Sans-Regular'
                      }}
                    >
                      {errors.region}
                    </div>
                  )}
                </Grid> */}
                {/* <Grid item xs={6} md={6} lg={6}>
                  
                  <CssTextField
                    label="Населений пункт"
                    variant="standard"
                    fullWidth
                    size="normal"
                    inputProps={{ style: { textAlign: 'left' } }}
                    error={Boolean(touched.city && errors.city)}
                    {...getFieldProps('city')}
                    value={values.city}
                  >
                    {values.city}
                  </CssTextField>
                </Grid> */}

                <Grid item xs={5} sx={{ display: 'flex', flexDirection: 'column' }}>
                  <InputMask
                    alwaysShowMask
                    maskChar=""
                    mask="+380999999999"
                    value={values.number}
                    {...getFieldProps('number')}
                  >
                    {(inputProps) => (
                      <CssTextField
                        {...inputProps}
                        label="Телефон"
                        variant="standard"
                        fullWidth
                        size="normal"
                        inputProps={{ style: { textAlign: 'left' } }}
                        error={Boolean(touched.number && errors.number)}
                      >
                        {values.number}
                      </CssTextField>
                    )}
                  </InputMask>

                  {touched.number && errors.number && (
                    <div
                      style={{
                        color: 'red',
                        margin: '5px 0px',
                        fontSize: '14px',
                        fontFamily: 'Kyiv-Sans-Regular'
                      }}
                    >
                      {errors.number}
                    </div>
                  )}
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <Link
                    onClick={(event) => {
                      event.preventDefault();
                      event.stopPropagation();
                      dispatch(setIsOpenUserEditModal(false));
                      navigate(`/${language}/account-settings`);
                    }}
                    variant="body2"
                    color="inherit"
                    sx={{ fontSize: 16, cursor: 'pointer' }}
                  >
                    До налаштувань приватності
                  </Link>
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <Typography
                    variant="subtitle2"
                    sx={{ fontSize: 16, fontFamily: 'Kyiv-Sans-Bold' }}
                  >
                    Загальний опис
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <CssTextField
                    placeholder="Тут можна описати красним слівцем всі свої переваги. В розумних рамках, звичайно, до 300 символів. "
                    multiline
                    rows={8}
                    fullWidth
                    inputProps={{ style: { textAlign: 'left' } }}
                    error={Boolean(touched.description && errors.description)}
                    {...getFieldProps('description')}
                    value={values.description}
                  >
                    {values.description}
                  </CssTextField>
                  {values.description.length !== 0 && (
                    <p
                      style={{
                        margin: '0 5px',
                        color: 'gray',
                        textAlign: 'right',
                        fontSize: '14px'
                      }}
                    >{`${values.description.length}/300`}</p>
                  )}
                  {touched.description && errors.description && (
                    <div
                      style={{
                        color: 'red',
                        margin: '5px 0px',
                        fontSize: '14px',
                        fontFamily: 'Kyiv-Sans-Regular'
                      }}
                    >
                      {errors.description}
                    </div>
                  )}
                </Grid>

                <Grid
                  item
                  xs={12}
                  style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}
                >
                  <LetsGoBtn3
                    disabled={
                      values?.name.length < 2 ||
                      values?.surname.length < 2 ||
                      values?.nickname.length < 2
                    }
                    size="large"
                    onClick={() => {
                      submitForm();
                    }}
                    width={257}
                    style={{ textTransform: 'inherit' }}
                    text="Зберегти зміни"
                  />
                </Grid>
              </Grid>
            </Form>
          </FormikProvider>
        </Box>
      </Modal>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    language: state.Intl.locale,
    isOpenSearchBar: state.global.isOpenSearchBar
  };
};
export default connect(mapStateToProps)(UserEditModal);
