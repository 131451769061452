import { Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import png404 from '../../assets/404.png';
import { LetsGoBtn3 } from '../../components/Buttons';
import { StyledTitle } from '../../components/StyledComponents';

import '../MainPage/index.css';

function Page404() {
  const [randomPhrase, setRandomPhrase] = useState(0);
  const [randomDesc, setRandomDesc] = useState(0);
  const navigate = useNavigate();
  const phrases = [
    'Ой, Леле !',
    'Ой, Лишенько !',
    'А щоб його підняло та гепнуло !',
    'А щоб йому курка на ногу наступила !',
    'А щоб його грім побив !'
  ];
  const descriptions = [
    'Схоже, тут  дірка від бублика. Ми не ловимо гав і уже працюємо над проблемою. ',
    'Здається, щось пішло не так. Зараз ми візьмемо бика за роги і все виправимо найближчим часом.  ',
    'Схоже, тут  дуля з маком. Можливо ця сторінка була видалена або ніколи не існувала.'
  ];
  useEffect(() => {
    setRandomPhrase(Math.floor(Math.random() * phrases.length));
    setRandomDesc(Math.floor(Math.random() * descriptions.length));
    // eslint-disable-next-line
  }, []);
  return (
    <div
      style={{
        height: 'calc(94vh - 230px)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column'
      }}
    >
      <img alt="404" src={png404} style={{ width: '471px', height: '273px' }} />
      <StyledTitle className="bottomBlock-title">{phrases[randomPhrase]}</StyledTitle>
      <Typography
        style={{
          fontStyle: 'normal',
          fontWeight: '400',
          fontSize: '24px',
          lineHeight: '29px',
          textAlign: 'center',
          margin: '0px 0px 40px 0px',
          maxWidth: '700px'
        }}
      >
        {descriptions[randomDesc]}
      </Typography>
      <LetsGoBtn3
        width={257}
        onClick={() => navigate('/', { replace: true })}
        text="Накивати п’ятами"
      />
    </div>
  );
}

export default Page404;
