import AddIcon from '@mui/icons-material/Add';
import { Grid, TextField, Typography, styled } from '@mui/material';
import { Form, FormikProvider, useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useTranslate } from 'react-redux-multilingual';
import * as Yup from 'yup';
import { LetsGoBtn3 } from '../../components/Buttons';
import Page from '../../components/Page';
import { StyledTitle } from '../../components/StyledComponents';
import { emailRegExp } from '../../services/regularExp';
import '../MainPage/index.css';
import ApiService from '../../helpers/apiHelper';
import { setIsOpenSupportModal } from '../../redux/mainReducer';
import { useDispatch } from 'react-redux';
import { Spinner } from '../../components/Spinner';

const CssTextField = styled(TextField)({
  '& label.Mui-focused': {
    color: '#000000'
  },
  '& label': {
    color: '#A1A1A1',
    fontFamily: 'Kyiv-Sans-Light'
  },
  '& .MuiInput-root:not(.Mui-error):before': {
    borderBottomColor: '#000000'
  },

  margin: '5px 0px'
});

function SupportPage() {
  // const apiSendMessage = new ApiService('send-message');
  // const apiSendPhotoMessage = new ApiService('send-photo-message');
  const apiSendMessage = new ApiService('mail/send-message');
  const apiSendPhotoMessage = new ApiService('mail/send-photo-message');

  const [imgs, setImgs] = useState([]);
  const [imgsToDelete, setImgsToDelete] = useState([]);
  const [randomPlaceholder, setRandomPlaceholder] = useState(0);
  const [randomDesc, setRandomDesc] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const translate = useTranslate();
  const dispatch = useDispatch();
  const placeholders = [
    'Не стримуйтесь, розкажіть про свою халепу.*',
    'Опишіть свою халепу тут, бажано обсягом до 1000 символів.*'
  ];
  const descriptions = [
    'Підтримаємо, яка б біда з платформою вас не спіткала. Просимо, розкажіть про халепу.',
    'Одна голова добре, дві ліпше, але хай одна розважить і поважить. В чому халепа?',
    'Одна голова добре, дві ліпше. Розкажіть, в чому халепа?',
    'Разом, кажуть люди, навіть легше плакати. Тому давайте розбиратись. '
  ];

  const schema = Yup.object().shape(
    {
      name: Yup.string().required(translate('error_required')).max(100, 'Максимально 100 символів'),
      contact: Yup.string()
        .matches(emailRegExp, translate('error_regExp_email'))
        .required(translate('error_required')),
      subject: Yup.string()
        .required(translate('error_required'))
        .max(150, 'Максимально 150 символів'),
      text: Yup.string()
        .min(1, 'Мінімально 1 символ')
        .max(1000, 'Максимально 1000 символів')
        .required('Мінімально 1 символ')
    },
    [['text', 'text']]
  );

  const formik = useFormik({
    initialValues: {
      name: '',
      contact: '',
      subject: '',
      text: ''
    },
    enableReinitialize: true,
    validationSchema: schema,

    onSubmit: (values, { resetForm, setErrors }) => {
      setIsLoading(true);
      if (values) {
        if (imgs.length === 0) {
          apiSendMessage
            .sendRequest({
              name: values.name,
              subject: values.subject,
              contact: values.contact,
              text: values.text
            })
            .then((res) => {
              setIsLoading(false);
              dispatch(setIsOpenSupportModal(true));
              resetForm();
            })
            .catch((error) => {
              console.error(error);
              setIsLoading(false);
            });
        } else {
          apiSendPhotoMessage
            .sendRequest(
              {
                name: values.name,
                subject: values.subject,
                contact: values.contact,
                text: values.text,
                photos: imgs
              },
              true
            )
            .then((res) => {
              setIsLoading(false);
              dispatch(setIsOpenSupportModal(true));
              resetForm();
            })
            .catch((error) => {
              console.error(error);
              setIsLoading(false);
            });
        }
      }
    }
  });

  const {
    errors,
    touched,
    values,
    handleSubmit,
    getFieldProps,
    submitForm,
    // eslint-disable-next-line
    resetForm,
    // eslint-disable-next-line
    setErrors
    // setFieldValue
  } = formik;
  useEffect(() => {
    setRandomPlaceholder(Math.floor(Math.random() * placeholders.length));
    setRandomDesc(Math.floor(Math.random() * descriptions.length));
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    const fileInput = document.getElementById('button-file-post');
    if (fileInput) {
      fileInput.addEventListener('change', (event) => {
        if (imgs.length !== 10) {
          const newArray = [...imgs];
          newArray.push(event.target.files[0]);
          setImgs([...newArray]);
        }
      });
    }
  });

  return (
    <Page
      title="Підтримка"
      style={{
        display: 'flex',
        marginTop: '150px',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        height: 'max-content',
        padding: '0px 100px'
      }}
    >
      {/* <div
        style={{
          height: 'calc(94vh - 230px)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column'
        }}
      > */}
      <StyledTitle
        maxWidth={800}
        sx={{
          lineHeight: '38px !important',
          fontSize: '32px !important',
          textTransform: 'initial !important',
          margin: '0px auto !important'
        }}
        className="bottomBlock-title"
      >
        Підтримка
      </StyledTitle>
      <Typography
        sx={{
          fontStyle: 'normal',
          fontWeight: '400',
          fontSize: '16px',
          lineHeight: '29px',
          textAlign: 'center',
          margin: '30px 0px 40px 0px',
          maxWidth: '500px',
          fontFamily: 'Kyiv-Sans-Regular !important'
        }}
      >
        {descriptions[randomDesc]}
      </Typography>
      <FormikProvider value={formik}>
        <Form
          autoComplete="off"
          noValidate
          onSubmit={handleSubmit}
          style={{ display: 'flex', flexDirection: 'column', marginTop: '10px' }}
        ></Form>
        <Grid container spacing={2} sx={{ maxWidth: '816px', mb: 5 }}>
          <Grid item xs={12}>
            <Typography variant="subtitle2" sx={{ fontSize: 16, fontFamily: 'Kyiv-Sans-Bold' }}>
              Контактна інформація
            </Typography>
          </Grid>
          <Grid item xs={6} sx={{ display: 'flex', flexDirection: 'column' }}>
            <CssTextField
              label="Ім'я*"
              placeholder="Ім'я*"
              variant="standard"
              fullWidth
              size="normal"
              inputProps={{ style: { textAlign: 'left' } }}
              error={Boolean(touched.name && errors.name)}
              {...getFieldProps('name')}
              value={values.name}
            >
              {values.name}
            </CssTextField>
            {touched.name && errors.name && (
              <div
                style={{
                  color: 'red',
                  margin: '5px 0px',
                  fontSize: '14px',
                  fontFamily: 'Kyiv-Sans-Regular'
                }}
              >
                {errors.name}
              </div>
            )}
          </Grid>
          <Grid item xs={6} sx={{ display: 'flex', flexDirection: 'column' }}>
            <CssTextField
              label="E-mail*"
              placeholder="E-mail*"
              variant="standard"
              fullWidth
              size="normal"
              inputProps={{ style: { textAlign: 'left' } }}
              error={Boolean(touched.contact && errors.contact)}
              {...getFieldProps('contact')}
              value={values.contact}
            >
              {values.contact}
            </CssTextField>
            {touched.contact && errors.contact && (
              <div
                style={{
                  color: 'red',
                  margin: '5px 0px',
                  fontSize: '14px',
                  fontFamily: 'Kyiv-Sans-Regular'
                }}
              >
                {errors.contact}
              </div>
            )}
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle2" sx={{ fontSize: 16, fontFamily: 'Kyiv-Sans-Bold' }}>
              Ваше повідомлення
            </Typography>
          </Grid>
          <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'column' }}>
            <CssTextField
              label="Тема звернення*"
              placeholder="Тема звернення*"
              variant="standard"
              fullWidth
              size="normal"
              inputProps={{ style: { textAlign: 'left' } }}
              error={Boolean(touched.subject && errors.subject)}
              {...getFieldProps('subject')}
              value={values.subject}
            >
              {values.subject}
            </CssTextField>
            {touched.subject && errors.subject && (
              <div
                style={{
                  color: 'red',
                  margin: '5px 0px',
                  fontSize: '14px',
                  fontFamily: 'Kyiv-Sans-Regular'
                }}
              >
                {errors.subject}
              </div>
            )}
          </Grid>
          <Grid item xs={12}>
            <CssTextField
              placeholder={placeholders[randomPlaceholder]}
              multiline
              rows={8}
              fullWidth
              inputProps={{ style: { textAlign: 'left' } }}
              error={Boolean(touched.text && errors.text)}
              {...getFieldProps('text')}
              value={values.text}
            >
              {values.text}
            </CssTextField>
            {values.text.length !== 0 && (
              <p
                style={{
                  margin: '0 5px',
                  color: 'gray',
                  textAlign: 'right',
                  fontSize: '14px'
                }}
              >{`${values.text.length}/1000`}</p>
            )}

            {touched.text && errors.text && (
              <div
                style={{
                  color: 'red',
                  margin: '5px 0px',
                  fontSize: '14px',
                  fontFamily: 'Kyiv-Sans-Regular'
                }}
              >
                {errors.text}
              </div>
            )}
          </Grid>

          <Grid item xs={12} md={12} lg={12} sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography variant="subtitle" sx={{ fontSize: 16, color: '#B1B1B1', width: '100%' }}>
              Ви можете прикріпити зображення, які ілюструють суть проблеми.
            </Typography>
            <Typography variant="subtitle" sx={{ fontSize: 16, color: '#B1B1B1', width: '100%' }}>
              Можливо завантажити до 10 вкладень. Один елемент не має важити більше 10 МБ.
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            md={12}
            lg={12}
            sx={{ display: 'flex', alignItems: 'left', flexDirection: 'column' }}
          >
            <div
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                // justifyContent:'space-between',
                paddingBottom: '14px',
                marginBottom: '20px',
                marginLeft: 'auto',
                marginRight: 'auto',
                border: '1px dashed #60a5df',
                borderRadius: '5px'
              }}
            >
              {imgs.length !== 0 &&
                imgs.map((file, index) => (
                  <div
                    key={'image' + index}
                    className="uploaded-img"
                    style={{ marginLeft: '14px', marginTop: '14px' }}
                    onClick={() => {
                      const newArray = imgs;
                      const newImgsToDelete = imgsToDelete;

                      newArray.splice(index, 1);
                      setImgs([...newArray]);
                      setImgsToDelete([...newImgsToDelete]);
                    }}
                  >
                    <img
                      style={{ cursor: 'pointer' }}
                      alt={'img' + index}
                      width={60}
                      src={URL.createObjectURL(file)}
                    />
                  </div>
                ))}
              <input
                accept="image/*"
                style={{ display: 'none' }}
                id="button-file-post"
                disabled={imgs.length === 10 ? true : false}
                // onChange={(e) => {
                //   if (imgs.length !== 10) {
                //     setFile(e.target.files[0]);
                //     // dispatch(setIsOpenImageCropModal(true));
                //   } else e.target.value = '';
                // }}
                type="file"
              />
              {imgs.length !== 10 && (
                <label
                  htmlFor="button-file-post"
                  style={{
                    width: '100px',
                    height: '100px',
                    marginLeft: '14px',
                    marginTop: '14px',

                    cursor: 'pointer'
                  }}
                >
                  <div
                    component="span"
                    variant="outlined"
                    style={{
                      width: '100px',
                      height: '100px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      border: '1px solid #A1A1A1',
                      borderRadius: '5px',
                      cursor: 'pointer'
                    }}
                  >
                    <AddIcon
                      style={{ color: 'rgba(96, 165, 223, 1)' }}
                      sx={{
                        '&:hover': {
                          width: 100
                        }
                      }}
                    />
                  </div>
                </label>
              )}
            </div>
          </Grid>
        </Grid>{' '}
        {!isLoading ? (
          <LetsGoBtn3
            disabled={
              values.name.length === 0 ||
              errors.name ||
              values.contact.length === 0 ||
              errors.contact ||
              values.subject.length === 0 ||
              errors.subject ||
              values.text.length === 0 ||
              errors.text
            }
            width={257}
            onClick={() => {
              submitForm();
            }}
            text="Відправити"
          />
        ) : (
          <div style={{ marginTop: '10px' }}>
            <Spinner type="small" />
          </div>
        )}
      </FormikProvider>

      {/* </div> */}
    </Page>
  );
}

export default SupportPage;
