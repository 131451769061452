import React from 'react';

//Material
import { Grid, Paper, Typography } from '@mui/material';
// import { Stack } from '@mui/system';
import { connect } from 'react-redux';
import Illustration1 from '../../assets/Banners/BannerMain/Illustration1.png';
import Illustration2 from '../../assets/Banners/BannerMain/Illustration2.png';

import '../../pages/MainPage/index.css';
import { LetsGoBtn } from '../Buttons';
import { useNavigate } from 'react-router-dom';
// import  ImageGallery  from 'react-image-gallery';

// const useStyles = makeStyles({

//   button: {
//     '&:hover': {
//       boxShadow: '-5px 5px 3px black !important',
// width: '155px !important'
//   },
// }})
function BannerMain() {
  const navigate = useNavigate();
  return (
    <Paper
      // {...props}
      // key={}
      // className="serviceCard"
      sx={{
        width: '100%',
        // maxWidth: '809px',
        margin: '0px',
        textAlign: 'center',
        overflow: 'hidden',
        alignItems: 'center',
        height: '395px',
        borderRadius: '5px',
        padding: '20px ',
        border: '1px solid #60A5DF !important',
        backgroundColor: 'white',
        position: 'relative'
      }}
    >
      <Grid
        container
        spacing={0}
        sx={{
          width: '100%',
          margin: '0px !important',
          height: '100%'
        }}
      >
        <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <img width={260} src={Illustration1} alt="Illustration1" />
        </Grid>

        <Grid
          item
          xs={6}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          <Typography
            variant="p"
            sx={{
              margin: '0px',
              wordBreak: 'break-word',
              fontFamily: 'Kyiv-Sans-Medium !important',
              fontSize: '38px',
              textAlign: 'center',              
              maxWidth: 500
            }}
          >
            Покращуй досвід на платформі з
            <Typography
              variant="p"
              sx={{ fontFamily: 'Kyiv-Sans-Medium !important', fontSize: '38px', color: '#60A5DF' }}
            >
              {' Преміум! '}
            </Typography>
          </Typography>
          <Typography
            variant="body2"
            sx={{ fontSize: '14px', textAlign: 'center', ml: 3, mt: 2, maxWidth: '65%' }}
          >
            Наші ексклюзивні інструменти та функції допоможуть вам забезпечити зростання вашого
            бізнесу. Більше переваг, більше результатів!
          </Typography>
          <LetsGoBtn
            className="service-card-btn"
            width="240px"
            margin="35px 0px 10px 24px"
            text="До тарифних планів"
            onClick={() => {
              navigate(`/ua/tariffs`);
            }}
          />
        </Grid>
        <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <img width={260} src={Illustration2} alt="Illustration2" />
        </Grid>
      </Grid>
    </Paper>
  );
}
const mapStateToProps = (state) => {
  return {
    language: state.Intl.locale
  };
};
export default connect(mapStateToProps)(BannerMain);
