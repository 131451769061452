import React, { useEffect, useState } from 'react';

//Material
import { Grid, Paper, Skeleton, Typography } from '@mui/material';

// import { Stack } from '@mui/system';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import basePhoto from '../assets/DefaultIMG.png';
import '../pages/MainPage/index.css';
import { IncrementBtn } from './Buttons';
import ApiService from '../helpers/apiHelper';

// import  ImageGallery  from 'react-image-gallery';

// const useStyles = makeStyles({

//   button: {
//     '&:hover': {
//       boxShadow: '-5px 5px 3px black !important',
// width: '155px !important'
//   },
// }})
function ServiceCardOrder({ el, setter, qty, ...props }) {
  const apiPhoto = new ApiService('photo/get-photo');
  // const apiPhoto = new ApiService('get-photo');
  const [photos, setPhotos] = useState([]);
  const [isLoadingPhotos, setIsLoadingPhotos] = useState(false);
  // const classes = useStyles()
  // eslint-disable-next-line
  const userData = useSelector((state) => state.global.userData);
  // eslint-disable-next-line
  const language = useSelector((state) => state.Intl.locale);
// eslint-disable-next-line
  const navigate = useNavigate();

  useEffect(() => {
    let mounted = true;

    if (el?.encodedPhotos && el?.encodedPhotos.length !== 0 && mounted) {
      setIsLoadingPhotos(true);
      apiPhoto
        .getItemById(el.encodedPhotos[0])
        .then((res) => {
          let newPhotos=[]
          newPhotos.push(res.result)
          setIsLoadingPhotos(false);
          setPhotos(newPhotos);
        })
        .catch((error) => {console.error(error)
        });
      return () => (mounted = false);
    }
    // eslint-disable-next-line
  }, []);
  return (
    <Paper
      {...props}
      className="serviceCard"
      sx={{
        width: '100%',
        margin: '0px 0.5%',
        textAlign: 'center',
        overflow: 'hidden',
        alignItems: 'center',
        backgroundColor: 'white',
        borderRadius: '5px',
        border: '1px solid #60A5DF !important',
        padding: '20px',
        maxHeight: '',
        minHeight: '124px'
        // '&:hover':
        //   isHoverMy || isHoverSearch || isHoverSaved
        //     ? {}
        //     : { boxShadow: '-10px 10px 3px black', transform: 'scale(1.025)', transition: 'all 1s' }
      }}
    >
      <Grid
        container
        style={{
          padding: '0',
          display: 'flex'
        }}
      >
        <Grid item xs={1} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
          {' '}
          {el !== null &&
            el &&
            el?.encodedPhotos &&
            el?.encodedPhotos.length !== 0 &&
            el?.encodedPhotos.length >= 1 &&photos.length!==0&&!isLoadingPhotos?
            photos.map(
              (photo, index) =>
                index === 0 && (
                  <img
                    key={`photo${index}`}
                    src={`data:${photo.contentType};base64, ${photo.encodedContent}`}
                    alt={photo.nameFile}
                    style={{
                      objectFit: 'contain',
                      maxHeight: '84px',
                      minHeight: '84px',
                      border: '1px solid #60A5DF',
                      borderRadius: '2.5px'
                    }}
                  />
                )
            ):null}
             {isLoadingPhotos&&<Skeleton sx={{ height: 84, width:84, border: '1px solid #60A5DF',
                borderRadius: '2.5px' }
                
                } animation="wave" variant="rectangular" />}
          {(el !== null && !el?.encodedPhotos) ||
          (el !== null && el?.encodedPhotos && el?.encodedPhotos.length === 0) ? (
            <img
              src={basePhoto}
              alt="defaultImg"
              style={{
                objectFit: 'contain',
                maxHeight: '84px',
                minHeight: '84px',
                border: '1px solid #60A5DF',
                borderRadius: '2.5px'
              }}
            />
          ) : null}
        </Grid>

        <Grid
          item
          xs={4}
          sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}
        >
          <Typography variant="h5" style={{ fontSize: '16px', wordBreak: 'break-word' }}>
            {el.nameService}
          </Typography>
          <Typography variant="subtitle" sx={{ fontSize: 14, color: '#B1B1B1', mt: 2 }}>
            {`${el.valueOfInitOfMeasure} ${el.initOfMeasure}`}
          </Typography>
        </Grid>
        <Grid
          item
          xs={4}
          sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}
        >
          <Typography variant="subtitle" sx={{ fontSize: 16, color: '#868686', fontWeight: '500' }}>
            {`Продавець: @${el.userDTO?.nickname}`}
          </Typography>
        </Grid>
        <Grid
          item
          xs={1.5}
          sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}
        >
          <IncrementBtn
            setter={(value) => setter(value)}
            value={qty}
            colorOfQty={+qty > +el?.availableQuantity?'red':'black'}
            // initOfMeasure={el.initOfMeasure}
            // disableIncrement={+el.valueOfInitOfMeasure === qty}
            disableDecrement={qty === 1}
          />
        </Grid>
        <Grid
          item
          xs={1.5}
          sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}
        >
          <Typography variant="subtitle" sx={{ fontSize: 16, color: '#60A5DF', fontWeight: '600' }}>
            {`${(+el.price*qty).toFixed(2)} ${el.currency}`}
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
}
// const mapStateToProps = (state) => {
//   return {
//     language: state.Intl.locale
//   };
// };
export default ServiceCardOrder;
