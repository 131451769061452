import JSEncrypt from 'jsencrypt';

export const generateKeys = () => {
  const crypt = new JSEncrypt({ default_key_size: 2048 });
  return { publicKey: crypt.getPublicKeyB64(), privateKey: crypt.getPrivateKeyB64() };
};
export const decryptData = (encryptedData, privateKey) => {
  const crypt = new JSEncrypt();
  crypt.setPrivateKey(privateKey);
  const decrypted = crypt.decrypt(encryptedData);
  return decrypted;
};
export const encryptData = async (password, randomCode) => {
  try {
    // Generate IV
    const iv = crypto.getRandomValues(new Uint8Array(16));

    // Generate Salt
    const salt = crypto.getRandomValues(new Uint8Array(16));

    // Import password as key material
    const passwordKey = await crypto.subtle.importKey(
      'raw',
      new TextEncoder().encode(randomCode),
      { name: 'PBKDF2' },
      false,
      ['deriveKey']
    );

    // Derive an AES key using PBKDF2
    const aesKey = await crypto.subtle.deriveKey(
      {
        name: 'PBKDF2',
        salt: salt,
        // iterations: 1000000,
        iterations: 1000,
        hash: 'SHA-1'
      },
      passwordKey,
      { name: 'AES-CBC', length: 256 },
      false,
      ['encrypt']
    );

    // Encrypt the data
    const encryptedBuffer = await crypto.subtle.encrypt(
      { name: 'AES-CBC', iv: iv },
      aesKey,
      new TextEncoder().encode(password)
    );

    // Concatenate IV, Salt, and Encrypted Data
    const combinedArray = new Uint8Array(
      iv.byteLength + salt.byteLength + encryptedBuffer.byteLength
    );
    combinedArray.set(iv, 0);
    combinedArray.set(salt, iv.byteLength);
    combinedArray.set(new Uint8Array(encryptedBuffer), iv.byteLength + salt.byteLength);

    // Encode the combined array to Base64
    const base64String = btoa(String.fromCharCode.apply(null, combinedArray));

    return base64String;
  } catch (error) {
    console.error('Encryption error:', error);
    return null;
  }
};
