import React, { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { base } from '../../redux/thunk';
import { useTranslate } from 'react-redux-multilingual';
import Page from '../../components/Page';
// import { useNavigate } from 'react-router-dom';

//Material

import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import PushPinOutlinedIcon from '@mui/icons-material/PushPinOutlined';
// import ReplyIcon from '@mui/icons-material/Reply';
import AddIcon from '@mui/icons-material/Add';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import PersonOffOutlinedIcon from '@mui/icons-material/PersonOffOutlined';
import {
  Avatar,
  Box,
  ClickAwayListener,
  Grid,
  Paper,
  Stack,
  TextField,
  Typography,
  styled
} from '@mui/material';
import DefaultAvatar from '../../assets/defaultPostImg.jpg';
import { FreeBtn, LetsGoBtn2, ToTopBtn } from '../../components/Buttons';
import ApiService from '../../helpers/apiHelper';
import '../MainPage/index.css';
import { setIsOpenUserContactsModal, setIsOpenUserReadersModal } from '../../redux/mainReducer';
import UserContactsModal from '../modals/UserContactsModal';

import UserReadersModal from '../modals/UserReadersModal';

import PostCard from '../../components/PostCard';
import { useNavigate, useParams } from 'react-router-dom';
import CommentCard from '../../components/CommentCard';
import { Spinner } from '../../components/Spinner';
import { Element } from 'react-scroll';
import EmojiPicker from 'emoji-picker-react';
import EmojiEmotionsOutlinedIcon from '@mui/icons-material/EmojiEmotionsOutlined';

// import { Spinner } from '../../components/Spinner';

const CssTextField = styled(TextField)({
  '& label.Mui-focused': {
    color: '#000000'
  },
  '& label': {
    color: '#A1A1A1',
    fontFamily: 'Kyiv-Sans-Light'
  },

  // '& .MuiInput-root.Mui-focused:after': {
  //   borderBottom: '2px #000000 solid'
  // },
  // '& .MuiInput-root:hover:not(.Mui-disabled):before': {
  //   borderBottom: '2px #000000 solid'
  // },
  // '& .MuiInput-root:not(.Mui-error):before': {
  //   borderBottom: '2px #000000 solid'
  // },
  margin: '5px 20px 0px'
});

function PostPage(props) {
  const [error, setError] = useState('');
  const [userData, setUserData] = useState({});
  const [userSubscribers, setUserSubscribers] = useState([]);
  const [userSubscriptions, setUserSubscriptions] = useState([]);
  const [isShowEmoji, setIsShowEmoji] = useState(false);
  const translate = useTranslate();
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const apiChats = new ApiService('notification/chats');
  const apiPosts = new ApiService('posts/post');
  const apiComments = new ApiService('posts/comment');
  const apiGetComments = new ApiService('posts/comment/get-comments');

  // const apiChats = new ApiService('chats');
  // const apiPosts = new ApiService('post');
  // const apiComments = new ApiService('/comment/');
  // const apiGetComments = new ApiService('/comment/get-comments');

  const apiSubscribe = new ApiService('auth/subscribe');
  const apiUnSubscribe = new ApiService('auth/unsubscribe');
  const apiSubscriptions = new ApiService('auth/get-subscriptions');
  const apiSubscribers = new ApiService('auth/get-subscribers');

  const [data, setData] = useState([]);
  const [comments, setComments] = useState([]);
  const [startWith, setStartWith] = useState(0);
  const [pageScroll, setPageScroll] = useState(0);
  const [loadMore, setLoadMore] = useState(false);
  const [contentMaxCount, setContentMaxCount] = useState(0);
  const [topComment, setTopComment] = useState('');
  const [isHideDesc, setIsHideDesc] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const isOpenUserContactsModal = useSelector((state) => state.global.isOpenUserContactsModal);
  const isOpenUserReadersModal = useSelector((state) => state.global.isOpenUserReadersModal);

  const dataSetter = () => {
    setIsLoading(true);
    apiPosts
      .getItemById(params.id)
      .then((res) => {
        setData(res.result);
        setIsLoading(false);
        apiSubscribers
          .getAll({ userId: res.result.userDTO?.id })
          .then((res) => {
            setUserSubscribers(res.result);
          })
          .catch((error) => console.error(error));

        apiSubscriptions
          .getAll({ userId: res.result.userDTO?.id })
          .then((res) => {
            setUserSubscriptions(res.result);
          })
          .catch((error) => console.error(error));
        apiGetComments
          .getItemById(params.id, { page: 0, size: 10 })
          .then((res) => {
            setContentMaxCount(res.total);
            setComments(res.result);
          })
          .catch((error) => console.error(error));
      })
      .catch((error) => {
        if (error?.message.includes(`Post with postId [${params.id}] not found`)) {
          navigate(`/${props.language}/error`);
        }
        if (error?.message.includes(`Can't get post`)) {
          navigate(`/${props.language}/error`);
        }
        console.error(error.message);
      });
  };
  const commentsSwitcher = () => {
    setData({ ...data, turnOnComments: !data?.turnOnComments });
  };
  const handleLoadMore = () => {
    setLoadMore(true);
    apiGetComments
      .getItemById(params.id, { page: startWith + 1, size: 10 })
      .then((res) => {
        setContentMaxCount(res.total);
        setLoadMore(false);
        setComments([...comments, ...res.result]);
        setStartWith(startWith + 1);
      })
      .catch((error) => console.error(error));
  };
  useEffect(() => {
    let mounted = true;
    if (mounted) {
      dataSetter();
    }
    return () => (mounted = false);
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (data.length !== 0 && data?.userDTO?.id !== props.userData.id) {
      setUserData(data.userDTO);
    } else {
      setUserData(props.userData);
    }
    // eslint-disable-next-line
  }, [data]);
  useEffect(() => {
    window.addEventListener('scroll', () => setPageScroll(window.scrollY));
    return () => {
      window.removeEventListener('scroll', () => setPageScroll(window.scrollY));
    };
  });
  return (
    <Page
      title={translate('bonfair_myPage')}
      style={{
        display: 'flex',
        marginTop: '110px',
        alignItems: 'center',
        flexDirection: 'column',
        height: 'max-content',
        padding: '0px 100px'
      }}
    >
      {!isLoading ? (
        <>
          {' '}
          <Element name="topPost" />
          <Stack spacing={1} justifyContent="center" alignItems="center">
            <Grid
              container
              style={{
                maxWidth: '1440px',
                paddingTop: '65px'
              }}
              spacing={3}
            >
              {/* <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                <Typography
                  variant="subtitle"
                  sx={{
                    display: 'inline-flex',
                    cursor: 'pointer',
                    color: 'black',
                    ':hover': { color: '#60A5DF' }
                  }}
                  onClick={() => navigate(-1)}
                >
                  <ReplyIcon sx={{ mr: 1, color: 'black' }} />
                  Повернутись на попередню сторінку
                </Typography>
              </Grid> */}
              {/* <Grid item xs={4}/> */}
              <Grid
                item
                xs={4}
                sx={{ position: 'relative !important' }}
                // sx={{
                //   justifyContent: 'flex-start',
                //   display: 'flex',
                //   flexDirection: 'column',
                //   minWidth: '400px'
                // }}
              >
                <Paper
                  elevation={1}
                  style={{
                    border: !props.blockedUsers.find((el) => el.id === userData.id)
                      ? '1px solid black'
                      : '1px solid red',
                    padding: '23px'
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12} flexDirection="row" display="flex">
                      <Avatar
                        src={
                          userData?.encodedMediaDTO
                            ? `data:${userData?.encodedMediaDTO.contentType};base64, ${userData?.encodedMediaDTO.encodedContent}`
                            : DefaultAvatar
                        }
                        sx={{
                          width: 150,
                          height: 150,
                          mr: 2,
                          border: !props.blockedUsers.find((el) => el.id === userData.id)
                            ? '1px solid black'
                            : '1px solid red',

                          boxShadow: props?.blockedUsers.find((el) => el.id === userData.id)
                            ? '0px 0px 2px 2px rgba(255,0,0,1)'
                            : 'none',
                          borderRadius: '5px'
                        }}
                        variant="square"
                      />
                      <Stack sx={{ width: '100%', position: 'relative' }}>
                        {userData?.name && userData?.surname && (
                          <Typography
                            fontSize={18}
                            lineHeight="20px"
                            fontWeight={400}
                            sx={{ cursor: 'pointer' }}
                            onClick={() =>
                              navigate(`/${props.language}/user-page/${userData.id}?type=posts`)
                            }
                          >
                            {`${userData?.name} ${userData?.surname}`}
                          </Typography>
                        )}

                        <Typography
                          component="p"
                          fontSize={userData?.name && userData?.surname ? 14 : 18}
                          lineHeight="16px"
                          fontWeight={400}
                          sx={{ wordBreak: 'break-all', maxWidth: '90%', mt: 1 }}
                        >
                          {`@${userData?.nickname}`}
                        </Typography>
                        {userData?.city &&
                          userData?.city.length !== 0 &&
                          userData?.country &&
                          userData?.country.length !== 0 &&
                          userData?.region &&
                          userData?.region.length !== 0 && (
                            <Typography
                              sx={{ paddingLeft: '25px', mt: '14px', position: 'relative' }}
                              fontSize={16}
                              lineHeight="20px"
                              fontWeight={400}
                            >
                              <PushPinOutlinedIcon
                                style={{
                                  position: 'absolute',
                                  left: '-1px',
                                  color: '#60A5DF',
                                  fontSize: '22px',
                                  transform: 'rotate(-45deg)'
                                }}
                              />
                              {`${userData?.city ? userData?.city : ''}${
                                userData?.city &&
                                userData?.city.length !== 0 &&
                                userData?.region &&
                                userData?.region.length !== 0
                                  ? ', '
                                  : ''
                              }${userData?.region ? `${userData.region} обл.` : ''}${
                                userData?.city &&
                                userData?.city.length !== 0 &&
                                userData?.country &&
                                userData?.country.length !== 0
                                  ? ', '
                                  : ''
                              }${userData?.country ? userData?.country : ''}`}
                            </Typography>
                          )}
                        {userData?.number && userData?.number.length !== 0 && (
                          <Typography
                            sx={{ paddingLeft: '25px', mt: '14px', position: 'relative' }}
                            fontSize={16}
                            lineHeight="20px"
                            fontWeight={400}
                          >
                            <PhoneAndroidIcon
                              style={{
                                position: 'absolute',
                                left: '-1px',
                                color: '#60A5DF',
                                fontSize: '22px'
                              }}
                            />
                            {`${userData.number}`}
                          </Typography>
                        )}
                      </Stack>
                    </Grid>
                    {userData?.description && userData?.description.length !== 0 && (
                      <Grid item xs={12}>
                        <Typography
                          sx={
                            isHideDesc
                              ? {
                                  paddingLeft: '0px',
                                  mt: '10px',
                                  display: '-webkit-box',
                                  WebkitLineClamp: 2,
                                  WebkitBoxOrient: 'vertical',
                                  overflowY: 'hidden',
                                  textOverflow: 'ellipsis'
                                }
                              : {
                                  paddingLeft: '0px',
                                  mt: '10px',
                                  display: '-webkit-box',
                                  WebkitLineClamp: 'unset',
                                  WebkitBoxOrient: 'vertical',
                                  overflowY: 'hidden',
                                  textOverflow: 'ellipsis'
                                }
                          }
                          fontSize={16}
                          lineHeight="20px"
                          fontWeight={400}
                        >
                          {`${userData.description}`}
                        </Typography>
                        {userData.description.length > 100 && (
                          <Typography
                            onClick={() => setIsHideDesc(!isHideDesc)}
                            sx={{
                              // paddingLeft: '70%'
                              textDecoration: 'underline',
                              color: '#60A5DF',
                              cursor: 'pointer',
                              width: 'fit-content',
                              marginLeft: 'auto'
                            }}
                            fontSize={16}
                            lineHeight="20px"
                            fontWeight={400}
                          >
                            {isHideDesc ? 'Читати більше' : 'Сховати'}
                          </Typography>
                        )}
                      </Grid>
                    )}
                    <Grid
                      item
                      xs={12}
                      flexDirection="row"
                      display="flex"
                      justifyContent="space-between"
                    >
                      {userData.id === props.userData.id ? (
                        <FreeBtn
                          onClick={() => dispatch(setIsOpenUserContactsModal(true))}
                          text="Контакти"
                          icon={
                            <PermIdentityIcon style={{ marginRight: '10px', color: '#60A5DF' }} />
                          }
                        />
                      ) : (
                        <FreeBtn
                          disabled={props.blockedUsers.find((user) => user.id === userData?.id)}
                          text={
                            props.subscriptions.find(
                              (subscription) => subscription.id === userData.id
                            )
                              ? 'Видалити з контактів'
                              : 'Додати до Контактів'
                          }
                          onClick={() => {
                            if (
                              props.subscriptions.find(
                                (subscription) => subscription.id === userData.id
                              )
                            ) {
                              apiUnSubscribe
                                .deleteItemQuery({ subscriptionId: userData.id })
                                .then((res) => {
                                  dataSetter();
                                  props.getUserSubscriptionsThunk(props.userData.id);
                                });
                            } else {
                              apiSubscribe
                                .updateDataQuery({ subscriptionId: userData.id })
                                .then((res) => {
                                  dataSetter();
                                  props.getUserSubscriptionsThunk(props.userData.id);
                                });
                            }
                          }}
                          icon={
                            props.subscriptions.find(
                              (subscription) => subscription.id === userData.id
                            ) ? (
                              <PersonOffOutlinedIcon
                                style={{ marginRight: '10px', color: '#60A5DF' }}
                              />
                            ) : (
                              <AddIcon
                                style={{
                                  marginRight: '10px',
                                  color: props.blockedUsers.find((user) => user.id === userData?.id)
                                    ? 'lightgray'
                                    : '#60A5DF'
                                }}
                              />
                            )
                          }
                        />
                      )}

                      <UserContactsModal
                        nickname={userData.nickname}
                        isOpenModal={isOpenUserContactsModal}
                        data={
                          userData.id !== props.userData.id
                            ? userSubscriptions
                            : props.subscriptions
                        }
                      />
                      {userData.id === props.userData.id ? (
                        <FreeBtn
                          onClick={() => dispatch(setIsOpenUserReadersModal(true))}
                          text="Підписники"
                          icon={
                            <PermIdentityIcon style={{ marginRight: '10px', color: '#60A5DF' }} />
                          }
                        />
                      ) : (
                        <FreeBtn
                          disabled={props.blockedUsers.find((user) => user.id === userData?.id)}
                          text=""
                          icon={
                            <MailOutlineIcon
                              onClick={() => {
                                apiChats
                                  .postDataQuery({
                                    userId: props.userData.id,
                                    recipientId: userData.id
                                  })
                                  .then((res) => {
                                    navigate(`/${props.language}/messages`);
                                  });
                              }}
                              style={{
                                color: props.blockedUsers.find((user) => user.id === userData?.id)
                                  ? 'lightgray'
                                  : '#60A5DF'
                              }}
                            />
                          }
                        />
                      )}

                      <UserReadersModal
                        nickname={userData.nickname}
                        isOpenModal={isOpenUserReadersModal}
                        data={
                          userData.id !== props.userData.id ? userSubscribers : props.subscribers
                        }
                      />
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>

              <Grid item xs={8}>
                {data.length !== 0 && (
                  <PostCard
                    noWidth
                    sx={{ marginBottom: '10px !important' }}
                    el={data}
                    dataSetter={dataSetter}
                    commentsSwitcher={commentsSwitcher}
                  />
                )}
              </Grid>
              <Grid item xs={4} />
              {!props.blockedUsers.find((user) => user.id === userData?.id) &&
              data?.turnOnComments ? (
                <Grid
                  item
                  xs={8}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    p: '0px 30px',
                    position: 'relative'
                  }}
                >
                  <CssTextField
                    disabled={props.blockedUsers.find((user) => user.id === userData?.id)}
                    variant="standard"
                    placeholder="Прокоментуй тут"
                    fullWidth
                    multiline
                    minRows={1}
                    maxRows={10}
                    size="normal"
                    inputProps={{ style: { textAlign: 'left' } }}
                    InputProps={{
                      endAdornment: (
                        <>
                          <EmojiEmotionsOutlinedIcon
                            onClick={() => {
                              setIsShowEmoji(!isShowEmoji);
                            }}
                            sx={{
                              color: '#60A5DF',
                              cursor: 'pointer',
                              fontSize: '20px',
                              mr: 1
                            }}
                          />
                        </>
                      )
                    }}
                    value={topComment}
                    onChange={(event) => {
                      if (isShowEmoji) {
                        setIsShowEmoji(false);
                      }
                      if (event.target.value.length <= 500) setTopComment(event.target.value);
                      setError('');
                    }}
                  />
                  {isShowEmoji && (
                    <ClickAwayListener
                      onClickAway={() => {
                        setIsShowEmoji(false);
                      }}
                    >
                      <Box>
                        {' '}
                        <EmojiPicker
                          onEmojiClick={(emojiData, event) => {
                            let newStr = `${topComment}${emojiData.emoji}`;
                            setTopComment(newStr);
                          }}
                          skinTonesDisabled
                          searchDisabled
                          previewConfig={{ showPreview: false }}
                          style={{
                            position: 'absolute',
                            bottom: '-290px',
                            right: '250px',
                            zIndex: '99'
                          }}
                          width={'60%'}
                          height={'300px'}
                        />
                      </Box>
                    </ClickAwayListener>
                  )}
                  <LetsGoBtn2
                    disabled={props.blockedUsers.find((user) => user.id === userData?.id)}
                    text="Додати"
                    width="225px"
                    marginTop="0px"
                    onClick={() => {
                      if (topComment.length === 0) {
                        setError('Мінімальна довжина коментаря: 1 символ');
                      } else if (topComment.length > 500) {
                        setError('Максимальна довжина коментаря: 500 символів');
                      } else {
                        apiComments
                          .sendRequest({ text: topComment, id: params.id, typeComment: 'post' })
                          .then((res) => {
                            setTopComment('');
                            dataSetter();
                          });
                      }
                    }}
                  />
                </Grid>
              ) : (
                <Grid item xs={8} />
              )}

              <Grid item xs={4} />
              <Grid
                item
                xs={8}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  p: '0px 30px',
                  mt: 0
                }}
                style={{ paddingTop: '5px' }}
              >
                {!props.blockedUsers.find((user) => user.id === userData?.id) &&
                data?.turnOnComments ? (
                  <p
                    style={{
                      margin: '0 5px',
                      color: 'gray',
                      textAlign: 'left',
                      fontSize: '14px',
                      paddingLeft: '20px'
                    }}
                  >{`${topComment.length}/500`}</p>
                ) : null}

                {error.length !== 0 && (
                  <p
                    style={{
                      margin: '0 5px',
                      color: 'red',
                      textAlign: 'left',
                      fontSize: '14px',
                      paddingLeft: '20px'
                    }}
                  >{`${error}`}</p>
                )}
              </Grid>
              <ToTopBtn
                to="topPost"
                visible={pageScroll > 1500}
                sx={{ position: 'fixed', right: '-80px', bottom: '0' }}
                icon={<ArrowUpwardIcon sx={{ fontSize: '22px' }} />}
              />
              {comments.length !== 0 &&
                comments.map((comment, index) => (
                  <React.Fragment key={`comment${index}`}>
                    <Grid item xs={4} />
                    <Grid item xs={8} sx={{ pl: '27px !important' }}>
                      <CommentCard
                        data={comment}
                        isOnComments={data?.turnOnComments}
                        setter={dataSetter}
                      />
                    </Grid>
                  </React.Fragment>
                ))}
              {contentMaxCount > startWith + 1 && (
                <React.Fragment key={`loadMore`}>
                  <Grid item xs={4} />
                  <Grid item xs={8} sx={{ display: 'flex', justifyContent: 'center' }}>
                    <LetsGoBtn2
                      sx={{ margin: '30px auto !important' }}
                      text={loadMore ? <Spinner type="small" /> : 'Завантажити більше'}
                      onClick={handleLoadMore}
                    />
                  </Grid>
                </React.Fragment>
              )}
            </Grid>
          </Stack>
        </>
      ) : (
        <div
          style={{
            // height: '240px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            marginBottom: '100px',
            height: '100vh'
          }}
        >
          <Spinner />
        </div>
      )}
    </Page>
  );
}
const mapStateToProps = (state) => {
  return {
    language: state.Intl.locale,
    userData: state.global.userData,
    subscribers: state.global.userSubscribers,
    subscriptions: state.global.userSubscriptions,
    blockedUsers: state.global.blockedUsers
  };
};
export default connect(mapStateToProps, {
  getUserSubscriptionsThunk: base.getUserSubscriptionsThunk
})(PostPage);
