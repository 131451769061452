import moment from 'moment/moment';

export const dateTimeFormattingByString = (stringDate) => {
  const date = stringDate ? new Date(stringDate) : null;
  return dateTimeFormattingByDate(date);
};

export const dateTimeFormattingByDate = (date) =>
  date ? `${timeFormattingByDate(date)} ${dateFormattingByDate(date)}` : null;

export const dateFormattingByString = (stringDate, showDay) => {
  const date = stringDate ? new Date(stringDate) : null;
  return dateFormattingByDate(date, showDay);
};

export const timeFormattingByString = (stringDate) => {
  const date = stringDate ? new Date(stringDate) : null;
  return timeFormattingByDate(date);
};

export const timeFormattingByDate = (date) =>
  date
    ? date
        .toLocaleString('uk-UA', {
          hour: 'numeric',
          minute: 'numeric',
          hour12: false
        })
        .toString()
    : null;
export const dateFormattingByDateShort = (date, showDay = true, monthType = 'numeric') =>
  date
    ? date
        .toLocaleString('uk-UA', {
          ...(showDay && { day: 'numeric' }),
          month: monthType,
          year: 'numeric'
        })
        .toString()
        .replace(/\./g, '/')
    : null;
export const dateFormattingByDate = (date, showDay = true, monthType = 'long') =>
  date
    ? date
        .toLocaleString('uk-UA', {
          ...(showDay && { day: 'numeric' }),
          month: monthType,
          year: 'numeric'
        })
        .toString()
    : null;
export const monthFormattingByDate = (date, showDay = true) =>
  date
    ? date
        .toLocaleString('uk-UA', {
          month: 'numeric'
        })
        .toString()
    : null;
export const yearFormattingByDate = (date, showDay = true) =>
  date
    ? date
        .toLocaleString('uk-UA', {
          year: 'numeric'
        })
        .toString()
    : null;
export const timeDiff = (date) => {
  let diffTime = Math.abs(new Date().valueOf() - new Date(date).valueOf());
  let days = diffTime / (24 * 60 * 60 * 1000);
  let hours = (days % 1) * 24;
  let minutes = (hours % 1) * 60;

  [days, hours, minutes] = [Math.floor(days), Math.floor(hours), Math.floor(minutes)];
  if (days !== 0) {
    return `${days} дн тому`;
  } else if (days === 0 && hours !== 0) {
    return `${hours} год тому`;
  } else if (days === 0 && hours === 0) {
    return `${minutes} хв тому`;
  }
};
export const timeDiffChat = (date, message = false) => {
  let diffTime = Math.abs(new Date().valueOf() - new Date(date).valueOf());
  let days = diffTime / (24 * 60 * 60 * 1000);
  let hours = (days % 1) * 24;
  let minutes = (hours % 1) * 60;

  [days, hours, minutes] = [Math.floor(days), Math.floor(hours), Math.floor(minutes)];

  if (days === 1) {
    return `вчора`;
  } else if (days > 1 && !message) {
    return `${dateFormattingByDate(new Date(date), true, 'numeric')}`;
  } else if (days > 1 && message) {
    return `${timeFormattingByDate(new Date(date))}`;
  } else if (days === 0 && hours === 0 && minutes <= 1) {
    return `щойно`;
  } else if (days === 0 && hours === 0 && minutes > 1) {
    return `${timeFormattingByDate(new Date(date))}`;
  } else if (days === 0 && hours !== 0) {
    return `${timeFormattingByDate(new Date(date))}`;
  }
};
export const timeDiffNotif = (date, message = false) => {
  let diffTime = Math.abs(new Date().valueOf() - new Date(date).valueOf());
  let days = diffTime / (24 * 60 * 60 * 1000);
  let hours = (days % 1) * 24;
  let minutes = (hours % 1) * 60;
  [days, hours, minutes] = [+days.toFixed(), +hours.toFixed(), +minutes.toFixed()];

  if (days === 1) {
    return `вчора`;
  } else if (days > 1 && !message) {
    return `${dateFormattingByDate(new Date(date), true, 'numeric')}`;
  } else if (days > 1 && message) {
    return `${timeFormattingByDate(new Date(date))}`;
  } else if (days === 0 && hours === 0 && minutes <= 1) {
    return `щойно`;
  } else if (days === 0 && hours === 0 && minutes > 1) {
    return `${timeFormattingByDate(new Date(date))}`;
  } else if (days === 0 && hours !== 0) {
    return `${timeFormattingByDate(new Date(date))}`;
  }
};
export const timeDiffMessages = (date) => {
  let diffTime = Math.abs(new Date().valueOf() - new Date(date).valueOf());
  let days = diffTime / (24 * 60 * 60 * 1000);
  let hours = (days % 1) * 24;
  let minutes = (hours % 1) * 60;

  [days, hours, minutes] = [Math.floor(days), Math.floor(hours), Math.floor(minutes)];

  if (days === 1) {
    return `вчора`;
  } else if (days > 1) {
    return `${dateFormattingByDate(new Date(date), true, 'numeric')}`;
  }
};
export const timeDiffMessagesDay = (date) => {
  var formats = {
    lastWeek: 'DD.MM.YYYY',
    lastDay: '[Вчора]',
    sameDay: '[Сьогодні]',
    nextDay: '[Tomorrow]',
    nextWeek: 'dddd',
    sameElse: 'DD.MM.YYYY'
  };
  // var formatedDate = date;

  var output = moment(date).calendar(null, formats);

  return output;
};
