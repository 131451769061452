import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom/client';
import { connect } from 'react-redux';

//Components MUi
import { TabContext, TabPanel } from '@mui/lab';
import {
  Autocomplete,
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Menu,
  Stack,
  Tab,
  TextField,
  Tooltip,
  Typography,
  styled
} from '@mui/material';
import { Spinner } from '../../components/Spinner';
import { StyledSubTabsScheduler } from '../../components/SubTabs.styles';
// Additional components
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin, { Draggable } from '@fullcalendar/interaction';
import momentPlugin from '@fullcalendar/moment';
import FullCalendar from '@fullcalendar/react';
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';
import ArrowDropUpOutlinedIcon from '@mui/icons-material/ArrowDropUpOutlined';
import ClearIcon from '@mui/icons-material/Clear';
import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import TuneIcon from '@mui/icons-material/Tune';
import moment from 'moment/moment';
import { useNavigate, useSearchParams } from 'react-router-dom';
import starRed from '../../assets/StarRed.png';
import expandIcon from '../../assets/arrowExpand.png';
import { FreeBtn } from '../../components/Buttons';
import Page from '../../components/Page';
import { StyledTitle } from '../../components/StyledComponents';
import { monthFormattingByDate } from '../../helpers/date-helpers';
import './index.css';
import OrderDraggable from './orders/OrderDraggable';
import History from './tabs/History';
import Orders from './tabs/Orders';

//PNG
import OrdersPng from '../../assets/SchedulerOrdersIllustration.png';
import ApiService from '../../helpers/apiHelper';
import { useSnackbar } from 'notistack';

// import { useSelector } from 'react-redux';
// const CssTextField = styled(TextField)({
//   '& label.Mui-focused': {
//     color: '#000000'
//   },
//   '& label': {
//     color: '#A1A1A1',
//     fontFamily: 'Kyiv-Sans-Light'
//   },
//   fontFamily: 'Kyiv-Sans-Regular !important',
//   margin: '5px 0px'
// });
const StyledTab = styled((props) => {
  if (props?.disabled) {
    return (
      <Tooltip
        title={
          props.label === 'Замовлення' ? 'У Вас немає жодної послуги' : 'У Вас немає жодної послуги'
        }
        placement="top"
      >
        <span>
          <Tab disableRipple {...props} style={{ color: 'gray !important' }} />
        </span>
      </Tooltip>
    );
  } else {
    return <Tab disableRipple {...props} />;
  }
})(({ theme }) => ({
  textTransform: 'none',
  marginRight: '10px !important',
  marginLeft: '10px !important'
}));
function createData(
  color,
  comment,
  contacts,
  createdAt,
  createdBy,
  date,
  deliveryType,
  endHour,
  id,
  paymentType,
  service,
  startHour,
  title,
  qty,
  serviceID
) {
  return {
    color,
    comment,
    contacts,
    createdAt,
    createdBy,
    date,
    deliveryType,
    endHour,
    id,
    paymentType,
    service,
    startHour,
    title,
    qty,
    serviceID
  };
}

function SchedulerPage({ language, userData, ...props }) {
  // eslint-disable-next-line
  const navigate = useNavigate();
  const [params, setParams] = useSearchParams();
  const { enqueueSnackbar } = useSnackbar();
  // eslint-disable-next-line
  const [isLoading, setIsLoading] = useState(false);
  const [tabIdx, setTabIdx] = useState(params.get('tab'));
  const [selectedMonth, setSelectedMonth] = useState(
    `${monthFormattingByDate(new Date()).padStart(2, '0')}`
  );
  const [selectedYear, setSelectedYear] = useState(+new Date().getFullYear());
  const calendarRef = useRef(null);
  const [calendarContainer, setCalendarContainer] = useState(null);
  // eslint-disable-next-line

  const [statuses, setStatuses] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [ordersCounter, setOrdersCounter] = useState(null);

  const [distributedEvents, setDistributedEvents] = useState([]);
  const [selectedDay, setSelectedDay] = useState('');
  const [selectedDayElement, setSelectedDayElemet] = useState('');
  const [todayEvents, setTodayEvents] = useState([]);
  const apiMyOrders = new ApiService('posts/scheduler/orders');
  const apiOrderChangeTime = new ApiService('posts/order/change-time');
  // const apiMyOrders = new ApiService('/scheduler/orders');
  // const apiOrderChangeTime = new ApiService('/order/change-time');

  const downloadPDF = async () => {
    const pdf = await fetch(
      // `${window.config.REACT_APP_API_HOST_2}/openpdf/export`, {
      `${window.location.protocol}//${window.location.hostname}/v1/posts/openpdf/export`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: localStorage.getItem('accessToken')
        },
        body: JSON.stringify(todayEvents.map((event) => event.id))
      }
    );
    const resolvedPdf = await pdf.arrayBuffer();

    if (resolvedPdf && pdf.status === 200) {
      const url = window.URL.createObjectURL(new Blob([resolvedPdf]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `Замовлення на ${moment(selectedDay).format('DD-MM-YYYY')}.pdf`
      );
      document.body.appendChild(link);
      link.click();
      link.remove();
    } else {
      // eslint-disable-next-line
      throw 'Помилка скачування';
    }
  };

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const updateCalendarDate = (selectedYear, selectedMonth) => {
    if (calendarRef.current) {
      const calendarApi = calendarRef.current.getApi();
      calendarApi.gotoDate(`${selectedYear}-${selectedMonth.toString()}-01`);
    }
  };
  const onYearChange = async (value) => {
    setSelectedYear(value);
    updateCalendarDate(value, selectedMonth);
    setTodayEvents([]);
  };

  const onMonthChange = async (value) => {
    setSelectedMonth(value);
    updateCalendarDate(selectedYear, value);
    setTodayEvents([]);
  };
  useEffect(() => {
    // document.addEventListener('DOMContentLoaded', function () {
    let containerEl = document.getElementById('external-events');

    // console.log(containerEl);
    if (containerEl) {
      new Draggable(containerEl, {
        itemSelector: '.draggableWrapper',
        eventData: function (eventEl) {
          let data = eventEl.getAttribute('data-event');
          let parsedData = JSON.parse(data);
          return {
            eventInfo: { ...JSON.parse(data) },
            title: parsedData.title,
            color: parsedData.color
          };
        }
      });
    }
    // });
  }, []);

  // eslint-disable-next-line
  const state = {
    weekendsVisible: true,
    currentEvents: []
  };

  const handleDateSelect = (selectInfo) => {
    if (selectInfo?.startStr) {
      const selectedDayEvents = distributedEvents.filter((ev) => ev.date === selectInfo.startStr);
      setSelectedDay(selectInfo.startStr);
      setTodayEvents(selectedDayEvents);
      setSelectedDayElemet('');
      if (selectedDayElement.length !== 0) {
        const prevElement = document.getElementById(selectedDayElement);
        prevElement.remove();
      }
    } else {
      if (selectedDayElement.length !== 0) {
        const prevElement = document.getElementById(selectedDayElement);
        prevElement.remove();
      }
      const backgroundEl =
        selectInfo.jsEvent.target.parentElement.parentElement.parentElement.children[2];
      backgroundEl.insertAdjacentHTML(
        'beforeend',
        `
      <div id='${moment(selectInfo.date).format(
        'YYYY-MM-DD'
      )}select' class="fc-daygrid-bg-harness" style="left: 0px; right: 0px;"><div class="fc-highlight"></div></div>
  `
      );
      setSelectedDayElemet(`${moment(selectInfo.date).format('YYYY-MM-DD')}select`);

      const selectedDayEvents = distributedEvents.filter(
        (ev) => ev.date === moment(selectInfo.date).format('YYYY-MM-DD')
      );
      setSelectedDay(moment(selectInfo.date).format('YYYY-MM-DD'));
      setTodayEvents(selectedDayEvents);
    }
    // console.log('OPEN!', selectInfo);
  };

  const handleEventClick = (clickInfo) => {
    return;
    // if (confirm(`Are you sure you want to delete the event '${clickInfo.event.title}'`)) {
    //   clickInfo.event.remove();
    // }
  };
  // const handleEvents = (events) => {
  //   // console.log(events);
  //   const distributed = events.map((ev) => {
  //     return { ...ev.extendedProps.eventInfo, date: ev.startStr };
  //   });

  //   // setDistributedEvents(distributed);
  // };
  function renderEventContent(eventInfo) {
    return (
      <>
        {/* <b>{eventInfo.timeText}</b> */}
        <i style={{ color: 'black', lineHeight: '12px', fontStyle: 'normal' }}>
          {eventInfo.event.title}
        </i>
      </>
    );
  }
  const handleChangeTab = async (event, newValue) => {
    params.set('tab', newValue);
    if (newValue === '1') {
      params.set('sd', params.get('sd') ? params.get('sd') : 'DESC');
      params.set('status', '');
      params.set('question', '');
    }
    if (newValue === '2') {
      params.set('sd', params.get('sd') ? params.get('sd') : 'DESC');
      params.set('question', '');
      params.delete('status');
    }
    if (newValue === '3') {
      params.delete('sd');
      params.delete('status');
      params.delete('question');
    }
    setParams(params);
  };

  const autocompletesRenderer = async () => {
    const collection = document.getElementsByClassName('fc-toolbar-chunk');

    if (collection.length > 0) {
      const container = collection[2];

      if (container) {
        let root;

        if (!calendarContainer) {
          root = ReactDOM.createRoot(container);
          setCalendarContainer(root);
        } else {
          root = calendarContainer;
        }
        root.render(
          <div
            id="autocompleteContainer"
            style={{ width: '250px', display: 'flex', justifyContent: 'space-between' }}
          >
            <Autocomplete
              sx={{ width: '100px' }}
              disableClearable
              onKeyPress={(e) => {
                e.preventDefault();
              }}
              defaultValue={+new Date().getFullYear()}
              options={[2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030]}
              getOptionLabel={(option) => option.toString()}
              onInputChange={(event, newValue) => onYearChange(newValue)}
              renderInput={(params) => (
                <TextField {...params} placeholder="Обери категорію послуги*" />
              )}
            />
            <Autocomplete
              sx={{ width: '140px' }}
              disableClearable
              onKeyPress={(e) => {
                e.preventDefault();
              }}
              options={['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12']}
              defaultValue={`${monthFormattingByDate(new Date()).padStart(2, '0')}`}
              getOptionLabel={(option) => {
                switch (option) {
                  case '01':
                    return 'Січень';
                  case '02':
                    return 'Лютий';
                  case '03':
                    return 'Березень';
                  case '04':
                    return 'Квітень';
                  case '05':
                    return 'Травень';
                  case '06':
                    return 'Червень';
                  case '07':
                    return 'Липень';
                  case '08':
                    return 'Серпень';
                  case '09':
                    return 'Вересень';
                  case '10':
                    return 'Жовтень';
                  case '11':
                    return 'Листопад';
                  case '12':
                    return 'Грудень';
                  default:
                    return;
                }
              }}
              onChange={(event, newValue) => onMonthChange(newValue)}
              renderInput={(params) => <TextField {...params} placeholder="Місяць" />}
            />
          </div>
        );
      }
    }
  };

  useEffect(() => {
    tabIdx === '3' && !document.getElementById('') && autocompletesRenderer();
    return () => setCalendarContainer(null);
    // eslint-disable-next-line
  }, [tabIdx]);
  // useEffect(() => {
  //   updateCalendarDate();
  //   // eslint-disable-next-line
  // }, [selectedMonth, selectedYear]);
  useEffect(() => {
    const tab = params.get('tab');
    if (userData?.countServices > 0) {
      switch (tab) {
        case '1':
          return setTabIdx('1');

        case '2':
          return setTabIdx('2');

        case '3':
          return setTabIdx('3');
        default:
          return setTabIdx('2');
      }
    } else {
      if (params.get('tab') !== '2') {
        params.set('tab', '2');
        setParams(params);
      }

      switch (tab) {
        case '1':
          return setTabIdx('2');

        case '2':
          return setTabIdx('2');

        case '3':
          return setTabIdx('2');
        default:
          return setTabIdx('2');
      }
    }

    // eslint-disable-next-line
  }, [params.get('tab')]);

  useEffect(() => {
    window.location.href.includes('scheduler') &&
      params.get('question') &&
      setSearchValue(params.get('question'));
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (params.get('status') && params.get('status').length !== 0) {
      setStatuses(params.get('status').split(','));
    } else {
      setStatuses([]);
    }
    // eslint-disable-next-line
  }, [params.get('status')]);

  useEffect(() => {
    const todayDate = moment(new Date()).format('YYYY-MM-DD');
    const todayEventsList = distributedEvents.filter((ev) => ev.date === todayDate);
    setTodayEvents([...todayEventsList]);
    // eslint-disable-next-line
  }, [distributedEvents]);
  useEffect(() => {
    const todayDate = moment(new Date()).format('YYYY-MM-DD');
    setSelectedDay(todayDate);
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    let mounted = true;
    if (tabIdx === '3' && mounted) {
      apiMyOrders
        .getAll({
          from: 0,
          size: 366,
          search: '',
          sortOrder: 'DESC',
          status: 'PROCESSING'
        })
        .then((res) => {
          const dates = res.result.map((el) =>
            createData(
              '#99D896',
              el?.details?.comment,
              el?.details?.contact,
              moment(new Date(el?.buyingDate)).format('YYYY-MM-DD'),
              `${el?.buyer?.surname} ${el?.buyer?.name}`,
              moment(new Date(el?.estimatedTimeShipment)).format('YYYY-MM-DD'),

              el?.details?.deliveryType,
              '05:00 AM',
              el?.id,
              el?.details?.paymentType,
              el?.service?.nameService,
              '04:00 AM',
              el?.orderNumber,
              el?.qty,
              el?.service?.id
            )
          );
          setDistributedEvents(dates);
        });
    }
    return () => (mounted = false);
    // eslint-disable-next-line
  }, []);
  // const onDrop = (dragIndex, overIndex) => {
  //   const nextItems = moveItems(selectedEvents, dragIndex, overIndex);
  //   setSelectedEvents(nextItems);
  // };

  // const context = useDraggableContext({
  //   onDrop
  // });
  return (
    <Page
      title="Календар подій"
      style={{
        display: 'flex',
        marginTop: '150px',
        alignItems: 'center',
        flexDirection: 'column',
        height: 'max-content',
        padding: '0px 100px 50px'
      }}
    >
      <Stack
        spacing={1}
        justifyContent="center"
        alignItems="center"
        width="100%"
        height={isLoading ? '80vh' : '100%'}
      >
        {!isLoading ? (
          <Grid
            container
            style={{
              maxWidth: '1440px'
            }}
          >
            {params.get('tab') === '1' && (
              <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'column' }}>
                {ordersCounter && (
                  <>
                    <Typography
                      component={'span'}
                      sx={{
                        fontFamily: 'Public Sans, sans-serif !important',
                        display: 'flex',
                        alignItems: 'center',
                        border: '2px solid #5CB85C',
                        borderRadius: '20px',
                        p: '10px 15px',
                        background: '#EDFFED',
                        width: 'fit-content',
                        mb: 1,
                        fontSize: '14px'
                      }}
                    >
                      <NotificationsNoneOutlinedIcon sx={{ mr: 1, color: '#5CB85C' }} /> Нові
                      замовлення:
                      <Typography
                        // variant="subtitle2"
                        sx={{
                          fontSize: 16,
                          fontFamily: 'Kyiv-Sans-Bold',
                          color: '#5CB85C',
                          ml: 0.5
                        }}
                      >
                        {`${ordersCounter?.new > 2 ? '' : ''}${ordersCounter?.new}`}
                      </Typography>
                    </Typography>
                    <Typography
                      component={'span'}
                      sx={{
                        fontFamily: 'Public Sans, sans-serif !important',
                        display: 'flex',
                        alignItems: 'center',
                        border: '2px solid #FDC940',
                        borderRadius: '20px',
                        p: '10px 15px',
                        background: '#FFF7E3',
                        width: 'fit-content',

                        fontSize: '14px'
                      }}
                    >
                      <NotificationsNoneOutlinedIcon sx={{ mr: 1, color: '#FDC940' }} /> Очікують
                      відправки:
                      <Typography
                        // variant="subtitle2"
                        sx={{
                          fontSize: 16,
                          fontFamily: 'Kyiv-Sans-Bold',
                          color: '#FDC940',
                          ml: 0.5
                        }}
                      >
                        {`${ordersCounter?.sentAwait > 2 ? '+' : ''}${ordersCounter?.sentAwait}`}
                      </Typography>
                    </Typography>
                  </>
                )}
              </Grid>
            )}
            <TabContext value={tabIdx}>
              <Grid
                item
                xs={12}
                sx={{
                  p: 1,
                  minHeight: '90px',
                  display: 'flex',
                  justifyContent: 'center'
                }}
              >
                <StyledSubTabsScheduler
                  sx={{
                    maxWidth: 'unset !important',
                    '& .MuiTabs-flexContainer': {
                      justifyContent: 'space-between',

                      maxWidth: 'unset !important'
                    }
                  }}
                  onChange={handleChangeTab}
                >
                  <StyledTab
                    disabled={userData && userData?.countServices === 0}
                    value="1"
                    label="Замовлення"
                  />

                  <StyledTab value="2" label="Ваші покупки" />

                  <StyledTab
                    disabled={userData && userData?.countServices === 0}
                    value="3"
                    label="Календар"
                  />
                </StyledSubTabsScheduler>
              </Grid>

              <Grid
                item
                xs={12}
                sx={{
                  display: 'flex',
                  p: 1,
                  minHeight: '60vh',
                  flexDirection: 'column'
                  // maxHeight: '60vh',
                  // overflow: 'scroll',
                  // '&::-webkit-scrollbar': { display: 'none' },
                  // msOverflowStyle: 'none',
                  // scrollbarWidth: 'none'
                }}
              >
                {(tabIdx === '1' || tabIdx === '2') && (
                  <TextField
                    style={{ margin: '0px auto 50px', maxWidth: '800px' }}
                    sx={{
                      input: {
                        fontFamily: 'Kyiv-Sans-Light',
                        fontSize: '16px',
                        fontWeight: '350',
                        lineHeight: '19px',
                        letterSpacing: '0em',
                        textAlign: 'left',
                        '&::placeholder': {
                          fontFamily: 'Kyiv-Sans-Light',
                          fontSize: '16px',
                          fontWeight: '350',
                          lineHeight: '19px',
                          letterSpacing: '0em',
                          textAlign: 'left'
                          // <----- Add this.
                          // opacity: 1,
                        }
                      }
                    }}
                    placeholder="Введіть свій пошуковий запит"
                    variant="standard"
                    fullWidth
                    value={searchValue}
                    onChange={(e) =>
                      e.target?.value !== null ? setSearchValue(e.target.value) : setSearchValue('')
                    }
                    InputProps={{
                      endAdornment: (
                        <>
                          {searchValue.length !== 0 && (
                            <ClearIcon
                              sx={{
                                cursor: 'pointer',
                                ':hover': {
                                  color: '#60A5DF'
                                }
                              }}
                              onClick={() => {
                                setSearchValue('');
                                navigate(`/${language}/scheduler?&tab=${params.get('tab')}`);
                              }}
                            />
                          )}
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              width: 'fit-content',
                              cursor: 'pointer'
                            }}
                            onClick={() => {
                              params.set('question', searchValue);
                              setParams(params);
                              // navigate(
                              //   `/${language}/scheduler?question=${searchValue}&tab=${params.get(
                              //     'tab'
                              //   )}`
                              // );
                            }}
                          >
                            <img
                              alt="arrow"
                              src={expandIcon}
                              style={{ width: '35px', margin: '5px' }}
                            />
                          </div>
                        </>
                      )
                    }}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        params.set('question', searchValue);
                        setParams(params);
                        // navigate(
                        //   `/${language}/scheduler?question=${searchValue}&tab=${params.get('tab')}`
                        // );
                      }
                    }}
                  />
                )}
                <TabPanel value="1">
                  <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <StyledTitle
                      maxWidth={800}
                      sx={{ textTransform: 'none' }}
                      className="titleCustom"
                    >
                      Замовлення
                    </StyledTitle>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      {tabIdx === '1' && (
                        <div
                          onClick={handleClick}
                          style={{
                            cursor: 'pointer',
                            alignItems: 'center',
                            display: 'flex',
                            marginRight: '20px'
                          }}
                        >
                          <TuneIcon
                            sx={
                              open
                                ? { fontSize: '25px', marginRight: '10px', color: '#60A5DF' }
                                : {
                                    fontSize: '25px',
                                    marginRight: '10px'
                                  }
                            }
                          />
                          {'Фільтрувати'}
                          {open ? (
                            <ArrowDropUpOutlinedIcon sx={{ ml: 5 }} />
                          ) : (
                            <ArrowDropDownOutlinedIcon sx={{ ml: 5 }} />
                          )}
                        </div>
                      )}
                      <Menu
                        anchorEl={anchorEl}
                        id="scheduler-filter-menu"
                        open={open}
                        onClose={handleClose}
                        // onClick={handleClose}
                        PaperProps={{
                          elevation: 0,
                          sx: {
                            overflow: 'visible',

                            boxShadow: 'none',
                            borderRadius: '5px',
                            border: '1px solid black',
                            paddingTop: '10px',
                            paddingLeft: '20px',
                            paddingRight: '20px',
                            width: '250px',
                            mt: 1.5,
                            '&:before': {
                              content: '""',
                              display: 'block',
                              position: 'absolute',
                              top: 0,
                              right: 20,
                              width: 10,
                              height: 10,
                              borderTop: '1px solid black',
                              borderLeft: '1px solid black',
                              bgcolor: 'background.paper',
                              transform: 'translateY(-50%) rotate(45deg)',
                              zIndex: 0
                            }
                          }
                        }}
                        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                      >
                        <Grid container>
                          <Grid item xs={12}>
                            <Typography
                              style={{
                                fontWeight: 'bold',
                                fontFamily: 'Kyiv-Sans-Bold',
                                marginBottom: '10px'
                              }}
                            >
                              Статус замовлення
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    disableRipple
                                    checked={statuses.includes('NEW')}
                                    onChange={(e) => {
                                      if (e.target.checked) {
                                        setStatuses([...statuses, 'NEW']);
                                      }
                                      if (!e.target.checked) {
                                        setStatuses([...statuses.filter((el) => el !== 'NEW')]);
                                      }
                                    }}
                                  />
                                }
                                label="Нове"
                              />
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    disableRipple
                                    checked={statuses.includes('PROCESSING')}
                                    onChange={(e) => {
                                      if (e.target.checked) {
                                        setStatuses([...statuses, 'PROCESSING']);
                                      }
                                      if (!e.target.checked) {
                                        setStatuses([
                                          ...statuses.filter((el) => el !== 'PROCESSING')
                                        ]);
                                      }
                                    }}
                                  />
                                }
                                label="В обробці"
                              />
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    disableRipple
                                    checked={statuses.includes('SENT')}
                                    onChange={(e) => {
                                      if (e.target.checked) {
                                        setStatuses([...statuses, 'SENT']);
                                      }
                                      if (!e.target.checked) {
                                        setStatuses([...statuses.filter((el) => el !== 'SENT')]);
                                      }
                                    }}
                                  />
                                }
                                label="Відправлено"
                              />
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    disableRipple
                                    checked={statuses.includes('DONE')}
                                    onChange={(e) => {
                                      if (e.target.checked) {
                                        setStatuses([...statuses, 'DONE']);
                                      }
                                      if (!e.target.checked) {
                                        setStatuses([...statuses.filter((el) => el !== 'DONE')]);
                                      }
                                    }}
                                  />
                                }
                                label="Отримано"
                              />
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    disableRipple
                                    checked={statuses.includes('CANCELED')}
                                    onChange={(e) => {
                                      if (e.target.checked) {
                                        setStatuses([...statuses, 'CANCELED']);
                                      }
                                      if (!e.target.checked) {
                                        setStatuses([
                                          ...statuses.filter((el) => el !== 'CANCELED')
                                        ]);
                                      }
                                    }}
                                  />
                                }
                                label="Скасовано"
                              />
                            </FormGroup>
                          </Grid>

                          <Grid
                            item
                            xs={12}
                            sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}
                          >
                            <FreeBtn
                              padding="7px 20px"
                              onClick={() => {
                                params.set('status', statuses);
                                setParams(params);
                                handleClose();
                              }}
                              text="Застосувати фільтри"
                            />
                          </Grid>
                          {tabIdx === '1' && (
                            <Grid
                              item
                              xs={12}
                              sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                mt: 1,
                                mb: 1,
                                alignItems: 'center'
                              }}
                            >
                              <Typography
                                fontSize={14}
                                variant="body"
                                sx={{
                                  mb: 2,
                                  textDecoration: 'underline',
                                  fontWeight: '700',
                                  cursor: 'pointer',
                                  zIndex: '99',
                                  ':hover': {
                                    fontWeight: '1000'
                                  }
                                }}
                                onClick={() => {
                                  params.set('status', '');
                                  setParams(params);
                                  setStatuses([]);
                                }}
                              >
                                Очистити фільтри
                              </Typography>
                            </Grid>
                          )}
                        </Grid>

                        <img
                          style={{
                            position: 'absolute',
                            zIndex: '0',
                            bottom: '0px',
                            right: '-20px'
                          }}
                          src={starRed}
                          alt="star-red"
                        />
                      </Menu>
                      <Autocomplete
                        disableClearable
                        onKeyPress={(e) => {
                          e.preventDefault();
                        }}
                        options={['Спочатку нові', 'Спочатку старіші']}
                        sx={{ width: 200 }}
                        value={params.get('sd') === 'DESC' ? 'Спочатку нові' : 'Спочатку старіші'}
                        onChange={(event, value) => {
                          if (value === 'Спочатку нові') {
                            params.set('sd', 'DESC');
                            setParams(params);
                          } else {
                            params.set('sd', 'ASC');
                            setParams(params);
                          }
                        }}
                        renderInput={(params) => <TextField {...params} variant="outlined" />}
                      />
                    </Box>
                  </Box>

                  <Orders countSetter={setOrdersCounter} />
                </TabPanel>
                <TabPanel value="2">
                  <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <StyledTitle
                      maxWidth={800}
                      sx={{ textTransform: 'none' }}
                      className="titleCustom"
                    >
                      Список ваших покупок
                    </StyledTitle>
                    <Autocomplete
                      disableClearable
                      onKeyPress={(e) => {
                        e.preventDefault();
                      }}
                      options={['Спочатку нові', 'Спочатку старіші']}
                      sx={{ width: 200 }}
                      value={params.get('sd') === 'DESC' ? 'Спочатку нові' : 'Спочатку старіші'}
                      onChange={(event, value) => {
                        if (value === 'Спочатку нові') {
                          params.set('sd', 'DESC');
                          setParams(params);
                        } else {
                          params.set('sd', 'ASC');
                          setParams(params);
                        }
                      }}
                      renderInput={(params) => <TextField {...params} variant="outlined" />}
                    />
                  </Box>

                  <History />
                </TabPanel>
                <TabPanel value="3">
                  <Grid container spacing={1}>
                    <Grid item xs={7.5}>
                      <FullCalendar
                        ref={calendarRef}
                        events={distributedEvents}
                        locale="uk"
                        plugins={[dayGridPlugin, interactionPlugin, momentPlugin]}
                        titleFormat={'DD-MM-YYYY'}
                        headerToolbar={{
                          left: 'titleCustom',
                          center: '',
                          right: ''
                        }}
                        customButtons={{
                          titleCustom: {
                            text: 'Календар'
                          }
                        }}
                        moreLinkContent={(data) => `${data.num}${data.num > 4 ? '+' : ''}`}
                        moreLinkClick={(info) => {
                          handleDateSelect(info);
                          return 'test';
                        }}
                        moreLinkText={``}
                        dayMaxEventRows={0}
                        eventDrop={(info) => {
                          // const event = distributedEvents.find((ev) => ev.id === info.event.id);
                          // const newEvents = distributedEvents.filter(
                          //   (ev) => ev.id !== info.event.id
                          // );
                          // setDistributedEvents([
                          //   ...newEvents,
                          //   { ...event, date: info.event.startStr }
                          // ]);
                        }}
                        eventReceive={(info) => {
                          const today = new Date().setHours(0, 0, 0, 0);
                          const eventStart = info.event.start.setHours(0, 0, 0, 0);
                          const newEvent = {
                            ...info.event.extendedProps.eventInfo,
                            date: info.event.startStr
                          };
                          if (today <= eventStart) {
                            apiOrderChangeTime
                              .postDataQuery({
                                orderId: newEvent?.id,
                                date: moment(new Date(info.event.start)).format('YYYY-MM-DD')
                              })
                              .then((res) => {
                                const allEventsRefresh = distributedEvents.filter(
                                  (ev) => ev.id !== newEvent.id
                                );
                                setDistributedEvents([...allEventsRefresh, newEvent]);
                              })
                              .catch((error) =>
                                enqueueSnackbar('Сталася помилка', { variant: 'error' })
                              );
                          }
                          info.revert();
                        }}
                        droppable={true}
                        initialView="dayGridMonth"
                        editable={false}
                        selectable={true}
                        selectMirror={false}
                        weekends={state.weekendsVisible}
                        select={handleDateSelect}
                        eventContent={renderEventContent} // custom render function
                        eventClick={handleEventClick}
                      />
                    </Grid>
                    <Grid item xs={4.5}>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'flex-end',
                          justifyContent: 'space-between'
                        }}
                      >
                        <Typography variant="subtitle2" sx={{ mt: '90px', mb: '12px' }}>
                          {moment(selectedDay).format('DD-MM-YYYY')}
                        </Typography>
                        {todayEvents.length !== 0 && (
                          <Tooltip title="Завантажити в PDF" placement="top">
                            <LocalPrintshopOutlinedIcon
                              onClick={downloadPDF}
                              sx={{ color: '#60A5DF', mb: '12px', cursor: 'pointer' }}
                            />
                          </Tooltip>
                        )}
                      </Box>

                      <Box
                        id="external-events"
                        className="eventsListWrapper"
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          justifyContent: 'start',
                          border: '1px solid #60A5DF',
                          borderRadius: '5px',
                          // height: 'calc(600px - 50px)',
                          height: '550px',
                          p: 0.2,
                          backgroundColor: 'white',
                          overflow: 'scroll',
                          scrollbarWidth: 'none',
                          '&::-webkit-scrollbar': {
                            display: 'none'
                          }
                        }}
                      >
                        {todayEvents.length !== 0 ? (
                          todayEvents.map((ev, index) => (
                            <OrderDraggable key={`eventDrag${index}`} index={index} event={ev} />
                          ))
                        ) : (
                          <>
                            <Typography
                              sx={{
                                mt: 10,
                                mb: 4,
                                maxWidth: '300px',
                                fontSize: '16px',
                                textAlign: 'center'
                              }}
                              variant="body2"
                            >
                              Тут відображатимуться замовлення, які очікують визначення дати
                              виконання. Перетягніть кожне замовлення на відповідну дату в
                              календарі, щоб вказати планований виконавчий термін.
                            </Typography>
                            <img src={OrdersPng} alt="ordersIllustration" width={340} />
                          </>
                        )}
                      </Box>
                    </Grid>
                    {/* <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'column' }}>
                      <Typography variant="subtitle2" sx={{ mt: '20px', mb: '12px' }}>
                        {selectedDay}
                      </Typography>
                      <Box
                        sx={{
                          display: 'flex',
                          flexWrap: 'wrap',
                          alignItems: 'center',
                          justifyContent: 'start',
                          flexDirection: todayEvents.length === 0 ? 'column' : 'row',
                          border: '1px solid #60A5DF',
                          height: '100%',
                          backgroundColor: 'white',
                          p: 1,
                          maxHeight: '500px',
                          overflow: 'scroll',
                          scrollbarWidth: 'none',
                          '&::-webkit-scrollbar': {
                            display: 'none'
                          }
                        }}
                      >
                        {todayEvents.length !== 0 ? (
                          todayEvents.map((ev, index) => (
                            <TodayEvent
                              setTodayEvents={setTodayEvents}
                              todayEvents={todayEvents}
                              setDistributedEvents={setDistributedEvents}
                              distributedEvents={distributedEvents}
                              allEvents={allEvents}
                              setAllevents={setAllevents}
                              key={`todayEv${index}`}
                              event={ev}
                            />
                          ))
                        ) : (
                          <>
                            <StyledTitle
                              maxWidth={800}
                              sx={{
                                lineHeight: '58px !important',
                                textTransform: 'unset',
                                marginBottom: '10px !important',
                                fontSize: '32px !important'
                              }}
                              className="bottomBlock-title"
                            >
                              Тут порожньо
                            </StyledTitle>
                            <Typography sx={{ mt: 1, mb: 5 }} variant="body2">
                              Поки що нічого не заплановано
                            </Typography>
                          </>
                        )}
                      </Box>
                    </Grid> */}
                  </Grid>
                </TabPanel>
              </Grid>
            </TabContext>
          </Grid>
        ) : (
          <Spinner />
        )}
      </Stack>
    </Page>
  );
}
const mapStateToProps = (state) => {
  return {
    language: state.Intl.locale,
    userData: state.global.userData
  };
};

export default connect(mapStateToProps)(SchedulerPage);
