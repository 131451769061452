import React from 'react';
import { connect } from 'react-redux';
import Page from '../../components/Page';
// import { useNavigate } from 'react-router-dom';

//Material

// import ReplyIcon from '@mui/icons-material/Reply';
import { Grid, Typography } from '@mui/material';
import '../MainPage/index.css';
import { StyledTitle } from '../../components/StyledComponents';
import FreeTariffCard from '../../components/FreeTariffCard';
import TariffCard from '../../components/TariffCard';

// import { Spinner } from '../../components/Spinner';

function TariffPage(props) {
  return (
    <Page
      title="Тарифи"
      style={{
        display: 'flex',
        marginTop: '90px',
        alignItems: 'center',
        flexDirection: 'column',
        height: 'max-content',
        padding: '0px 100px'
      }}
    >
      <Grid
        container
        style={{
          maxWidth: '1440px',
          paddingTop: '65px'
        }}
        spacing={3}
      >
        <Grid item xs={12}>
          <StyledTitle
            className="bottomBlock-title"
            sx={{
              textTransform: 'none',
              fontSize: '32px !important',
              fontFamily: 'Kyiv-Sans-Regular !important'
            }}
          >
            Тарифні плани
          </StyledTitle>
        </Grid>
        <Grid item xs={12}>
          <FreeTariffCard />
        </Grid>
        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', m: '20px 0px' }}>
          <Typography
            variant="p"
            sx={{
              margin: '0px',
              wordBreak: 'break-word',
              fontFamily: 'Kyiv-Sans-Regular !important',
              fontSize: '28px',
              textAlign: 'left'
            }}
          >
            Більше можливостей, більше зручності
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <TariffCard type="base" />
        </Grid>
        <Grid item xs={3}>
          <TariffCard type="MONTH_PREMIUM" />
        </Grid>
        <Grid item xs={3} sx={{ position: 'relative' }}>
          <TariffCard type="THREE_MONTH_PREMIUM" />
        </Grid>
        <Grid item xs={3} sx={{ position: 'relative' }}>
          <TariffCard type="YEAR_PREMIUM" />
        </Grid>
      </Grid>
    </Page>
  );
}
const mapStateToProps = (state) => {
  return {
    language: state.Intl.locale,
    userData: state.global.userData
  };
};
export default connect(mapStateToProps)(TariffPage);
